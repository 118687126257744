import toast from 'react-hot-toast'

import { FvButton, ValidatedForm } from '@fv/client-components'
import { useFormModelState, validateEmailList } from '@fv/client-core'

import { Help } from '../../../components/Help'
import { InputGroup } from '../../../components/inputs/InputGroup'
import { useUpdateSubscriptionSettings } from './mutations'
import { useSubscriptionContextQuery } from './queries'

export const ReceiptEmailsForm = () => {
  const query = useSubscriptionContextQuery()
  const update = useUpdateSubscriptionSettings()
  const { register, value } = useFormModelState({
    initialValue: { emails: query.data.receiptEmails?.join(',') ?? '' },
    schema: {
      emails: {
        validate: val =>
          validateEmailList(val) ? '' : 'Please enter a valid list of emails',
      },
    },
  })

  const handleSubmit = async () => {
    try {
      await update.mutateAsync({
        receiptEmails: value.emails
          .split(',')
          .map(e => e.trim())
          .filter(e => !!e),
      })
      toast.success('Updated receipt emails')
    } catch (e) {
      toast.error('Unable to update receipt emails')
    }
  }
  return (
    <ValidatedForm onValidSubmit={handleSubmit}>
      <InputGroup
        inputType="text"
        label={
          <>
            <span>Email receipts to</span>
            <Help>
              Enter one or more email addresses (comma separated). We'll send
              them a receipt every time we process one of your payments.
            </Help>
          </>
        }
        inputProps={{
          ...register('emails'),
        }}
        endContent={
          <FvButton
            type="submit"
            theme="primary"
            icon="check"
            loading={update.isLoading}
          />
        }
      />
    </ValidatedForm>
  )
}
