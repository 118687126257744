import { type EquipmentTypeEnum } from '@fv/client-types'
import { type Workflow } from '@fv/models'

import {
  useAppSettings,
  useQuoteSettings,
  useUserSettings,
} from '../../../hooks/settings'
import { type UserPreferences } from './types'

const emptyEnums: EquipmentTypeEnum[] = []
const emptyPrefs: UserPreferences = {}

export function useAppPreferences() {
  const userSettingsQuery = useUserSettings()
  return userSettingsQuery.data?.preferences ?? emptyPrefs
}

export function useEquipmentTypeEnums(workflow: Workflow) {
  const appSettingsQuery = useAppSettings()
  const equipmentTypes = appSettingsQuery.data?.enums.equipmentTypes

  return (
    equipmentTypes?.filter(t => t.workflows.includes(workflow)) ?? emptyEnums
  )
}

export function useFavoriteEquipmentTypes() {
  const preferences = useAppPreferences()
  const settingsQuery = useQuoteSettings('truckload')

  if (preferences.tlEquipmentTypes?.length) {
    return preferences.tlEquipmentTypes
  }

  const options = settingsQuery.data?.equipmentOptions ?? []
  const defaultTypes = options.filter(e => e.favorite)

  return defaultTypes.map(e => e.key)
}
