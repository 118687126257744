import {
  type CarrierProvisioningDetails,
  type DetailedAccountProvisioningDto,
} from '@fv/client-types'
import { type AccountCarrierMode } from '@fv/models'

import { type AccountCarrierUpsertModel } from './types'

export const getCarrierModeDisplay = (
  override: string | undefined,
  mode: AccountCarrierMode,
) => {
  if (override) return override

  if (mode === 'tl') return 'Truckload'
  if (mode === 'parcel') return 'Parcel'
  return 'LTL'
}

export const toFormModel = (
  val: DetailedAccountProvisioningDto | undefined,
): AccountCarrierUpsertModel => ({
  carrierId: val?.carrierId ?? '',
  isOnboarding: false,
  details: val?.details ?? [],
  salesRepEmails: val?.salesReps?.map(e => e.email).join() ?? '',
  salesRepFirstName: val?.salesRepFirstName ?? '',
  salesRepLastName: val?.salesRepLastName ?? '',
  salesRepPhone: val?.salesRepPhone ?? '',
  enableLTL: val?.enableLTL ?? true,
  enableTL: val?.enableTL ?? true,
  enableParcel: val?.enableParcel ?? true,
})

export const fillModeDefaults = (
  model: AccountCarrierUpsertModel,
  carrier: CarrierProvisioningDetails,
): AccountCarrierUpsertModel => ({
  ...model,
  enableLTL: model.enableLTL ?? carrier.ltl.enabled,
  enableParcel: model.enableParcel ?? carrier.parcel.enabled,
  enableTL: model.enableTL ?? carrier.tl.enabled,
})
