import dayjs from 'dayjs'
import { setProperty } from 'dot-prop'
import { type FieldPath } from 'react-hook-form'
import toast from 'react-hot-toast'

import { downloadCSV } from '@fv/client-core'
import { type CSVRow } from '@fv/client-types'
import {
  type ContractedRate,
  type Country,
  CountryMapper,
  equipmentTypes,
  type ParsedFuelTableRow,
  states,
} from '@fv/models'
import { type IValidationErrorEntry, validateSync } from '@fv/models/validation'

import { fuelTableTemplate } from './templates'
import {
  type FlatContractedRate,
  flatContractedRateSchema,
  type FuelTableEntry,
} from './types'

const searchify = (s: string) => s.replace(/[^a-zA-Z0-9]+/g, '').toLowerCase()
const parsePrice = (s: string) => Number(s.replace(/[^0-9.]/g, ''))

const parsePostalCode = (value: string) => {
  let postalCode = value

  if (value.length === 6) {
    // Ensure Canadian postal codes contain a space
    postalCode = `${value.slice(0, 3)} ${value.slice(3)}`
  }
  return postalCode
}

export function parseRate(data: CSVRow): FlatContractedRate {
  const rateEntries = Object.entries(data).map<
    [FieldPath<FlatContractedRate> | undefined, unknown | undefined]
  >(([k, v]) => {
    const key: string = k.replace(/ /g, '')
    const value = v as string

    if (/date/i.test(key)) {
      if (/end/i.test(key)) return ['endDate', dayjs.utc(value).toDate()]
      if (/start/i.test(key)) return ['startDate', dayjs.utc(value).toDate()]
    }

    if (/dest/i.test(key)) {
      if (/codeend/i.test(key)) {
        return ['destinationPostalCodeEnd', parsePostalCode(value)]
      } else if (/code/i.test(key)) {
        return ['destinationPostalCode', parsePostalCode(value)]
      } else if (/state/i.test(key) && value) {
        return [
          'destinationState',
          states.find(s => s.abbreviation === value || s.name === value)
            ?.abbreviation,
        ]
      } else if (/country/i.test(key)) {
        const country = CountryMapper.legacyToCore(value)
        return ['destinationCountry', country]
      }
    }

    if (/distance/i.test(key)) {
      if (/unit/i.test(key)) {
        let uom = value.toLowerCase().slice(0, 2)
        if (uom.charAt(0) === 'k') uom = 'km'
        return ['distanceUOM', uom]
      }

      return ['distance', value ? Number(value) : undefined]
    }

    if (/equip/i.test(key)) {
      const providedTypes = value.split(',').map(t => t.trim())
      const parsedTypes = equipmentTypes
        .filter(t => providedTypes.some(p => searchify(p) === searchify(t.key)))
        .map(t => t.key)

      return ['equipmentTypes', parsedTypes]
    }

    if (/fuel/i.test(key)) {
      let fuelIncluded = value.toLowerCase()

      try {
        fuelIncluded = JSON.parse(fuelIncluded)
      } catch (e) {
        console.warn(`Invalid value for "fuelIncluded": "${fuelIncluded}"`)
      }

      return ['fuelIncluded', fuelIncluded]
    }

    if (/number/i.test(key)) {
      return ['contractNumber', value]
    }

    if (/minimum/i.test(key)) {
      return ['minimumRate', parsePrice(value)]
    }

    if (/origin/i.test(key)) {
      if (/code/i.test(key)) {
        return ['originPostalCode', parsePostalCode(value)]
      } else if (/state/i.test(key) && value) {
        return [
          'originState',
          states.find(s => s.abbreviation === value || s.name === value)
            ?.abbreviation,
        ]
      } else if (/country/i.test(key)) {
        const country = CountryMapper.legacyToCore(value)
        return ['originCountry', country]
      }
    }

    if (/rate/i.test(key)) {
      if (/currency/i.test(key)) {
        return ['rateCurrency', value.toLowerCase()]
      }
      if (/type/i.test(key)) {
        let rateType = value.replace(/-/g, '').toLocaleLowerCase()
        if (rateType === 'permile') rateType = 'per-mile'
        return ['rateType', rateType]
      }
      return ['rateAmount', parsePrice(value)]
    }

    if (/service/i.test(key)) {
      return ['serviceId', value.toLowerCase()]
    }
    return [undefined, undefined]
    // return [key, value]
  })

  const entry = {} as FlatContractedRate
  rateEntries
    .filter(([path]) => !!path)
    .forEach(([path, value]) => setProperty(entry, path as string, value))

  if (entry.originPostalCode && entry.originState) {
    delete entry.originState
  }

  if (entry.destinationPostalCode && entry.destinationState) {
    delete entry.destinationState
  }

  return entry
}

const defaultCountries: Country[] = ['us', 'ca']
const withMexico: Country[] = [...defaultCountries, 'mx']
const validateCountry = (
  country: Country,
  name: string,
  allowMexico: boolean,
): IValidationErrorEntry[] => {
  const errors: IValidationErrorEntry[] = []
  const countries = allowMexico ? withMexico : defaultCountries
  if (!countries.includes(country)) {
    errors.push({
      message: `${name} country must be ${countries.join(' or ')}`,
      propertyName: `${name}Country`,
    })
  }
  return errors
}

export function validateRate(
  rate: FlatContractedRate,
  hasFuelAndDistance: boolean,
  hasMexico: boolean,
) {
  if (!rate || Object.values(rate).every(v => !v)) {
    return []
  }

  const rateErrors: IValidationErrorEntry[] = validateSync(
    flatContractedRateSchema,
    rate,
  ).errors

  rateErrors.push(
    ...validateCountry(rate.destinationCountry, 'destination', hasMexico),
  )
  rateErrors.push(...validateCountry(rate.originCountry, 'origin', hasMexico))

  if (rate.rateType !== 'flat') {
    if (!hasFuelAndDistance) {
      rateErrors.push({
        message: `Rate type must be 'flat'`,
        propertyName: 'rateType',
      })
    } else if (rate.rateType !== 'per-mile') {
      rateErrors.push({
        message: `Rate type must be 'flat' or 'per-mile'`,
        propertyName: 'rateType',
      })
    }
  }

  return rateErrors
}

export function csvToFuelTableRow(data: CSVRow): ParsedFuelTableRow {
  const row: ParsedFuelTableRow = {}

  Object.entries(data).forEach(([k, v]) => {
    const key = k.replace(/\W/g, '')
    const value = v

    if (/lower|min|bottom|from/i.test(key)) {
      row.lowerBoundary = Number(value)
    } else if (/upper|max|top|to/i.test(key)) {
      row.upperBoundary = Number(value)
    } else if (/type/i.test(key)) {
      let type = value.replace(/\W/g, '')
      if (/permile/i.test(type)) type = 'per-mile'
      row.calculationType = type
    } else if (/currency/i.test(key)) {
      row.currency = value.toLowerCase()
    } else if (/amount|rate|value/i.test(key)) {
      row.rate = Number(value)
    }
  })

  return row
}

export function validateFuelTableRows(parsedRows: ParsedFuelTableRow[]) {
  const errors: string[] = []
  const rows: FuelTableEntry[] = []

  parsedRows.forEach((r, index) => {
    // Accounting for header row
    const row = `row ${index + 2}`
    const rowErrors: string[] = []

    // Defaults for empty or missing columns
    const calculationType = r.calculationType || 'per-mile'
    const currency = r.currency || 'usd'
    const lowerBoundary = r.lowerBoundary ?? -1
    const upperBoundary = r.upperBoundary ?? -1
    const rate = r.rate ?? -1

    if (
      typeof lowerBoundary !== 'number' ||
      isNaN(lowerBoundary) ||
      lowerBoundary < 0
    ) {
      rowErrors.push(`invalid lower boundary in ${row}`)
    }

    if (
      typeof upperBoundary !== 'number' ||
      isNaN(upperBoundary) ||
      upperBoundary < 0
    ) {
      rowErrors.push(`invalid upper boundary in ${row}`)
    }

    if (
      typeof lowerBoundary === 'number' &&
      typeof upperBoundary === 'number' &&
      !isNaN(lowerBoundary) &&
      !isNaN(upperBoundary) &&
      lowerBoundary > upperBoundary
    ) {
      rowErrors.push(
        `lower boundary must be less than upper boundary in ${row}`,
      )
    }

    if (calculationType !== 'per-mile') {
      rowErrors.push(`calculation type must be 'per mile' in ${row}`)
    }

    if (typeof rate !== 'number' || isNaN(rate) || rate < 0) {
      rowErrors.push(`invalid rate in ${row}`)
    }

    if (currency !== 'usd') {
      rowErrors.push(`currency must be 'usd' in ${row}`)
    }

    if (rowErrors.length) {
      errors.push(...rowErrors)
    } else {
      rows.push({
        calculationType: 'per-mile',
        currency: 'usd',
        lowerBoundary,
        rate,
        upperBoundary,
      })
    }
  })

  return { errors, rows }
}

export function exportFuelTable() {
  const csvData: string[] = []
  const rows = document.getElementsByClassName('fuel-table-row')

  if (!rows?.length) {
    toast.error('No fuel table found.')
    return
  }

  const headers = fuelTableTemplate[0]
  csvData.push(headers)

  Array.from(rows).forEach(r => {
    const csvRow: string[] = []

    Array.from(r.children).forEach(c => {
      let value = c.textContent ?? ''
      value = value.replace('$', '').toLocaleLowerCase()
      csvRow.push(value)
    })

    csvData.push(csvRow.join(','))
  })

  downloadCSV(csvData, 'FuelTable')
}

export const xOverflowShadow =
  'after:absolute after:w-px after:inset-y-0 after:bg-transparent'
export const xOverflowShadowRight =
  'after:left-0 after:shadow-[-.2rem_0px_.5rem_rgba(0,0,0,.45)]'
export const xOverflowShadowLeft =
  'after:right-0 after:shadow-[.2rem_0px_.5rem_rgba(0,0,0,.45)]'

export const rowHasError = (
  row: FlatContractedRate,
  hasFuelAndDistance: boolean,
  hasMexico: boolean,
) => {
  return validateRate(row, hasFuelAndDistance, hasMexico).length > 0
}

export const unflattenRates = (
  carrierId: string,
  rates: FlatContractedRate[],
): ContractedRate[] => {
  return rates
    .filter(rate => !Object.values(rate).every(v => !v))
    .map(rate => {
      const { output, errors } = validateSync(flatContractedRateSchema, rate)

      const {
        rateAmount,
        rateCurrency,
        rateType,
        minimumRate,
        originPostalCode,
        originState,
        originCountry,
        destinationPostalCode,
        destinationPostalCodeEnd,
        destinationState,
        destinationCountry,
        distance,
        distanceUOM,
        startDate,
        endDate,
        fuelIncluded,
        contractNumber,
        serviceId,
        equipmentTypes,
      } = output

      if (errors.length) {
        throw new Error(errors.map(e => e.message).join(', '))
      }

      // Create origin and destination location objects
      const origin: ContractedRate['origin'] = {
        country: originCountry,
        postalCode: originPostalCode,
        state: originState,
      }

      const destination: ContractedRate['destination'] = {
        country: destinationCountry,
        postalCode: destinationPostalCode,
        postalCodeEnd: destinationPostalCodeEnd,
        state: destinationState,
      }

      // Create rate detail object
      const rateDetail: ContractedRate['rate'] = {
        amount: rateAmount,
        currency: rateCurrency,
        rateType: rateType,
        serviceId: serviceId,
      }

      // Create the contracted rate
      const contractedRate: ContractedRate = {
        carrierId,
        contractNumber,
        destination,
        distance,
        distanceUOM,
        endDate: new Date(endDate).toISOString(),
        equipmentTypes,
        fuelIncluded,
        origin,
        rate: rateDetail,
        startDate: new Date(startDate).toISOString(),
        minimumRate,
      }

      return contractedRate
    })
}
