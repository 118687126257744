import { type FormSectionProps } from '../../components/inputs/types'
import { PillHeader } from '../../components/PillHeader'
import { getCustomsBrokerLocationContext } from '../../utils/shipmentFuncs'
import { useCarrierBookingFormSettings } from '../book/bookFuncs'
import { useBookingFormCtx } from '../book/BookingFormProvider'
import { CustomsBrokerForm } from './CustomsBrokerForm'

export const LTLBroker = ({ disabled }: FormSectionProps) => {
  const { setBrokerValues } = useBookingFormCtx(s => s.actions)
  const broker = useBookingFormCtx(s => s.customsBroker)
  const { required, location } = useBookingFormCtx(s =>
    getCustomsBrokerLocationContext(s.locations),
  )
  const requireValue =
    useCarrierBookingFormSettings()?.requireCustomsBrokerValue

  if (!location) return null
  return (
    <div className="border-fv-gray relative my-8 border bg-fv-gray-100 px-4 pb-2 pt-10">
      <PillHeader className="beer-container">Customs broker</PillHeader>
      <div className="form-row">
        <CustomsBrokerForm
          workflow="ltl"
          loadLocation={location}
          disabled={disabled}
          required={required}
          setValues={setBrokerValues}
          values={broker}
          requireValue={requireValue}
        />
      </div>
    </div>
  )
}
