import { type EmergencyContact } from '@fv/client-types'
import { type EmergencyContactDto } from '@fv/models'

// TODO VP remove & remove client type
export type FormEmergencyContact = EmergencyContact & {
  contractNumber: string
  phoneExt: string
}

export type EmergencyContactFormModel = EmergencyContactDto & {
  contractNumber: string
  phoneExt: string
}

export const buildEmergencyContactFormModel = (
  value: EmergencyContact,
): EmergencyContactFormModel => ({
  ...value,
  contractNumber: value.contractNumber || '',
  phoneExt: value.phoneExt || '',
})
