import { ObjectId } from '@freightview/object-id'
import sum from 'lodash/sum'
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useRef,
} from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { create, useStore } from 'zustand'

import { setPathValue, setPathValues, submitForm } from '@fv/client-core'
import { type FullShipment, type QuoteStopType } from '@fv/client-types'
import { type Workflow } from '@fv/models'

import InfoBox from '../../components/InfoBox'
import { useLoad } from '../../hooks/shipments'
import { routes } from '../../routes'
import {
  formStopsToLocationsDTO,
  sanitizeLocation,
} from '../../utils/locationFuncs'
import { requireRateLocation } from '../book/bookFuncs'
import {
  buildHandlingUnitDTO,
  unitIsHazardous,
  useLoadHandlingUnitFormModels,
} from '../commodities/load-items/loadItemHelpers'
import {
  defaultDestination,
  defaultOrigin,
} from '../shipment-location-form/types'
import { quoteRequestMapper, type QuoteRequestMode } from './quoteFuncs'
import {
  type BuildRequestArgs,
  type QuotePageActions,
  type QuotePageState,
} from './types'

const getInitialState = (): QuotePageState => ({
  load: {
    isFreightCollect: false,
    loadId: new ObjectId().toHexString(),
    protectFromFreezing: false,
    quoteRequestId: '',
    workflow: 'ltl',
    rateLocationType: 'origin',
  },
  locations: [defaultOrigin(), defaultDestination()],
  routeFormRef: null,
})

export function createQuotePageStore(props: {
  load?: FullShipment
  mode?: QuoteRequestMode
  workflow: Workflow
  createFreightCollect?: boolean
}) {
  const { load, mode, workflow } = props
  const initializeFromLoad = !!load && !!mode
  const initialState = getInitialState()
  return create<QuotePageActions & QuotePageState>((...store) => {
    const [set, get] = store
    return {
      ...initialState,
      load: {
        ...initialState.load,
        workflow,
        schedulePickup: workflow !== 'parcel' || !!load?.schedulePickup,
        ...(props.createFreightCollect && {
          isFreightCollect: true,
        }),
      },
      ...(initializeFromLoad &&
        quoteRequestMapper.ltlRequest({
          load,
          mode,
        })),
      actions: {
        buildRequest: ({ items }: BuildRequestArgs) => {
          const { actions, load, locations, deliveryOption } = get()
          const isValidRoute = actions.validateForm()
          if (!isValidRoute) return

          // TODO: turnable?
          const declaredValue = items.reduce(
            (value, item) => value + Number(item.declaredValue || 0),
            0,
          )
          const itemDtos = items.map(buildHandlingUnitDTO(workflow))
          return {
            ...(requireRateLocation(locations, load.isFreightCollect) && {
              rateLocationType: load.rateLocationType,
            }),
            isLiveLoad: true,
            locations: formStopsToLocationsDTO(locations),
            orderNumber: '',
            workflow: load.workflow,
            schedulePickup: load.schedulePickup,
            equipment: [
              {
                accessorials: load.protectFromFreezing
                  ? [{ key: 'protect-from-freezing' }]
                  : [],
                alternateTypes: [],
                ...(declaredValue && { declaredValue }),
                declaredValueCurrency: 'usd',
                deliveryOption,
                description: items[0].description?.trim() ?? '',
                isHazardous: items.some(unitIsHazardous),
                loadId: load.loadId,
                mode: load.workflow,
                type: load.workflow === 'truckload' ? 'ltl' : load.workflow, // TODO if we ever want truckload to use the same store
                weight: sum(itemDtos.map(i => i.weight)),
                weightUOM: 'lbs',
                items: itemDtos,
              },
            ],
          }
        },
        handleLoadChange: e => {
          set(prev => {
            const { name } = e.target
            const load = { ...prev.load }

            if (name === 'rateLocationType') {
              load[name] = e.target.value as QuoteStopType
            }

            if (name === 'isFreightCollect' || name === 'protectFromFreezing') {
              const checkbox = e.target as HTMLInputElement
              load[name] = checkbox.checked
            }

            if (name === 'schedulePickup') {
              const radio = e.target as HTMLInputElement
              load.schedulePickup = radio.value === 'true'
            }

            if (
              name === 'isFreightCollect' &&
              load.workflow === 'parcel' &&
              load.isFreightCollect
            ) {
              load.schedulePickup = false
            }

            return { load }
          })
        },
        setLocationData: (stopType, data) => {
          set(prev => {
            const { stopDate, ...location } = data
            const stopIx = stopType === 'origin' ? 0 : 1
            let nextStop = { ...prev.locations[stopIx] }
            setPathValues(nextStop, location)

            nextStop = sanitizeLocation(nextStop, workflow)

            if (stopDate || stopDate === null) {
              setPathValue(nextStop, 'stopDate', stopDate)
            }

            const load = { ...prev.load }
            const locations = prev.locations.map((x, ix) =>
              ix === stopIx ? nextStop : x,
            )

            return { load, locations }
          })
        },
        setFormRef: ref => {
          set({ routeFormRef: ref })
        },
        updateDeliveryOption(deliveryOption) {
          set(() => ({
            deliveryOption,
          }))
        },
        validateForm: () => {
          const formEl = get().routeFormRef
          const isValid = formEl?.checkValidity() ?? false

          if (!isValid) submitForm(formEl)
          return isValid
        },
      },
    }
  })
}

type QuotePageStore = ReturnType<typeof createQuotePageStore>
type Params = { loadId?: string; mode?: QuoteRequestMode }
type Props = PropsWithChildren<{ workflow: 'ltl' | 'parcel' }>
const QuotePageContext = createContext<QuotePageStore | null>(null)

export const QuotePageProvider = ({ children, workflow }: Props) => {
  const { loadId, mode } = useParams<Params>()
  const { search } = useLocation()
  const loadQuery = useLoad(loadId)
  const handlingUnitsQuery = useLoadHandlingUnitFormModels(loadQuery.data)
  const storeRef = useRef<QuotePageStore>()

  if (
    loadQuery.isError ||
    (loadQuery.isLoading && loadQuery.isFetching) ||
    handlingUnitsQuery.isLoading
  ) {
    return (
      <>
        <div className="ltl-quote-route-ctn b1200:pb-28">
          <InfoBox icon={loadQuery.isError ? 'exclamation-triangle' : 'sync'}>
            {loadQuery.isError ? 'Shipment not found.' : 'Loading quote...'}
          </InfoBox>

          {loadQuery.isError && (
            <Navigate to={routes.quote(workflow)} replace />
          )}
        </div>
      </>
    )
  }

  if (!storeRef.current) {
    const queryString = new URLSearchParams(search)
    storeRef.current = createQuotePageStore({
      load: loadQuery.data,
      workflow,
      mode,
      createFreightCollect: !!queryString.get('cr'),
    })
  }

  return (
    <QuotePageContext.Provider value={storeRef.current}>
      {children}
    </QuotePageContext.Provider>
  )
}

export function useQuotePageStore() {
  const store = useContext(QuotePageContext)

  if (!store) {
    throw new Error('Missing QuotePageContext.Provider in the tree')
  }
  return useStore(store)
}

export function useQuotePageCtx<T>(
  selector: (state: QuotePageActions & QuotePageState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const store = useContext(QuotePageContext)

  if (!store) {
    throw new Error('Missing QuotePageContext.Provider in the tree')
  }
  return useStore(store, selector, equalityFn)
}

export const useQuotePageWorkflow = () =>
  useQuotePageCtx(ctx => ctx.load.workflow)
