import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { SliderPanel } from '@fv/client-components'
import { type ProductMode } from '@fv/client-types'

import { Loading } from '../../../components/Loading'
import { TabLink, TabLinkList } from '../../../components/TabLink'
import { buildProductCatalogEntry } from '../../commodities/commodityUtils'
import {
  ProductCatalogList,
  ProductCatalogListSearch,
} from '../../commodities/ProductCatalog'
import { ProductCatalogForm } from '../../commodities/ProductCatalogForm'
import { useProduct } from '../../commodities/useHandlingUnits'
import { useProductCatalog } from '../../commodities/useProductCatalog'
import { useWorkflows } from '../../profiile/hooks'
import { SettingsPageLayout } from '../SettingsPageLayout'
import { ProductSettings } from './ProductSettings'

export const ProductCatalogPage = () => {
  const workflows = useWorkflows()
  const [searchParams, setSearchParam] = useSearchParams()
  const hasFreight = workflows.some(w => w === 'ltl' || w === 'truckload')
  const hasParcel = workflows.some(w => w === 'parcel')
  const defaultMode: ProductMode = hasFreight ? 'ltl' : 'parcel'
  const mode = (searchParams.get('mode') ?? defaultMode) as ProductMode
  const [showSettings, setShowSettings] = useState(false)
  const { setFilter, editingId, view, edit } = useProductCatalog()
  const editingQuery = useProduct(editingId)

  useEffect(() => {
    setFilter({ mode })
  }, [mode, setFilter])
  if (!searchParams.get('mode')) {
    setSearchParam({ mode: defaultMode })
  }
  return (
    <SettingsPageLayout className="pb-0">
      <div>
        <TabLinkList className="border-fv-gray mb-6">
          {hasFreight && (
            <TabLink icon="truck" isActive={mode === 'ltl'} to="?mode=ltl">
              LTL / Truckload
            </TabLink>
          )}
          {hasParcel && (
            <TabLink icon="box" isActive={mode === 'parcel'} to="?mode=parcel">
              Parcel
            </TabLink>
          )}
          <TabLink icon="cog" onClick={() => setShowSettings(true)}>
            Settings
          </TabLink>
          {/* <TabLink icon="upload" onClick={() => setSliderType('upload')}>
          Upload catalog
        </TabLink> */}
        </TabLinkList>
        <div className="mb-4">
          <ProductCatalogListSearch />
        </div>
      </div>
      <div className="-mx-8 flex-1 overflow-auto px-8 pb-8 pt-4">
        <ProductCatalogList inline />
      </div>
      <SliderPanel
        isOpen={showSettings}
        closePanel={() => setShowSettings(false)}
      >
        {showSettings && (
          <ProductSettings
            onCancel={() => setShowSettings(false)}
            onSave={() => setShowSettings(false)}
            mode={mode}
          />
        )}
      </SliderPanel>
      <SliderPanel
        isOpen={!!editingId || view === 'form'}
        closePanel={() => {
          edit(undefined)
        }}
      >
        {editingQuery.isLoading && !!editingId ? (
          <Loading />
        ) : (
          <ProductCatalogForm
            initialValues={
              editingQuery.data
                ? buildProductCatalogEntry(mode, editingQuery.data)
                : undefined
            }
            mode={mode}
          />
        )}
      </SliderPanel>
    </SettingsPageLayout>
  )
}
