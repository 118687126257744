import { type FullShipment } from '@fv/client-types'
import { type ShipperUserRole } from '@fv/models'

import { usePermissions } from '../../features/auth'
import { useRole } from '../../features/auth/hooks'
import { loadCanBeBooked } from '../../features/book/bookFuncs'
import {
  type BookingMode,
  type ReferrerType,
} from '../../features/booking/types'
import { routes } from '../../routes'

type MyShipment = Pick<
  FullShipment,
  'loadId' | 'quoteRequestId' | 'selectedQuote' | 'workflow' | 'status'
>

interface ComposeOpts {
  shipment: MyShipment
  referrer: ReferrerType
  mode?: BookingMode
  role: ShipperUserRole
}

export const composeEditShipmentUrl = ({
  shipment,
  referrer,
  mode,
  role,
}: ComposeOpts): string | undefined => {
  const { selectedQuote, loadId, quoteRequestId } = shipment
  if (!selectedQuote) return

  if (role === 'vendor') {
    if (loadCanBeBooked(shipment)) {
      return routes.vendorBook(loadId, selectedQuote._id)
    } else {
      return routes.editLoad(loadId)
    }
  }

  if (shipment.workflow === 'truckload') {
    return routes.bookTruckload({
      mode,
      quoteRequestId,
      referrer,
      selectedQuotes: { [loadId]: selectedQuote._id },
    })
  }

  return routes.book({
    loadId,
    quoteId: selectedQuote._id,
  })
}

export function useEditBookingPage(shipment?: MyShipment) {
  const { canBook } = usePermissions()
  const role = useRole()
  if (!shipment) {
    return { canEdit: false, composeEditLink: () => '' }
  }
  const { selectedQuote } = shipment
  const canEdit =
    !!selectedQuote?._id &&
    canBook &&
    shipment.status !== 'canceled' &&
    shipment.workflow !== 'parcel'
  const composeEditLink = (referrer?: ReferrerType, mode?: BookingMode) =>
    composeEditShipmentUrl({ shipment, referrer, mode, role })

  return {
    canEdit,
    composeEditLink,
  }
}
