import { emailRegExpMatcher, militaryToStandardTime } from '@fv/client-core'
import {
  type BaseClientAddress,
  type ClientAddressLookupResult,
} from '@fv/client-types'
import { type AddressUpsertDTO, CountryMapper, type Workflow } from '@fv/models'

import {
  mapAddressBookToShipmentLocationFormModel,
  type ShipmentLocationFormModel,
} from '../../features/shipment-location-form/types'
import { type FormAddress } from './types'

export type PartialLocation = Partial<
  Omit<BaseClientAddress, 'lat' | 'lng' | 'timezone'>
>
export type AddressWithCompany = PartialLocation & { company?: string }

export const addressMapper = {
  dtoToFormStop: (
    location: ClientAddressLookupResult | null,
    workflow: Workflow,
  ): Partial<ShipmentLocationFormModel> => {
    return mapAddressBookToShipmentLocationFormModel(location, workflow)
  },
  formAddressToDTO: (a: FormAddress): AddressUpsertDTO => {
    const adminArea3 = a.adminArea3?.trim()
    const contactEmail = a.contactEmail.trim()
    const contactName = a.contactName.trim()
    const contactPhone = a.contactPhone.trim()
    const contactPhoneExt = a.contactPhoneExt.trim()
    const opensAt = a.opensAt?.trim()
    const closesAt = a.closesAt?.trim()

    return {
      address: a.address.trim(),
      address2: a.address2.trim(),
      bolFollowers: a.bolFollowers.match(emailRegExpMatcher) || [],
      city: a.city.trim(),
      company: a.company.trim(),
      country: CountryMapper.coreToLegacy(a.country),
      instructions: a.instructions.trim(),
      lat: a.lat ?? 0,
      lng: a.lng ?? 0,
      mappedAccessorials: a.mappedAccessorials,
      postalCode: a.postalCode.trim(),
      sendBOL: !!a.sendBOL,
      shipType: a.shipType === '' ? 'business dock' : a.shipType,
      state: a.state.trim(),
      timezone: a.timezone ?? '',
      dockHoursStart: opensAt ? militaryToStandardTime(opensAt) : '',
      dockHoursEnd: closesAt ? militaryToStandardTime(closesAt) : '',
      ...(adminArea3 && { adminArea3 }),
      ...(contactEmail && { contactEmail }),
      ...(contactName && { contactName }),
      ...(contactPhone && { contactPhone }),
      ...(contactPhoneExt && { contactPhoneExt }),
    }
  },
}
