import { useMutation } from '@tanstack/react-query'

import { type FullShipment } from '@fv/client-types'
import { type UpsertLoadDTO } from '@fv/models'
import { type DTO } from '@fv/models/core'

import { shipperFetch } from '../../utils/shipperFetch'

const postShipment = async (model: UpsertLoadDTO | DTO<UpsertLoadDTO>) => {
  return await shipperFetch<FullShipment[]>('/shipments', {
    method: 'POST',
    body: model,
  })
}
export const useCreateShipment = () => {
  return useMutation(postShipment)
}

const putShipment =
  (loadId: string) => async (model: UpsertLoadDTO | DTO<UpsertLoadDTO>) => {
    return await shipperFetch<FullShipment>(`/shipments/${loadId}`, {
      method: 'PUT',
      body: model,
    })
  }
export const useUpdateShipment = (loadId: string) => {
  return useMutation(putShipment(loadId))
}
