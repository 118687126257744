import clsx from 'clsx'

import {
  type LegacyShipType,
  legacyShipTypes,
  type PropsWithClassName,
} from '@fv/client-types'
import { type LocationAccessorialDTO } from '@fv/models'

import { type ShipmentLocationFormModel } from '../../features/shipment-location-form/types'
import { InputGroup } from './InputGroup'

const options = [...legacyShipTypes]

type Model = Pick<ShipmentLocationFormModel, 'accessorials' | 'shipType'>

type Props = PropsWithClassName<{
  label?: string
  name: string
  required?: boolean
  showDetails?: boolean
  showLabels?: boolean
  value: Model
  onChange: (value: Model) => void
}>
export const ShipTypeSelector = ({
  className,
  label,
  name,
  required,
  showDetails,
  showLabels = true,
  value,
  onChange,
}: Props) => {
  const showBooth = value.shipType === 'trade show'
  const boothName =
    value.accessorials.find(a => a.key === 'tradeshow')?.tradeshowBooth ?? ''

  const handleShipTypeChange = (shipType: LegacyShipType) => {
    const accessorials = value.accessorials.filter(a => a.key !== 'tradeshow')
    if (shipType === 'trade show') {
      accessorials.push({ key: 'tradeshow' })
    }
    onChange({
      shipType,
      accessorials,
    })
  }

  const handleBoothChange = (booth: string) => {
    onChange({
      shipType: value.shipType,
      accessorials: value.accessorials.reduce<LocationAccessorialDTO[]>(
        (next, item) => {
          return [
            ...next,
            {
              ...item,
              ...(item.key === 'tradeshow' && {
                tradeshowBooth: booth,
              }),
            },
          ]
        },
        [],
      ),
    })
  }

  // TODO - if it's important that the parent element has control of the layout
  // we could require a grid class on the component and the selector would always take the first col
  // while the detials would take the second col.  This could allow the parent to specify the col widths.
  // for now - control will be left with this control and it'll be 75/25
  return (
    <div className={clsx('flex', className)}>
      <InputGroup
        className={clsx(showDetails && showBooth ? 'basis-3/4' : 'basis-full')}
        label={showLabels ? (label ?? 'Location type') : ''}
        inputType="select"
        required={required}
        inputProps={{
          options: options,
          required,
          name,
          value: value.shipType,
          onChange: e => {
            handleShipTypeChange(e.target.value as LegacyShipType)
          },
        }}
      />
      {showBooth && showDetails && (
        <InputGroup
          className="basis-1/4"
          label={showLabels ? 'Booth#' : ''}
          inputType="text"
          inputProps={{
            name: `${name}.booth`,
            onChange: e => handleBoothChange(e.target.value),
            value: boothName,
          }}
        />
      )}
    </div>
  )
}
