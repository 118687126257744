import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import { useMemo } from 'react'

import { useQuoteSettings } from '../../../hooks/settings'
import { useSpotQuoteContacts } from '../../spot-quote-contacts/queries'
import { type EquipmentTypeOption } from './types'

export function useCarrierOptions() {
  const contactsQuery = useSpotQuoteContacts()
  return {
    ...contactsQuery,
    data: sortBy(
      uniqBy(
        contactsQuery.data?.filter(c => !!c.carrierId) ?? [],
        c => c.carrierId,
      ).map(g => ({
        text: g.carrierName,
        value: g.carrierId,
      })),
      i => i.text,
    ),
  }
}

export function useEquipmentOptions(): EquipmentTypeOption[] {
  const settingsQuery = useQuoteSettings('truckload')

  return useMemo(() => {
    const types = settingsQuery.data?.equipmentOptions ?? []

    return types.map(t => ({
      label: t.name,
      value: t.key,
    }))
  }, [settingsQuery.data?.equipmentOptions])
}
