import { type PropsWithClassName } from '@fv/client-types'

import { useBookingForm } from '../booking/hooks'
import { ShipmentBolFormField } from '../shipment-form/ShipmentBolFormField'
import { useBookingFormCtx } from './BookingFormProvider'

export const LtlBookingBolField = ({ className }: PropsWithClassName) => {
  const bolNumber = useBookingFormCtx(s => s.bolNumber)
  const { updateBolNumber } = useBookingFormCtx(s => s.actions)
  return (
    <ShipmentBolFormField
      className={className}
      value={bolNumber ?? ''}
      onChange={updateBolNumber}
    />
  )
}
export const TLBookingBolField = ({ className }: PropsWithClassName) => {
  const { bolNumber, setBolNumber } = useBookingForm()
  return (
    <ShipmentBolFormField
      className={className}
      value={bolNumber ?? ''}
      onChange={setBolNumber}
    />
  )
}
