import { useCallback, useRef } from 'react'
import toast from 'react-hot-toast'

import { ValidatedForm } from '../../../components/inputs'
import CsvUploadField from './CsvUploadField'
import { type FlatContractedRate } from './types'
import { parseRate } from './utils'

interface UploadFormElements extends HTMLFormControlsCollection {
  carrierId: HTMLInputElement
  csvFile: HTMLInputElement
  fileName: HTMLInputElement
}

interface UploadFormEl extends HTMLFormElement {
  elements: UploadFormElements
}

type Props = {
  onSubmit: (rates: FlatContractedRate[]) => void
}

const UploadForm = ({ onSubmit }: Props) => {
  const formRef = useRef<UploadFormEl>(null)

  const getInputEl = useCallback(
    (name: 'carrierId' | 'csvFile' | 'fileName') => {
      if (!formRef.current?.elements) return
      return formRef.current.elements[name]
    },
    [],
  )

  const onValidSubmit = useCallback(async () => {
    const file = getInputEl('csvFile')?.files?.[0]
    if (!file) return

    try {
      const { parseCSVFile } = await import('../../../utils/parse')
      const data = await parseCSVFile(file)
      const parsedRates = data.map(parseRate)

      onSubmit(
        parsedRates.length < 10
          ? parsedRates.concat(
              [...Array(10 - parsedRates.length)].map(
                () => ({}) as FlatContractedRate,
              ),
            )
          : parsedRates,
      )
    } catch (e: any) {
      toast.error(e.message, {
        duration: 10000,
      })
    }
  }, [getInputEl, onSubmit])

  return (
    <>
      <ValidatedForm autoReportNextError={false} ref={formRef}>
        <CsvUploadField
          isBusy={false}
          resetForm={() => {
            formRef.current?.reset()
          }}
          onChange={onValidSubmit}
        />
      </ValidatedForm>
    </>
  )
}

export default UploadForm
