import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { AddressBookSlider } from '../features/addresses/AddressBook'
import { useRole } from '../features/auth/hooks'
import { SessionGate } from '../features/auth/SessionGate'
import { ProductCatalog } from '../features/commodities/ProductCatalog'
import { OnboardingProvider } from '../features/onboarding/OnboardingProvider'
import { ManualRatePanel } from '../features/quote/ManualRatePanel'
import { UserSetupPage } from '../features/user-setup/UserSetupPage'
import { useCurrentUser } from '../hooks/settings/useAppContext'
import { AppTooling } from './AppTooling'
import { Loading } from './Loading'
import { ShipperRoutes } from './ShipperRoutes'

const VendorWelcome = lazy(() => import('../features/vendor/VendorWelcome'))
// Extend dayjs here for use throughout the app (move to util?)
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
dayjs.extend(utc)

export const AuthenticatedApp = () => {
  return (
    <SessionGate>
      <AppWithSession />
    </SessionGate>
  )
}

const AppWithSession = () => {
  const user = useCurrentUser()
  const role = useRole()
  if (!user.registrationDate) {
    return <UserSetupGate />
  }
  if (role === 'vendor' && (!user.firstName || !user.lastName)) {
    return <VendorSetupGate />
  }
  return (
    <OnboardingProvider>
      <div className="App">
        <ShipperRoutes />
        <AddressBookSlider />
        <ManualRatePanel />
        <ProductCatalog />
        <AppTooling />
      </div>
    </OnboardingProvider>
  )
}

const UserSetupGate = () => {
  return (
    <Routes>
      <Route path="user-setup" element={<UserSetupPage />} />
      <Route path="*" element={<Navigate to="/user-setup" />} />
    </Routes>
  )
}

const VendorSetupGate = () => {
  return (
    <Routes>
      <Route
        path="vendor-welcome"
        element={
          <Suspense fallback={<Loading />}>
            <VendorWelcome />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/vendor-welcome" />} />
    </Routes>
  )
}
