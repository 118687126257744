import upperFirst from 'lodash/upperFirst'

import { FvLinkButton, PhoneNumberDisplay } from '@fv/client-components'
import {
  addressFuncs,
  displayLocShipType,
  getLocAccessorials,
  militaryToStandardTime,
} from '@fv/client-core'
import {
  type HoldAtLocation,
  type RefNum,
  type ShipmentLocation,
} from '@fv/client-types'
import { type Workflow } from '@fv/models'

import { type TerminalAndType } from '../../features/quote/useTerminals'
import { useTypeNames } from '../../hooks/settings'
import { getApplicableAccessorials } from '../../utils/locationFuncs'

type StopCardProps = {
  expanded: boolean
  isOrigin?: boolean
  location: ShipmentLocation
  toggleExpanded: () => void
  workflow: Workflow
  holdAtLocation?: HoldAtLocation
  terminal?: TerminalAndType
}

export const StopCard = ({
  expanded,
  isOrigin,
  location,
  toggleExpanded,
  workflow,
  holdAtLocation,
  terminal,
}: StopCardProps) => {
  const {
    address,
    address2,
    adminArea3,
    city,
    closesAt,
    company,
    contactEmail,
    contactName,
    contactPhone,
    contactPhoneExt,
    country,
    instructions,
    opensAt,
    postalCode,
    refNums,
    sequence,
    state,
    stopType,
    type,
    shipType,
  } = location

  const accessorials = getApplicableAccessorials(location, workflow)
  const shipTypeLabel = displayLocShipType({ shipType, accessorials })
  const typeNames = useTypeNames()
  const hasAccessorials = !!accessorials?.length
  const hasContactInfo = !!(contactEmail || contactName || contactPhone)
  const hasDockHours = !!(opensAt && closesAt)
  const hasRefNum = !!refNums?.length
  const stopName = type === 'stop' ? `stop ${sequence}` : type
  const stopTypeLabel =
    stopType === 'both' ? (isOrigin ? 'pickup' : 'delivery') : stopType

  const accessorialList = getLocAccessorials({ accessorials }, typeNames)
    ?.map(a => {
      let label = a.name
      if (
        !['sort-and-segregate', 'driver-assist', 'notify', 'schedule'].includes(
          a.key,
        ) &&
        stopType !== 'both'
      ) {
        label += ` ${stopType}`
      }

      if (a.appointmentTime) {
        label += ` (${a.appointmentTime})`
      }

      return label
    })
    .join(' / ')

  return (
    <div className={`shipment-details-route__${type} relative`}>
      <FvLinkButton
        className="shipment-details-route__more"
        icon="arrows-from-line"
        onClick={toggleExpanded}
      />
      <div className="shipment-details-route__header">
        {isOrigin ? 'From' : 'To'} {stopName}
      </div>
      <h3 className="mb-0">{company}</h3>
      <hr className="mx-0 my-2" />
      <p className="mb-0">
        {address}
        <br />
        {address2}
        {address2 && <br />}
        {adminArea3}
        {adminArea3 && <br />}
        {city}, {state} {postalCode} - {country.toLocaleUpperCase()}
        {hasRefNum && <br />}
        {displayRefNums({ isOrigin, refNums, workflow })}
        {!isOrigin && workflow === 'parcel' && holdAtLocation && (
          <span>
            <hr className="mx-0 my-2" />
            Hold at {holdAtLocation.inLocation}
            {holdAtLocation.inLocation && <br />}
            {holdAtLocation.locTypeDisplay}
            <br />
            {holdAtLocation.address}
            {holdAtLocation.address2 && <br />}
            {address2}
            <br />
            {holdAtLocation.city}, {holdAtLocation.state}{' '}
            {holdAtLocation.postalCode} - {holdAtLocation.country}
          </span>
        )}
        {expanded && (
          <span>
            <hr className="mx-0 my-2" />
            {hasDockHours && `${upperFirst(stopTypeLabel)} hours `}
            {hasDockHours && militaryToStandardTime(opensAt)}
            {hasDockHours && ' - '}
            {hasDockHours && militaryToStandardTime(closesAt)}
            {!hasDockHours && `No ${stopTypeLabel} hours given`}
            {(hasAccessorials || shipTypeLabel) && <hr className="mx-0 my-2" />}
            {shipTypeLabel && shipTypeLabel}
            {shipTypeLabel && <br />}
            {accessorialList}
            {hasContactInfo && <hr className="mx-0 my-2" />}
            {contactName}
            {contactName && <br />}
            {contactPhone && (
              <PhoneNumberDisplay val={contactPhone} ext={contactPhoneExt} />
            )}
            {contactPhone && <br />}
            {contactEmail}
            {instructions && <hr className="mx-0 my-2" />}
            {instructions}
            {terminal && (
              <>
                <hr className="mx-0 my-2" />
                <p>
                  {terminal.terminalCode
                    ? `Terminal code: ${terminal.terminalCode}`
                    : ''}
                  <br /> {terminal.address} <br />{' '}
                  {addressFuncs.cityStateZip(terminal)}
                  <br />
                  <PhoneNumberDisplay val={terminal.contactPhone} />
                </p>
              </>
            )}
          </span>
        )}
      </p>
    </div>
  )
}

function displayRefNums({
  isOrigin,
  refNums,
  workflow,
}: {
  isOrigin?: boolean
  refNums?: RefNum[]
  workflow: Workflow
}) {
  if (!refNums?.length) return null

  const label =
    workflow === 'truckload'
      ? 'Ref #'
      : isOrigin
        ? "Shipper's #"
        : 'Purchase order #'

  return `${label} ${refNums.map(n => n.value).join(' / ')}`
}
