import { type ChangeEvent, useCallback, useRef, useState } from 'react'

import { FvButton, Icon, ValidatedForm } from '@fv/client-components'
import {
  useFormModelState,
  validateEmailString,
  waitForStateUpdate,
} from '@fv/client-core'
import { type UserPermissionType, type Workflow } from '@fv/models'
import { toggleArrayItem } from '@fv/models/core'

import { ActionListItem } from '../../../components/ActionList'
import { FormActions } from '../../../components/forms/FormActions'
import CheckboxField from '../../../components/inputs/CheckboxField'
import { InputGroup } from '../../../components/inputs/InputGroup'
import { List, ListItem, ListItemActions } from '../../../components/List'
import {
  SliderPanelFooter,
  SliderPanelLayout,
} from '../../../components/SliderPanelLayout'
import { usePreferredWorkflows } from '../../profiile/hooks'
import { useUpsertVendor } from './mutations'
import { type VendorFormModel, type VendorWithUsersDTO } from './types'

type Props = {
  existingVendor?: VendorWithUsersDTO
  onSaved?: () => void
  onCancel?: () => void
}

const vendorPermissions: Array<{
  permission: UserPermissionType
  display: string
}> = [
  { permission: 'view-rates', display: 'Show rates' },
  { permission: 'book', display: 'Allow booking' },
]

const workflowLabels: Record<Workflow, string> = {
  parcel: 'Parcel',
  ltl: 'LTL',
  truckload: 'Truckload',
}

export const VendorForm = ({ existingVendor, onSaved, onCancel }: Props) => {
  const preferredWorkflows = usePreferredWorkflows().filter(
    w => w !== 'truckload',
  )
  const [userEmails, setUserEmails] = useState<string[]>(
    existingVendor?.users?.map(user => user.email) || [],
  )
  const emailRef = useRef<HTMLInputElement | null>(null)
  const [currentEmail, setCurrentEmail] = useState('')

  const {
    register,
    value: model,
    setValue: setModel,
  } = useFormModelState<VendorFormModel>({
    initialValue: {
      workflows: existingVendor?.workflows ?? preferredWorkflows,
      name: existingVendor?.name ?? '',
      permissions: existingVendor?.permissions ?? ['view-rates', 'book'],
      users: existingVendor?.users?.map(u => u.email) ?? [],
    },
  })

  const upsertVendor = useUpsertVendor(existingVendor?._id)

  const handleAddEmail = useCallback(() => {
    emailRef.current?.setCustomValidity('')
    if (!currentEmail) {
      reportEmailError('Please enter an email')
      return false
    }

    if (!validateEmailString(currentEmail)) {
      reportEmailError('Please enter a valid email')
      return false
    }

    if (userEmails.includes(currentEmail)) {
      reportEmailError('This email has already been added')
      return false
    }

    const updatedEmails = [...userEmails, currentEmail]
    setUserEmails(updatedEmails)
    setCurrentEmail('')
    return updatedEmails
  }, [currentEmail, userEmails])

  const handleSubmit = useCallback(async () => {
    let emailsDto = userEmails
    if (
      currentEmail.length &&
      window.confirm(
        `${currentEmail} has not been added.  Would you like to add them?`,
      )
    ) {
      const updatedEmails = await waitForStateUpdate(() => handleAddEmail())
      if (updatedEmails === false) return // validation failed
      emailsDto = updatedEmails
    }
    await upsertVendor.mutateAsync({
      ...model,
      users: emailsDto,
    })
    onSaved?.()
  }, [model, userEmails, currentEmail, upsertVendor, onSaved, handleAddEmail])

  const reportEmailError = (error: string) => {
    emailRef.current?.setCustomValidity(error)
    emailRef.current?.reportValidity()
  }

  const handleRemoveEmail = (email: string) => {
    setUserEmails(userEmails.filter(e => e !== email))
  }

  const createPermissionHandler =
    (permission: UserPermissionType) => (e: ChangeEvent<HTMLInputElement>) => {
      setModel(prev => {
        return {
          ...prev,
          permissions: toggleArrayItem(
            prev.permissions,
            permission,
            e.target.checked,
          ),
        }
      })
    }

  const createWorkflowHandler =
    (workflow: Workflow) => (e: ChangeEvent<HTMLInputElement>) => {
      setModel(prev => {
        return {
          ...prev,
          workflows: toggleArrayItem(
            prev.workflows,
            workflow,
            e.target.checked,
          ),
        }
      })
    }

  return (
    <SliderPanelLayout
      title={existingVendor ? 'Edit vendor' : 'Add a vendor'}
      titleIcon="store-alt"
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center">
          <InputGroup
            inputType="text"
            label="Vendor name"
            className="w-96"
            inputProps={{
              ...register('name', {
                required: () => true,
              }),
            }}
          />
        </div>
        <div className="flex gap-x-4 mb-2">
          {preferredWorkflows.map(w => (
            <CheckboxField
              key={w}
              name={w}
              onChange={createWorkflowHandler(w)}
              label={`Has ${workflowLabels[w]}`}
              checked={model.workflows.includes(w)}
            />
          ))}
          {vendorPermissions.map(({ permission, display }) => (
            <CheckboxField
              key={permission}
              name={permission}
              onChange={createPermissionHandler(permission)}
              label={display}
              checked={model.permissions.includes(permission)}
            />
          ))}
        </div>
        <div>
          <h6 className="border-t border-dashed py-2 mt-2 mb-4 border-fv-gray border-b">
            <Icon icon="user" className="text-inherit" fixedWidth />
            <span>Add vendor users</span>
          </h6>
          <ValidatedForm
            onValidSubmit={handleAddEmail}
            className="flex items-center gap-x-2"
          >
            <InputGroup
              className="w-96"
              inputType="text"
              label="User email"
              inputProps={{
                ref: emailRef,
                name: 'vendor-user-email',
                value: currentEmail,
                onChange: e => setCurrentEmail(e.target.value),
              }}
            />
            <div className="flex-1 border-t h-px border-dashed border-fv-gray mt-2" />
            <FvButton icon="plus" className="mt-2">
              Add user
            </FvButton>
          </ValidatedForm>
          <List className="overflow-auto -mx-6 px-6">
            {userEmails.map(e => (
              <ListItem
                key={e}
                className="flex-wrap"
                actions={
                  <ListItemActions>
                    <ActionListItem
                      iconClass="text-fv-orange"
                      icon="trash"
                      title="Remove"
                      onClick={() => handleRemoveEmail(e)}
                    />
                  </ListItemActions>
                }
              >
                <div>{e}</div>
              </ListItem>
            ))}
          </List>
        </div>

        <SliderPanelFooter>
          <FormActions
            loading={upsertVendor.isLoading}
            submitText={existingVendor ? 'Save' : 'Create vendor'}
            onCancel={onCancel}
            onSubmit={handleSubmit}
            jstart
          />
        </SliderPanelFooter>
      </div>
    </SliderPanelLayout>
  )
}
