import { lazy, Suspense, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  FvLinkButton,
  SelectField,
  SliderPanel,
  type SliderPanelProps,
} from '@fv/client-components'

import InfoBox from '../../../components/InfoBox'
import { TabLink, TabLinkList } from '../../../components/TabLink'
import FuelTable from './FuelTable'
import { useCarrierOptions } from './hooks'
import { useFuelTable } from './queries'

const FuelTableUpload = lazy(() => import('./FuelTableUpload'))

const message =
  'Freightview requires the .CSV file to be in a specific structure. Please download the example file for the proper upload format.'

type Props = SliderPanelProps

type FuelTableMode = 'carrier' | 'account'

export const FuelTableSlider = (props: Props) => {
  const carriers = useCarrierOptions()

  const [searchParams] = useSearchParams()
  const [mode, setMode] = useState<FuelTableMode>('account')

  const [carrierId, setCarrierId] = useState<string | null>(null)
  const fuelTableQuery = useFuelTable(mode === 'carrier' ? carrierId : null)

  useEffect(() => {
    if (!carrierId) {
      setCarrierId(carriers.data?.[0]?.value)
    }
  }, [carriers, carrierId])

  useEffect(() => {
    setCarrierId(searchParams.get('carrierId') || '')
    setMode(searchParams.get('carrierId') ? 'carrier' : 'account')
  }, [searchParams])

  return (
    <SliderPanel isOpen width="50vw" {...props}>
      <div className="flex items-start">
        <TabLinkList>
          <TabLink
            icon="gas-pump"
            isActive={mode === 'account'}
            onClick={() => {
              setMode('account')
            }}
          >
            Account fuel table
          </TabLink>
          <TabLink
            icon="truck"
            isActive={mode === 'carrier'}
            onClick={() => {
              setMode('carrier')
            }}
          >
            Carrier fuel table
          </TabLink>
        </TabLinkList>
      </div>

      <hr />

      {mode === 'carrier' && (
        <>
          <div className="flex gap-x-2 mb-4 items-end">
            <div className="flex-1">
              <label className="label" htmlFor="carrierId">
                Select a carrier
              </label>
              <SelectField
                name="carrierId"
                required
                options={carriers.data}
                onChange={c => setCarrierId(c.target.value)}
                value={carrierId}
                className="form-control form-control--select"
              />
            </div>
          </div>

          <FuelTable
            carrierId={carrierId}
            className="mt-6 border border-fv-gray"
            emptyMessage={
              <>
                This carrier is currently using your{' '}
                <FvLinkButton
                  theme="underlined"
                  onClick={() => setMode('account')}
                >
                  account fuel table
                </FvLinkButton>
                . Below, you can upload a separate fuel table specifically for
                this carrier.
              </>
            }
          />
          {fuelTableQuery.data?.carrierId !== carrierId && (
            <Suspense fallback={<InfoBox icon="sync">Loading...</InfoBox>}>
              <FuelTableUpload carrierId={carrierId} message={message} />
            </Suspense>
          )}
        </>
      )}
      {mode === 'account' && (
        <>
          <p>
            This is your account-level fuel table. It will be used to calculate
            fuel-costs for each contracted rate of type per-mile. You can
            override fuel tables for specific carriers by uploading a{' '}
            <FvLinkButton theme="underlined" onClick={() => setMode('carrier')}>
              carrier fuel table
            </FvLinkButton>
            .
          </p>
          <FuelTable carrierId={null} className="mt-6 border border-fv-gray" />
          {!fuelTableQuery.data?.rows.length && (
            <Suspense fallback={<InfoBox icon="sync">Loading...</InfoBox>}>
              <FuelTableUpload
                carrierId={mode === 'account' ? null : carrierId}
                message={message}
              />
            </Suspense>
          )}
        </>
      )}
    </SliderPanel>
  )
}
