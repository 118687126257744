import { isHolidayOrWeekend } from '@freightview/holidays'
import { shallow } from 'zustand/shallow'

import { type UIQuote } from '@fv/client-types'
import { type LocationType, type Workflow } from '@fv/models'
import { hasMixedPallets, type SlimLoadItem } from '@fv/models/core'

import { useUserSettings } from '../../hooks/settings'
import { useCarrierPublicSettings } from '../../hooks/useCarrierPublicSettings'
import { originStopDate } from '../../utils/shipmentFuncs'
import { quoteFuncs } from '../quote/quoteFuncs'
import { useBookingFormCtx } from './BookingFormProvider'
import { useCustomerRoutedCarriers } from './useCustomerRoutedCarriers'

interface CanSchedulePickupProps {
  isFreightCollect?: boolean
  workflow: Workflow
  locations: Array<{ type: LocationType; stopDate?: Date }>
  items: SlimLoadItem[]
  customerRoutedCarrierId?: string
  selectedQuote?: UIQuote
}

export function useCanSchedulePickup({
  isFreightCollect,
  workflow,
  locations,
  items,
  customerRoutedCarrierId: carrierId,
  selectedQuote,
}: CanSchedulePickupProps) {
  const settingsQuery = useUserSettings()
  const accountCarriers = settingsQuery.data?.carriers ?? []
  const { data: crData } = useCustomerRoutedCarriers(workflow)
  const quoteCarrierSettings = useCarrierPublicSettings(
    selectedQuote?.carrierId,
  ).carrierPublicSettings
  const crCarrierSettings = useCarrierPublicSettings(carrierId)
  const carrier = crData?.carriers?.find(c => c.id === carrierId)

  const pickupDate = originStopDate({
    locations: locations.map(loc => ({
      type: loc.type,
      stopDate: loc.stopDate?.toISOString(),
    })),
  })

  const provisionedCarrier = accountCarriers.find(
    c => c._id === carrierId || c._id === selectedQuote?.carrierId,
  )

  if (quoteFuncs.isSpotWorkflow(selectedQuote)) return true
  if (hasMixedPallets(items)) return false

  const carrierDispatchDisabled =
    quoteCarrierSettings?.dispatchMethod === 'disabled'
  if (carrierDispatchDisabled) {
    return false
  }
  const isManualQuote = !selectedQuote || selectedQuote.method === 'manual'
  if (
    !isFreightCollect &&
    isManualQuote &&
    (provisionedCarrier?.status !== 'visible' ||
      quoteCarrierSettings?.status !== 'active')
  ) {
    return false
  }
  if (
    isFreightCollect &&
    (carrier?.status !== 'active' ||
      crCarrierSettings?.carrierPublicSettings?.dispatchMethod === 'disabled')
  ) {
    return false
  }

  return !isHolidayOrWeekend(pickupDate)
}

export function useBookingFormCanSchedulePickup() {
  const isFreightCollect = useBookingFormCtx(s => s.isFreightCollect)
  const workflow = useBookingFormCtx(s => s.workflow)
  const locations = useBookingFormCtx(s => s.locations)
  const items = useBookingFormCtx(s => s.items)
  const { carrierId, selectedQuote } = useBookingFormCtx(
    s => ({
      carrierId: s.customerRoutedDetail.carrier?.id,
      selectedQuote: s.quote,
    }),
    shallow,
  )

  return useCanSchedulePickup({
    isFreightCollect,
    workflow,
    locations,
    items,
    customerRoutedCarrierId: carrierId,
    selectedQuote,
  })
}
