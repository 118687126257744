import { useToggle } from 'usehooks-ts'

import { FvDate, FvLinkButton } from '@fv/client-components'
import { type Workflow } from '@fv/models'

import { RouteStopDate } from '../../components/QuoteRequest/RouteStopDate'
import { type ShipmentLocationFormModel } from './types'

type PillDateEditProps = {
  allowDateEdit: true
  allLocations: ShipmentLocationFormModel[]
}
type PillNoDateEditProps = {
  allowDateEdit?: false
}

export type PillDateProps = PillDateEditProps | PillNoDateEditProps

type Props = PillDateProps & {
  workflow: Workflow
  value: ShipmentLocationFormModel
  onChange: (value: ShipmentLocationFormModel) => void
}
export const ShipmentLocationPillHeader = (props: Props) => {
  const { value, onChange, workflow, allowDateEdit } = props
  const [open, toggleOpen] = useToggle()
  const prefix = value.stopType === 'pickup' ? 'Pickup' : 'Deliver'
  const when = value.stopDate ? (value.stopDateType === 'by' ? 'by' : 'on') : ''
  const canChange =
    allowDateEdit && (workflow === 'truckload' || value.type === 'origin')
  return (
    <>
      <div className="divided-content divided-content--start divided-content--end">
        <span>
          {prefix}{' '}
          {canChange && (
            <>
              {when} <FvDate val={value.stopDate} />
            </>
          )}
        </span>
        {canChange && <FvLinkButton onClick={toggleOpen} icon="pen-alt" />}
      </div>
      {allowDateEdit && (
        <RouteStopDate
          isOpen={open}
          showStopDetailConfig={workflow === 'truckload'}
          stops={props.allLocations}
          onClose={toggleOpen}
          sequence={value.sequence}
          onChange={v => onChange({ ...value, stopDate: v.stopDate })}
        />
      )}
    </>
  )
}
