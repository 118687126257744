import { useMutation } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { useFvNavigate } from '@fv/client-components'
import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type ListShipment } from '@fv/client-types'

import { apiUri, supportMessage } from '../../constants'
import { routes } from '../../routes'
import { useCarrierBookSettings } from '../settings'
import { useRemoveFromList, useUpdateCachedLoad } from '.'

export type AwardChangeReason = 'carrier-withdraw' | 'shipper-retract'

async function retenderLoad(dto: {
  loadId: string
  quoteId: string
  reason: AwardChangeReason | null
}): Promise<ListShipment> {
  const { loadId, quoteId, reason } = dto
  const endpoint = `${apiUri}/shipments/${loadId}/retender`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      quoteId,
      ...(reason && { reason }),
    }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json // updatedLoad
  throw response.errorMessage
}

export function useRetenderLoad() {
  const removeFromList = useRemoveFromList()
  const updateCachedLoad = useUpdateCachedLoad()

  return useMutation(retenderLoad, {
    onSuccess: updatedLoad => {
      removeFromList({
        loadId: updatedLoad.loadId,
        queryFilter: ['shipments', 'confirmed'],
      })

      updateCachedLoad({ updatedLoad })
      toast.success('Load retendered successfully.')
    },
  })
}

type RetenderOpts = {
  carrierId?: string
  quoteId: string
  reason: AwardChangeReason | null
}

export function useRetenderLoadToNewCarrier({
  hasBOLDetails,
  loadId,
  quoteRequestId,
}: {
  hasBOLDetails: boolean
  loadId: string
  quoteRequestId: string
}) {
  const [opts, setOpts] = useState<RetenderOpts | null>(null)
  const mutation = useRetenderLoad()
  const navigate = useFvNavigate()
  const settingsQuery = useCarrierBookSettings(
    'truckload',
    opts?.carrierId && !hasBOLDetails ? [opts.carrierId] : [],
  )

  const retender = useCallback(
    (quoteId: string, reason: AwardChangeReason | null) => {
      mutation
        .mutateAsync({
          loadId,
          quoteId,
          reason,
        })
        .catch(e => {
          toast.error(
            e?.message ?? `Unable to retender load, ${supportMessage}`,
          )
        })
        .finally(() => {
          setOpts(null)
        })
    },
    [loadId, mutation],
  )

  useEffect(() => {
    if (
      !opts?.carrierId ||
      !opts?.quoteId ||
      hasBOLDetails ||
      mutation.isLoading ||
      mutation.isSuccess ||
      settingsQuery.isLoading
    ) {
      return
    }

    if (settingsQuery.data.requiresFullBOLDetails) {
      navigate(
        routes.bookTruckload({
          mode: 'retender',
          quoteRequestId,
          reason: opts.reason,
          selectedQuotes: { [loadId]: opts.quoteId },
        }),
      )
    } else {
      retender(opts.quoteId, opts.reason)
    }
  }, [
    hasBOLDetails,
    loadId,
    mutation.isLoading,
    mutation.isSuccess,
    navigate,
    opts,
    quoteRequestId,
    retender,
    settingsQuery,
  ])

  const mutate = useCallback(
    (opts: RetenderOpts) => {
      if (hasBOLDetails) {
        retender(opts.quoteId, opts.reason)
      } else {
        setOpts(opts)
      }
    },
    [hasBOLDetails, retender],
  )

  return {
    mutate,
    isLoading: settingsQuery.isLoading || mutation.isLoading,
    requireReason: (load: Pick<ListShipment, 'selectedQuote' | 'status'>) => {
      return (
        !!load.selectedQuote &&
        (load.status === 'awarded' || load.status === 'confirmed')
      )
    },
  }
}
