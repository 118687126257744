import { type ChargeModel, generateCharge } from '@fv/client-core'
import { type Quote } from '@fv/client-types'
import { type UpdateQuoteDTO } from '@fv/models'

export type QuoteEditModel = Omit<UpdateQuoteDTO, 'charges'> & {
  charges: ChargeModel[]
}

export const createQuoteEditModel = (
  quote: Partial<Quote>,
): QuoteEditModel => ({
  amount: quote.amount,
  quoteId: quote._id,
  charges: quote.charges?.map(c => generateCharge(c)),
  currency: quote.currency,
})
