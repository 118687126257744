import { ShipmentFormSection } from '../shipment-form/ShipmentFormSection'
import { type EmergencyContactFormModel } from './contactTypes'
import { EmergencyContactForm } from './EmergencyContactForm'

type Props = {
  disabled?: boolean
  required?: boolean
  value: EmergencyContactFormModel
  onChange: (value: EmergencyContactFormModel) => void
}
export const EmergencyContactFormSection = ({
  disabled,
  value,
  onChange,
  required,
}: Props) => {
  return (
    <ShipmentFormSection
      title="Emergency"
      icon="radiation-alt"
      pillClassName="beer-container !gap-x-0"
      iconClassName="text-fv-orange"
      className="gray-container"
    >
      <div className="form-row">
        <EmergencyContactForm
          disabled={disabled}
          required={required}
          setValues={v => onChange({ ...value, ...v })}
          values={value}
        />
      </div>
    </ShipmentFormSection>
  )
}
