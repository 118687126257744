import pluralize from 'pluralize'
import { useState } from 'react'
import toast from 'react-hot-toast'

import { FvButton } from '@fv/client-components'

import { DayPickerPanel } from '../../dates/DayPickerPanel'
import { useExtendContractedRates } from './mutations'

type Props = {
  rates: string[]
  onExtend?: () => void
}

export const ExtendContractedRates = ({ rates, onExtend }: Props) => {
  const [sliderOpen, setSlider] = useState(false)
  const extendContractedRates = useExtendContractedRates()

  function reset() {
    setSlider(false)
  }

  function saveDates(endDate?: Date) {
    if (!endDate) {
      toast.error('No date selected.')
      return
    }

    const confirmed = window.confirm(
      `Are you sure you want to update the expiration date on ${pluralize(
        'rate',
        rates?.length,
        true,
      )}?`,
    )
    if (!confirmed) return

    extendContractedRates.mutate(
      { endDate, rates },
      {
        onSuccess: () => {
          onExtend?.()
          reset()
        },
      },
    )
  }

  return (
    <>
      <FvButton
        onClick={e => {
          setSlider(true)
        }}
        theme="default"
        icon="calendar-day"
        transform="up-.5"
      >
        Extend selected contract dates
      </FvButton>
      <DayPickerPanel
        mode="single"
        numberOfMonths={2}
        open={sliderOpen}
        onClose={() => setSlider(false)}
        onCancel={reset}
        isSaving={extendContractedRates.isLoading}
        saveText="Update"
        onSave={saveDates}
        titleContent={() => (
          <>
            Please select your new contract end date.
            <hr className="my-6" />
          </>
        )}
      />
    </>
  )
}
