import { type PropsWithChildren } from 'react'

import { type ShipperUserRole } from '@fv/models'

import { useRole } from './hooks'

type Props = PropsWithChildren<{
  role: ShipperUserRole
}>
export const HasRole = (props: Props) => {
  const role = useRole()
  if (role !== props.role) return null

  return props.children
}

export const HideForVendor = ({ children }: PropsWithChildren) => (
  <HasRole role="shipper">{children}</HasRole>
)
