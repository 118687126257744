import { type Dispatch, type SetStateAction } from 'react'

import { type FormRegister, validatePasswordStrength } from '@fv/client-core'
import { type AccountUserProfileUpdateDto } from '@fv/models'
import { passwordRequirementsMsg } from '@fv/models/marketing'

import { ContactPhoneInput } from '../../components/inputs/ContactPhoneInput'
import { InputGroup } from '../../components/inputs/InputGroup'

type ClassNameMap = {
  [K in keyof AccountUserProfileUpdateDto as `${K}ClassName`]?: string
} & {
  // these properties are conditionally on the parn ttyp
  companyClassName?: string
  passwordClassName?: string
  emailClassName?: string
}
type Props = ClassNameMap & {
  register: FormRegister<AccountUserProfileUpdateDto>
  autoFocus?: boolean
  onChange?: Dispatch<SetStateAction<AccountUserProfileUpdateDto>>
  value: AccountUserProfileUpdateDto
  password?: boolean
  company?: boolean
  email?: boolean
}
export const ProfileInfoForm = ({
  register,
  autoFocus,
  onChange,
  value,
  password = false,
  company = true,
  email = true,
  ...props
}: Props) => {
  return (
    <>
      <InputGroup
        className={props.firstNameClassName}
        inputProps={{
          ...register('firstName'),
          autoFocus: autoFocus,
        }}
        inputType="text"
        label="First name"
        required
      />

      <InputGroup
        className={props.lastNameClassName}
        inputProps={{
          ...register('lastName'),
        }}
        inputType="text"
        label="Last name"
        required
      />
      {company && (
        <InputGroup
          className={props.companyClassName}
          inputProps={{
            ...register('company'),
          }}
          inputType="text"
          label="Company name"
          required
        />
      )}
      <InputGroup
        className={props.titleClassName}
        inputProps={{
          ...register('title'),
        }}
        inputType="text"
        label="Job Title"
        required
      />

      <ContactPhoneInput
        className={props.phoneNumberClassName}
        required
        name="contactPhone"
        onChange={e => onChange?.(f => ({ ...f, phoneNumber: e.contactPhone }))}
        values={{
          contactPhone: value.phoneNumber || '',
        }}
        hideExt
      />

      {email && (
        <InputGroup
          className={props.emailClassName}
          inputProps={{
            ...register('email'),
          }}
          inputType="text"
          label="Email"
          required
        />
      )}
      {password && (
        <InputGroup
          className={props.emailClassName}
          inputProps={{
            ...register('password', {
              validate: e =>
                validatePasswordStrength(e) ? '' : passwordRequirementsMsg,
            }),
            type: 'password',
          }}
          inputType="text"
          label="Password"
          required
        />
      )}
    </>
  )
}
