import toast from 'react-hot-toast'
import { useToggle } from 'usehooks-ts'

import { FvLinkButton, SliderPanel } from '@fv/client-components'
import { generateString } from '@fv/client-core'
import { type SelectFieldOption } from '@fv/client-types'
import {
  type EquipmentAccessorial,
  type EquipmentType,
  equipmentTypes,
} from '@fv/models'

import {
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import MultiSelectField from '../../components/inputs/MultiSelectField'
import EquipmentAccessorialConfig from '../../components/shared/EquipmentAccessorialConfig'
import { MAX_ALT_EQUIP } from '../../constants'
import { useQuoteSettings, useTypeNames } from '../../hooks/settings'
import { ShipmentFormSection } from '../shipment-form/ShipmentFormSection'
import { type ShipmentEquipmentFormModel } from './types'
import { composeEquipmentAccessorialsDescription } from './utils'

type MultiSelectOption = {
  id?: string
  label: string
  value: string
}
// const equipmentOptions = equipmentTypes.map<SelectFieldOption>(t => ({
//   text: t.name,
//   value: t.key,
// }))

type Props = {
  value: ShipmentEquipmentFormModel
  onChange: (value: ShipmentEquipmentFormModel) => void
}
export const ShipmentEquipmentForm = ({ value, onChange }: Props) => {
  const [configOpen, toggleConfig] = useToggle()
  const { accessorialName } = useTypeNames()
  const quoteSettingsQuery = useQuoteSettings('truckload')
  if (!quoteSettingsQuery.data) return null
  const equipmentOptions = quoteSettingsQuery.data?.equipmentOptions ?? []
  const equipSelectOpts = equipmentOptions.map<SelectFieldOption>(o => ({
    text: o.name,
    value: o.key,
  }))

  const selectedEquipment = value.type
    ? equipmentOptions.find(o => o.key === value.type)
    : equipmentOptions[0]
  const altOptions = equipmentTypes
    .filter(t => t.key !== value.type)
    .map<MultiSelectOption>(o => ({
      label: o.name,
      value: o.key,
    }))
  return (
    <ShipmentFormSection
      title="Equipment"
      icon="truck"
      iconClassName="text-fv-gray-dark"
      pillClassName="bg-fv-blue-50 border-2 border-fv-blue-300 !gap-x-0"
      className="bg-fv-blue-50 !border-fv-blue-200"
    >
      <div className="flex gap-x-2 flex-wrap">
        <InputGroup
          inputType="select"
          label="Preferred equipment"
          inputProps={{
            options: equipSelectOpts,
            name: 'equipmentType',
            value: value.type,
            onChange: e => {
              onChange({ ...value, type: e.target.value as EquipmentType })
            },
          }}
        />

        <InputGroupWrapper
          label="Alternates"
          name="alternateTypes"
          className="flex-1"
        >
          <MultiSelectField
            // @ts-expect-error component not TS

            isClearable={false}
            isSearchable
            name="alternateTypes"
            onChange={(nextValues: MultiSelectOption[]) => {
              if (nextValues.length > MAX_ALT_EQUIP) {
                toast.error(
                  `Max of ${MAX_ALT_EQUIP} alternate equipment types.`,
                )
                return
              }

              onChange({
                ...value,
                alternateTypes: nextValues.map(v => v.value as EquipmentType),
              })
            }}
            options={altOptions}
            placeholder="Optional equipment"
            values={altOptions.filter(o =>
              value.alternateTypes.includes(o.value as EquipmentType),
            )}
          />
        </InputGroupWrapper>
      </div>
      <div className="flex gap-x-2 border-t border-dashed border-fv-blue pt-4">
        <span>Equipment Accessorials:</span>
        <FvLinkButton theme="underlined" onClick={toggleConfig}>
          {composeEquipmentAccessorialsDescription(
            accessorialName,
            value.accessorials,
          )}
        </FvLinkButton>
        <SliderPanel isOpen={configOpen} closePanel={toggleConfig}>
          <div className="flex flex-col gap-y-4">
            {/* TODO VP - this is almost duplicated from the `BookingLoad.tsx` */}
            <EquipmentAccessorialConfig
              accessorials={value.accessorials}
              id={generateString(4)}
              accessorialOptions={selectedEquipment.accessorialOptions}
              setAccessorials={({ accessorials }) => {
                onChange({
                  ...value,
                  accessorials: accessorials as EquipmentAccessorial[],
                })
              }}
            />
          </div>
        </SliderPanel>
      </div>
    </ShipmentFormSection>
  )
}
