import { useState } from 'react'

import { FvButton, FvNum, ValidatedForm } from '@fv/client-components'
import {
  generateCharge,
  getAmount,
  modelToDto,
  switchFromCharges,
  switchToCharges,
} from '@fv/client-core'
import { type ListShipment } from '@fv/client-types'

import {
  composeCityState,
  getDestination,
  getOrigin,
} from '../../utils/shipmentFuncs'
import {
  createQuoteEditModel,
  type QuoteEditModel,
} from '../mini-quote-list/types'
import { useUpdateQuote } from '../quote/useUpdateQuote'
import { BaseRateField } from './BaseRateField'
import { ChargeField } from './ChargeField'
import { type ChargeListState } from './types'

type Props = {
  chargeState: ChargeListState
  load: ListShipment
  onCancel: () => void
}

export const ChargeForm = ({ chargeState, load, onCancel }: Props) => {
  const model = createQuoteEditModel(chargeState.quote)

  const [localQuote, setLocalQuote] = useState<QuoteEditModel>(model)
  const updateQuote = useUpdateQuote(load.loadId, chargeState.quote._id)

  const handleUpdate = (update: QuoteEditModel) => {
    const needsSwitched =
      localQuote.charges?.length > 1 && update.charges?.length <= 1

    setLocalQuote(needsSwitched ? switchFromCharges(update) : update)
  }

  const handleAddClick = () => {
    const needsSwitched = !localQuote.charges?.length

    setLocalQuote(prev => {
      return needsSwitched
        ? switchToCharges(prev)
        : {
            ...prev,
            charges: [...prev.charges, generateCharge()],
          }
    })
  }

  const handleSubmit = () => {
    updateQuote.mutate(modelToDto(localQuote))

    onCancel()
  }

  const origin = getOrigin(load)
  const destination = getDestination(load)

  return (
    <ValidatedForm className="flex flex-col h-full" onSubmit={handleSubmit}>
      <div className="overflow-auto">
        <p className="">
          Edit charges from {chargeState.quote.providerName} <br />
          {origin.company} {composeCityState(origin)} {origin.postalCode} to{' '}
          {destination.company} {composeCityState(destination)}{' '}
          {destination.postalCode}
        </p>

        <div className="bg-fv-blue-50 border border-fv-blue-200 p-4 mb-6">
          <div className="flex flex-col gap-y-2 mb-4">
            <BaseRateField quote={localQuote} onChange={handleUpdate} />
            {localQuote.charges
              ?.filter(c => c.name !== 'linehaul')
              .map((charge, ix) => (
                <ChargeField
                  key={ix}
                  charge={charge}
                  onChange={newCharge =>
                    handleUpdate({
                      ...localQuote,
                      charges: localQuote.charges?.map(c =>
                        c.id === newCharge.id ? newCharge : c,
                      ),
                    })
                  }
                  onRemove={() =>
                    handleUpdate({
                      ...localQuote,
                      charges: localQuote.charges?.filter(
                        c => c.id !== charge.id,
                      ),
                    })
                  }
                />
              ))}
          </div>
          <div className="flex justify-between bg-fv-blue-25 -mx-4 px-4 py-3 -mb-4 border-t border-fv-blue-200 border-dotted">
            <FvButton icon="plus" onClick={handleAddClick}>
              Add an additional charge
            </FvButton>
            <div className="flex items-center gap-x-2">
              <div>Total price =</div>
              <div className="bg-fv-beer-xlight border font-mono border-fv-beer-medium p-2">
                <FvNum
                  val={getAmount(localQuote)}
                  currency
                  includeEmptyDecimals
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-fv-blue-150 py-3 px-6 border-t mt-auto border-fv-blue-300 -mx-6 -mb-6 flex gap-x-3 justify-end">
        <FvButton icon="times" onClick={onCancel}>
          Cancel
        </FvButton>
        <FvButton icon="check" theme="primary" type="submit">
          Update price / Save charges
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
