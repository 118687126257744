import clsx from 'clsx'

import {
  FvLinkButton,
  type IconName,
  useFvNavigate,
} from '@fv/client-components'
import { type Workflow } from '@fv/models'

import { useLoadRatesPage } from '../../route-hooks'
import { usePermissions } from '../auth'
import { HasWorkflow } from '../auth/HasWorkflow'
import { usePreferredWorkflows } from '../profiile/hooks'
import { useCreateShipment } from './mutations'
import { ShipmentForm } from './ShipmentForm'
import { ShipmentFormPageLayout } from './ShipmentFormPageLayout'
import { ShipmentFormProvider } from './ShipmentFormProvider'
import { useShipmentFormActions, useShipmentFormState } from './store'
import { type ShipmentFormModel } from './types'
import { mapShipmentFormModelToLoadDTO } from './utils'

type ModeLinkProps = {
  icon: IconName
  label: string
  isActive: boolean
  onClick: () => void
}

const ModeLink = ({ icon, label, isActive, onClick }: ModeLinkProps) => {
  return (
    <FvLinkButton
      className={clsx(
        'border-fv-blue-300 b1350:px-4 b1350:py-2 b1350:mb-0 relative z-20 block rounded-lg border-2 p-4',
        {
          'after:border-fv-blue-300 b1350:after:right-1/2 b1350:after:translate-x-1/2  b1350:after:-rotate-45 b1350:after:bg-fv-blue-50 b1350:after:w-2 b1350:after:h-2 b1350:after:top-full bg-fv-beer-light after:absolute after:-right-3 after:top-2/4 after:z-10 after:h-3 after:w-3 after:-translate-y-1/2 after:translate-x-[calc(-50%_+_1px)] after:rotate-45 after:border-2 after:border-b-0 after:border-l-0 after:bg-fv-beer-light':
            isActive,
        },
        { 'bg-white': !isActive },
      )}
      fw
      icon={icon}
      onClick={onClick}
    >
      {label}
    </FvLinkButton>
  )
}

type WorkflowSelectorProps = {
  value: Workflow
  onChange: (value: Workflow) => void
}
const WorkflowSelector = ({ value, onChange }: WorkflowSelectorProps) => {
  console.log(value)
  return (
    <nav
      className={clsx(
        `bg-fv-blue-50 border-r py-8 px-6 flex flex-col gap-3 b1350:pt-5 b1350:pb-3 b1350:border-r-0 border-fv-gray b1350:border-b b1350:col-span-full b1350:flex b1350:flex-row overflow-auto`,
      )}
    >
      <HasWorkflow workflow="parcel">
        <ModeLink
          icon="box"
          label="Parcel"
          onClick={() => onChange('parcel')}
          isActive={value === 'parcel'}
        />
      </HasWorkflow>
      <HasWorkflow workflow="ltl">
        <ModeLink
          icon="truck"
          label="LTL"
          onClick={() => onChange('ltl')}
          isActive={value === 'ltl'}
        />
      </HasWorkflow>
      <HasWorkflow workflow="truckload">
        <ModeLink
          icon="truck-moving"
          label="Truckload"
          onClick={() => onChange('truckload')}
          isActive={value === 'truckload'}
        />
      </HasWorkflow>
    </nav>
  )
}

export const ShipmentCreatePage = () => {
  const [workflow] = usePreferredWorkflows()
  return (
    <ShipmentFormProvider init={{ workflow }}>
      <ShipmentCreatePageContent />
    </ShipmentFormProvider>
  )
}

const ShipmentCreatePageContent = () => {
  const { workflow } = useShipmentFormState()
  const { setWorkflow } = useShipmentFormActions()
  const { canViewRates, canBook } = usePermissions()
  const createLoad = useCreateShipment()
  const navigate = useFvNavigate()
  const ratesPage = useLoadRatesPage()

  const handleCreate = async (model: ShipmentFormModel) => {
    const result = await createLoad.mutateAsync(
      mapShipmentFormModelToLoadDTO(model, workflow),
    )
    if (!canBook && !canViewRates) {
      return navigate('/pending')
    }
    navigate(ratesPage(result[0]))
  }
  return (
    <ShipmentFormPageLayout
      sidebarContent={
        <WorkflowSelector value={workflow} onChange={setWorkflow} />
      }
    >
      <ShipmentForm isSaving={createLoad.isLoading} onCreate={handleCreate} />
    </ShipmentFormPageLayout>
  )
}
