import {
  FvButton,
  type FvButtonProps,
  type IconProps,
  Tooltip,
} from '@fv/client-components'

import { type ChargeListState } from './types'

type Props = Omit<FvButtonProps, 'icon'> & {
  icon?: IconProps['icon'] | false
  chargeState: ChargeListState
}

export const ChargesToggle = ({ icon, chargeState, ...buttonProps }: Props) => {
  if (!chargeState.canView) {
    return null
  }

  if (!chargeState.canEdit) {
    return buttonProps.children
  }

  const buttonIcon = icon === false ? undefined : icon || 'money-check-alt'

  return (
    <Tooltip label={chargeState.canEdit ? 'Edit charges' : 'View charges'}>
      <FvButton
        icon={buttonIcon}
        onClick={() => chargeState.toggleViewing()}
        {...buttonProps}
      />
    </Tooltip>
  )
}
