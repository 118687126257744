import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useToggle } from 'usehooks-ts'

import {
  FvButton,
  Icon,
  SlashSeparated,
  SliderPanel,
} from '@fv/client-components'

import { ActionList, ActionListItem } from '../../../components/ActionList'
import InfoBox from '../../../components/InfoBox'
import { List, ListHeaderActions, ListItem } from '../../../components/List'
import { Loading } from '../../../components/Loading'
import { routes } from '../../../routes'
import { useAccountFeatures } from '../../auth'
import { SettingsSection } from '../SettingsSection'
import { useDeleteVendor } from './mutations'
import { useVendors } from './queries'
import { type VendorWithUsersDTO } from './types'
import { VendorForm } from './VendorForm'

export const VendorsPage = () => {
  const { vendorPortal } = useAccountFeatures()
  const [showSlider, , setSliderVis] = useToggle(false)
  const [expandedVendorId, setExpandedVendorId] = useState<string | null>(null)
  const [selectedVendor, setSelectedVendor] =
    useState<VendorWithUsersDTO | null>(null)

  const { data: vendors, isLoading } = useVendors()
  const deleteVendor = useDeleteVendor()

  const handleDeleteVendor = async (vendor: VendorWithUsersDTO) => {
    if (window.confirm(`Are you sure you want to remove ${vendor.name}?`)) {
      await deleteVendor.mutateAsync(vendor._id)
    }
  }

  const handleEditVendor = (vendor: VendorWithUsersDTO) => {
    setSelectedVendor(vendor)
    setSliderVis(true)
  }

  const handleAddVendor = () => {
    setSliderVis(true)
  }

  const handleSliderClose = () => {
    setSelectedVendor(null)
    setSliderVis(false)
  }

  const toggleVendorUsers = (vendorId: string) => {
    setExpandedVendorId(prevId => (prevId === vendorId ? null : vendorId))
  }

  if (!vendorPortal) {
    return <Navigate to={routes.settings('user-preferences')} />
  }

  return (
    <div className="settings-main pt-8">
      <SettingsSection title="Vendors">
        <ListHeaderActions className="mb-2">
          <FvButton icon="plus" iconClass="fa-fw" onClick={handleAddVendor}>
            Add vendor
          </FvButton>
        </ListHeaderActions>

        {isLoading ? (
          <Loading />
        ) : vendors && vendors.length > 0 ? (
          <List>
            {vendors.map(vendor => (
              <VendorListItem
                key={vendor._id}
                vendor={vendor}
                isExpanded={expandedVendorId === vendor._id}
                onToggleExpand={() => toggleVendorUsers(vendor._id)}
                onEdit={() => handleEditVendor(vendor)}
                onDelete={() => handleDeleteVendor(vendor)}
              />
            ))}
          </List>
        ) : (
          <InfoBox>No vendors found. Click "Add vendor" to create one.</InfoBox>
        )}
      </SettingsSection>

      <SliderPanel isOpen={showSlider} closePanel={handleSliderClose}>
        <VendorForm
          existingVendor={selectedVendor || undefined}
          onSaved={handleSliderClose}
          onCancel={handleSliderClose}
        />
      </SliderPanel>
    </div>
  )
}

type VendorListItemProps = {
  vendor: VendorWithUsersDTO
  isExpanded: boolean
  onToggleExpand: () => void
  onEdit: () => void
  onDelete: () => void
}

const VendorListItem = ({
  vendor,
  isExpanded,
  onToggleExpand,
  onEdit,
  onDelete,
}: VendorListItemProps) => {
  const vendorUsers = vendor.users
  const isLoadingUsers = false

  return (
    <ListItem className="flex-wrap">
      <div className="flex-1 flex items-center gap-x-2 leading-none">
        <Icon
          icon="store-alt"
          fixedWidth
          className="text-inherit"
          transform="up-1"
        />
        <div className="truncate w-36 inline-block" title={vendor.name}>
          {vendor.name}
        </div>
        <SlashSeparated>
          <div key="rates" className="flex items-center gap-x-1">
            {vendor.permissions.includes('view-rates') ? (
              <>
                <Icon icon="check-circle" className="text-fv-green" />
                <div>Allowed to see rates</div>
              </>
            ) : (
              <>
                <Icon icon="ban" className="text-fv-gray" />
                <div>Allowed to see rates</div>
              </>
            )}
          </div>
          <div key="book" className="flex items-center gap-x-1">
            {vendor.permissions.includes('book') ? (
              <>
                <Icon icon="check-circle" className="text-fv-green" />
                <div>Allowed to book</div>
              </>
            ) : (
              <>
                <Icon icon="ban" className="text-fv-gray" />
                <div>Allowed to book</div>
              </>
            )}
          </div>
        </SlashSeparated>
      </div>
      <ActionList>
        <ActionListItem
          onClick={onToggleExpand}
          icon={isExpanded ? 'angle-up' : 'angle-down'}
          title={isExpanded ? 'Collapse' : 'Expand'}
          theme="plain"
        />
        <ActionListItem
          theme="plain"
          icon="pen-alt"
          title="Edit"
          onClick={onEdit}
        />
        <ActionListItem
          theme="plain"
          iconClass="text-fv-orange"
          icon="trash"
          title="Remove"
          onClick={onDelete}
        />
      </ActionList>

      {isExpanded && (
        <div className="bg-fv-beer-light !border-fv-beer-dark !border-b relative mb-2 mt-4 border p-4 z-10 w-full">
          <div className="border-l-fv-beer-dark border-t-fv-beer-dark bg-fv-beer-light absolute -top-2 left-8 h-4 w-4 rotate-45 border-l border-t" />

          {isLoadingUsers ? (
            <Loading />
          ) : (
            <>
              <div>
                {vendorUsers && vendorUsers.length > 0 ? (
                  <div className="divide-fv-beer-dark divide-y divide-dotted [&>div]:py-3">
                    {vendorUsers.map(user => (
                      <div
                        key={user._id}
                        className="first:pt-0 last:pb-0 flex items-center justify-between"
                      >
                        <div>
                          {user.profile?.firstName || user.profile?.lastName ? (
                            <span>
                              {[user.profile?.firstName, user.profile?.lastName]
                                .filter(Boolean)
                                .join(' ')}{' '}
                              /{' '}
                            </span>
                          ) : null}
                          <span>{user.email}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center py-2">No users added yet</div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </ListItem>
  )
}
