import { type EquipmentAccessorialType, type Workflow } from '@fv/models'

import { ShipmentLocationForm } from '../shipment-location-form/ShipmentLocationForm'
import { type ShipmentLocationFormModel } from '../shipment-location-form/types'

type Props = {
  workflow: Workflow
  value: ShipmentLocationFormModel[]
  onChange: (locations: ShipmentLocationFormModel[]) => void
  equipmentAccessorials?: EquipmentAccessorialType[]
  onEquipmentAccessorialChange?: (a: EquipmentAccessorialType[]) => void
}
export const ShipmentFormLocations = ({
  value,
  workflow,
  onChange,
  equipmentAccessorials,
  onEquipmentAccessorialChange,
}: Props) => {
  const handleChange =
    (sequence: number) => (updates: Partial<ShipmentLocationFormModel>) => {
      onChange(
        value.map(loc =>
          loc.sequence === sequence ? { ...loc, ...updates } : loc,
        ),
      )
    }

  return (
    <>
      {value.map(loc => (
        <ShipmentLocationForm
          key={loc.sequence}
          workflow={workflow}
          value={loc}
          onChange={handleChange(loc.sequence)}
          equipmentAccessorials={equipmentAccessorials}
          onEquipmentAccessorialChange={onEquipmentAccessorialChange}
          allLocations={value}
          allowDateEdit
        />
      ))}
    </>
  )
}
