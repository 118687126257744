import { SelectField } from '@fv/client-components'
import { getPathValue } from '@fv/client-core'
import {
  type AccountSettingsKey,
  type SelectFieldOption,
  type ShippingLabelSize,
} from '@fv/client-types'

import InfoBox from '../../../components/InfoBox'
import YesNoInput from '../../../components/inputs/YesNoInput'
import { ListItem } from '../../../components/List'
import { PillHeader } from '../../../components/PillHeader'
import { HasWorkflow } from '../../auth/HasWorkflow'
import { useAccountSettings } from './hooks'
import { useAccountSettingMutation } from './mutations'
import { sheetOptions, ShippingLabelSizeForm } from './ShippingLabelSizes'

const shippingLabelUnitOptions: SelectFieldOption[] = [
  { text: '1', value: '1' },
  { text: '2', value: '2' },
  { text: '4', value: '4' },
  { text: 'Prompt me', value: '0' },
]

const SheetSizeWarning = ({
  rememberSelection,
  size,
}: {
  rememberSelection?: boolean
  size?: ShippingLabelSize
}) => {
  const isSheetSize = !!size && sheetOptions.map(o => o.size).includes(size)
  if (!rememberSelection || !isSheetSize) return null

  return (
    <InfoBox className="mb-0 mt-4">
      The label you have chosen will still prompt you for a start position since
      this kind of label has more than one label per sheet. If you wish to avoid
      a prompt, Freightview suggests using a roll label with a roll printer.
    </InfoBox>
  )
}

const ShippingLabelFormSection = ({
  productType,
}: {
  productType: 'ltl' | 'tl'
}) => {
  const label = productType === 'ltl' ? 'LTL' : 'Truckload'
  const accountSettings = useAccountSettings()
  const { updateAccountSetting } = useAccountSettingMutation(
    accountSettings,
    true,
  )

  const sizeSetting: AccountSettingsKey =
    productType === 'tl'
      ? 'shipping.tl.preferredLabelSize'
      : 'shipping.ltl.preferredLabelSize'

  const countSetting: AccountSettingsKey =
    productType === 'tl'
      ? 'shipping.tl.preferredLabelCount'
      : 'shipping.ltl.preferredLabelCount'

  const rememberSetting: AccountSettingsKey =
    productType === 'tl'
      ? 'shipping.tl.rememberLabelSize'
      : 'shipping.ltl.rememberLabelSize'

  const sizeSelection =
    getPathValue(accountSettings, sizeSetting) ??
    getPathValue(accountSettings, 'shipping.ltl.preferredLabelSize')

  const countSelection =
    getPathValue(accountSettings, countSetting) ??
    getPathValue(accountSettings, 'shipping.ltl.preferredLabelCount')

  const rememberSelection =
    getPathValue(accountSettings, rememberSetting) ??
    getPathValue(accountSettings, 'shipping.ltl.rememberLabelSize')

  return (
    <>
      <ListItem className="!block">
        <PillHeader st className="default-container mb-4 relative -left-6 mt-2">
          {label}
        </PillHeader>
        <div className="flex items-center w-full">
          <label htmlFor={rememberSetting} className="leading-[1.6rem]">
            Set default shipping label size and skip label selection screen
          </label>
          <div className="flex-pinline flex-pinline--subtle flex-pinline--tall flex-1" />
          <div>
            <YesNoInput
              checked={!!rememberSelection}
              name={rememberSetting}
              onChange={e =>
                updateAccountSetting(rememberSetting, e.target.checked)
              }
            />
          </div>
        </div>
        <hr />
        <ShippingLabelSizeForm
          selection={sizeSelection}
          setting={sizeSetting}
          onChange={size => updateAccountSetting(sizeSetting, size)}
        />
      </ListItem>

      <ListItem className="flex-wrap items-center !gap-x-0">
        <SelectField
          className="form-control form-control--select mr-3 max-w-[8rem]"
          name="preferredLabelCount"
          onChange={e =>
            updateAccountSetting(countSetting, Number(e.target.value))
          }
          options={shippingLabelUnitOptions}
          value={countSelection}
        />
        <span>labels per handling unit</span>

        <SheetSizeWarning
          rememberSelection={rememberSelection}
          size={sizeSelection}
        />
      </ListItem>
    </>
  )
}

const ParcelFormSection = () => {
  const accountSettings = useAccountSettings()
  const setting: AccountSettingsKey = 'shipping.parcel.preferredLabelType'
  const selection = getPathValue(accountSettings, setting) || 'pdf'
  const { updateAccountSetting } = useAccountSettingMutation(
    accountSettings,
    true,
  )

  return (
    <ListItem className="!block">
      <PillHeader st className="default-container mb-2 mt-2 -left-6 relative">
        Parcel
      </PillHeader>
      <div className="flex items-center w-full">
        <label htmlFor={setting} className="leading-[1.6rem]">
          Preferred label type
        </label>
        <div className="flex-pinline flex-pinline--subtle flex-pinline--tall flex-1" />
        <div>
          <SelectField
            className="form-control form-control--select"
            options={[
              {
                text: 'PDF',
                value: 'pdf',
              },
              {
                text: 'ZPL',
                value: 'zpl',
              },
            ]}
            name={setting}
            onChange={e => {
              updateAccountSetting(setting, e.currentTarget.value)
            }}
            value={selection}
          />
        </div>
      </div>
    </ListItem>
  )
}

export const ShippingLabelForm = () => (
  <>
    <HasWorkflow workflow="ltl">
      <ShippingLabelFormSection productType="ltl" />
    </HasWorkflow>
    <HasWorkflow workflow="parcel">
      <ParcelFormSection />
    </HasWorkflow>
    <HasWorkflow workflow="truckload">
      <ShippingLabelFormSection productType="tl" />
    </HasWorkflow>
  </>
)
