import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import {
  DelimitedContent,
  FvButton,
  Modal,
  useFvNavigate,
  ValidatedForm,
} from '@fv/client-components'
import { isApiError, submitForm } from '@fv/client-core'

import { Loading } from '../../components/Loading'
import { LoadSummary } from '../../components/LoadSummary/LoadSummary'
import { supportMessage } from '../../constants'
import { useQuotes } from '../../hooks/shipments'
import { quoteKeys } from '../../hooks/shipments/useQuotes'
import { routes } from '../../routes'
import { isBookedAndDispatched } from '../../utils/shipmentFuncs'
import { addressBookKeys } from '../addresses/useAddressBookAddresses'
import { BillTo } from '../billTo/BillTo'
import { useAwardLoads } from '../booking/mutations'
import {
  LoadItemsForm,
  type LoadItemsFormRef,
} from '../commodities/load-items/LoadItemsFormProvider'
import { LTLBroker } from '../customsBroker/LTLBroker'
import { LTLContact } from '../emergencyContact/LTLContact'
import { quoteFuncs } from '../quote/quoteFuncs'
import { QuoteReasonSlider } from '../quote-reason/QuoteReasonForm'
import { buildShipmentLocation } from '../shipment-location-form/types'
import { useCarrierBookingFormSettings } from './bookFuncs'
import { LtlBookingBolField } from './BookingFormBolField'
import { BookingFormLocation } from './BookingFormLocation'
import { useBookingFormCtx, useBookingFormLoad } from './BookingFormProvider'
import { LtlBookingFormShare } from './BookingFormShare'
import { LtlBookingFormTags } from './BookingFormTags'
import { BookingRateDetails } from './BookingRateDetail'
import { CustomerRoutedForm } from './CustomerRoutedForm'
import { BookingErrorNotice } from './ErrorNotice'
import { FedExHomeDeliveryBooking } from './FedExHomeDelivery'
import { FedExShipAlert } from './FedExShipAlert'
import { HoldAtLocationForm } from './HoldAtLocationForm'
import { LTLRateLocation } from './LTLRateLocation'
import { RateChangeNotice } from './RateChangeNotice'
import { ReratingOverlay } from './ReratingOverlay'
import { type BuildAwardRequestArgs } from './types'
import { useBookingFormCanSchedulePickup } from './useCanSchedulePickup'
import { useCreateCustomerRoutedLoad } from './useCreateCustomerRouted'

export const BookingForm = () => {
  const queryClient = useQueryClient()
  const itemsFormRef = useRef<LoadItemsFormRef>(null)
  const actions = useBookingFormCtx(s => s.actions)
  const workflow = useBookingFormCtx(s => s.workflow)
  const isFreightCollect = useBookingFormCtx(s => s.isFreightCollect)
  const isParcel = workflow === 'parcel'
  const isLtl = workflow === 'ltl'
  const addCRLoad = useCreateCustomerRoutedLoad()
  const awardLoads = useAwardLoads()
  const canSchedulePickup = useBookingFormCanSchedulePickup()
  const canSchedulePickupOnly = useCanSchedulePickupOnly()
  const canDoBolOnly = useCanRequestBolOnly()
  const formRef = useRef<HTMLFormElement>(null)
  const isEdit = useBookingFormCtx(s => s.isEdit)
  const load = useBookingFormLoad()
  const navigate = useFvNavigate()
  const quote = useBookingFormCtx(s => s.quote)
  const [bookCache, setBookCache] = useState<{
    createBOL: boolean
    schedulePickup: boolean
  }>()
  const [rerating, setRerating] = useState(
    load?.selectedQuote?._id === quote?._id &&
      (load?.quoteProcessStatus === 'rerating' ||
        load?.quoteProcessStatus === 'rerate-ready'),
  )
  const { invalidate: clearQuoteCache } = useQuotes(load?.loadId, {
    enabled: false,
  })
  const isBusy =
    addCRLoad.isLoading ||
    addCRLoad.isSuccess ||
    awardLoads.isLoading ||
    awardLoads.isSuccess
  const [errorList, setErrorList] = useState<string[]>()
  const {
    billTo,
    bolNumber,
    locations,
    shareEmails,
    tags,
    items,
    initialItems,
    equipmentAccessorials,
  } = useBookingFormCtx(s => s)
  useEffect(() => {
    setErrorList([])
  }, [
    billTo,
    bolNumber,
    locations,
    shareEmails,
    tags,
    items,
    equipmentAccessorials,
  ])

  useEffect(() => {
    queryClient.invalidateQueries(quoteKeys.rerated(load?.loadId))
  }, [queryClient, rerating, load])

  function bookShipment({
    createBOL,
    schedulePickup,
    quoteId,
  }: BuildAwardRequestArgs) {
    setErrorList([])
    if (isBusy) return
    if (!formRef.current?.checkValidity()) {
      return submitForm(formRef.current)
    }

    const itemsValid = itemsFormRef.current?.validateItems(workflow, 'booking')
    if (!itemsValid) {
      return submitForm(formRef.current)
    }

    setBookCache({ schedulePickup: Boolean(schedulePickup), createBOL })

    if (isFreightCollect && !isEdit) {
      const dto = actions.buildCustomerRoutedDTO(createBOL, schedulePickup)

      addCRLoad.mutate(dto, {
        onError: error => {
          if (isApiError(error)) {
            setErrorList(
              error.message
                ? [error.message]
                : error.errors?.map(e => e.message),
            )
          } else {
            setErrorList([`There was an error, ${supportMessage}`])
          }
        },
        onSuccess: loads => {
          navigate(routes.details(loads[0].loadId))
        },
      })
    } else {
      const dto = actions.buildRequestDTO({
        createBOL,
        schedulePickup,
        quoteId,
      })

      awardLoads.mutate(dto, {
        onSuccess: ({ loads }) => {
          const { loadId, quoteProcessStatus, status } = loads[0]

          if (quoteProcessStatus === 'rerating') {
            awardLoads.reset()
            setRerating(true)
          } else {
            clearQuoteCache()
            queryClient.invalidateQueries(addressBookKeys.all)
            navigate(
              status === 'pending'
                ? routes.rates(loads[0])
                : routes.details(loadId),
            )
          }
        },
        onError: error => {
          console.warn({ error })

          const err = error as any
          let msg = err.message

          if (err.errors?.length) {
            const { propertyName, message } = err.errors[0]
            msg += ` "${propertyName}" ${message?.toLowerCase()}.`
          }

          setErrorList(msg)
          toast.error(msg)
        },
      })
    }
  }

  useEffect(() => {
    if (load?.pickup?.status === 'error') {
      setTimeout(
        // force this hook to happen after the clearing out hook
        () =>
          setErrorList([
            load?.pickup?.error || `Unable to book shipment, ${supportMessage}`,
          ]),
        0,
      )
    }
  }, [load?.loadId, load?.pickup?.error, load?.pickup?.status]) // leave load?.loadId as a dependency otherwise errors get cleared out by other hooks

  // If the customer is editing a load after booking, we want to go ahead and redirect the customer to
  // the details page once the re-rate process finishes even if no matching quote was found.
  useEffect(() => {
    if (
      (load?.quoteProcessStatus === 'rerate-no-compat' ||
        load?.quoteProcessStatus === 'ok') &&
      rerating &&
      isBookedAndDispatched(load)
    ) {
      navigate(routes.details(load.loadId))
    }
  }, [load, rerating, navigate])

  if (!load) {
    return <Loading />
  }

  return (
    <>
      <LoadSummary
        load={{
          ...load,
          locations: locations.map(buildShipmentLocation),
        }}
        isEditingLoad
        onDateEdit={(sequence, date) => {
          actions.setLocationValues(sequence === 1 ? 'destination' : 'origin', {
            stopDate: date,
          })
        }}
        quote={quote}
      />

      <div className="parcel-ltl-booking-main row-start-2 [scrollbar-gutter:stable]">
        {!!errorList?.length && (
          <BookingErrorNotice errors={errorList} className="-mx-8 " />
        )}
        {quote && (
          <BookingRateDetails
            load={load}
            quote={quote}
            className="-mx-6 mb-10 "
          />
        )}
        <ValidatedForm ref={formRef}>
          <CustomerRoutedForm disabled={isBusy} />
          <BookingFormLocation disabled={isBusy} type="origin" />
          <BookingFormLocation disabled={isBusy} type="destination" />
          {isParcel && <FedExHomeDeliveryBooking />}
          {isParcel && <HoldAtLocationForm />}
          <LoadItemsForm
            ref={itemsFormRef}
            step="booking"
            workflow={workflow}
            initialItems={items}
            onChange={actions.setItems}
            type="inline"
          />
          <hr className="mb-12" />
          {isParcel && <FedExShipAlert />}
          {isLtl && (
            <>
              <LTLContact disabled={isBusy} />
              <LTLBroker disabled={isBusy} />
              <LTLRateLocation disabled={isBusy} />
            </>
          )}
          <BillTo disabled={isBusy} />
          <div className="gray-container flex gap-2 px-4 pb-2 pt-4">
            {workflow !== 'parcel' && (
              <LtlBookingBolField className="basis-3/12" />
            )}
            <LtlBookingFormShare
              className={clsx({
                'basis-5/12': isLtl,
                'basis-6/12': isParcel,
              })}
              disabled={isBusy}
            />
            <LtlBookingFormTags
              className={clsx({
                'basis-4/12': isLtl,
                'basis-6/12': isParcel,
              })}
            />
          </div>
          <RateChangeNotice
            onUndo={() => {
              actions.undoChanges()
              itemsFormRef.current?.setItems(initialItems)
            }}
          />
        </ValidatedForm>
      </div>

      <div className="footer-actions row-start-[-1] col-start-1 col-span-3">
        <DelimitedContent delimiter={<span className="ml-2 mr-2">-or-</span>}>
          {!isEdit && canSchedulePickupOnly && (
            <FvButton
              loading={isBusy}
              icon="check"
              theme="primary"
              onClick={() =>
                bookShipment({
                  createBOL: false,
                  schedulePickup: true,
                })
              }
            >
              Submit pickup request & upload BOL
            </FvButton>
          )}
          {isEdit && workflow !== 'parcel' && (
            <FvButton
              loading={isBusy}
              icon="check"
              theme="primary"
              fwd
              className="mr-1"
              onClick={() =>
                bookShipment({
                  createBOL: load.bol?.status !== 'not-requested',
                  schedulePickup: false,
                })
              }
            >
              Save Changes
            </FvButton>
          )}
          {!isEdit && canDoBolOnly && workflow !== 'parcel' && (
            <FvButton
              loading={isBusy}
              icon="file-contract"
              theme="default"
              onClick={() =>
                bookShipment({
                  createBOL: true,
                  schedulePickup: false,
                })
              }
            >
              Print BOL
            </FvButton>
          )}
          {!isEdit && canSchedulePickup && workflow !== 'parcel' && (
            <FvButton
              theme="primary"
              fwd
              icon={isBusy ? 'spinner' : 'truck'}
              onClick={() =>
                bookShipment({
                  createBOL: true,
                  schedulePickup: true,
                })
              }
            >
              Schedule pickup & print BOL
            </FvButton>
          )}
          {workflow === 'parcel' && (
            <FvButton
              fwd
              icon={isBusy ? 'spinner' : 'truck'}
              onClick={() =>
                bookShipment({
                  createBOL: true,
                  schedulePickup: load.schedulePickup,
                })
              }
              theme="primary"
            >
              Create Shipment and Labels
            </FvButton>
          )}
          {isEdit && (
            <FvButton icon="times" theme="default" onClick={() => navigate(-1)}>
              Cancel
            </FvButton>
          )}
        </DelimitedContent>
      </div>

      {rerating && (
        <Modal>
          <ReratingOverlay
            loadId={load.loadId}
            onAcceptRerate={quoteId => {
              clearQuoteCache()
              if (bookCache) {
                bookShipment({
                  ...bookCache,
                  quoteId,
                })
              } else {
                setRerating(false)
                navigate(routes.book({ loadId: load.loadId, quoteId }), {
                  replace: true,
                })
              }
            }}
            onDeclineRerate={() => {
              setRerating(false)
            }}
          />
        </Modal>
      )}
      {quote && load && <QuoteReasonSlider load={load} quote={quote} />}
    </>
  )
}

function useCanRequestBolOnly() {
  const carrierSetting = useCarrierBookingFormSettings()
  const isFreightCollect = useBookingFormCtx(s => s.isFreightCollect)
  if (isFreightCollect) return true
  return carrierSetting?.allowBolOnly
}

function useCanSchedulePickupOnly() {
  const selectedQuote = useBookingFormCtx(s => s.quote)
  return quoteFuncs.isSpotWorkflow(selectedQuote)
}
