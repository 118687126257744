import { createContext, useContext } from 'react'
import { create, useStore } from 'zustand'

import { type FullShipment, type Quote } from '@fv/client-types'
import { type Workflow } from '@fv/models'

import { useLoadHandlingUnitFormModels } from '../commodities/load-items/loadItemHelpers'
import { type LoadHandlingUnitFormModel } from '../commodities/load-items/types'
import {
  buildShipmentCustomsBrokerFormModel,
  defaultFormBroker,
  type ShipmentCustomsBrokerFormModel,
} from '../customsBroker/brokerTypes'
import {
  buildEmergencyContactFormModel,
  type EmergencyContactFormModel,
} from '../emergencyContact/contactTypes'
import {
  buildShipmentEquipmentFormModel,
  type ShipmentEquipmentFormModel,
} from '../shipment-equipment-form/types'
import {
  defaultDestination,
  defaultOrigin,
  type ShipmentLocationFormModel,
} from '../shipment-location-form/types'
import { useShipLocFormModelBuilder } from '../shipment-location-form/useLocFormModelBuilder'

type ShipmentFormState = {
  initialLoad?: FullShipment
  quote?: Quote
  items: LoadHandlingUnitFormModel[]
  locations: ShipmentLocationFormModel[]
  equipment: ShipmentEquipmentFormModel
  emergencyContact: EmergencyContactFormModel
  customsBrokers: ShipmentCustomsBrokerFormModel
  bolNum: string
  type: 'create' | 'book' | 'edit'
  workflow: Workflow
}
type ShipmentFormActions = {
  actions: {
    setItems: (items: LoadHandlingUnitFormModel[]) => void
    setLocations: (locations: ShipmentLocationFormModel[]) => void
    setEquipment: (equipment: ShipmentEquipmentFormModel) => void
    setEmergencyContact: (emergencyContact: EmergencyContactFormModel) => void
    setCustomsBrokers: (customsBrokers: ShipmentCustomsBrokerFormModel) => void
    setBolNum: (bolNum: string) => void
    setWorkflow: (workflow: Workflow) => void
  }
}
type ShipmentFormStoreContents = ShipmentFormState & ShipmentFormActions

export type ShipmentFormStore = ReturnType<typeof createShipmentFormStore>
export const ShipmentFormContext = createContext<ShipmentFormStore | null>(null)

function useShipmentFormStore<T>(
  selector?: (state: ShipmentFormStoreContents) => T,
) {
  const store = useContext(ShipmentFormContext)

  if (!store) {
    throw new Error('Missing ShipmentFormContext.Provider in the tree')
  }
  return useStore(store, selector)
}

export const useShipmentFormActions = () => {
  return useShipmentFormStore(s => s.actions)
}

export const useShipmentFormState = () => {
  return useShipmentFormStore(({ actions, ...state }) => state)
}

type CreateProps = {
  workflow: Workflow
}

type BookProps = {
  load: FullShipment
  quote: Quote
}

type EditProps = {
  load: FullShipment
}
export type StoreInitProps = CreateProps | BookProps | EditProps

const isBookForm = (props: StoreInitProps): props is BookProps => {
  return 'load' in props && 'quote' in props
}

const isEditForm = (props: StoreInitProps): props is EditProps => {
  return 'load' in props && !isBookForm(props)
}
const createShipmentFormStore = (state: ShipmentFormState) => {
  return create<ShipmentFormStoreContents>((set, get) => ({
    ...state,
    actions: {
      setItems: (items: LoadHandlingUnitFormModel[]) => set({ items }),
      setLocations: (locations: ShipmentLocationFormModel[]) =>
        set({ locations }),
      setEquipment: (equipment: ShipmentEquipmentFormModel) =>
        set({ equipment }),
      setEmergencyContact: (emergencyContact: EmergencyContactFormModel) =>
        set({ emergencyContact }),
      setCustomsBrokers: (customsBrokers: ShipmentCustomsBrokerFormModel) =>
        set({ customsBrokers }),
      setBolNum: (bolNum: string) => set({ bolNum }),
      setWorkflow: (workflow: Workflow) => set({ workflow }),
    },
  }))
}

export const useCreateShipmentFormStore = (props: StoreInitProps) => {
  const isBook = isBookForm(props)
  const isEdit = isEditForm(props)
  const hasLoad = isBook || isEdit
  const locBuilder = useShipLocFormModelBuilder(
    hasLoad ? props.load : { workflow: props.workflow },
  )

  const loadHandlingUnits = useLoadHandlingUnitFormModels(
    hasLoad ? props.load : undefined,
  )

  return {
    isLoading: loadHandlingUnits.isLoading || locBuilder.isLoading,
    create: () => {
      const locations = hasLoad
        ? props.load.locations.map(l => locBuilder.build(l))
        : [{ ...defaultOrigin() }, defaultDestination()]
      const items = hasLoad ? loadHandlingUnits.data : []
      return createShipmentFormStore({
        initialLoad: hasLoad ? props.load : undefined,
        quote: isBook ? props.quote : undefined,
        type: isBook ? 'book' : isEdit ? 'edit' : 'create',
        workflow: isBook || isEdit ? props.load.workflow : props.workflow,
        locations,
        items,
        bolNum: hasLoad ? props.load.bol?.bolNumber : '',

        equipment: hasLoad
          ? buildShipmentEquipmentFormModel(props.load)
          : {
              type: 'dry-van-53',
              accessorials: [],
              alternateTypes: [],
            },

        emergencyContact:
          hasLoad && props.load.emergencyContact
            ? buildEmergencyContactFormModel(props.load.emergencyContact)
            : {
                contractNumber: '',
                name: '',
                phone: '',
                phoneExt: '',
              },

        customsBrokers:
          hasLoad && props.load.customsBroker?.length > 0
            ? props.load.customsBroker.map(broker =>
                buildShipmentCustomsBrokerFormModel(broker),
              )[0]
            : {
                ...defaultFormBroker,
              },
      })
    },
  }
}
