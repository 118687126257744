import { FvButton } from '@fv/client-components'
import { type UIQuote } from '@fv/client-types'
import { type Workflow } from '@fv/models'

import { useCarrierPublicSettings } from '../../hooks/useCarrierPublicSettings'
import { useCanSchedulePickup } from '../book/useCanSchedulePickup'
import { type LoadHandlingUnitFormModel } from '../commodities/load-items/types'
import { type ShipmentLocationFormModel } from '../shipment-location-form/types'

type Props = {
  onPrintBOL: () => void
  items: LoadHandlingUnitFormModel[]
  locations: ShipmentLocationFormModel[]
  workflow: Workflow
  quote: UIQuote
  isLoading: boolean
}
export const ShipmentBookActions = ({
  onPrintBOL,
  items,
  locations,
  workflow,
  quote,
  isLoading,
}: Props) => {
  const canSchedulePickup = useCanSchedulePickup({
    isFreightCollect: false,
    items,
    locations,
    workflow,
    selectedQuote: quote,
  })
  const carrierSettings = useCarrierPublicSettings(quote.carrierId)
  return (
    <>
      {carrierSettings.carrierPublicSettings?.allowBolOnly && (
        <FvButton
          theme="default"
          icon="check"
          onClick={onPrintBOL}
          loading={isLoading}
        >
          Print BOL
        </FvButton>
      )}
      {canSchedulePickup && (
        <FvButton
          type="submit"
          theme="primary"
          icon="check"
          fwd
          loading={isLoading}
        >
          Schedule pickup & print BOL
        </FvButton>
      )}
    </>
  )
}
