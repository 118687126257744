import dayjs from 'dayjs'

import {
  type Currency,
  type EquipmentType,
  equipmentTypes,
  states,
} from '@fv/models'
import {
  vBoolean,
  vCoerceArray,
  vCountryAny,
  vDate,
  vMinLength,
  vMinValue,
  vNumber,
  vObject,
  vOptional,
  type vOutput,
  vPicklist,
  vPipe,
  vPostalCode,
  vRawCheck,
  vString,
  vTransform,
} from '@fv/models/validation'

export type EquipmentTypeOption = {
  label: string
  value: EquipmentType
}

export type FuelTableEntry = {
  calculationType: 'per-mile'
  currency: Currency
  lowerBoundary: number
  rate: number
  upperBoundary: number
}

export type FuelTable = {
  _id: string
  accountId: string
  addedById: string
  carrierId: string | null
  createdDate: string
  rows: FuelTableEntry[]
}

export const flatContractedRateSchema = vPipe(
  vObject({
    rateAmount: vPipe(vNumber(), vMinValue(0)),
    rateCurrency: vPicklist(['usd']),
    rateType: vPicklist(['flat', 'per-mile']),
    minimumRate: vPipe(vNumber(), vMinValue(0)),
    originPostalCode: vOptional(vPostalCode(true)),
    originState: vOptional(vPicklist(states.map(s => s.abbreviation))),
    originCountry: vCountryAny,
    destinationPostalCode: vOptional(vPostalCode(true)),
    destinationPostalCodeEnd: vOptional(vPostalCode(true)),
    destinationState: vOptional(vPicklist(states.map(s => s.abbreviation))),
    destinationCountry: vCountryAny,
    distance: vOptional(vPipe(vNumber(), vMinValue(0))),
    distanceUOM: vPicklist(['mi']),
    startDate: vDate(),
    endDate: vPipe(
      vDate(),
      vMinValue(
        dayjs().startOf('day').toDate(),
        'Must be greater than or equal to today',
      ),
    ),
    fuelIncluded: vBoolean(),
    contractNumber: vOptional(vString()),
    serviceId: vPicklist(['truckload']),
    equipmentTypes: vPipe(
      vCoerceArray(
        vPipe(
          vString(),
          vTransform(
            v =>
              equipmentTypes.find(
                e =>
                  e.key.replace(/[^a-zA-Z0-9]/g, '') ===
                  v.toLowerCase().replace(/[^a-zA-Z0-9]/g, ''),
              )?.key || v,
          ),
          vPicklist(equipmentTypes.map(e => e.key)),
        ),
      ),
      vMinLength(1, 'At least one equipment type is required'),
    ),
  }),
  vRawCheck(({ dataset, addIssue }) => {
    if (dataset.typed) {
      if (
        dataset.value.destinationPostalCodeEnd &&
        !dataset.value.destinationPostalCode
      ) {
        addIssue({
          message:
            'Destination postal code is required when destination postal code end is provided.',
          path: [
            {
              type: 'object',
              key: 'destinationPostalCode',
              input: dataset.value,
              origin: 'value',
              value: dataset.value.destinationPostalCode,
            },
          ],
        })
      }

      if (
        dataset.value.destinationPostalCodeEnd &&
        dataset.value.destinationPostalCode &&
        dataset.value.destinationPostalCodeEnd <=
          dataset.value.destinationPostalCode
      ) {
        addIssue({
          message:
            'Destination postal code end must be greater than destination postal code.',
          path: [
            {
              type: 'object',
              key: 'destinationPostalCodeEnd',
              input: dataset.value,
              origin: 'value',
              value: dataset.value.destinationPostalCodeEnd,
            },
          ],
        })
      }

      if (
        dataset.value.destinationPostalCode &&
        dataset.value.destinationState
      ) {
        addIssue({
          message: 'Destination state and postal code are mutually exclusive.',
          path: [
            {
              type: 'object',
              key: 'destinationState',
              input: dataset.value,
              origin: 'value',
              value: dataset.value.destinationState,
            },
          ],
        })
      }

      if (dataset.value.originPostalCode && dataset.value.originState) {
        addIssue({
          message: 'Origin state and postal code are mutually exclusive.',
          path: [
            {
              type: 'object',
              key: 'originState',
              input: dataset.value,
              origin: 'value',
              value: dataset.value.originState,
            },
          ],
        })
      }

      if (
        dataset.value.destinationState &&
        states.find(s => s.abbreviation === dataset.value.destinationState)
          ?.country !== dataset.value.destinationCountry
      ) {
        addIssue({
          message: 'Invalid destination state and country combination.',
          path: [
            {
              type: 'object',
              key: 'destinationState',
              input: dataset.value,
              origin: 'value',
              value: dataset.value.destinationState,
            },
          ],
        })
      }

      if (
        dataset.value.originState &&
        states.find(s => s.abbreviation === dataset.value.originState)
          ?.country !== dataset.value.originCountry
      ) {
        addIssue({
          message: 'Invalid origin state and country combination.',
          path: [
            {
              type: 'object',
              key: 'originState',
              input: dataset.value,
              origin: 'value',
              value: dataset.value.originState,
            },
          ],
        })
      }

      if (!dataset.value.originState && !dataset.value.originPostalCode) {
        addIssue({
          message: 'Origin state or postal code is required.',
          path: [
            {
              type: 'object',
              key: 'originState',
              input: dataset.value,
              origin: 'value',
              value: dataset.value.originState,
            },
            {
              type: 'object',
              key: 'originPostalCode',
              input: dataset.value,
              origin: 'value',
              value: dataset.value.originPostalCode,
            },
          ],
        })
      }

      if (
        !dataset.value.destinationState &&
        !dataset.value.destinationPostalCode
      ) {
        addIssue({
          message: 'Destination state or postal code is required.',
          path: [
            {
              type: 'object',
              key: 'destinationState',
              input: dataset.value,
              origin: 'value',
              value: dataset.value.destinationState,
            },
            {
              type: 'object',
              key: 'destinationPostalCode',
              input: dataset.value,
              origin: 'value',
              value: dataset.value.destinationPostalCode,
            },
          ],
        })
      }
      if (dataset.value.endDate <= dataset.value.startDate) {
        addIssue({
          message: 'End date must be greater than start date.',
          path: [
            {
              type: 'object',
              key: 'endDate',
              input: dataset.value,
              origin: 'value',
              value: dataset.value.endDate,
            },
          ],
        })
      }
    }

    return true
  }),
)

export type FlatContractedRate = vOutput<typeof flatContractedRateSchema>

export type ContractedRateSort =
  | 'createdAt'
  | 'startDate'
  | 'endDate'
  | 'contractNumber'
  | 'carrierName'
  | 'origin'
  | 'destination'
  | 'amount'
  | 'status'
