import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FvButton, FvLinkButton, useFvNavigate } from '@fv/client-components'
import { isEnter, isEnterPrevented } from '@fv/client-core'
import { type Workflow } from '@fv/models'
import { hasMixedPallets } from '@fv/models/core'

import InfoBox from '../../components/InfoBox'
import { type FormSectionProps } from '../../components/inputs/types'
import { Loading } from '../../components/Loading'
import { useQuoteSettings } from '../../hooks/settings'
import { useCancelLoad, useLoad } from '../../hooks/shipments'
import { routes } from '../../routes'
import { locationToFormStop } from '../../utils/locationFuncs'
import { defaultStopDate } from '../../utils/shipmentFuncs'
import { crStorageKey } from '../book/CustomerRoutedBookingPage'
import { FedExHomeDeliveryQuoting } from '../book/FedExHomeDelivery'
import {
  defaultLoadHandlingUnit,
  useLoadHandlingUnitFormModels,
} from '../commodities/load-items/loadItemHelpers'
import {
  LoadItemsForm,
  type LoadItemsFormRef,
} from '../commodities/load-items/LoadItemsFormProvider'
import { type LoadHandlingUnitFormModel } from '../commodities/load-items/types'
import { useCreateQuoteRequest } from '../quote-request/useCreateQuoteRequest'
import { useUpdateQuoteRequest } from '../quote-request/useUpdateQuoteRequest'
import { AutofillPreviousShipment } from '../shipment-form/AutofillPreviousShipment'
import {
  defaultDestination,
  defaultOrigin,
} from '../shipment-location-form/types'
import { QuoteFormLocations } from './QuoteFormLocations'
import { type QuoteRequestMode } from './quoteFuncs'
import { useQuotePageCtx, useQuotePageWorkflow } from './QuotePageProvider'

type SettingsPanelProps = FormSectionProps & {
  workflow: Workflow
  mode: QuoteRequestMode
  onItemsChange: (items: LoadHandlingUnitFormModel[]) => void
}

const SettingsPanel = ({
  disabled,
  workflow,
  mode,
  onItemsChange,
}: SettingsPanelProps) => {
  const actions = useQuotePageCtx(s => s.actions)

  return (
    <div className="bg-fv-blue-50 border-fv-blue-300 -mx-6 -mt-10 mb-8 flex items-center border-b border-dashed px-6 py-4">
      <ul className="mb-0 flex items-center">
        <li className="whitespace-nowrap border-0 py-0">
          <FvLinkButton
            disabled={disabled}
            fw
            icon="history"
            to={routes.recentQuotes(workflow)}
          >
            <span>Recent quotes</span>
          </FvLinkButton>
        </li>
        <li className="ml-4 mr-4 whitespace-nowrap border-0 border-l border-r py-0 pl-4 pr-4">
          <FvLinkButton
            disabled={disabled}
            fw
            icon="star-sharp"
            to={routes.savedQuotes(workflow)}
            transform="up-1"
          >
            <span>Saved quotes</span>
          </FvLinkButton>
        </li>
      </ul>
      {mode !== 'edit' && mode !== 'copy' && (
        <AutofillPreviousShipment
          onDestinationChange={loc =>
            actions.setLocationData(
              'destination',
              loc === null
                ? defaultDestination()
                : locationToFormStop(loc, workflow),
            )
          }
          onOriginChange={loc =>
            actions.setLocationData(
              'origin',
              loc === null
                ? defaultOrigin()
                : {
                    ...locationToFormStop(loc, workflow),
                    stopDate: defaultStopDate('origin'),
                    refNum: '',
                  },
            )
          }
          onProductChange={onItemsChange}
          workflow={workflow}
        />
      )}
    </div>
  )
}

export type QuotePageFormParams = {
  loadId?: string
  mode?: QuoteRequestMode
}

export const QuotePageForm = () => {
  const { loadId, mode } = useParams<QuotePageFormParams>()
  const loadQuery = useLoad(loadId)
  const itemsFormRef = useRef<LoadItemsFormRef>(null)
  const actions = useQuotePageCtx(s => s.actions)
  const workflow = useQuotePageWorkflow()
  const itemFormModelsQuery = useLoadHandlingUnitFormModels(loadQuery.data)
  const [items, setItems] = useState<LoadHandlingUnitFormModel[]>(
    itemFormModelsQuery.data.length
      ? itemFormModelsQuery.data
      : [defaultLoadHandlingUnit(workflow)],
  )
  const isEditing = loadId && mode === 'edit'
  const isFreightCollect = useQuotePageCtx(s => s.load.isFreightCollect)
  const navigate = useFvNavigate()
  const requestQuotes = useCreateQuoteRequest()
  const cancelLoad = useCancelLoad(loadId, false)
  const quoteRequestId = useQuotePageCtx(s => s.load.quoteRequestId)
  const updateQuoteRequest = useUpdateQuoteRequest(quoteRequestId)
  const quoteSettings = useQuoteSettings(workflow)
  const isLoading =
    quoteSettings.isLoading ||
    requestQuotes.isLoading ||
    requestQuotes.isSuccess ||
    updateQuoteRequest.isLoading ||
    updateQuoteRequest.isSuccess ||
    cancelLoad.isLoading ||
    cancelLoad.isSuccess

  const parentClasses =
    'ltl-quote-route-ctn row-start-2 b1350:row-start-3 b1200:pb-28 b1350:col-span-full'

  async function submitForm() {
    if (isLoading) return
    if (
      !itemsFormRef.current?.validateForm() ||
      !itemsFormRef.current?.validateItems(workflow, 'quoting')
    )
      return

    const quoteRequest = actions.buildRequest({ items })
    if (!quoteRequest) return

    if (isFreightCollect) {
      if (isEditing) {
        // The only way editing & isFreightCollect is true is if they created the load as non-freight-collect originally
        // so we cancel the original load
        try {
          await cancelLoad.mutateAsync(false)
        } catch {
          // if we fail to cancel the original load for any reason - the worst that could happen is they have
          // a duplicate entry in their in process page.  This doesn't seem worth preventing them from creating
          // the CR shipment
        }
      }
      const crData = JSON.stringify(quoteRequest)
      localStorage.setItem(crStorageKey, crData)
      return navigate(routes.customerRouted())
    }

    if (isEditing) {
      updateQuoteRequest.mutate(quoteRequest)
    } else {
      requestQuotes.mutate(quoteRequest, {
        onSuccess: loads => {
          navigate(routes.rates(loads[0]))
        },
      })
    }
  }

  if (
    workflow === 'parcel' &&
    !quoteSettings.data?.apiEnabledCarriers?.length
  ) {
    return (
      <div className={parentClasses}>
        <div className="parcel-from-to b900:flex-col flex gap-x-6 b1500:gap-x-4 items-stretch b1350:gap-x-6">
          <InfoBox>
            For a more detailed look at our{' '}
            <a
              className="text-fv-blue"
              href="https://blog.freightview.com/parcel-in-freightview"
              target="blank"
            >
              parcel product
            </a>{' '}
            and how to add it to your account please visit our{' '}
            <a
              className="text-fv-blue"
              href="https://blog.freightview.com/parcel-in-freightview"
              target="blank"
            >
              blog article
            </a>
            . If you have any more questions please contact our Support Team at{' '}
            <a href="mailto:support@freightview.com" className="text-fv-blue">
              support@freightview.com
            </a>
          </InfoBox>
        </div>
      </div>
    )
  }

  return (
    <>
      {isLoading ? (
        <div className={parentClasses}>
          <div className="parcel-from-to b900:flex-col flex gap-x-6 b1500:gap-x-4 items-stretch b1350:gap-x-6">
            <Loading>Loading Shipment...</Loading>
          </div>
        </div>
      ) : (
        <div
          className={parentClasses}
          onKeyDown={e => {
            isEnter(e) && !isEnterPrevented(e) && submitForm()
          }}
        >
          <SettingsPanel
            disabled={isLoading}
            mode={mode}
            workflow={workflow}
            onItemsChange={items => {
              setItems(items)
              itemsFormRef.current?.setItems(items)
            }}
          />
          <div className="parcel-from-to b900:flex-col flex gap-x-6 b1500:gap-x-4 items-stretch b1350:gap-x-6">
            <QuoteFormLocations disabled={isLoading} />

            <div className="ltl-quoting-destination basis-1/2 bg-gray-50 pt-10 px-4 pb-6 relative border border-fv-gray">
              <LoadItemsForm
                ref={itemsFormRef}
                type="paged"
                workflow={workflow}
                step="quoting"
                onChange={setItems}
                initialItems={items}
              />
            </div>
          </div>

          {workflow === 'parcel' && <FedExHomeDeliveryQuoting />}
        </div>
      )}

      <div className="footer-actions row-start-[-1] col-start-1 col-span-3 bg-fv-blue-150 p-3 flex justify-center items-center border-fv-blue-400 border-t b1200:fixed b1200:bottom-0 w-screen">
        {loadId && mode && (
          <>
            <FvButton icon="times" onClick={() => navigate(-1)} theme="default">
              Cancel
            </FvButton>
            <span className="ml-2 mr-2">-or-</span>
          </>
        )}

        <FvButton
          fwd
          icon={isLoading ? 'spinner' : 'dollar-sign'}
          onClick={submitForm}
          theme="primary"
        >
          {isFreightCollect
            ? 'Book shipment'
            : isEditing
              ? 'Save changes'
              : hasMixedPallets(items)
                ? 'Add rates'
                : 'Show my rates'}
        </FvButton>
      </div>
    </>
  )
}
