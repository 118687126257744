import dayjs from 'dayjs'
import { useRef } from 'react'
import toast from 'react-hot-toast'

import { FvButton, ValidatedForm } from '@fv/client-components'
import { useFormModelState } from '@fv/client-core'
import { type SelectFieldOption } from '@fv/client-types'
import { type ContractedRate } from '@fv/models'

import {
  InputAdornment,
  InputGroup,
  InputGroupWrapper,
} from '../../../components/inputs/InputGroup'
import MultiSelectField from '../../../components/inputs/MultiSelectField'
import { SelectField } from '../../../components/inputs/SelectField'
import TextInput from '../../../components/inputs/TextInput'
import { useAccountFeatures } from '../../auth'
import { useCarrierOptions, useEquipmentOptions } from './hooks'
import { useAddContractedRates, useUpdateContractedRate } from './mutations'
import { type LocType, RateLocationForm } from './RateLocationForm'
import { type EquipmentTypeOption } from './types'

type ContractedRateFormProps = {
  rate?: ContractedRate
  onSubmit?: (carrierId: string) => void
  onCancel?: () => void
}

const getRateLocType = (rate?: ContractedRate): LocType => {
  if (rate?.destination.postalCodeEnd) return 'zip-range'
  if (
    !rate?.origin.postalCode &&
    !rate?.destination.postalCode &&
    rate?.origin.state &&
    rate?.destination.state
  )
    return 'state-to-state'
  if (rate?.destination.state) return 'state'
  return 'zip'
}

export const ContractedRateForm = ({
  rate,
  onSubmit,
  onCancel,
}: ContractedRateFormProps) => {
  const isNew = !rate
  const startDateRef = useRef<HTMLInputElement>(null)
  const endDateRef = useRef<HTMLInputElement>(null)
  const addRates = useAddContractedRates()
  const carriers = useCarrierOptions()
  const equipmentOptions = useEquipmentOptions()
  const { fuelAndDistance } = useAccountFeatures()
  const updateRate = useUpdateContractedRate()
  const isBusy = addRates.isLoading || updateRate.isLoading
  const rateTypeOptions: SelectFieldOption<
    ContractedRate['rate']['rateType']
  >[] = [{ text: 'Flat', value: 'flat' }]
  if (fuelAndDistance)
    rateTypeOptions.push({ text: 'Per mile', value: 'per-mile' })

  const form = useFormModelState<ContractedRate>({
    initialValue: {
      ...(rate ?? {
        destination: {
          country: 'us',
        },
        origin: {
          country: 'us',
        },
        distanceUOM: 'mi',
        fuelIncluded: false,
        equipmentTypes: [],
        carrierId: carriers.data?.[0]?.value ?? '',
        rate: {
          currency: 'usd',
          rateType: 'flat',
          serviceId: 'truckload',
          amount: 0,
        },
      }),
      ...(rate && {
        startDate: dayjs(rate?.startDate).format('MM/DD/YYYY'),
        endDate: dayjs(rate?.endDate).format('MM/DD/YYYY'),
      }),
    },
  })
  const { register, value, setValue } = form

  const onValidSubmit = () => {
    if (isBusy || !startDateRef.current?.value || !endDateRef.current?.value)
      return
    if (!value.carrierId) {
      value.carrierId = carriers.data?.[0]?.value ?? ''
    }

    const startDate = dayjs(startDateRef.current?.value).toISOString()
    const endDate = dayjs(endDateRef.current?.value).toISOString()

    if (isNew) {
      addRates
        .mutateAsync([
          {
            ...value,
            endDate,
            startDate,
          },
        ])
        .then(() => onSubmit?.(value.carrierId))
        .catch(e => {
          toast.error(e.message)
        })
    } else {
      updateRate
        .mutateAsync({
          ...rate,
          ...value,
          endDate,
          startDate,
        })
        .then(() => onSubmit?.(value.carrierId))
        .catch(e => {
          toast.error(e.message)
        })
    }
  }

  return (
    <ValidatedForm onValidSubmit={onValidSubmit}>
      {isNew ? (
        <div className="form-row">
          <div className="form-group col-12">
            <label className="label" htmlFor="carrierId">
              Carrier
            </label>
            <SelectField
              {...register('carrierId', {})}
              placeholder="Select a carrier"
              required
              options={carriers.data}
            />
          </div>
        </div>
      ) : (
        <div className="mb-4">Edit contracted rate</div>
      )}
      <RateLocationForm initialType={getRateLocType(rate)} form={form} />
      <div className="form-row">
        <InputGroup
          className="w-2/12"
          label="Rate"
          required
          inputType="amount"
          startContent={<InputAdornment position="start" icon="dollar-sign" />}
          inputProps={{
            ...register('rate.amount', {
              valueAsNumber: true,
            }),
            isPrice: true,
            min: 0.01,
          }}
        />

        <InputGroup
          className="w-2/12"
          label="Currency"
          required
          inputType="select"
          inputProps={{
            ...register('rate.currency'),
            options: [{ value: 'usd', text: 'USD' }],
          }}
        />
        <InputGroup
          className="w-4/12"
          label="Rate type"
          required
          inputType="select"
          inputProps={{
            ...register('rate.rateType'),
            options: rateTypeOptions,
          }}
        />

        <InputGroup
          className="w-2/12"
          label="Distance"
          inputType="amount"
          inputProps={{
            ...register('distance', {
              valueAsNumber: true,
              min: 1,
            }),
          }}
        />

        <InputGroup
          className="w-2/12"
          label="Unit"
          inputType="select"
          inputProps={{
            ...register('distanceUOM'),
            options: [{ text: 'Miles', value: 'mi' }],
          }}
        />

        <InputGroupWrapper
          label="Contract start date"
          required
          name="startDate"
          className="w-1/4"
        >
          <InputAdornment
            onClick={() => {
              startDateRef.current?.showPicker()
            }}
            icon="calendar-day"
            position="start"
            iconClass="fv-blue"
          />

          <TextInput
            className="form-control"
            name="startDate"
            defaultValue={
              value.startDate ? dayjs(value.startDate).format('YYYY-MM-DD') : ''
            }
            type="date"
            required
            ref={startDateRef}
          />
        </InputGroupWrapper>
        <InputGroupWrapper
          label="Contract end date"
          required
          name="startDate"
          className="w-1/4"
        >
          <InputAdornment
            onClick={() => {
              endDateRef.current?.showPicker()
            }}
            icon="calendar-day"
            position="start"
            iconClass="fv-blue"
          />

          <TextInput
            className="form-control"
            name="endDate"
            defaultValue={
              value.endDate ? dayjs(value.endDate).format('YYYY-MM-DD') : ''
            }
            type="date"
            ref={endDateRef}
            required
          />
        </InputGroupWrapper>

        <InputGroup
          className="w-1/4"
          label="Contract number"
          inputType="text"
          inputProps={{
            ...register('contractNumber'),
          }}
        />
        <InputGroup
          className="w-1/4"
          label="Minimum rate"
          inputType="amount"
          inputProps={{
            isPrice: true,
            ...register('minimumRate', {
              valueAsNumber: true,
            }),
          }}
        />

        <InputGroupWrapper
          label="Equipment type"
          required
          name="equipmentTypes"
          className="w-10/12"
        >
          <MultiSelectField
            // @ts-expect-error component not TS
            isSearchable
            menuPlacement="top"
            name="equip-type"
            onChange={(nextEquipment: EquipmentTypeOption[]) => {
              setValue(p => ({
                ...p,
                equipmentTypes: nextEquipment.map(e => e.value),
              }))
            }}
            options={equipmentOptions}
            required
            values={
              value.equipmentTypes
                ?.map(id => equipmentOptions.find(e => e.value === id))
                .filter((e): e is EquipmentTypeOption => !!e) ?? []
            }
          />
        </InputGroupWrapper>

        <InputGroup
          className="w-2/12"
          label="Fuel incl"
          inputType="select"
          inputProps={{
            options: [
              { text: 'Yes', value: 'true' },
              { text: 'No', value: 'false' },
            ],
            ...register('fuelIncluded'),
          }}
        />
      </div>

      <hr />

      <div className="flex !justify-end">
        <FvButton theme="plain" onClick={onCancel} type="button" icon="times" />

        <FvButton
          type="submit"
          icon="cloud-upload"
          theme="primary"
          loading={isBusy}
        >
          {isNew ? 'Save contracted rate' : 'Update contracted rate'}
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
