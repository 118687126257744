import { type MutateOptions, useMutation } from '@tanstack/react-query'
import { type ChangeEvent, useState } from 'react'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import {
  type FullShipment,
  type ListShipment,
  type RefNum,
} from '@fv/client-types'

import { apiUri, supportMessage } from '../../constants'
import { useUpdateCachedLoad } from '../../hooks/shipments'
import { usePermissions } from '../auth'

async function editRefNums({
  loadId,
  refNums,
}: {
  loadId: string
  refNums: RefNum[]
}): Promise<ListShipment> {
  const endpoint = `${apiUri}/shipments/${loadId}/ref-nums`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify(refNums),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

function useEditRefNums(loadId: string) {
  const updateCachedLoad = useUpdateCachedLoad()

  return useMutation(
    (refNums: RefNum[]) => {
      return editRefNums({ loadId, refNums })
    },
    {
      onSettled: (updatedLoad, error) => {
        if (error) {
          toast.error(`Unable to update reference number, ${supportMessage}`)
          return
        }

        if (updatedLoad) updateCachedLoad({ updatedLoad })
        toast.success('Reference number updated successfully.')
      },
    },
  )
}

export function useEditableRefNums(load: FullShipment) {
  const { canBook } = usePermissions()

  const editRefNums = useEditRefNums(load.loadId)
  const canEditRefNums = canBook
  const editable = load.refNums.filter(n => {
    return canEditRefNums && n.type === 'other'
  })

  if (!editable.length && canEditRefNums && load.workflow === 'truckload') {
    editable.push({ type: 'other', value: '' })
  }

  const [editableRefNums, setEditableRefNums] = useState<RefNum[]>(editable)

  const staticRefNums = load.refNums.filter(n => {
    return !canEditRefNums || n.type !== 'other'
  })

  function onRefNumChange(event: ChangeEvent<HTMLInputElement>, index: number) {
    setEditableRefNums(prev => {
      return prev.map((n, i) => {
        if (i === index) return { ...n, value: event.target.value }
        return n
      })
    })
  }

  function onRefNumSave(
    opts: MutateOptions<ListShipment, unknown, RefNum[], unknown>,
  ) {
    editRefNums.mutate([...staticRefNums, ...editableRefNums], opts)
  }

  return {
    editableRefNums,
    canEditRefNums,
    staticRefNums,
    onRefNumChange,
    onRefNumSave,
    isLoading: editRefNums.isLoading,
  }
}

const createSpecialRefNumMutation =
  (type: 'seal-num' | 'trailer-num', display: string) =>
  (loadId: string, refNums: RefNum[]) => {
    const updateCachedLoad = useUpdateCachedLoad()
    return useMutation(
      (value: string) => {
        return editRefNums({
          loadId,
          refNums: [
            ...refNums.filter(r => r.type !== type),
            ...(value && [{ type, value }]),
          ],
        })
      },
      {
        onSettled: (updatedLoad, error) => {
          if (error) {
            toast.error(`Unable to update ${display}, ${supportMessage}`)
            return
          }

          if (updatedLoad) updateCachedLoad({ updatedLoad })
          toast.success(`${display} updated successfully.`)
        },
      },
    )
  }

export const useEditTrailerNumber = createSpecialRefNumMutation(
  'trailer-num',
  'Trailer number',
)
export const useEditSealNumber = createSpecialRefNumMutation(
  'seal-num',
  'Seal number',
)
