import partition from 'lodash/partition'
import sortBy from 'lodash/sortBy'

import { type IconProps } from '@fv/client-components'
import { type FullShipment, type UIQuote } from '@fv/client-types'

import { useQuoteSettings } from '../../hooks/settings'
import { quoteFuncs } from './quoteFuncs'

export type RatesListingMode = 'view' | 'change' | undefined
export type SortType = 'cheapest' | 'fastest'

export const rateSortTypes: Array<{ type: SortType; icon: IconProps['icon'] }> =
  [
    {
      type: 'cheapest',
      icon: 'dollar-sign',
    },
    {
      type: 'fastest',
      icon: 'truck-fast',
    },
  ]
export interface RateListingData {
  manualRates: UIQuote[]
  activeApiRates: UIQuote[]
  erroredRates: UIQuote[]
  pendingRates: UIQuote[]
}
export function useRateListing(
  {
    workflow,
    source,
    quoteProcessStatus,
  }: Pick<FullShipment, 'source' | 'workflow' | 'quoteProcessStatus'>,
  rates: UIQuote[] = [],
  sortType: SortType,
): RateListingData {
  const settingsQuery = useQuoteSettings(workflow)
  const apiCarriers = settingsQuery.data?.apiEnabledCarriers ?? []
  const allRates = rates.slice()

  allRates.push(
    ...apiCarriers
      .filter(
        c =>
          // Add processing/errored rate for API carriers with no response
          !allRates.some(
            r => r.providerCode === c.code || r.assetCarrierCode === c.code,
          ),
      )
      .map<UIQuote>(c => {
        const carrierName = c.label.trim()
        const isRequesting =
          quoteProcessStatus === 'requesting' ||
          quoteProcessStatus === 'rerating'
        let message = `${carrierName} did not return rates.`

        if (source === 'v1') {
          message += `  Please ensure you are not excluding this carrier when rating via an integration.`
        }
        return {
          _id: c._id,
          amount: 0,
          assetCarrierCode: c.code,
          assetCarrierName: carrierName,
          carrierId: c._id,
          createdDate: new Date().toISOString(),
          carrierSort: carrierName.toLowerCase(),
          currency: 'usd',
          equipmentType: workflow === 'parcel' ? 'parcel' : 'ltl',
          method: 'api',
          mode: workflow === 'parcel' ? 'parcel' : 'ltl',
          pricingMethod: 'capacity',
          pricingType: 'pallet',
          providerCode: c.code,
          providerName: carrierName,
          serviceId: '',
          source: 'carrier',
          status: isRequesting ? 'requesting' : 'error',
          ...(!isRequesting && {
            errors: [
              {
                message,
                type: 'timeout',
              },
            ],
          }),
        }
      }),
  )

  const [active, errored] = partition(allRates, r => r.status !== 'error')
  const sortedRates = sortRates(sortType, active)
  const [manualRates, apiRates] = partition(sortedRates, r => {
    return r.method === 'manual' && !quoteFuncs.isSpotWorkflow(r)
  })

  // List manual rates first
  const activeApiRates = apiRates.filter(r => r.status !== 'requesting')
  const pendingApiRates = apiRates.filter(
    r =>
      r.status === 'requesting' &&
      !activeApiRates.some(
        a =>
          getCarrierIdentifier(a) === getCarrierIdentifier(r) ||
          a.providerCode === r.providerCode,
      ),
  )

  return {
    activeApiRates,
    erroredRates: errored,
    pendingRates: pendingApiRates,
    manualRates,
  }
}

export function getCarrierIdentifier(quote: UIQuote) {
  const code = quote.assetCarrierCode ?? quote.providerCode
  if (code) {
    return code
  }
  return quote.providerName
}

const sortRates = (type: SortType, quotes: UIQuote[]): UIQuote[] => {
  if (type === 'cheapest') {
    return sortBy(
      quotes,
      q => q.status !== 'awarded',
      'amount',
      'transitDaysMin',
      q => quoteFuncs.guaranteedSort(q),
      'deliveryDate',
      'serviceId',
      q => quoteFuncs.carrierSort(q),
    )
  } else {
    return sortBy(
      quotes,
      q => q.status !== 'awarded',
      q => quoteFuncs.interlineSort(q),
      'transitDaysMin',
      'deliveryDate',
      q => quoteFuncs.guaranteedSort(q),
      'amount',
      'serviceId',
      q => quoteFuncs.carrierSort(q),
    )
  }
}
