import { useMemo } from 'react'

import { type Workflow } from '@fv/models'

import { useQuoteSettings } from '../settings'

export function useRecentGroups(workflow: Workflow) {
  const settingsQuery = useQuoteSettings(workflow)

  return {
    isLoading: settingsQuery.isLoading,
    ids: useMemo(() => {
      const recentGroups = settingsQuery.data?.recentSpotQuoteGroups ?? []
      return recentGroups.map(g => g.groupId)
    }, [settingsQuery.data?.recentSpotQuoteGroups]),
  }
}
