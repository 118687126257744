import dayjs from 'dayjs'
import { type PropsWithChildren, useMemo } from 'react'

import { type SavedView } from '@fv/models'

import { readyCompletedStatusOptions } from '../../components/CustomFilterForm/filterOptions'
import { type FilterConfig } from '../../components/CustomFilterForm/types'
import {
  type SavedFilter,
  type SavedFilterGroup,
} from '../../types/SavedFilter'
import { useRole } from '../auth/hooks'
import { ShipmentFilterListProvider } from './ShipmentFilterListProvider'
import {
  archivedFilter,
  bookedDateFilter,
  deliveryDateFilter,
  directionFilter,
  hasDocumentFilter,
  interlineFilter,
  isFreightCollectFilter,
  missingDocumentFilter,
  pickupDateFilter,
  pricingMethodFilter,
  pricingTypeFilter,
  shippingModeFilter,
  useAccountFilters,
} from './useFilterConfigs'
import { useHiddenViews, useSavedViews } from './useSavedViews'

export const ConfirmedFiltersProvider = ({ children }: PropsWithChildren) => {
  const filters = useConfirmedFilters()
  const filterConfig = useConfirmedFilterConfig()
  return (
    <ShipmentFilterListProvider
      state={{
        listType: 'readyCompletedList',
        filters,
        filterConfig,
      }}
    >
      {children}
    </ShipmentFilterListProvider>
  )
}

const confirmedFilters: Array<SavedFilter> | Array<SavedFilterGroup> = [
  {
    _id: 'active',
    isDefault: true,
    isLocked: true,
    isSystemFilter: true,
    name: 'Active',
    queryString: '',
  },
  {
    _id: 'confirmed',
    isSystemFilter: true,
    name: 'Ready / Scheduled',
    queryString: 'isArchived=false&status[]=confirmed&view=confirmed&',
  },
  {
    _id: 'picked-up',
    isSystemFilter: true,
    name: 'Picked up',
    queryString: 'isArchived=false&status[]=picked-up&view=picked-up',
  },
  {
    _id: 'delivered',
    isSystemFilter: true,
    name: 'Delivered',
    queryString: 'isArchived=false&status[]=delivered&view=delivered',
  },
  {
    _id: 'all',
    icon: 'list',
    isLocked: true,
    isSystemFilter: true,
    name: 'All',
    queryString: 'view=all',
  },
  {
    _id: 'archived',
    icon: 'archive',
    isSystemFilter: true,
    name: 'Archived',
    queryString: 'isArchived=true&view=archived',
  },
]

export type ConfirmedFilter = SavedFilter & Pick<SavedView, 'scheduledReports'>
export const useConfirmedFilters = (): ConfirmedFilter[] => {
  const hiddenViews = useHiddenViews()
  const savedViews = useSavedViews('readyCompletedList')

  return useMemo(() => {
    const systemFilters =
      confirmedFilters?.filter(f => !hiddenViews.find(id => id === f._id)) ?? []

    const splitIndex = systemFilters.findIndex(f => f._id === 'all')

    return systemFilters
      .slice(0, splitIndex)
      .concat(savedViews)
      .concat(systemFilters.slice(splitIndex))
      .map(s => ({
        ...s,
        scheduledReports: s.scheduledReports?.map(r => ({
          ...r,
          time: dayjs.utc(r.time, 'HH:mm').local().format('HH:mm'),
        })),
      }))
  }, [hiddenViews, savedViews])
}

const useConfirmedFilterConfig = (): FilterConfig[] => {
  const role = useRole()
  const {
    bookedByFilter,
    carrierFilter,
    locationFilter,
    quotedByFilter,
    tagFilter,
    vendorFilter,
  } = useAccountFilters()

  return [
    pickupDateFilter,
    bookedDateFilter,
    deliveryDateFilter,
    {
      label: 'Status',
      name: 'status[]',
      options: readyCompletedStatusOptions,
    },
    shippingModeFilter,
    carrierFilter,
    ...(role === 'shipper'
      ? [
          directionFilter,
          quotedByFilter,
          bookedByFilter,
          interlineFilter,
          isFreightCollectFilter,
          pricingMethodFilter,
          pricingTypeFilter,
          tagFilter,
          locationFilter,
          hasDocumentFilter,
          missingDocumentFilter,
          archivedFilter,
          vendorFilter,
        ]
      : []),
  ].filter(f => f !== null)
}
