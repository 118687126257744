import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useLocation } from 'react-router-dom'

import { getNextPageParam } from '@fv/client-core'
import { type PagedShipments } from '@fv/client-types'

import { defaultPageSize } from '../../../constants'
import { shipperFetch } from '../../../utils/shipperFetch'
import { migrateParams } from '../../../utils/stringTransforms'
import { defaultSortBy, defaultSortDirection } from './useShipmentListParams'

// Ensure order in queryKey used by cache
function normalizeQueryString(qs: string) {
  const source = new URLSearchParams(qs)
  const target = new URLSearchParams()

  migrateParams('pickupDate', source, target)
  migrateParams('bookedDate', source, target)
  migrateParams('deliveryDate', source, target)
  migrateParams('status[]', source, target)
  migrateParams('direction[]', source, target)
  migrateParams('mode[]', source, target)
  migrateParams('carrier[]', source, target)
  migrateParams('quotedBy[]', source, target)
  migrateParams('bookedBy[]', source, target)
  migrateParams('isInterline', source, target)
  migrateParams('isFreightCollect', source, target)
  migrateParams('pricingMethod[]', source, target)
  migrateParams('pricingType[]', source, target)
  migrateParams('tag[]', source, target)
  migrateParams('location[]', source, target)
  migrateParams('isArchived', source, target)
  migrateParams('hasDocs[]', source, target)
  migrateParams('missingDocs[]', source, target)
  migrateParams('search', source, target)
  migrateParams('sortBy', source, target)
  migrateParams('sortDirection', source, target)
  migrateParams('vendors[]', source, target)

  return target.toString()
}

export const confirmedShipmentKeys = {
  all: ['shipments', 'confirmed'] as const,
  filtered: (qs: string) =>
    [...confirmedShipmentKeys.all, normalizeQueryString(qs)] as const,
}

export function isDefaultFilter(qs: string) {
  const params = new URLSearchParams(qs)
  const view = params.get('view')
  return !view || view === 'active'
}

export function useConfirmedShipments() {
  const { search } = useLocation()
  const params = new URLSearchParams(search)

  if (isDefaultFilter(search)) params.set('isArchived', 'false')
  if (!params.get('sortBy')) params.set('sortBy', defaultSortBy)
  if (!params.get('sortDirection')) {
    params.set('sortDirection', defaultSortDirection)
  }

  const shipmentsQuery = useInfiniteQuery(
    confirmedShipmentKeys.filtered(params.toString()),
    ({ pageParam = 0 }) => {
      params.set('limit', String(defaultPageSize))
      params.set('skip', String(pageParam))
      return shipperFetch<PagedShipments>(
        `/shipments/ready-completed?${params.toString()}`,
      )
    },
    {
      getNextPageParam,
    },
  )

  // Handle invalid filters
  useEffect(() => {
    if (
      shipmentsQuery.error instanceof Error &&
      shipmentsQuery.error.name === 'EventValidationError'
    ) {
      toast.error(shipmentsQuery.error.message)
    }
  }, [shipmentsQuery.error])

  return shipmentsQuery
}
