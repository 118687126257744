import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'

import { waitForStateUpdate } from '@fv/client-core'
import { type FullShipment } from '@fv/client-types'
import { type BookRateDTO } from '@fv/models'

import { useUpdateCachedLoad } from '../../hooks/shipments'
import { shipperFetch } from '../../utils/shipperFetch'

type BookRateRequestOpts = BookRateDTO & {
  loadId: string
  quoteId: string
}
const postLoadBook = async ({
  loadId,
  quoteId,
  ...body
}: BookRateRequestOpts) =>
  await shipperFetch<FullShipment>(
    `/shipments/${loadId}/quotes/${quoteId}/book`,
    {
      method: 'POST',
      body,
    },
  )
export const useBookRate = () => {
  return useMutation(postLoadBook)
}

export const useVendorBookRate = () => {
  const bookRate = useMutation(postLoadBook)
  const updateCachedLoad = useUpdateCachedLoad()
  const mutateAsync = useCallback(
    async (...args: Parameters<typeof bookRate.mutateAsync>) => {
      const updatedLoad = await bookRate.mutateAsync(...args)
      // Wait for the state update to complete
      await waitForStateUpdate(() => {
        updateCachedLoad({
          updatedLoad,
        })
      })
    },
    [bookRate, updateCachedLoad],
  )
  return {
    mutateAsync,
    isLoading: bookRate.isLoading,
  }
}
