import clsx from 'clsx'
import pluralize from 'pluralize'

import { DelimitedContent, FvLinkButton, Tooltip } from '@fv/client-components'
import {
  type FullShipment,
  type PropsWithClassName,
  type Quote,
  type UIQuote,
} from '@fv/client-types'

import { routes } from '../../routes'
import { mapPaymentTerms } from '../../utils/shipmentFuncs'
import { HasPermission } from '../auth'
import { quoteFuncs } from '../quote/quoteFuncs'

const TransitDays = ({
  load,
  quote,
}: {
  load: FullShipment
  quote: UIQuote
}) => {
  const daysText = `${quote.transitDaysMin} ${pluralize(
    'day',
    quote.transitDaysMin,
  )}`
  return (
    <Tooltip
      label={`ETA ${quoteFuncs
        .getEstimatedDeliveryDate(load.pickupDate, quote)
        ?.format('MM-DD-YYYY')}`}
    >
      <span className="decoration-fv-blue ml-0 underline">{daysText}</span>
    </Tooltip>
  )
}
type Props = PropsWithClassName<{
  load?: FullShipment
  quote: Quote
}>
export const BookingRateDetails = ({ load, quote, className }: Props) => {
  const paymentTerms = mapPaymentTerms({ selectedQuote: quote })
  const transitDays = load && quote.transitDaysMin && (
    <TransitDays load={load} quote={quote} />
  )
  return (
    <div
      className={clsx(
        ' flex divide-x divide-[#e8e0b2] border-b border-[#e8e0b2] bg-fv-beer-light px-6 py-4',
        className,
      )}
    >
      <p className="mb-0">
        <DelimitedContent delimiter={<> / </>}>
          {quote.assetCarrierName ?? quote.providerName}
          {quote.serviceDescription && <> {quote.serviceDescription} </>}
          <HasPermission name="view-rates">
            ${quote.amount.toFixed(2)} {quote.paymentTerms && paymentTerms}
          </HasPermission>
          {transitDays && transitDays}
          {!!quote.quoteNum && <>Quote # {quote.quoteNum}</>}
        </DelimitedContent>
      </p>
      {!!load && (
        <FvLinkButton
          icon="list"
          className="ml-4 pl-4 leading-6"
          to={routes.rates(load)}
        >
          View other rates
        </FvLinkButton>
      )}
    </div>
  )
}
