import { useNavigate } from 'react-router-dom'

import { useFormModelState } from '@fv/client-core'
import { type AccountUserProfileUpdateDto } from '@fv/models'

import { EntryOverlay } from '../../components/EntryOverlay'
import { FormActions } from '../../components/forms/FormActions'
import { ValidatedForm } from '../../components/inputs'
import { useCurrentUser } from '../../hooks/settings/useAppContext'
import { useUpdateProfile } from '../profiile/mutations'
import { ProfileInfoForm } from '../profiile/ProfileInfoForm'

export const UserSetupPage = () => {
  const user = useCurrentUser()
  const navigate = useNavigate()
  const updateUserInfo = useUpdateProfile(true)
  const { register, value, setValue } =
    useFormModelState<AccountUserProfileUpdateDto>({
      initialValue: {
        email: user.email,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        phoneNumber: user.phoneNumber || '',
        title: user.title || '',
        password: '',
      },
    })

  const handleSubmit = async () => {
    await updateUserInfo.mutateAsync(value)
    return navigate('/active')
  }

  return (
    <EntryOverlay
      title="Welcome to Freightview!"
      subtitle={`${user.company} has invited you to be a member of their Freightview account. We need a little information from you, then you'll be in.`}
    >
      <ValidatedForm onValidSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-x-2">
          <ProfileInfoForm
            password
            company={false}
            register={register}
            value={value}
            onChange={setValue}
          />
          <div className="col-span-2 flex justify-end">
            <FormActions loading={updateUserInfo.isLoading} />
          </div>
        </div>
      </ValidatedForm>
    </EntryOverlay>
  )
}
