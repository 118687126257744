import { FvLinkButton } from '@fv/client-components'

import { routes, useQuotePage } from '../../routes'
import { usePermissions, useRole } from '../auth/hooks'

export const VendorRatesFooter = () => {
  const role = useRole()
  const { canBook } = usePermissions()
  const quotePage = useQuotePage()
  if (role !== 'vendor' || canBook) return null
  return (
    <div className="bg-fv-blue-50 border-t border-t-fv-gray-300 flex justify-center py-4 gap-x-4">
      <FvLinkButton to={quotePage} theme="default" icon="arrow-right">
        Create another shipment
      </FvLinkButton>{' '}
      <FvLinkButton to={routes.activeShipments()} theme="default" icon="truck">
        View shipments
      </FvLinkButton>
    </div>
  )
}
