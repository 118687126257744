import { type OverviewReportDto } from '@fv/models'

import { type ShipperUserPermissionType } from '../account-users/types'

export const basePath = '/analytics'
export const lanes = 'lanes'
export const providers = 'providers'
export const spotQuote = 'spot-quote'
export const users = 'users'
export const overview = 'overview'
export const subViews = [
  'cost-per-cwt',
  'spend',
  'volume',
  'weight',
  'win-rate',
  'response-time',
  'carrier-overview',
  'documents',
  'accessorials',
  'top-lanes',
  'map',
] as const

export type AnalyticsView =
  | typeof lanes
  | typeof providers
  | typeof spotQuote
  | typeof users
  | typeof overview
export type AnalyticsSubView = (typeof subViews)[number]

export type RouteParams = {
  subView?: AnalyticsSubView
  view?: AnalyticsView
}

export interface AnalyticsPageParams extends RouteParams {
  pickupDate: string | null
  qs: string
}

export type SpotQuoteAnalyticsDTO = {
  carrier: string
  declinedCount: number
  quoteCount: number
  responseTime: number
  totalCount: number
  winCount: number
}

export type SpotQuoteChartProps = {
  data: SpotQuoteAnalyticsDTO[]
}

export type UserStatsDTO = {
  userId: string
  parcelBooks: number
  parcelQuotes: number
  ltlBooks: number
  ltlQuotes: number
  tlBooks: number
  tlQuotes: number
  firstName: string
  lastName: string
  email: string
  permissions: ShipperUserPermissionType[]
  lastLogin: Date
  lastQuoteDate: Date
  lastBookDate: Date
  displayName: string
}

export type UserAnalyticsDTO = {
  user: UserStatsDTO[]
}

export interface FreightOverviewOpts
  extends Pick<OverviewReportDto, 'metric' | 'timeframe'> {}
