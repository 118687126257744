import { useEffect, useState } from 'react'
import { useToggle } from 'usehooks-ts'

import { usePermissions } from '../auth'
import { type ChargeListQuote, type ChargeListState } from './types'

export const useChargeList = (quote: ChargeListQuote): ChargeListState => {
  const { canBook } = usePermissions()
  const [isViewing, setIsViewing] = useState(false)
  const [isEditing, toggleEditing, setIsEditing] = useToggle()

  const setViewing = (value: boolean) => {
    setIsViewing(value)
    if (!quote?.charges?.length && value) {
      setIsEditing(true)
    }
  }

  useEffect(() => {
    if (!quote?.charges?.length && isViewing && !canBook) {
      setIsViewing(false)
    }
  }, [quote?.charges?.length, isViewing, canBook, setIsViewing])

  return {
    canView: canBook || !!quote?.charges?.length,
    canEdit: canBook && quote?.isSpot,
    quote,
    isEditing,
    isViewing,
    toggleViewing: () => setViewing(!isViewing),
    toggleEditing,
    setIsViewing: setViewing,
    setIsEditing,
  }
}
