import { type PropsWithChildren } from 'react'

import { type Workflow } from '@fv/models'

import { useCurrentUser } from '../../hooks/settings/useAppContext'

type Props = PropsWithChildren<{ workflow: Workflow }>
export const HasWorkflow = ({ children, workflow }: Props) => {
  const { workflows } = useCurrentUser()
  const hasWorkflow = workflows.includes(workflow)
  if (!hasWorkflow) return null
  return <>{children}</>
}
