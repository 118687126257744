import { type ChangeEvent } from 'react'

import { FvButton, Tooltip } from '@fv/client-components'
import { parseCurrency } from '@fv/client-core'

import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import { type QuoteEditModel } from '../mini-quote-list/types'

type Props = {
  quote: QuoteEditModel
  onChange: (quote: QuoteEditModel) => void
}

export const BaseRateField = ({ quote, onChange }: Props) => {
  const isEditingCharge = quote.charges?.length
  const charge = isEditingCharge
    ? quote.charges?.find(c => c.name === 'linehaul')
    : undefined
  const value = isEditingCharge ? charge?.amount : quote.amount

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const amount = parseCurrency(e.target.value)

    onChange({
      ...quote,
      ...(isEditingCharge
        ? {
            charges: quote.charges?.map(c =>
              c.name === charge.name ? { ...c, amount } : c,
            ),
          }
        : { amount }),
    })
  }

  return (
    <div className="flex items-center gap-3">
      <div className="italic">Base rate</div>

      <div className="flex-1 h-px border-t border-dotted w-full border-fv-gray" />
      <Tooltip label="You have the option to input either an 'all-in' rate here or a base rate along with itemized charges.">
        <FvButton
          icon="question-circle"
          iconClass="text-fv-orange-bright"
          className="cursor-default"
        />
      </Tooltip>
      <InputGroup
        className="mb-0 w-32"
        inputProps={{
          name: 'base-amount',
          value: value,
          className: 'text-right',
          isPrice: true,
          onChange: handleChange,
        }}
        inputType="amount"
        required
        startContent={<InputAdornment icon="dollar-sign" position="start" />}
      />
    </div>
  )
}
