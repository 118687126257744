import { useQuery } from '@tanstack/react-query'

import { shipperFetch } from '../../../utils/shipperFetch'
import { type VendorUserDTO, type VendorWithUsersDTO } from './types'

export const vendorKeys = {
  all: ['vendors'],
  users: (vendorId: string) => [...vendorKeys.all, vendorId, 'users'],
}

export const useVendors = (enabled = true) => {
  return useQuery(
    vendorKeys.all,
    () => shipperFetch<VendorWithUsersDTO[]>(`/vendors`),
    {
      enabled,
    },
  )
}

export const useVendorUsers = (
  vendorId: string,
  options?: { enabled?: boolean },
) => {
  return useQuery(
    vendorKeys.users(vendorId),
    () => shipperFetch<VendorUserDTO[]>(`/vendors/${vendorId}/users`),
    {
      enabled: options?.enabled !== false && !!vendorId,
      // Don't refetch on window focus for better UX
      refetchOnWindowFocus: false,
    },
  )
}
