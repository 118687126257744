import { type ReactNode } from 'react'

import { type UserPermissionType } from '@fv/models'

import { useHasPermission } from './hooks'

type Props = {
  children: ReactNode
  fallback?: JSX.Element
  name: UserPermissionType
}

export const HasPermission = ({ children, fallback, name }: Props) => {
  const hasPermission = useHasPermission(name)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (hasPermission) return <>{children}</>
  if (fallback) return fallback
  return null
}
