import { apiUri, buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

export async function shipperFetch<T>(
  path: string,
  options?: {
    query?: Record<string, any>
    body?: Record<string, any>
    method?: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH'
  } & Pick<RequestInit, 'signal'>,
): Promise<T> {
  const endpoint = `${apiUri}${path}`
  const response = await fetchJson(
    endpoint,
    buildFetchOptionsWithAuth({
      method: 'GET',
      ...options,
      ...(options?.body && { body: JSON.stringify(options.body) }),
    }),
  )
  if (response.ok) return response.json
  throw response.errorMessage
}

export async function shipperFetchRaw(uri: string, options?: RequestInit) {
  return fetch(`${apiUri}${uri}`, {
    ...buildFetchOptionsWithAuth(),
    ...options,
  })
}
