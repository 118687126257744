import { HazardousDetailFormModel, LoadItem } from '@fv/client-types'

import { HazardDetails } from './HazardDetails'
import { Icon } from './Icon'

type ContainsDisplayProps = {
  item?: LoadItem
  iconClassName?: string
  border?: boolean
  textMargin?: string
  bottomMargin?: string
  containsIcon?: JSX.Element | null
  hazardous?: HazardousDetailFormModel
}

export const ContainsDisplay = ({
  item,
  border,
  textMargin,
  bottomMargin,
  containsIcon,
  hazardous,
}: ContainsDisplayProps) => {
  const i = item

  return (
    <>
      {border && (
        <div className="my-2 ml-10 h-px border-t border-dashed border-[#ddd]" />
      )}
      <div className={`${bottomMargin} flex items-start`}>
        {!!containsIcon && containsIcon}

        <div className={textMargin}>
          {i?.description && <span>{i.description} / </span>}
          {i?.freightClass && <span> Class {i.freightClass} / </span>}
          {i?.nmfcNumber && <span> NMFC {i?.nmfcNumber} / </span>}
          {i?.weight && (
            <span>
              {i?.weight} {i?.weightUOM && <span>{i?.weightUOM} /</span>}
            </span>
          )}
          {i?.type && <span> {i?.type} /</span>}
          {i?.quantity && <span> count of {i?.quantity} </span>}
          {i?.hazardous && <>{getHazardDetails(i?.hazardous)}</>}
          {hazardous && !i?.hazardous && getHazardDetails([hazardous])}
        </div>
      </div>
    </>
  )
}

const getHazardDetails = (hazardous: HazardousDetailFormModel[]) => {
  return (
    <span>
      / <Icon icon="radiation-alt" className="fa-fw color-secondary" /> Hazmat
      <HazardDetails hazardDetails={hazardous} />
    </span>
  )
}
