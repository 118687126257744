import { useCallback, useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { Modal, useFvNavigate } from '@fv/client-components'
import { waitForStateUpdate } from '@fv/client-core'
import { type AwardLoadsDTO, type Quote } from '@fv/client-types'

import { Loading } from '../../components/Loading'
import { LoadSummary } from '../../components/LoadSummary/LoadSummary'
import { useLoad, useQuotes } from '../../hooks/shipments'
import { useLoadRatesPage } from '../../route-hooks'
import { routes } from '../../routes'
import { BookingRateDetails } from '../book/BookingRateDetail'
import { BookingErrorNotice } from '../book/ErrorNotice'
import { ReratingOverlay } from '../book/ReratingOverlay'
import { useAwardLoads } from '../booking/mutations'
import { useVendorBookRate } from '../quote/useBookRate'
import { buildShipmentLocation } from '../shipment-location-form/types'
import { ShipmentForm } from './ShipmentForm'
import { ShipmentFormPageLayout } from './ShipmentFormPageLayout'
import { ShipmentFormProvider } from './ShipmentFormProvider'
import { useShipmentFormState } from './store'
import { type ShipmentFormModel } from './types'
import { mapLoadDtoToAwardDto } from './utils'

export const ShipmentBookPage = () => {
  const { loadId, quoteId } = useParams()
  const loadQuery = useLoad(loadId)
  const quotesQuery = useQuotes(loadId)
  const ratesPage = useLoadRatesPage()

  if (loadQuery.isLoading || quotesQuery.isLoading) return <Loading />

  if (loadQuery.data.quoteProcessStatus === 'rerate-no-compat') {
    return <Navigate to={ratesPage(loadQuery.data)} replace />
  }

  return (
    <ShipmentFormProvider
      init={{
        load: loadQuery.data,
        quote: quotesQuery.data?.find(q => q._id === quoteId) as Quote,
      }}
    >
      <ShipmentBookPageContent />
    </ShipmentFormProvider>
  )
}

const ShipmentBookPageContent = () => {
  const { loadId, quoteId } = useParams()
  const navigate = useFvNavigate()
  const awardLoad = useAwardLoads()
  const bookRate = useVendorBookRate()
  const [errorList, setErrorList] = useState<string[]>()
  const [rerating, setRerating] = useState<boolean>()
  const { initialLoad: load, quote, locations } = useShipmentFormState()
  const { data: loadUpdates } = useLoad(loadId) // only used to watch for relevant updates

  /* Effects */
  useEffect(() => {
    if (load?.pickup?.status === 'error' && load.pickup.error) {
      setErrorList([load.pickup.error])
    }
  }, [load])
  useEffect(() => {
    if (
      rerating === undefined &&
      (load?.quoteProcessStatus === 'rerating' ||
        load?.quoteProcessStatus === 'rerate-ready')
    ) {
      setRerating(true)
    }
  }, [load, rerating])
  /* /Effects */

  const handlePrintBOL = async (model: ShipmentFormModel) => {
    const awardLoadsDTO = mapLoadDtoToAwardDto(
      model,
      loadId,
      quoteId,
      true,
      false,
    )
    await bookAndNavigate(awardLoadsDTO)
  }
  const handleSchedulePickup = async (model: ShipmentFormModel) => {
    const awardLoadsDTO = mapLoadDtoToAwardDto(
      model,
      loadId,
      quoteId,
      true,
      true,
    )
    await bookAndNavigate(awardLoadsDTO)
  }

  const handleRerateAccept = async (newQuoteId: string) => {
    await bookRate.mutateAsync({
      loadId: loadId,
      quoteId: newQuoteId,
      schedulePickup: loadUpdates.schedulePickup,
    })
    navigate(routes.details(loadId))
  }

  const bookAndNavigate = useCallback(
    async (dto: AwardLoadsDTO) => {
      const result = await awardLoad.mutateAsync(dto)
      const bookedLoad = result.loads?.[0]
      if (bookedLoad.quoteProcessStatus !== 'ok') {
        setRerating(true)
      } else {
        waitForStateUpdate(() => {
          navigate(routes.details(result.loads[0].loadId))
        })
      }
    },
    [awardLoad, navigate],
  )

  if (rerating && loadUpdates?.quoteProcessStatus === 'ok') {
    return <Navigate to={routes.details(loadId)} replace />
  }

  if (rerating) {
    return (
      <Modal>
        <ReratingOverlay
          loadId={loadId}
          onAcceptRerate={handleRerateAccept}
          onDeclineRerate={() => setRerating(false)}
        />
      </Modal>
    )
  }

  return (
    <ShipmentFormPageLayout
      sidebarContent={
        <div className="h-full flex">
          <LoadSummary
            load={{
              ...load,
              locations: locations.map(buildShipmentLocation),
            }}
          />
        </div>
      }
    >
      <div>
        {errorList?.length && <BookingErrorNotice errors={errorList} />}
        {load && quote && <BookingRateDetails load={load} quote={quote} />}
        {load && quote && (
          <ShipmentForm
            isSaving={awardLoad.isLoading}
            onPrintBOL={handlePrintBOL}
            onSchedulePickup={handleSchedulePickup}
          />
        )}
      </div>
    </ShipmentFormPageLayout>
  )
}
