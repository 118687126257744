import dayjs from 'dayjs'
import omit from 'lodash/omit'

import { defaultCloseTime, defaultOpenTime } from '@fv/client-core'
import {
  type ClientAddressLookupResult,
  type FullShipment,
  type MilitaryTime,
  type ShipmentLocation,
} from '@fv/client-types'
import {
  type Country,
  CountryMapper,
  type LegacyShipType,
  type LoadLocationDto,
  type LocationAccessorialDTO,
  type Workflow,
} from '@fv/models'
import { type DTO, standardToMilitary } from '@fv/models/core'

import { buildStop } from '../../utils/locationFuncs'
import { defaultStopDate } from '../../utils/shipmentFuncs'

export type BaseShipmentLocationFormModel = Pick<
  ShipmentLocationFormModel,
  | 'company'
  | 'address'
  | 'address2'
  | 'city'
  | 'state'
  | 'postalCode'
  | 'country'
  | 'timezone'
  | 'shipType'
  | 'opensAt'
  | 'closesAt'
  | 'instructions'
  | 'accessorials'
  | 'contactName'
  | 'contactEmail'
  | 'contactPhone'
  | 'contactPhoneExt'
  | 'copyBOL'
  | 'shares'
  | 'stopDate'
  | 'refNum'
>

export type ShipmentLocationFormModel = Omit<
  LoadLocationDto,
  | 'shipType'
  | 'stopDate'
  | 'address'
  | 'address2'
  | 'lat'
  | 'lng'
  | 'refNums'
  | 'timezone'
> &
  Required<Pick<LoadLocationDto, 'address' | 'address2'>> & {
    accessorials: LocationAccessorialDTO[]
    company: string
    contactEmail: string
    contactName: string
    contactPhone: string
    contactPhoneExt: string
    instructions: string
    refNum: string
    timezone?: string
    shipType: '' | LegacyShipType
    stopDate: Date | null
    copyBOL: boolean
    shares: string
    opensAt?: MilitaryTime
    closesAt?: MilitaryTime
    //frontend only
    id?: string
  }

export const buildLoadLocationDto = (
  model: ShipmentLocationFormModel,
): DTO<LoadLocationDto> => {
  return {
    ...omit(model, 'id', 'accountLocationId', 'contactEmail', 'refNums'),
    timezone: model.timezone ?? '',
    shipType: model.shipType === '' ? 'business dock' : model.shipType,
    ...(model.accountLocationId && {
      accountLocationId: model.accountLocationId,
    }),
    ...(model.refNum && {
      refNums: [{ value: model.refNum, type: 'other' }],
    }),
    address: model.address.trim(),
    address2: model.address2?.trim(),
    city: model.city.trim(),
    company: model.company?.trim(),
    contactEmail: model.contactEmail?.trim() || undefined,
    contactName: model.contactName?.trim() || undefined,
    contactPhone: model.contactPhone?.trim() || undefined,
    contactPhoneExt: model.contactPhoneExt?.trim() || undefined,
    country: model.country.trim().toLowerCase() as Country,
    instructions: model.instructions?.trim(),
    postalCode: model.postalCode.trim(),
    state: model.state.trim(),
    stopDate: model.stopDate ? dayjs.utc(model.stopDate).format() : null,
    stopType: model.stopType,
  }
}

export type LocBuilderLoadProps = Pick<FullShipment, 'shares' | 'workflow'> & {
  locations?: Array<{ addressBookId?: string }>
}
export const buildShipmentLocationFormModel = (
  location: ShipmentLocation,
  load?: Pick<FullShipment, 'shares' | 'workflow'>,
  addresses?: ClientAddressLookupResult[],
): ShipmentLocationFormModel => {
  const addressBookEntry = addresses?.find(
    a => a._id === location.addressBookId,
  )
  const useDefaultDockTime =
    location.type === 'origin' || load?.workflow === 'truckload'
  const shares = load?.shares
    ? load?.shares
        .filter(s => s.sequence === location.sequence)
        .map(s => s.email)
    : (addressBookEntry?.bolFollowers ?? [])
  const copyBOL = load?.shares ? !!shares.length : !!addressBookEntry?.sendBOL

  return {
    ...location,
    company: location.company ?? '',
    contactEmail: location.contactEmail ?? '',
    contactName: location.contactName ?? '',
    contactPhone: location.contactPhone ?? '',
    contactPhoneExt: location.contactPhoneExt ?? '',
    instructions: location.instructions ?? '',
    stopDate: location.stopDate ? dayjs.utc(location.stopDate).toDate() : null,
    address: location.address ?? '',
    address2: location.address2 ?? '',
    city: location.city ?? '',
    state: location.state ?? '',
    postalCode: location.postalCode ?? '',
    country: location.country ?? 'us',
    timezone: location.timezone ?? '',
    shipType: location.shipType,
    refNum: location.refNums?.[0]?.value,
    closesAt:
      location.closesAt || (useDefaultDockTime ? defaultCloseTime : undefined),
    opensAt:
      location.opensAt || (useDefaultDockTime ? defaultOpenTime : undefined),
    copyBOL,
    shares: shares.join(', '),
    accessorials: location.accessorials ?? [],
  }
}

export const buildShipmentLocation = (
  formModel: ShipmentLocationFormModel,
): ShipmentLocation => {
  return {
    ...buildLoadLocationDto(formModel),
    opensAt: formModel.opensAt,
    closesAt: formModel.closesAt,
    lat: 0,
    lng: 0,
    ...(formModel.stopDate && {
      stopDate: dayjs(formModel.stopDate).format('YYYY-MM-DD'),
    }),
  }
}

export const emptyLocationFormModel: BaseShipmentLocationFormModel = {
  company: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  country: 'us',
  timezone: '',
  shipType: 'business dock',
  instructions: '',
  accessorials: [],
  contactName: '',
  contactEmail: '',
  contactPhone: '',
  contactPhoneExt: '',
  copyBOL: false,
  shares: '',
  stopDate: undefined,
  refNum: '',
}

export const defaultOrigin = (): ShipmentLocationFormModel =>
  buildStop({
    sequence: 0,
    shipType: 'business dock',
    stopDate: defaultStopDate('origin'),
    stopType: 'pickup',
  })

export const defaultDestination = (): ShipmentLocationFormModel =>
  buildStop({
    sequence: 1,
    shipType: 'business dock',
    stopType: 'delivery',
  })

export const mapAddressBookToShipmentLocationFormModel = (
  loc: ClientAddressLookupResult | null,
  workflow: Workflow,
  clearExisting: boolean = true,
): Partial<ShipmentLocationFormModel> => {
  if (!loc) {
    return emptyLocationFormModel
  }
  const {
    accountLocationId = '',
    address = '',
    address2 = '',
    adminArea3,
    city = '',
    company = '',
    contactEmail = '',
    contactName = '',
    contactPhone = '',
    country,
    dockHoursEnd,
    dockHoursStart,
    instructions = '',
    mappedAccessorials,
    postalCode = '',
    state = '',
    timezone = '',
    shipType = '',
    _id,
  } = loc ?? {}

  return {
    ...(_id && {
      addressBookId: _id,
    }),
    accessorials: mappedAccessorials?.[workflow] ?? [],
    address,
    address2: address2 || '',
    ...(adminArea3 && { adminArea3 }),
    city,
    country: CountryMapper.legacyToCore(country?.toLowerCase() || 'us'),
    // lat,
    // lng,
    postalCode,
    shipType,
    state,
    timezone,
    copyBOL: !!loc.sendBOL,
    shares: (loc?.bolFollowers ?? []).join(', '),
    ...(dockHoursStart && {
      opensAt: standardToMilitary(dockHoursStart) as MilitaryTime,
    }),
    ...(dockHoursEnd && {
      closesAt: standardToMilitary(dockHoursEnd) as MilitaryTime,
    }),
    ...((clearExisting || accountLocationId) && { accountLocationId }),
    ...((clearExisting || company) && { company }),
    ...((clearExisting || contactEmail) && { contactEmail }),
    ...((clearExisting || contactName) && { contactName }),
    ...((clearExisting || contactPhone) && {
      contactPhone,
      contactPhoneExt: '',
    }),
    ...((clearExisting || instructions) && { instructions }),
  }
}
export const mapAtlasAddressToShipmentLocationFormModel = (
  atlasAddress: ClientAddressLookupResult | null,
  workflow: Workflow,
  clearExisting: boolean = true,
): Partial<ShipmentLocationFormModel> => {
  if (!atlasAddress) {
    return {
      accountLocationId: '',
      city: '',
      country: 'us',
      postalCode: '',
      shipType: 'business dock',
      state: '',
      timezone: '',
    }
  } else {
    const { address, address2, shipType, accessorials, ...values } =
      mapAddressBookToShipmentLocationFormModel(
        atlasAddress,
        workflow,
        clearExisting,
      )

    return {
      ...values,
      accountLocationId: '',
    }
  }
}
