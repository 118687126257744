import { type ChangeEvent, useCallback, useEffect, useRef } from 'react'
import toast from 'react-hot-toast'

import { CheckboxField } from '@fv/client-components'
import { type ShipmentLocation } from '@fv/client-types'
import { type Workflow } from '@fv/models'

import {
  defaultLoadHandlingUnit,
  toLoadHandlingUnitFormModel,
} from '../commodities/load-items/loadItemHelpers'
import { type LoadHandlingUnitFormModel } from '../commodities/load-items/types'
import { useHandlingUnits } from '../commodities/useHandlingUnits'
import { useRecentShipment } from '../quote/useRecentShipment'
import { useAppPreferences } from '../settings/user-preferences/hooks'

type Props = {
  workflow: Workflow
  onDestinationChange: (location: ShipmentLocation | null) => void
  onOriginChange: (location: ShipmentLocation | null) => void
  onProductChange: (items: LoadHandlingUnitFormModel[]) => void
}
export const AutofillPreviousShipment = ({
  workflow,
  onOriginChange,
  onDestinationChange,
  onProductChange,
}: Props) => {
  const prefs = useAppPreferences()
  const recentLoadQuery = useRecentShipment(workflow)
  const recentLoad = recentLoadQuery.data
  const recentUnitsQuery = useHandlingUnits(recentLoad)
  const recentUnits = recentUnitsQuery.data
  const initialCalled = useRef(false)

  const autoFillOrigin =
    workflow === 'truckload'
      ? false
      : !!prefs[`${workflow}_quote_remember_origin`]
  const autoFillDestination =
    workflow === 'truckload'
      ? false
      : !!prefs[`${workflow}_quote_remember_destination`]
  const autoFillProduct =
    workflow === 'truckload'
      ? false
      : !!prefs[`${workflow}_quote_remember_product`]

  const sendFillOrigin = useCallback(
    (useRecent: boolean) => {
      onOriginChange(
        useRecent
          ? (recentLoad.locations.find(l => l.type === 'origin') ?? null)
          : null,
      )
    },
    [onOriginChange, recentLoad],
  )
  const sendFillDestination = useCallback(
    (useRecent: boolean) => {
      onDestinationChange(
        useRecent
          ? (recentLoad.locations.find(l => l.type === 'destination') ?? null)
          : null,
      )
    },
    [onDestinationChange, recentLoad],
  )

  const sendFillProduct = useCallback(
    (useRecent: boolean) => {
      onProductChange(
        useRecent
          ? recentLoad?.items.map(i =>
              toLoadHandlingUnitFormModel(
                i,
                recentUnits?.find(u => u._id === i.handlingUnitId),
              ),
            )
          : [defaultLoadHandlingUnit(workflow)],
      )
    },
    [onProductChange, recentLoad, recentUnits, workflow],
  )

  useEffect(() => {
    if (!recentLoad || recentUnitsQuery.loading || initialCalled.current) return
    initialCalled.current = true
    if (autoFillOrigin) {
      sendFillOrigin(true)
    }
    if (autoFillDestination) {
      sendFillDestination(true)
    }
    if (autoFillProduct) {
      sendFillProduct(true)
    }
  }, [
    recentLoad,
    recentUnitsQuery,
    autoFillOrigin,
    autoFillDestination,
    autoFillProduct,
    sendFillOrigin,
    sendFillDestination,
    sendFillProduct,
  ])

  const handleNoRecentLoads = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked && !recentLoad) {
      toast.error(`You haven't created any shipments yet`, {
        id: 'recent-shipment',
      })
      return false
    }
    return true
  }

  return (
    <div className="flex items-center space-x-5">
      <div>Autofill from last shipment &gt;</div>

      <div>
        <CheckboxField
          name="autofillOrigin"
          defaultChecked={autoFillOrigin}
          label="Origin"
          onChange={e =>
            handleNoRecentLoads(e) && sendFillOrigin(e.target.checked)
          }
        />
      </div>

      <div>
        <CheckboxField
          name="autofillDestination"
          defaultChecked={autoFillDestination}
          label="Destination"
          onChange={e =>
            handleNoRecentLoads(e) && sendFillDestination(e.target.checked)
          }
        />
      </div>

      <div>
        <CheckboxField
          name="autofillProduct"
          defaultChecked={autoFillProduct}
          label="Product"
          onChange={e =>
            handleNoRecentLoads(e) && sendFillProduct(e.target.checked)
          }
        />
      </div>
    </div>
  )
}
