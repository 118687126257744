import { type PropsWithChildren, useRef } from 'react'

import { Loading } from '../../components/Loading'
import {
  ShipmentFormContext,
  type ShipmentFormStore,
  type StoreInitProps,
  useCreateShipmentFormStore,
} from './store'

type Props = {
  init: StoreInitProps
} & PropsWithChildren
export const ShipmentFormProvider = ({ children, init }: Props) => {
  const storeCreator = useCreateShipmentFormStore(init)
  const store = useRef<ShipmentFormStore | null>(null)

  if (storeCreator.isLoading) {
    return <Loading />
  }

  if (!store.current) {
    store.current = storeCreator.create()
  }

  return (
    <ShipmentFormContext.Provider value={store.current}>
      {children}
    </ShipmentFormContext.Provider>
  )
}
