import clsx from 'clsx'
import { type ChangeEvent, type MouseEvent } from 'react'

import { Icon } from '@fv/client-components'
import {
  type LocationAccessorialDTO,
  type LocationAccessorialType,
} from '@fv/models'
import { toggleArrayItem } from '@fv/models/core'

import { useAccessorialOptions } from '../../hooks/settings'
import {
  apptRequiredKey,
  getApptTimes,
  type LocationAccessorialWithMilitaryTimes,
} from './AppointmentConfig'
import CheckboxField from './CheckboxField'

type LocationAccessorialsProps = {
  accessorials: LocationAccessorialDTO[]
  className?: string
  inputNamePrefix?: string
  onChange: (newAccessorials: LocationAccessorialDTO[]) => void
  openDatePicker: (e: MouseEvent<HTMLDivElement>) => void
  vertical?: boolean
}

export const FtlLocationAccessorials = ({
  accessorials,
  className,
  inputNamePrefix,
  onChange,
  openDatePicker,
  vertical,
}: LocationAccessorialsProps) => {
  const accessorialOptions = useAccessorialOptions('location')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const key = e.target.value as LocationAccessorialType
    const newAccessorials = toggleArrayItem(
      [...accessorials],
      { key },
      e.target.checked,
      e => e.key === key,
    )

    onChange(newAccessorials)
  }

  return (
    <div className={className}>
      {!accessorialOptions.length && <Icon icon="spinner" />}

      {accessorialOptions.map(o => {
        const accKey = o.key
        const accDetails = accessorials.find(a => a.key === accKey)
        const isAppointment = accKey === apptRequiredKey
        const name = `${inputNamePrefix ?? ''}${accKey}`

        return (
          <CheckboxField
            checked={!!accDetails}
            hint={
              !!accDetails &&
              isAppointment && (
                <div
                  className={clsx(
                    'text-fv-blue cursor-pointer text-sm hover:text-fv-gray-dark',
                    {
                      'absolute top-[calc(50%+.75rem)] left-[1.9rem]':
                        !vertical,
                      'ml-4': vertical,
                    },
                  )}
                  onClick={openDatePicker}
                >
                  {getApptTimes(
                    accDetails as LocationAccessorialWithMilitaryTimes,
                  )}
                </div>
              )
            }
            key={accKey}
            label={o.name}
            name={name}
            onChange={handleChange}
            value={accKey}
          />
        )
      })}
    </div>
  )
}
