import {
  createContext,
  type PropsWithChildren,
  useContext,
  useRef,
} from 'react'
import { create, useStore } from 'zustand'

import {
  type ClientAddressLookupResult,
  type EquipmentAccessorial,
  type FullShipment,
  type PropsWithLoad,
  type Quote,
  type QuoteStopType,
} from '@fv/client-types'
import { toggleArrayItem } from '@fv/models/core'

import { Loading } from '../../components/Loading'
import { useLoad } from '../../hooks/shipments'
import { sanitizeLocation } from '../../utils/locationFuncs'
import { buildSharesDto } from '../../utils/shares'
import {
  defaultStopDate,
  getCustomsBrokerLocationContext,
} from '../../utils/shipmentFuncs'
import { billToMapper, defaultFormBillTo } from '../billTo/billToFuncs'
import { toFormLocation } from '../booking/hooks'
import {
  buildHandlingUnitDTO,
  unitIsHazardous,
  useLoadHandlingUnitFormModels,
} from '../commodities/load-items/loadItemHelpers'
import { type LoadHandlingUnitFormModel } from '../commodities/load-items/types'
import { customsBrokerMapper } from '../customsBroker/brokerFuncs'
import { defaultFormBroker } from '../customsBroker/brokerTypes'
import {
  defaultFormContact,
  emergencyContactMapper,
} from '../emergencyContact/contactFuncs'
import { buildShipmentLocation } from '../shipment-location-form/types'
import {
  loadCanBeBooked,
  processsLoadNotifications,
  requireRateLocation,
} from './bookFuncs'
import {
  type BookingFormActions,
  type BookingFormState,
  type BuildAwardRequestArgs,
  type LocationWithFollowers,
} from './types'

const initialState: BookingFormState = {
  billTo: defaultFormBillTo,
  bolNumber: '',
  customerRoutedDetail: {
    accountNum: '',
    carrier: null,
    service: '',
    terms: 'outbound-collect',
  },
  customsBroker: defaultFormBroker,
  tags: [],
  emergencyContact: defaultFormContact,
  equipmentAccessorials: [],
  isEdit: false,
  isNew: false,
  loadId: undefined,
  isFreightCollect: false,
  locations: [],
  quote: undefined,
  rateLocationType: 'origin',
  shareEmails: '',
  workflow: 'ltl',
  items: [],
  initialItems: [],
}

export function createBookingFormStore(props: {
  addresses?: ClientAddressLookupResult[]
  items?: LoadHandlingUnitFormModel[]
  isNew: boolean
  load: FullShipment
  quote?: Quote
}) {
  const { addresses, items, isNew, load, quote } = props

  return create<BookingFormActions & BookingFormState>((...store) => {
    const [set, get] = store
    return {
      ...initialState,
      ...buildFormData(load, addresses),
      items,
      initialItems: JSON.parse(JSON.stringify(items)),
      isNew,
      quote,
      initialLoad: load,
      actions: {
        buildCRDetail: () => {
          const { actions, customerRoutedDetail } = get()
          const { accountNum, postalCode, carrier, service } =
            customerRoutedDetail

          const billToDTO = actions.buildBillToDTO()
          return {
            billTo: {
              ...(billToDTO ?? {}),
              accountNumber: accountNum,
              postalCode: postalCode || billToDTO?.postalCode,
            },
            carrierId: carrier?.id ?? '',
            serviceId: service,
          }
        },
        buildBillToDTO: () => {
          const { billTo, locations, customerRoutedDetail } = get()
          const dto = billToMapper.formToDTO(
            load.isFreightCollect &&
              customerRoutedDetail?.terms === 'outbound-collect'
              ? locations[1]
              : billTo,
          )
          if (Object.entries(dto).some(([k, v]) => !!v && k !== 'country')) {
            return dto
          }
          return undefined
        },
        buildCustomerRoutedDTO: (createBOL, schedulePickup) => {
          const {
            actions,
            equipmentAccessorials,
            items,
            locations,
            workflow,
            tags,
          } = get()
          const customsBroker = actions.buildCustomsBrokerDTO()
          const emergencyContact = actions.buildEmergencyContactDTO()
          const declaredValue = items.reduce(
            (value, item) => value + Number(item.declaredValue || 0),
            0,
          )

          return {
            ...(customsBroker && { customsBroker: [customsBroker] }),
            ...(emergencyContact && { emergencyContact }),
            ...actions.buildCRDetail(),
            createBOL,
            equipment: [
              {
                ...(declaredValue && { declaredValue }),
                accessorials: equipmentAccessorials.map(key => ({
                  key,
                })) as EquipmentAccessorial[],
                alternateTypes: [],
                declaredValueCurrency: 'usd',
                description: items[0].description || load.equipment.description,
                isHazardous: items.some(unitIsHazardous),
                items: items.map(buildHandlingUnitDTO(workflow)),
                loadId: load.loadId,
                mode: workflow,
                type: workflow === 'parcel' ? 'parcel' : 'ltl',
                weight: items
                  .map(i => i.weight ?? 0)
                  .reduce((sum, i) => sum + Number(i || 0), 0),
                weightUOM: 'lbs',
              },
            ],
            tags,
            isFreightCollect: true,
            isLiveLoad: true,
            locations: locations.map(buildShipmentLocation),
            orderNumber: '',
            schedulePickup: !!schedulePickup,
            shares: actions.buildSharesDTO(),
            workflow: workflow,
          }
        },
        buildCustomsBrokerDTO: () => {
          const { customsBroker, locations } = get()
          const { location: customsBrokerLoc } =
            getCustomsBrokerLocationContext(locations)
          if (!customsBrokerLoc) return
          return customsBrokerMapper.formToDTO(customsBroker)
        },
        buildEmergencyContactDTO: () => {
          const { emergencyContact, items } = get()
          if (!items.some(unitIsHazardous)) return
          return emergencyContactMapper.formToDTO(emergencyContact)
        },
        buildRequestDTO: ({
          createBOL,
          schedulePickup,
          quoteId,
        }: BuildAwardRequestArgs) => {
          const {
            actions,
            deliveryOption,
            equipmentAccessorials,
            holdAtLocation,
            isEdit,
            items,
            locations,
            notifications,
            rateLocationType,
            workflow,
            tags,
            bolNumber,
          } = get()

          const billToDTO = actions.buildBillToDTO()
          const customsBroker = actions.buildCustomsBrokerDTO()
          const emergencyContact = actions.buildEmergencyContactDTO()
          const hasRateLocation = requireRateLocation(
            locations,
            !!load.isFreightCollect,
          )

          quoteId = (quoteId || quote?._id) ?? ''

          return {
            ...(!isEdit && { schedulePickup: !!schedulePickup }),
            ...(customsBroker && { customsBroker: [customsBroker] }),
            ...(emergencyContact && { emergencyContact }),
            ...(hasRateLocation && { rateLocationType }),
            ...(billToDTO && { billTo: billToDTO }),
            createBOL,
            isEdit,
            bolNumber,
            loads: [
              {
                deliveryOption: deliveryOption,
                equipment: {
                  accessorials: equipmentAccessorials.map(key => ({
                    key,
                  })) as EquipmentAccessorial[],
                },
                holdAtLocation: holdAtLocation,
                items: items.map(buildHandlingUnitDTO(workflow)),
                loadId: load.loadId,
                notifications: processsLoadNotifications(notifications),
                quoteId,
              },
            ],
            locations: locations.map(buildShipmentLocation),
            shares: actions.buildSharesDTO(),
            tags,
          }
        },
        buildSharesDTO: () => {
          const { locations, shareEmails } = get()
          return buildSharesDto(shareEmails, locations)
        },
        setBillToValues: values => {
          set(prev => ({
            billTo: {
              ...prev.billTo,
              ...values,
            },
          }))
        },
        setBrokerValues: values => {
          set(prev => ({
            customsBroker: {
              ...prev.customsBroker,
              ...values,
            },
          }))
        },
        setCustomerRoutedValues: values => {
          set(prev => ({
            customerRoutedDetail: {
              ...prev.customerRoutedDetail,
              ...values,
            },
          }))
        },
        setEmergencyContactValues: values => {
          set(prev => ({
            emergencyContact: {
              ...prev.emergencyContact,
              ...values,
            },
          }))
        },
        setItems: items => {
          set({ items })
        },
        setLocationValues: (type, values) => {
          set(prev => {
            const nextLocation = sanitizeLocation(
              {
                ...prev.locations[type === 'origin' ? 0 : 1],
                ...values,
              },
              get().workflow,
            )

            return {
              locations: prev.locations.map(x =>
                x.type === type ? nextLocation : x,
              ),
            }
          })
        },
        setRateLocationType: e => {
          set({ rateLocationType: e.target.value as QuoteStopType })
        },
        setShareEmails: shareEmails => {
          set({ shareEmails })
        },
        toggleEquipmentAccessorial: (name, include) => {
          set(prev => {
            const hasAccessorial = prev.equipmentAccessorials.some(
              a => a === name,
            )

            return {
              equipmentAccessorials: toggleArrayItem(
                prev.equipmentAccessorials,
                name,
                include !== undefined ? include : !hasAccessorial,
              ),
            }
          })
        },
        updateBolNumber(bolNumber) {
          set({ bolNumber })
        },
        updateDeliveryOption(deliveryOption) {
          set({ deliveryOption })
        },
        updateHoldAtLocation(holdAtLocation) {
          set({
            holdAtLocation,
          })
        },
        updateNotifications(notifications) {
          set({ notifications })
        },
        updateTags(tags) {
          set({ tags })
        },
        undoChanges: () => {
          set(() => ({
            ...buildFormData(load, addresses),
            items: get().initialItems,
          }))
        },
      },
    }
  })
}

function buildFormData(
  load: FullShipment,
  addresses?: ClientAddressLookupResult[],
): Omit<BookingFormState, 'initialItems'> {
  const locations: LocationWithFollowers[] = load.locations.map(x => {
    const formLocation = toFormLocation(x, load, addresses)

    if (load.status === 'pending' || load.pickup?.errorType === 'validation') {
      formLocation.stopDate = defaultStopDate(
        formLocation.type,
        formLocation.stopDate,
      )
    }
    return formLocation
  })

  const rateLocationType: QuoteStopType =
    requireRateLocation(load.locations, !!load.isFreightCollect) &&
    load.direction === 'inbound'
      ? 'destination'
      : 'origin'

  return {
    billTo: billToMapper.dtoToForm(load.billTo),
    customsBroker: customsBrokerMapper.dtoToForm(load.customsBroker?.[0]),
    deliveryOption: load.deliveryOption
      ? {
          ...load.deliveryOption,
          date: load.deliveryOption.date
            ? new Date(load.deliveryOption.date)
            : undefined,
        }
      : undefined,
    emergencyContact: emergencyContactMapper.dtoToForm(load.emergencyContact),
    equipmentAccessorials: (load.equipment.accessorials ?? []).map(a => a.key),
    isEdit: !loadCanBeBooked(load),
    isNew: false,
    isFreightCollect: load.isFreightCollect ?? false,
    loadId: load.loadId,
    shareEmails: '',
    locations,
    rateLocationType,
    items: [],
    workflow: load.workflow,
    bolNumber: load.bol?.bolNumber ?? '',
    tags: load.tags?.map(t => t.name ?? '')?.filter(v => !!v) ?? [],
    ...(load.isFreightCollect && load.selectedQuote
      ? {
          customerRoutedDetail: {
            accountNum: load.billTo?.accountNumber ?? '',
            postalCode: load.billTo?.postalCode,
            carrier: {
              code: load.selectedQuote.providerCode,
              id: load.selectedQuote.carrierId,
              name: load.selectedQuote.providerName,
              status: 'pending',
            },
            service: load.selectedQuote.serviceId || '',
            terms:
              load.selectedQuote.paymentTerms === 'third-party-prepaid'
                ? 'third-party'
                : 'outbound-collect',
          },
        }
      : {
          customerRoutedDetail: {
            accountNum: '',
            postalCode: '',
            carrier: null,
            service: '',
            terms: 'outbound-collect',
          },
        }),
  }
}

type BookingFormStore = ReturnType<typeof createBookingFormStore>
const BookingFormContext = createContext<BookingFormStore | null>(null)
type BookingFormProviderProps = PropsWithChildren<
  PropsWithLoad<{
    addresses: ClientAddressLookupResult[]
    quote?: Quote
    isNew?: boolean
  }>
>

export const BookingFormProvider = ({
  addresses,
  children,
  isNew,
  load,
  quote,
}: BookingFormProviderProps) => {
  const storeRef = useRef<BookingFormStore>()
  const items = useLoadHandlingUnitFormModels(load)
  if (items.isLoading) return <Loading />

  if (!storeRef.current) {
    storeRef.current = createBookingFormStore({
      addresses,
      items: items.data ?? [],
      isNew: isNew ?? false,
      load,
      quote,
    })
  }

  return (
    <BookingFormContext.Provider value={storeRef.current}>
      {children}
    </BookingFormContext.Provider>
  )
}

export function useBookingFormsStore() {
  const store = useContext(BookingFormContext)

  if (!store) {
    throw new Error('Missing BookingFormContext.Provider in the tree')
  }

  return useStore(store)
}

export function useBookingFormCtx<T>(
  selector: (state: BookingFormActions & BookingFormState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const store = useContext(BookingFormContext)

  if (!store) {
    throw new Error('Missing BookingFormContext.Provider in the tree')
  }

  return useStore(store, selector, equalityFn)
}

export function useBookingFormLoad() {
  const loadId = useBookingFormCtx(s => s.loadId)
  const isNew = useBookingFormCtx(s => s.isNew)
  const isFreightCollect = useBookingFormCtx(s => s.isFreightCollect)
  const initialLoad = useBookingFormCtx(s => s.initialLoad)
  const load = useLoad(isNew ? undefined : loadId, {
    refetchWhileQuoting: true,
  })

  if (isNew && isFreightCollect) {
    return initialLoad
  }

  return load.data
}
