import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import { type Workflow } from '@fv/models'

import { HandlingUnitContainsPanel } from './HandlingUnitContainsPanel'
import { InlineItemsFormWrapper } from './InlineItemsForm'
import {
  LoadItemsContext,
  useItemActions,
  useItemsState,
} from './loadItemsContext'
import {
  createItemsStore,
  type LoadItemsStore,
  type LoadItemsStoreInit,
} from './loadItemsFormState'
import { PagedItemsFormWrapper } from './PagedItemsForm'
import { type LoadHandlingUnitFormModel, type LoadStep } from './types'

export type LoadItemsFormRef = {
  validateForm: () => boolean
  validateItems: (workflow: Workflow, step: LoadStep) => boolean
  setItems: (items: LoadHandlingUnitFormModel[]) => void
}
type Props = Omit<LoadItemsStoreInit, 'items'> & {
  type: 'inline' | 'paged'
  onChange: (items: LoadHandlingUnitFormModel[]) => void
  initialItems?: LoadHandlingUnitFormModel[]
}
export const LoadItemsForm = forwardRef<LoadItemsFormRef, Props>(
  ({ workflow, step, type, onChange, initialItems }: Props, ref) => {
    const storeRef = useRef<LoadItemsStore>()
    const isRefItemsUpdate = useRef(false)
    if (!storeRef.current) {
      storeRef.current = createItemsStore({
        workflow,
        items: initialItems,
        step,
      })
    }

    // Expose the validateForm method to the parent component
    useImperativeHandle(ref, () => ({
      validateForm: () => {
        return storeRef.current.getState().actions.validateForm()
      },
      validateItems: (workflow: Workflow, step: LoadStep) => {
        return storeRef.current.getState().actions.validateItems(workflow, step)
      },
      setItems: (items: LoadHandlingUnitFormModel[]) => {
        isRefItemsUpdate.current = true
        storeRef.current.getState().actions.setItems(items)
        setTimeout(() => {
          isRefItemsUpdate.current = false
        }, 0)
      },
    }))

    useEffect(() => {
      storeRef.current.setState(p => ({
        ...p,
        step,
        workflow,
      }))
    }, [step, workflow])

    useEffect(() => {
      const unsub = storeRef.current.subscribe(({ items }) => {
        !isRefItemsUpdate.current && onChange(items)
      })
      return () => {
        unsub()
      }
    }, [onChange])
    return (
      <LoadItemsContext.Provider value={storeRef.current}>
        <LoadItemsFormProviderContents type={type} />
      </LoadItemsContext.Provider>
    )
  },
)

const LoadItemsFormProviderContents = ({ type }: Pick<Props, 'type'>) => {
  const { editingContainsUnit, items } = useItemsState()
  const { setEditingContains, updateItem, activateContains } = useItemActions()

  const handleContainsClose = (fromSave: boolean) => {
    if (
      fromSave ||
      window.confirm(
        `You have not saved your changes.  Are you sure you're finished editing commodities?`,
      )
    ) {
      setEditingContains(undefined)
      activateContains(undefined)
    }
  }

  return (
    <>
      {type === 'inline' ? (
        <InlineItemsFormWrapper />
      ) : (
        <PagedItemsFormWrapper />
      )}

      <HandlingUnitContainsPanel
        isOpen={!!editingContainsUnit}
        closePanel={() => handleContainsClose(false)}
        handlingUnit={editingContainsUnit}
        onSubmit={({ contains }) => {
          if (!editingContainsUnit) return
          const ix = items.indexOf(editingContainsUnit)
          updateItem(ix, { contains })
          handleContainsClose(true)
        }}
      />
    </>
  )
}
