import { type Dispatch, type SetStateAction, useState } from 'react'
import { Link } from 'react-router-dom'

import { FvButton, FvLinkButton, Icon, Tooltip } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { HasPermission, usePermissions } from '../../../features/auth'
import { useRole } from '../../../features/auth/hooks'
import { AddRateButton } from '../../../features/quote/ManualRatePanel'
import { useCancelLoad, useEditBookingPage } from '../../../hooks/shipments'
import { useLoadEditRoute, useLoadRatesPage } from '../../../route-hooks'

type Props = {
  isShowingRates: boolean
  load: FullShipment
  setShowingRates: Dispatch<SetStateAction<boolean>>
  setViewingActive: Dispatch<SetStateAction<boolean>>
}

export const PanelFooter = ({
  isShowingRates,
  load,
  setShowingRates,
  setViewingActive,
}: Props) => {
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const { isMultiTruck, loadId, status } = load
  const { canBook, canViewRates } = usePermissions()
  const { canEdit, composeEditLink } = useEditBookingPage(load)
  const editRoute = useLoadEditRoute()
  const ratesPage = useLoadRatesPage()
  const role = useRole()

  const cancelLoad = useCancelLoad(loadId)
  const isCanceling = cancelLoad.isLoading || cancelLoad.isSuccess

  const cancelLoads = (isAllLoads: boolean) => {
    if (isCanceling) return

    cancelLoad.mutate(isAllLoads, {
      onSuccess: () => {
        setShowCancelDialog(false)
        setShowingRates(false)
      },
    })
  }

  const hasCancelButton = status !== 'awarded' || canBook
  const hasEditLoadButton = canEdit && status === 'awarded'
  const hasEditRequestButton = status === 'pending'
  const hasManualRateButton =
    load.workflow !== 'parcel' &&
    (status !== 'awarded' || isShowingRates) &&
    canViewRates &&
    role !== 'vendor'

  if (
    !hasCancelButton &&
    !hasEditLoadButton &&
    !hasEditRequestButton &&
    !hasManualRateButton
  ) {
    return null
  }

  return (
    <div className="shipment-list-ancillary-details__footer">
      <div className="divided-content divided-content--start">
        {hasManualRateButton && (
          <AddRateButton
            icon="plus"
            load={load}
            onSuccess={() => setViewingActive(true)}
            rateType={load.workflow === 'ltl' ? 'manual-rate' : 'spot-quote'}
          >
            Rate
          </AddRateButton>
        )}

        {hasEditLoadButton && (
          <FvLinkButton icon="pen-alt" to={composeEditLink()}>
            Edit load
          </FvLinkButton>
        )}

        {hasEditRequestButton && (
          <Link to={editRoute(load, 'edit')}>
            <Icon icon="pen-alt" />
            <span>Edit request</span>
          </Link>
        )}

        {hasCancelButton && (
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              if (isCanceling) return
              setShowCancelDialog(!showCancelDialog)
            }}
          >
            <Icon
              icon={isCanceling ? 'spinner' : 'times'}
              transform="down-.5"
            />
            <span>Cancel request</span>
          </a>
        )}

        <HasPermission name="view-rates">
          <Tooltip label="Details">
            <Link to={ratesPage(load)}>
              <Icon icon="list" transform="down-.5" />
            </Link>
          </Tooltip>
        </HasPermission>
      </div>

      {showCancelDialog && (
        <div className="shipment-list-ancillary-details-ctn__cancel-prompt">
          <div className="help-box mb-6">
            <Icon
              icon="hand-paper"
              transform="down-2"
              className="text-fv-orange"
            />
            {!isMultiTruck && (
              <p className="mb-0">
                Cancelling this quote request will remove it from this list and
                lose all of its data. Carriers will be notified that this load
                is no longer available. Proceed?
              </p>
            )}

            {isMultiTruck && (
              <p className="mb-0">
                This quote request contains multiple loads. Do you just want to
                cancel this specific load or every load for this entire quote
                request? Canceling a load will remove it from this list and lose
                all of its data. Carriers will be notified that these loads are
                no longer available. Proceed?
              </p>
            )}
          </div>
          <div className="mb-2 flex items-center !justify-end">
            <FvButton
              theme="default"
              onClick={() => {
                !isCanceling && setShowCancelDialog(false)
              }}
            >
              No
            </FvButton>

            {isMultiTruck && (
              <FvButton
                theme="primary"
                onClick={() => cancelLoads(false)}
                icon={isCanceling ? 'spinner' : 'check'}
              >
                <span>Yes, this load only</span>
              </FvButton>
            )}

            <FvButton
              theme="primary"
              onClick={() => cancelLoads(true)}
              icon={isCanceling ? 'spinner' : 'check'}
            >
              <span>
                {isMultiTruck
                  ? 'Yes, all loads'
                  : 'Yes, cancel this quote request'}
              </span>
            </FvButton>
          </div>
        </div>
      )}
    </div>
  )
}
