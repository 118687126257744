import { useRef } from 'react'

import { FvButton } from '@fv/client-components'

import TextInput from '../../../components/inputs/TextInput'

type Props = {
  isBusy: boolean
  resetForm?: () => void
  onChange: (value: File | null) => void
}

const CsvUploadField = ({ isBusy, resetForm, onChange }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const nameInputRef = useRef<HTMLInputElement>(null)

  function openFilePicker() {
    if (isBusy) return
    fileInputRef.current?.click()
  }

  return (
    <div className="input-group flex-1">
      <div className="input-group__prepend">
        <FvButton
          onClick={openFilePicker}
          theme="default"
          icon="file"
          className="z-50"
        >
          <span>Choose a document</span>
        </FvButton>
      </div>

      <TextInput
        className="form-control"
        name="fileName"
        onFocus={e => {
          openFilePicker()
          e.target.blur()
        }}
        ref={nameInputRef}
        required
      />

      <input
        accept=".csv"
        name="csvFile"
        onChange={e => {
          const file = e.target.files?.[0]
          if (nameInputRef.current) {
            nameInputRef.current.value = file?.name ?? ''
          }

          onChange(file)
        }}
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
      />

      <FvButton
        theme="plain"
        icon="times"
        onClick={() => {
          if (isBusy) return
          if (fileInputRef.current) fileInputRef.current.value = ''
          if (nameInputRef.current) nameInputRef.current.value = ''
          resetForm?.()
        }}
        type="button"
      />
    </div>
  )
}

export default CsvUploadField
