import { type PropsWithChildren, type ReactNode } from 'react'

import TopNav from '../../components/TopNav'

type Props = PropsWithChildren<{
  sidebarContent: ReactNode
}>
export const ShipmentFormPageLayout = ({ sidebarContent, children }: Props) => {
  return (
    <div className="grid grid-cols-[15rem_1fr] h-screen grid-rows-[auto_1fr_auto] b1350:block">
      <TopNav />

      {sidebarContent}

      <div className="overflow-auto">{children}</div>
    </div>
  )
}
