import clsx from 'clsx'
import { type PropsWithChildren, type ReactNode } from 'react'

import { Icon, type IconName } from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'

import { PillHeader } from '../../components/PillHeader'

type Props = PropsWithChildren<
  PropsWithClassName<{
    icon?: IconName
    pillClassName?: string
    pillContents?: ReactNode
    iconClassName?: string
    title?: string
    cy?: string
  }>
>
export const ShipmentFormSection = ({
  className,
  pillClassName,
  iconClassName,
  pillContents,
  title,
  cy,
  icon,
  children,
}: Props) => {
  return (
    <div
      className={clsx('mb-12 border p-4 relative', className, {
        'pt-10': !!title || !!pillContents,
      })}
      data-cy={cy}
    >
      {(title || pillContents) && (
        <PillHeader className={pillClassName}>
          {icon && <Icon className={iconClassName} icon={icon} />}
          {!pillContents && title && <span>{title}</span>}
          {pillContents}
        </PillHeader>
      )}
      {children}
    </div>
  )
}
