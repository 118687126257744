import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { useToggle } from 'usehooks-ts'

import { FvButton, Tooltip } from '@fv/client-components'

import { TabLink, TabLinkList } from '../../../components/TabLink'
import { HasFeature } from '../../auth'
import { AddRateSlider } from './AddRateSlider'
import { ContractedRatesListing } from './ContractedRatesListing'
import { FuelTableSlider } from './FuelTableSlider'

export const ContractedRatesPage = () => {
  const { pathname } = useLocation()
  const [isFixed, toggleFixed] = useToggle()
  const [adding, toggleAdding] = useToggle()
  const [openFuel, toggleOpenFuel] = useToggle()

  return (
    <div
      className={clsx('settings-main flex flex-col @container', {
        'bg-white inset-0 z-20 fixed ': isFixed,
      })}
    >
      <div className="flex mb-6 -mt-2">
        <Tooltip label={!isFixed ? 'Maximize' : 'Minimize'}>
          <FvButton
            icon={!isFixed ? 'expand' : 'window-minimize'}
            iconClass={clsx('text-fv-orange-bright', !isFixed && 'fa-flip')}
            transform={isFixed && 'up-6'}
            theme="round"
            className="mx-4"
            onClick={toggleFixed}
          />
        </Tooltip>
        <TabLinkList>
          <TabLink
            icon="file-contract"
            isActive={!pathname.includes('add') && !pathname.includes('fuel')}
          >
            <span>Your contracted rates</span>
          </TabLink>
          <TabLink icon="plus" onClick={toggleAdding}>
            Add contracted rates
          </TabLink>

          <HasFeature name="fuelAndDistance">
            <TabLink onClick={toggleOpenFuel} icon="gas-pump">
              Fuel tables
            </TabLink>
          </HasFeature>
        </TabLinkList>
      </div>

      <ContractedRatesListing />

      <AddRateSlider isOpen={adding} closePanel={toggleAdding} />

      <FuelTableSlider isOpen={openFuel} closePanel={toggleOpenFuel} />
    </div>
  )
}
