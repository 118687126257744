import dayjs from 'dayjs'

import { ClipboardButton, FvNum, Icon } from '@fv/client-components'
import {
  formatNumber,
  getDistanceDisplay,
  totalShipmentWeight,
} from '@fv/client-core'
import { type PropsWithLoad } from '@fv/client-types'

import { HasPermission } from '../../features/auth'
import { HideForVendor } from '../../features/auth/HasRole'
import { ChargesSlider } from '../../features/charges/ChargesSlider'
import { ChargesToggle } from '../../features/charges/ChargesToggle'
import { useChargeList } from '../../features/charges/useChargeList'
import { RefNumsEditor } from '../../features/ref-nums/RefNumsEditor'
import { LoadUserDisplay } from '../../features/shipment-list/LoadUserDisplay'
import { VendorShipmentIcon } from '../../features/shipment-list/VendorShipmentIndicator'
import { useAppContext, useLocale, useTypeNames } from '../../hooks/settings'
import { mapPaymentTerms, mapRateType } from '../../utils/shipmentFuncs'

export const OverviewPanel = ({ load }: PropsWithLoad) => {
  const { accessorialName, equipmentName } = useTypeNames()
  const contextQuery = useAppContext()
  const locale = useLocale()

  const { equipment, pickupDate, selectedQuote, tracking, items } = load
  const chargeList = useChargeList(selectedQuote)
  const equipmentType = selectedQuote?.equipmentType || equipment.type
  const paymentTerms = mapPaymentTerms(load)
  const rateType = mapRateType(load)
  const user = contextQuery.data?.user
  const totalWeight = totalShipmentWeight(load)
  const weightUOM = items?.[0]?.weightUOM ?? 'lbs'

  const estimatedPickup = dayjs.utc(pickupDate)
  const actualPickup = tracking?.pickupDateActual
    ? dayjs(tracking.pickupDateActual)
    : undefined
  const showActualPickup =
    actualPickup &&
    !estimatedPickup.startOf('day').isSame(actualPickup.startOf('day'))
  return (
    <div className="b1350:row-start-2 b1350:col-span-full b1350:bg-[#f9f9f9] relative b1350:pr-10 b1350:border-b b1350:border-fv-gray b1350:px-4 bg-fv-blue-50 border-r border-fv-gray p-6 pt-8 overflow-auto b1350:p-0 b1350:border-r-0">
      <div className="b1350:py-6 b1350:border-fv-gray b1350:border-r b1350:overflow-auto">
        <ul className="b1350:mb-0 b1350:flex b1350:items-center b1350:space-x-4 b1350:whitespace-nowrap b1350:space-y-0 space-y-2">
          <RefNumsEditor load={load} wrap={children => <li>{children}</li>} />

          <li className="b1350:p-0 b1350:m-0 b1350:border-0 mb-3 mt-3 border-b border-gray-300 pb-4">
            {showActualPickup && (
              <div className="b1350:flex-row b1350:items-center b1350:gap-y-0 b1350:pt-0 flex flex-col gap-x-3 gap-y-1 pt-2">
                <div>
                  <Icon
                    icon="calendar-day"
                    className="text-fv-gray-dark fa-fw"
                    transform="up-.5"
                  />
                  <span>
                    Est Pickup:{' '}
                    {estimatedPickup.local().format('ddd MMM D, YYYY')}
                  </span>
                </div>
                <div>
                  <Icon
                    icon="calendar-day"
                    className="text-fv-gray-dark fa-fw"
                    transform="up-.5"
                  />
                  <span>
                    Act Pickup: {actualPickup.local().format('ddd MMM D, YYYY')}
                  </span>
                </div>
              </div>
            )}
            {!showActualPickup && (
              <div className="b1350:pt-0 pt-2">
                <Icon
                  icon="calendar-day"
                  className="text-fv-gray-dark fa-fw"
                  transform="up-.5"
                />
                <span>{estimatedPickup.local().format('ddd MMM D, YYYY')}</span>
              </div>
            )}
          </li>
          <li>{equipmentName(equipmentType)}</li>
          <li>
            {equipment.accessorials
              ?.map(a => accessorialName(a.key))
              .join(', ')}{' '}
          </li>
          <li>{totalWeight && `${formatNumber(totalWeight)} ${weightUOM}`}</li>
          <li>{getDistanceDisplay(load, locale)}</li>
          {!load.isFreightCollect && (
            <HasPermission name="view-rates">
              <li>
                <ChargesToggle
                  className="font-oxygen"
                  theme="underlined"
                  chargeState={chargeList}
                  icon={false}
                  onClick={chargeList.toggleEditing}
                >
                  <FvNum
                    val={selectedQuote?.amount}
                    currency
                    includeEmptyDecimals
                  />
                </ChargesToggle>

                <ChargesSlider chargeState={chargeList} load={load} />
              </li>
            </HasPermission>
          )}

          <li className="b1350:border-0 b1350:m-0 b1350:p-0 mb-3 border-b border-gray-300 pb-4">
            {!load.isFreightCollect && selectedQuote?.quoteNum ? (
              <span>
                Quote# {selectedQuote.quoteNum}{' '}
                <ClipboardButton value={selectedQuote.quoteNum} />
              </span>
            ) : (
              <span>Quote# NA</span>
            )}
          </li>
          <HideForVendor>
            <li>{rateType}</li>
            <li>{paymentTerms}</li>
            <li className="last:pr-4">
              Booked by <LoadUserDisplay {...load.bookedBy} />
            </li>
            <li className="last:pr-4">{user?.company}</li>

            {load.createdBy?.vendorId && (
              <li className="b1350:border-0 b1350:m-0 b1350:p-0 mb-3 border-t border-gray-300 pt-4">
                <div className="flex items-center gap-x-2">
                  <VendorShipmentIcon />
                  <span>Vendor created shipment</span>
                </div>
              </li>
            )}
          </HideForVendor>
        </ul>
      </div>

      <div className="mouse-wheel-animation hidden b1350:block right-2" />
    </div>
  )
}
