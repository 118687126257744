export const loadedRatesTemplate = [
  'Origin Postal Code,Origin State,Origin Country*,Destination Postal Code,Destination Postal Code End,Destination State,Destination Country*,Rate*,Rate Currency,Rate Type*,Minimum charge,Distance,Distance Unit of Measure,ServiceID*,Contract Start Date*,Contract End Date*,Contract Number,Equipment Type*,fuelincluded*',
  "84106,,USA,66040,,,USA,1300.67,USD,flat,,1200,miles,truckload,1/1/25,12/31/25,8765309,Dry Van 53',TRUE",
  "84106,,USA,64101,64199,,USA,5,USD,per-mile,500,,miles,truckload,1/1/25,12/31/25,8765340,Dry Van 53',TRUE",
  "84106,,USA,,,Missouri,USA,5,USD,per-mile,500,,miles,truckload,1/1/25,12/31/25,8765351,Dry Van 48',FALSE",
  ",Ohio,USA,,,Missouri,USA,5,USD,per-mile,500,,miles,truckload,1/1/25,12/31/25,8765351,Dry Van 48',FALSE",
]
export const fuelTableTemplate = [
  'lower boundary,upper boundary,calculation type,rate,currency',
  '4.50,4.59,per mile,0.70,usd',
  '4.60,4.69,per mile,0.75,usd',
  '4.70,4.79,per mile,0.80,usd',
  '4.80,4.89,per mile,0.85,usd',
  '4.90,4.99,per mile,0.90,usd',
  '5.00,5.09,per mile,0.95,usd',
  '5.10,5.19,per mile,1.00,usd',
  '5.20,5.29,per mile,1.05,usd',
  '5.30,5.39,per mile,1.10,usd',
  '5.40,5.49,per mile,1.15,usd',
]
