import { useQueries } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type Workflow } from '@fv/models'

import { apiUri } from '../../constants'
import { type CarrierBookSettings } from '../../features/booking/types'
import { shipperFetch } from '../../utils/shipperFetch'

const defaultSettings: CarrierBookSettings = {
  allowTLPostalCodeChange: true,
  requiresDeclaredValue: false,
  requiresFullBOLDetails: false,
}

const settingsKeys = {
  all: ['carrier-book-settings'] as const,
  carrier: (id?: string) => [...settingsKeys.all, id ?? null] as const,
}

async function fetchCarrierBookSettings(
  workflow: Workflow,
  carrierId?: string,
): Promise<CarrierBookSettings> {
  if (!carrierId) throw new Error('`carrierId` required to fetch book settings')

  return shipperFetch(`/book-settings/${carrierId}`, {
    query: { workflow },
  })
}

// May need settings for multiple carriers when booking multi-truck shipment.
export function useCarrierBookSettings(
  workflow: Workflow,
  carrierIds: string[],
) {
  const uniqueCarrierIds = Array.from(new Set(carrierIds))

  const settingsQueries = useQueries({
    queries: uniqueCarrierIds.map(id => ({
      enabled: Boolean(id),
      queryFn: () => fetchCarrierBookSettings(workflow, id),
      queryKey: settingsKeys.carrier(id),
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    })),
  })

  const data: CarrierBookSettings = settingsQueries.reduce(
    (settings, q) => ({
      requiresDeclaredValue:
        q.data?.requiresDeclaredValue || settings.requiresDeclaredValue,
      requiresFullBOLDetails:
        q.data?.requiresFullBOLDetails || settings.requiresFullBOLDetails,
      allowTLPostalCodeChange:
        q.data?.allowTLPostalCodeChange ?? settings.allowTLPostalCodeChange,
    }),
    defaultSettings,
  )

  return {
    data,
    isLoading: settingsQueries.some(q => q.isLoading && q.isFetching),
  }
}
