import { type FormSchema, useFormModel } from '@fv/client-core'
import {
  type DeepPartial,
  type HazardousDetailFormModel,
  type LegacyReadOnlyDetail,
} from '@fv/client-types'

import { InputGroup } from '../../../components/inputs/InputGroup'
import {
  useHazardClassOptions,
  usePackingGroupOptions,
} from '../../../hooks/settings/useEnumOptions'
import { validateHazmatId } from '../commodityUtils'

const schema: FormSchema<HazardousDetailFormModel> = {
  hazmatId: {
    validate: validateHazmatId,
  },
}

type Props = {
  value?: HazardousDetailFormModel
  readOnly?: DeepPartial<LegacyReadOnlyDetail>
  onChange: (value: Partial<HazardousDetailFormModel>) => void
  itemId: string
  disabled?: boolean
}
export const HazmatForm = ({
  disabled,
  itemId,
  value,
  readOnly,
  onChange,
}: Props) => {
  const packingGroupOptions = usePackingGroupOptions()
  const { hazardClassOptions, hazardSubClassOptions } = useHazardClassOptions()
  const { register } = useFormModel({
    value: value ?? {},
    key: `${itemId}.hazard`,
    onChange,
    schema,
    disabled,
  })
  return (
    <>
      <InputGroup
        inputProps={register('hazmatId')}
        inputType="text"
        label="Hazmat ID"
        locked={readOnly?.hazmatId}
        required
      />

      <InputGroup
        inputProps={{
          ...register('packingGroup'),
          options: packingGroupOptions,
        }}
        inputType="select"
        label="Packing group"
        locked={readOnly?.packingGroup}
      />

      <InputGroup
        inputProps={register('permitNumber')}
        inputType="text"
        label="Special permit #"
        locked={readOnly?.specialPermit}
      />

      <InputGroup
        inputProps={{
          ...register('primaryClass'),
          options: hazardClassOptions,
        }}
        inputType="select"
        label="Hazard class"
        locked={readOnly?.hazardClass}
        required
      />

      <InputGroup
        inputProps={{
          ...register('secondaryClass'),
          options: hazardSubClassOptions,
        }}
        inputType="select"
        label="Sub hazard class 1"
        locked={readOnly?.secondaryHazardClass}
      />

      <InputGroup
        inputProps={{
          ...register('tertiaryClass'),
          options: hazardSubClassOptions,
        }}
        inputType="select"
        label="Sub hazard class 2"
        locked={readOnly?.tertiaryHazardClass}
      />
    </>
  )
}
