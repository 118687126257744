import { useMemo } from 'react'

import {
  type EquipmentAccessorialType,
  type LocationAccessorialType,
} from '@fv/models'

import { useQuoteSettings } from '.'

export type AccessorialPrompt = {
  key: 'count' | 'max' | 'min' | 'size' | 'uom'
  label: string
  max?: number
  min?: number
  options?: Array<{
    label: string
    value: number | string
  }>
  promptType: 'boolean' | 'number' | 'string'
}

export type AccessorialTypeEnum = {
  key: EquipmentAccessorialType | LocationAccessorialType
  name: string
  prompts?: Array<AccessorialPrompt>
  type: 'equipment' | 'location'
}

export function useAccessorialOptions(type: 'equipment' | 'location') {
  const quoteSettingsQuery = useQuoteSettings('truckload')
  const accessorialOptions = quoteSettingsQuery.data?.accessorialOptions

  return useMemo(() => {
    return (accessorialOptions ?? []).filter(
      (o: AccessorialTypeEnum) => o.type === type,
    )
  }, [accessorialOptions, type])
}
