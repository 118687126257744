import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import { create } from 'zustand'
import { shallow } from 'zustand/shallow'

import { submitForm } from '@fv/client-core'

import { buildStop } from '../../utils/locationFuncs'
import { resequenceFormItems } from '../../utils/shipmentFuncs'
import { quoteRequestMapper } from '../quote/quoteFuncs'
import { type ShipmentLocationFormModel } from '../shipment-location-form/types'
import { buildLoad, countLoads, getItemsBySequence } from './quoteRequestUtils'
import {
  type QuoteFormActions,
  type QuoteFormLoad,
  type QuoteFormState,
  type QuoteRequestLoads,
} from './types'

const initialState = (): QuoteFormState => ({
  isAddingItems: false,
  isLiveLoad: true,
  loads: new Map(),
  orderNumber: '',
  requestIdentifier: '',
  quoteRequestId: '',
  stopFormRef: null,
  stops: [
    buildStop({
      sequence: 0,
      stopDate: dayjs().toDate(),
      stopType: 'pickup',
    }),
    buildStop({ sequence: 1, stopType: 'delivery' }),
  ],
})

export const useQuoteFormState = create<QuoteFormActions & QuoteFormState>(
  (set, get) => ({
    ...initialState(),
    actions: {
      activateLoad: (type, loadId) => {
        const loadsOfType = get().loads.get(type) ?? []
        const nextLoadIx = loadsOfType.findIndex(x => x.loadId === loadId)
        const nextLoad = loadsOfType[nextLoadIx]
        if (!nextLoad || nextLoad.isActiveInUI) return

        const activeLoadIx = loadsOfType.findIndex(x => x.isActiveInUI)
        const activeLoad = loadsOfType[activeLoadIx]
        const isPreviousLoad = nextLoadIx < activeLoadIx

        // Allow going backward without validating current load
        const isValid =
          isPreviousLoad ||
          get().actions.validateLoad(activeLoad.type, activeLoad.loadId)

        if (isValid) {
          get().actions.setLoadData(type, loadId, {
            isActiveInUI: true,
          })
        }
      },
      addLoads: (type, quantity = 1) => {
        const nextLoads = new Map(get().loads)
        const loadCount = countLoads(nextLoads)
        const prevStops = get().stops
        let shouldResetStops = false

        // Cannot do multi-truck AND multi-stop together
        if (loadCount > 0 && prevStops.length > 2) {
          const shouldProceed = window.confirm(
            'Adding multiple pieces of equipment will remove additional route stops. Do you want to proceed?',
          )
          if (!shouldProceed) return
          shouldResetStops = true
        }

        const newLoadsOfType: QuoteFormLoad[] = []

        for (let i = quantity; i > 0; i--) {
          newLoadsOfType.push(buildLoad(type))
        }

        const prevLoadsOfType = nextLoads.get(type) ?? []
        const hasActiveLoadOfType = !!prevLoadsOfType.find(x => x.isActiveInUI)
        if (!hasActiveLoadOfType) newLoadsOfType[0].isActiveInUI = true

        nextLoads.set(type, prevLoadsOfType.concat(newLoadsOfType))

        if (shouldResetStops) {
          for (const k of nextLoads.keys()) {
            const loadsOfType = nextLoads.get(k)?.map(x => ({
              ...x,
              items: x.items.map(i => ({
                ...i,
                dropSequence: 1,
                pickSequence: 0,
              })),
            }))

            nextLoads.set(k, loadsOfType ?? [])
          }
        }

        set({
          loads: nextLoads,
          ...(shouldResetStops && {
            stops: [prevStops[0], prevStops[prevStops.length - 1]].map(
              (s, i) => ({
                ...s,
                sequence: i,
              }),
            ),
          }),
        })
      },
      addStops: (quantity = 1) => {
        const nextLoads = new Map(get().loads)
        const newStops: ShipmentLocationFormModel[] = []
        const prevStops = get().stops
        const prevDestSequence = prevStops.length - 1

        for (let i = quantity; i > 0; i--) {
          newStops.push(
            buildStop({
              sequence: i, // re-sequencing below
              stopType: 'delivery',
            }),
          )
        }

        const destination = prevStops[prevStops.length - 1]
        const nextStops = prevStops
          .slice(0, -1)
          .concat(newStops)
          .concat(destination)
          .map((stop, i) => ({
            ...stop,
            sequence: i,
          }))

        // Update destination sequence on items
        for (const k of nextLoads.keys()) {
          const loadsOfType = nextLoads.get(k)?.map(x => ({
            ...x,
            items: x.items.map(i => ({
              ...i,
              dropSequence:
                i.dropSequence === prevDestSequence
                  ? nextStops.length - 1
                  : i.dropSequence,
            })),
          }))

          nextLoads.set(k, loadsOfType ?? [])
        }

        set({
          loads: nextLoads,
          stops: nextStops,
        })
      },
      initializeFromTemplate: (template, mode) => {
        const qr = quoteRequestMapper.tlRequest(template, mode)
        const currentQuoteRequestId = get().quoteRequestId

        if (
          currentQuoteRequestId &&
          qr.metadata.quoteRequestId === currentQuoteRequestId
        ) {
          return
        }

        set({
          ...qr.metadata,
          loads: qr.loads,
          stops: qr.stops,
        })
      },
      openQuoteItemsForm: () => {
        if (countLoads(get().loads) < 1) {
          toast.error(
            'Please select an equipment type before adding product details.',
          )

          return
        }

        set({ isAddingItems: true })
      },
      removeLoads: (type, startIndex = 0, quantity) => {
        const nextLoads = new Map(get().loads)

        // Remove all (deselect equipmentType, set quantity 0)
        if (!quantity) {
          nextLoads.delete(type)
          return set({ loads: nextLoads })
        }

        const endIndex = startIndex + quantity
        const loadsOfType = nextLoads.get(type) ?? []
        const activeIndex = loadsOfType?.findIndex(x => x.isActiveInUI) ?? 0

        const nextLoadsOfType = loadsOfType
          .slice(0, startIndex)
          .concat(loadsOfType.slice(endIndex))

        if (nextLoadsOfType.length === 0) {
          nextLoads.delete(type)
          return set({ loads: nextLoads })
        }

        const hasActiveLoadOfType = !!nextLoadsOfType.find(x => x.isActiveInUI)

        if (!hasActiveLoadOfType) {
          if (nextLoadsOfType[activeIndex]) {
            nextLoadsOfType[activeIndex].isActiveInUI = true
          } else {
            nextLoadsOfType[nextLoadsOfType.length - 1].isActiveInUI = true
          }
        }

        nextLoads.set(type, nextLoadsOfType)
        set({ loads: nextLoads })
      },
      removeStop: sequence => {
        const nextLoads = new Map(get().loads)
        const items = getItemsBySequence(nextLoads, sequence)
        const prevStops = get().stops
        let shouldReassignItems = false

        if (items.length) {
          const isPickup = items.some(i => i.pickSequence === sequence)
          const shouldRemove = window.confirm(
            `You have items being ${
              isPickup ? 'picked up' : 'delivered'
            } at this stop. They will be reassigned to the ${
              isPickup ? 'previous pickup location' : 'next delivery location'
            }. Are you sure?`,
          )

          if (!shouldRemove) return
          shouldReassignItems = true
        }

        // Destination has moved to prev dest sequence - 1
        if (!shouldReassignItems) {
          const destItems = getItemsBySequence(nextLoads, prevStops.length - 1)
          if (destItems.length) shouldReassignItems = true
        }

        const nextStops = prevStops
          .filter(s => s.sequence !== sequence)
          .map((stop, i) => ({
            ...stop,
            sequence: i,
          }))

        // Reassign items to prev pickup/next delivery sequence
        if (shouldReassignItems) {
          for (const k of nextLoads.keys()) {
            const loadsOfType = resequenceFormItems(
              nextLoads.get(k) ?? [],
              nextStops,
              sequence,
            )

            nextLoads.set(k, loadsOfType)
          }
        }

        set({
          stops: nextStops,
          ...(shouldReassignItems && { loads: nextLoads }),
        })
      },
      setLoadData: (type, loadId, data) => {
        const nextLoads = new Map(get().loads)
        if (!nextLoads.get(type)) return

        const isSettingActiveInUI = data.isActiveInUI
        const nextLoadsOfType =
          nextLoads.get(type)?.map(load => {
            if (load.loadId !== loadId) {
              return {
                ...load,
                ...(isSettingActiveInUI && { isActiveInUI: false }),
              }
            }

            return { ...load, ...data }
          }) ?? []

        nextLoads.set(type, nextLoadsOfType)
        set({ loads: nextLoads })
      },
      setStopData: (sequence, data) => {
        if (data.formRef) return set({ stopFormRef: data.formRef })

        const prevStops = get().stops
        const prevStop = prevStops.find(s => s.sequence === sequence)
        const nextStops = prevStops.map(stop => {
          if (stop.sequence !== sequence) return stop
          return { ...stop, ...data }
        })

        const nextStop = nextStops.find(s => s.sequence === sequence)
        const nextLoads = new Map(get().loads)
        let shouldReassignItems = false

        if (
          nextStop?.stopType !== prevStop?.stopType &&
          nextStop?.stopType !== 'both'
        ) {
          const items = getItemsBySequence(nextLoads, sequence)

          if (items.length) {
            const pickups = items.filter(i => i.pickSequence === sequence)
            const deliveries = items.filter(i => i.dropSequence === sequence)
            let shouldUpdate = true

            if (pickups.length && nextStop?.stopType === 'delivery') {
              shouldUpdate = window.confirm(
                'You have items being picked up at this stop. They will be reassigned to the previous pickup location. Are you sure?',
              )
            }

            if (deliveries.length && nextStop?.stopType === 'pickup') {
              shouldUpdate = window.confirm(
                'You have items being delivered at this stop. They will be reassigned to the next delivery location. Are you sure?',
              )
            }

            if (!shouldUpdate) return
            shouldReassignItems = true
          }
        }

        // Reassign items to prev pickup/next delivery sequence
        if (shouldReassignItems) {
          for (const k of nextLoads.keys()) {
            const loadsOfType = resequenceFormItems(
              nextLoads.get(k) ?? [],
              nextStops,
              sequence,
            )

            nextLoads.set(k, loadsOfType)
          }
        }

        set({
          stops: nextStops,
          ...(shouldReassignItems && { loads: nextLoads }),
        })
      },
      setMetadata: ({ isAddingItems, isLiveLoad, orderNumber }) => {
        set({
          // Use `openQuoteItemsForm` to set `isAddingItems` true
          ...(isAddingItems === false && { isAddingItems }),
          ...(typeof isLiveLoad === 'boolean' && { isLiveLoad }),
          ...(typeof orderNumber === 'string' && { orderNumber }),
        })
      },
      updateLoads: loads => {
        const nextLoads: QuoteRequestLoads = new Map()

        loads.forEach(load => {
          const loadsOfType = nextLoads.get(load.type)
          nextLoads.set(load.type, loadsOfType?.concat(load) ?? [load])
        })

        set({ loads: nextLoads })
      },
      validateLoad: (type, loadId, delay = 0) => {
        const load = get()
          .loads.get(type)
          ?.find(x => x.loadId === loadId)

        if (!load) return true

        const isValid = load.formRef?.checkValidity() ?? false
        let isActiveInUI = load.isActiveInUI
        let revalidationDelay: number | null = null

        // Load must be active before dispatching submit event
        if (!isValid) {
          if (load.isActiveInUI) {
            submitForm(load.formRef)
          } else {
            isActiveInUI = true
            revalidationDelay = delay
          }
        }

        if (
          isActiveInUI !== load.isActiveInUI ||
          revalidationDelay !== load.revalidationDelay
        ) {
          get().actions.setLoadData(type, loadId, {
            isActiveInUI,
            revalidationDelay,
          })
        }

        return isValid
      },
      validateStops: () => {
        const locationsForm = get().stopFormRef
        const isValid = locationsForm?.checkValidity() ?? false

        if (!isValid) submitForm(locationsForm)
        return isValid
      },
      validateQuoteRequest: () => {
        const loads = get().loads
        const equipmentTypes = Array.from(loads.keys())

        if (equipmentTypes.length === 0) {
          toast.error('Must select at least one equipment type.')
          return false
        }

        const hasValidStops = get().actions.validateStops()
        let hasValidLoads = true

        // Validating equipment in reverse-alphabetical order so
        // error is reported on top-most equipment in the list.
        equipmentTypes
          .sort((a, b) => b.localeCompare(a))
          .forEach((t, ix) => {
            const equipmentOfType = loads.get(t)
            let hasInvalidLoad = false

            equipmentOfType?.forEach(x => {
              if (hasInvalidLoad) return
              const isValid = get().actions.validateLoad(t, x.loadId, ix)

              if (!isValid) {
                hasInvalidLoad = true
                hasValidLoads = false
              }
            })
          })

        return hasValidLoads && hasValidStops
      },
    },
  }),
)

export const useQuoteFormActions = () => useQuoteFormState(s => s.actions)
export const useQuoteFormLoads = () => useQuoteFormState(s => s.loads)
export const useQuoteFormLocations = () => useQuoteFormState(s => s.stops)
export const useQuoteFormMetadata = () =>
  useQuoteFormState(
    s => ({
      isAddingItems: s.isAddingItems,
      isLiveLoad: s.isLiveLoad,
      orderNumber: s.orderNumber,
      requestIdentifier: s.requestIdentifier,
    }),
    shallow,
  )

export function resetQuoteForm() {
  useQuoteFormState.setState(initialState())
}
