import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { type PropsWithClassName } from '@fv/client-types'

type Props = {
  st?: boolean // static is reserved
}
export const PillHeader = ({
  children,
  className,
  st,
}: PropsWithClassName<PropsWithChildren<Props>>) => (
  <div
    className={clsx(
      { 'absolute -left-2 -top-3.5': !st },
      'rounded-full px-4 py-2 inline-flex gap-x-2 border',
      className,
    )}
  >
    {children}
  </div>
)
