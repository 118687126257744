import { type ForwardedRef, forwardRef, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'

import {
  FvButton,
  type IconName,
  SliderPanel,
  TextField,
  useFvNavigate,
} from '@fv/client-components'
import {
  type FullShipment,
  type QuoteSelectionReason,
  type UIQuote,
} from '@fv/client-types'
import { quoteSelectionReasonLabels, type ReasonType } from '@fv/models'
import { type DTO } from '@fv/models/core'

import InfoBox from '../../components/InfoBox'
import { InputGroup } from '../../components/inputs/InputGroup'
import {
  RadioItem,
  type RadioItemProps,
} from '../../components/inputs/RadioField'
import { useQuotes } from '../../hooks/shipments'
import { routes } from '../../routes'
import {
  type QuoteReasonPayload,
  useNeedsQuoteReason,
  useUpdateQuoteReason,
} from './hooks'

const orderedOptions: Array<ReasonType> = [
  'urgent',
  'serviceLevel',
  'preference',
  'other',
]

type QuoteReasonFormProps = {
  onCancel?: () => void
  onSuccess: (shipment: FullShipment) => void
  loadId: string
  value?: DTO<QuoteSelectionReason>
  cancelText?: string
  cancelIcon?: IconName
}
export const QuoteReasonForm = ({
  loadId,
  onCancel,
  onSuccess,
  value,
  cancelText = 'Cancel',
  cancelIcon = 'times',
}: QuoteReasonFormProps) => {
  const updateQuoteReason = useUpdateQuoteReason(loadId)
  const { register, watch, handleSubmit } = useForm<QuoteReasonPayload>({
    defaultValues: {
      reasonType: value?.reasonType ?? 'urgent',
      description: value?.description ?? '',
    },
  })
  const reasonType = watch('reasonType')

  const formSubmit = async (data: QuoteReasonPayload) => {
    try {
      const shipment = await updateQuoteReason.mutateAsync(data)
      onSuccess(shipment)
    } catch {
      toast.error('There was an error saving your response')
    }
  }

  return (
    <div>
      <InfoBox>
        Please choose a reason you have not chosen the cheapest option.
      </InfoBox>
      <form onSubmit={handleSubmit(formSubmit)}>
        {orderedOptions.map(o => (
          <RadioOption key={o} {...register('reasonType')} type={o} value={o} />
        ))}
        {reasonType !== 'other' && <hr />}
        {reasonType === 'other' && (
          <div className="py-4">
            <TextField
              placeholder="Enter your reason here (50 characters max)"
              {...register('description')}
              maxLength={50}
              className="form-control"
            />
          </div>
        )}
        <div className="flex">
          <FvButton
            type="submit"
            icon="check"
            theme="primary"
            loading={updateQuoteReason.isLoading}
          >
            Save and proceed
          </FvButton>
          <FvButton icon={cancelIcon} onClick={onCancel} theme="plain">
            {cancelText}
          </FvButton>
        </div>
      </form>
    </div>
  )
}

const RadioOption = forwardRef(
  (
    { type, ...props }: RadioItemProps & { type: ReasonType },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const label = quoteSelectionReasonLabels[type]
    return (
      <div className="flex flex-col py-3">
        <RadioItem {...props} ref={ref}>
          <div>{label.label}</div>
          {label.desc && (
            <div className="py-1 italic text-gray-400">{label.desc}</div>
          )}
        </RadioItem>
      </div>
    )
  },
)

type CompactProps = {
  onChange: (payload: QuoteReasonPayload) => void
}
export const CompactQuoteReasonForm = ({ onChange }: CompactProps) => {
  const { register, control } = useForm<QuoteReasonPayload>({
    defaultValues: { reasonType: 'urgent' },
  })
  const [mounted, setMounted] = useState(false)
  const form = useWatch({ control })
  useEffect(() => {
    if (!mounted) return
    onChange({
      ...form,
      reasonType: form.reasonType ?? 'urgent',
    })
  }, [onChange, form, mounted])
  useEffect(() => {
    setMounted(true)
  }, [])
  return (
    <div>
      <InputGroup
        label="Please choose a reason you have not chosen the cheapest option."
        inputType="select"
        inputProps={{
          options: orderedOptions.map(o => ({
            text: quoteSelectionReasonLabels[o].label,
            value: o,
          })),
          ...register('reasonType'),
        }}
      />
      {form.reasonType === 'other' && (
        <TextField
          placeholder="Enter your reason here (50 characters max)"
          {...register('description')}
          maxLength={50}
          className="form-control"
        />
      )}
    </div>
  )
}

type QuoteReasonSliderProps = {
  load: Pick<
    FullShipment,
    'workflow' | 'loadId' | 'quoteRequestId' | 'quoteSelectionReason' | 'status'
  >
  quote: Pick<UIQuote, 'amount'>
}
export const QuoteReasonSlider = ({ quote, load }: QuoteReasonSliderProps) => {
  const navigate = useFvNavigate()
  const needsQuoteReason = useNeedsQuoteReason()
  const quotes = useQuotes(load.loadId)
  const [submitted, setSubmitted] = useState(false)
  const canSkip =
    load.status !== 'pending' && !!load.quoteSelectionReason?.reasonType

  if (!quotes.data?.length) {
    return null
  }

  const needsForm = needsQuoteReason(quotes.data ?? [], quote)
  if (!needsForm) {
    return null
  }

  return (
    <SliderPanel position="right" isOpen={!submitted}>
      <QuoteReasonForm
        loadId={load.loadId}
        value={load.quoteSelectionReason}
        onCancel={() =>
          canSkip ? setSubmitted(true) : navigate(routes.rates(load))
        }
        onSuccess={() => setSubmitted(true)}
        cancelText={canSkip ? 'Close' : 'Take me back to my rates'}
        cancelIcon="arrow-left"
      />
    </SliderPanel>
  )
}
