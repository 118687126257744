import { type FullShipment } from '@fv/client-types'
import { type Workflow } from '@fv/models'

import { useAccountFeatures, useRole } from './features/auth/hooks'
import { type RatesListingMode } from './features/quote/useRatesListing'
import { useAccountSettings } from './features/settings/account-settings/hooks'
import { type QuoteRouteParams, routes } from './routes'

export const useQuotePage = () => {
  const { quoting } = useAccountSettings()
  const { ltl, truckLoad } = useAccountFeatures()
  const role = useRole()

  if (role === 'vendor') return routes.create()

  const quoteWorkflow: Workflow =
    quoting?.defaultWorkflow ??
    (ltl ? 'ltl' : truckLoad ? 'truckload' : 'parcel')

  return routes.quote(quoteWorkflow)
}

export const useHomePage = () => {
  return useQuotePage()
}

export const useLoadEditRoute = () => {
  const role = useRole()

  return (
    load: Pick<FullShipment, 'loadId' | 'workflow' | 'quoteRequestId'>,
    mode: QuoteRouteParams['mode'],
  ) => {
    if (role === 'vendor') return routes.editLoad(load.loadId)
    return routes.quote(load.workflow, {
      id: load.workflow === 'truckload' ? load.quoteRequestId : load.loadId,
      mode,
    })
  }
}

export const useLoadRatesPage = () => {
  const role = useRole()

  return (
    load: Pick<FullShipment, 'workflow' | 'loadId' | 'quoteRequestId'>,
    mode?: RatesListingMode,
  ) => {
    return routes.rates(load, mode, role)
  }
}
