import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import orderBy from 'lodash/orderBy'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type ContractedRate, type ContractedRatesFilter } from '@fv/models'

import { apiUri, supportMessage } from '../../../constants'
import { shipperFetch } from '../../../utils/shipperFetch'
import { useCarrierOptions } from './hooks'
import { type FuelTable } from './types'

export const contractedRatesKeys = {
  all: ['contracted-rates'],
  carriers: ['contracted-carriers'],
  search: (filter: ContractedRatesFilter) => [
    ...contractedRatesKeys.all,
    ...Object.values(filter),
  ],
}
export const fuelTableKeys = {
  all: ['fuel-tables'] as const,
  carrier: (id: string | null) => [...fuelTableKeys.all, id] as const,
}

async function fetchFuelTable(
  carrierId: string | null,
): Promise<FuelTable | null> {
  const endpoint = `${apiUri}/contracted-rates/fuel/${carrierId ?? 'account'}`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json

  throw (
    response.errorMessage ??
    new Error(`Unable to load fuel table, ${supportMessage}`)
  )
}

export function useFuelTable(carrierId: string | null) {
  return useQuery(fuelTableKeys.carrier(carrierId), () =>
    fetchFuelTable(carrierId),
  )
}

export const useContractedRateCarriers = () => {
  const carrierOptions = useCarrierOptions()
  const query = useQuery(
    contractedRatesKeys.carriers,
    () => shipperFetch<{ carrierId: string }[]>('/contracted-rates/carriers'),
    {
      enabled: !carrierOptions.isLoading,
      select: data => {
        return orderBy(
          data.map(({ carrierId }) => ({
            carrierId,
            carrierName: carrierOptions.data?.find(o => o.value === carrierId)
              ?.text,
          })),
          c => c.carrierName,
        )
      },
    },
  )
  return {
    ...query,
    isLoading: query.isLoading || carrierOptions.isLoading,
  }
}

export const useContractedRates = (filter: ContractedRatesFilter) => {
  const query = useInfiniteQuery(
    contractedRatesKeys.search(filter),
    async ({ pageParam = '' }) => {
      const pageSize = 10000
      const batch = await shipperFetch<ContractedRate[]>('/contracted-rates', {
        query: {
          ...filter,
          limit: pageSize,
          continuationToken: pageParam,
        },
      })

      // If the batch is empty or less than the page size, there are no more pages
      const hasMore = batch.length === pageSize && batch.length > 0

      return {
        rates: batch,
        nextOffset: hasMore ? batch.at(-1)?.continuationToken : undefined,
      }
    },
    {
      getNextPageParam: lastPage => lastPage.nextOffset,
      select: data => ({
        pages: data.pages,
        pageParams: data.pageParams,
        rates: data.pages.flatMap(page => page.rates),
      }),
    },
  )

  return query
}
