import clsx from 'clsx'
import { type PropsWithChildren, useCallback, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import logo from '@fv/client-assets/freightview.svg'
import { FvButton, FvNavLink, Icon, type IconName } from '@fv/client-components'

import { adminUri } from '../constants'
import { HasPermission } from '../features/auth'
import { HasRole, HideForVendor } from '../features/auth/HasRole'
import { useIsAdminAccount, useIsCarrierAccount } from '../features/auth/hooks'
import { SystemNotifications } from '../features/system-notifications/SystemNotifications'
import { ShipperSelector } from '../features/vendor/ShipperSelector'
import { useUnreadNotificationCount } from '../hooks/notifications'
import { useAppContext } from '../hooks/settings'
import { useQuotePage } from '../route-hooks'
import { routes } from '../routes'
import TopNavSearch from './TopNavSearch'

type TopNavLinkProps = PropsWithChildren<{
  activePath?: string
  to: string
  icon: IconName
}>
const TopNavLink = ({ activePath, to, icon, children }: TopNavLinkProps) => {
  const location = useLocation()
  return (
    <FvNavLink
      className={r =>
        clsx(
          'group py-6 block relative whitespace-nowrap px-[calc(1.25rem+1px)]',

          r.isActive || (activePath && location.pathname.includes(activePath))
            ? 'border-l border-r bg-fv-beer-light border-l-fv-gray border-r-fv-gray -mr-px -ml-px before:h-4 before:w-4 before:bg-fv-beer-light before:z-30 before:left-1/2 before:-translate-x-1/2 before:absolute before:rotate-45 before:-bottom-2 before:border-l-transparent before:border-t-transparent before:border-fv-gray before:border after:w-2 after:h-2 after:bg-fv-orange after:z-30 after:absolute after:rotate-45 after:-bottom-1 after:-translate-x-1/2 after:left-1/2 [&>svg]:text-fv-gray-dark !no-underline'
            : 'border-l-transparent border-r-transparent before:w-px before:h-8 before:bg-fv-gray before:absolute before:z-10 before:left-0 before:top-1/2 before:-translate-y-1/2 underline underline-offset-4 decoration-fv-blue-300',
        )
      }
      to={to}
    >
      <Icon
        icon={icon}
        className="group-hover:text-fv-gray-dark text-fv-orange-bright"
      />
      <span>{children}</span>
    </FvNavLink>
  )
}

const TopNav = () => {
  const contextQuery = useAppContext()
  const { firstName, lastName, accountIds } = contextQuery.data?.user ?? {}
  const isCarrier = useIsCarrierAccount()
  const isAdmin = useIsAdminAccount()
  const isViewingAs = !!contextQuery.data?.user?.isAdmin
  const quotePage = useQuotePage()
  let homeUrl = quotePage

  if (isAdmin) {
    homeUrl = `${adminUri}/accounts`
  } else if (isViewingAs) {
    homeUrl = `${adminUri}/accounts/${accountIds[0]}/overview`
  }

  const notificationsQuery = useUnreadNotificationCount()
  const unreadNotificationCount = notificationsQuery.data?.unreadCount ?? 0

  const [searchActive, setSearchActive] = useState(false)
  const resetNav = useCallback(() => {
    setSearchActive(false)
  }, [])

  return !searchActive ? (
    <div className="col-span-full">
      <SystemNotifications />
      <nav className="flex items-center bg-white px-6 border-b border-fv-gray">
        <Link className="pr-4" to={homeUrl}>
          <img src={logo} alt="Freightview" className="min-w-[3rem]" />
        </Link>

        {isCarrier && (
          <TopNavLink
            activePath="/provisioning"
            icon="user-check"
            to={routes.provisioning('requested')}
          >
            Provisioning
          </TopNavLink>
        )}

        {!isCarrier && (
          <>
            <HasPermission name="quote">
              <TopNavLink activePath="/quote" icon="usd-square" to={quotePage}>
                Get a quote
              </TopNavLink>
            </HasPermission>

            <TopNavLink icon="warehouse" to="/pending">
              In process
            </TopNavLink>

            <TopNavLink icon="shipping-fast" to="/active">
              Shipments
            </TopNavLink>

            <HideForVendor>
              <TopNavLink icon="analytics" to="/analytics">
                Analytics
              </TopNavLink>
            </HideForVendor>

            <HasPermission name="audit">
              <TopNavLink icon="donate" to="/audit">
                Auditing
              </TopNavLink>
            </HasPermission>

            <FvButton
              className="main-nav-link hidden b1350:flex"
              icon="search"
              iconClass="text-fv-orange"
              onClick={() => {
                setSearchActive(prev => !prev)
              }}
            />

            <TopNavSearch />
          </>
        )}

        <div className="items-center flex overflow-hidden ml-auto pl-4">
          <HideForVendor>
            <FvNavLink to="/notifications" rel="noreferrer">
              <Icon
                icon="bell-on"
                transform="up-.5"
                className={clsx('block', 'mr-2', {
                  'bellshake-animation text-fv-orange':
                    unreadNotificationCount > 0,
                })}
              />
            </FvNavLink>
          </HideForVendor>
          <FvNavLink
            className="limit-characters"
            to={routes.settings('user-preferences')}
          >
            <Icon icon="cog" />
            <span className="main-nav__user">
              {firstName} {lastName}
            </span>
          </FvNavLink>

          <HasRole role="vendor">
            <ShipperSelector />
          </HasRole>
        </div>
      </nav>
    </div>
  ) : (
    <div className="main-nav-search row-start-1 col-span-full">
      <SystemNotifications />
      <TopNavSearch focusSearch resetNav={resetNav} />
      <FvButton icon="times" theme="plain" onClick={resetNav} />
    </div>
  )
}

export default TopNav
