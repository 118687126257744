import { type PropsWithChildren } from 'react'

import { FvButton, type FvButtonProps, Tooltip } from '@fv/client-components'

import { useDownloadRatesPDF } from './useDownloadRatesPDF'

type Props = FvButtonProps & {
  loadId: string
  tooltip?: boolean
}

export const PrintRatesButton = ({
  loadId,
  tooltip,
  ...rest
}: PropsWithChildren<Props>) => {
  const { setFetching, isFetchingFile } = useDownloadRatesPDF(loadId)
  return (
    <Tooltip label={tooltip ? `Print rates` : ''}>
      <FvButton
        onClick={() => setFetching(true)}
        icon={isFetchingFile ? 'spinner' : 'print'}
        {...rest}
      >
        {rest.children}
      </FvButton>
    </Tooltip>
  )
}
