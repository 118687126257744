import { useParams } from 'react-router-dom'

import { Icon } from '@fv/client-components'

import TopNav from '../../components/TopNav'
import { useHomePage } from '../../route-hooks'
import { HasFeature } from '../auth'
import { BookingPage } from '../booking/BookingPage'
import { useQuoteRequest } from '../quote-request/useQuoteRequest'
import { InProcessShipmentList } from './InProcessShipmentList'

export const InProcessPage = () => {
  const { quoteRequestId } = useParams()
  const homePage = useHomePage()
  const quoteRequestQuery = useQuoteRequest(quoteRequestId)
  const isLoading = quoteRequestQuery.isLoading && quoteRequestQuery.isFetching
  const gridClass = quoteRequestId
    ? 'grid grid-cols-[20rem_1fr] grid-rows-[auto_1fr_auto] bg-[#f2f2f2] h-screen'
    : 'grid overflow-hidden bg-[#f2f2f2] grid-tl-shipment-list grid-cols-[19rem_1fr_30.5rem] grid-rows-[auto_1fr_0] h-screen'
  const loads = quoteRequestQuery.data ?? []

  return (
    <div className={gridClass}>
      <TopNav />

      {quoteRequestId &&
        (isLoading ? (
          <div style={{ gridColumn: '1 / 3', margin: 'auto' }}>
            <Icon icon="spinner" size="2x" />
          </div>
        ) : (
          <HasFeature name="truckLoad" redirect={homePage}>
            <BookingPage loads={loads} />
          </HasFeature>
        ))}

      {!quoteRequestId && <InProcessShipmentList />}
    </div>
  )
}
