import orderBy from 'lodash/orderBy'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type Workflow } from '@fv/models'

import { apiUri } from '../../constants'
import { useStaticDataQuery } from '../../hooks/useStaticDataQuery'
import { type SlimCarrier } from '../quote/useCarrierList'

export type CustomerRoutedCarrierDTO = SlimCarrier

export type CustomerRoutedDTO = {
  carriers: CustomerRoutedCarrierDTO[]
}

async function fetchCustomerRoutedCarriers(
  workflow: Workflow,
): Promise<CustomerRoutedDTO> {
  const endpoint = `${apiUri}/carriers/customer-routed?workflow=${workflow}&v=2`
  const response = await fetchJson(endpoint, buildFetchOptionsWithAuth())

  if (response.ok) {
    const dto = response.json as CustomerRoutedDTO

    return {
      carriers: orderBy(dto.carriers, c => c.name?.toLowerCase()),
    }
  }
  throw response.errorMessage
}

export function useCustomerRoutedCarriers(workflow: Workflow) {
  return useStaticDataQuery(['customer-routed-carriers', workflow], () =>
    fetchCustomerRoutedCarriers(workflow),
  )
}
