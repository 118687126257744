import { lazy, useEffect, useRef, useState } from 'react'
import {
  checkboxColumn,
  dateColumn,
  floatColumn,
  intColumn,
  keyColumn,
  textColumn,
} from 'react-datasheet-grid'
import { type CellWithId } from 'react-datasheet-grid/dist/types'
import toast from 'react-hot-toast'
import { useResizeObserver } from 'usehooks-ts'

import { equipmentTypes, states } from '@fv/models'
import { camelCaseToTitleCase } from '@fv/models/core'

import {
  multiSelectColumn,
  selectColumn,
} from '../../../components/datagrid/select-column'
import { useAccountFeatures } from '../../auth'
import { useAccountSettings } from '../account-settings/hooks'
import { type FlatContractedRate } from './types'
import { rowHasError, validateRate } from './utils'

/**
 * Get the rate type based on the origin and destination data
 */
const getRateType = (rowData: FlatContractedRate): string | null => {
  if (rowData.originPostalCode && rowData.destinationPostalCodeEnd) {
    return 'P2R'
  }

  if (rowData.originPostalCode && rowData.destinationState) {
    return 'P2S'
  }

  if (rowData.originState && rowData.destinationState) {
    return 'S2S'
  }

  if (rowData.originPostalCode && rowData.destinationPostalCode) {
    return 'P2P'
  }

  return null
}

const DataGrid = lazy(() =>
  import('../../../components/DataGrid').then(d => ({ default: d.DataGrid })),
)

type Props = {
  value: FlatContractedRate[]
  onChange: (value: FlatContractedRate[]) => void
}

export const ContractedRatesBulkEditor = ({ value, onChange }: Props) => {
  const { fuelAndDistance } = useAccountFeatures()
  const [activeCell, setActiveCell] = useState<CellWithId | null>(null)
  const settings = useAccountSettings()
  const ref = useRef<HTMLDivElement>(null)
  const { height } = useResizeObserver({
    ref,
    box: 'border-box',
  })

  useEffect(() => {
    const rowErrors = validateRate(
      value[activeCell?.row],
      fuelAndDistance,
      false,
    )
    const activeError =
      rowErrors.filter(r => r.propertyName === activeCell?.colId)[0] ||
      rowErrors[0]

    if (activeError) {
      toast.error(
        `${camelCaseToTitleCase(activeError.propertyName)}: ${activeError.message}`,
        {
          duration: Infinity,
          id: 'contracted-rates-bulk-editor-error',
          position: 'top-center',
        },
      )
    } else {
      toast.dismiss('contracted-rates-bulk-editor-error')
    }
  }, [activeCell, fuelAndDistance, value])

  return (
    <div ref={ref} className="h-full">
      <DataGrid
        height={height}
        headerRowHeight={75}
        onChange={onChange}
        value={value}
        addRowsComponent={false}
        className="contracted-rates-upload [&>.dsg-container]:!h-full"
        onActiveCellChange={({ cell }) => {
          setActiveCell(cell)
        }}
        rowClassName={({ rowData }) => {
          return rowHasError(
            rowData,
            fuelAndDistance,
            settings.truckload?.enableMexico,
          )
            ? 'row-error'
            : undefined
        }}
        cellClassName={({ rowData, columnId }) => {
          const col = columnId as keyof FlatContractedRate
          const errors = validateRate(
            rowData as FlatContractedRate,
            fuelAndDistance,
            settings.truckload?.enableMexico,
          )
          const invalid = errors.some(
            e => e.propertyName.split('.')?.[0] === col,
          )

          return invalid ? 'cell-error' : undefined
        }}
        columns={[
          {
            disabled: true,
            component: ({ rowData }) => {
              const rateType = getRateType(rowData)
              return <div className="pl-2">{rateType}</div>
            },
            minWidth: 75,
          },
          {
            ...keyColumn('originPostalCode', textColumn),
            title: 'Origin Postal Code',
          },
          {
            ...keyColumn('originState', statesSelectColumn()),
            title: 'Origin State',
          },
          {
            ...keyColumn('originCountry', textColumn),
            title: 'Origin Country',
          },
          {
            ...keyColumn('destinationPostalCode', textColumn),
            title: 'Destination Postal Code',
            minWidth: 110,
          },
          {
            ...keyColumn('destinationPostalCodeEnd', textColumn),
            title: 'Destination Postal Code End',
            minWidth: 110,
          },

          {
            ...keyColumn('destinationState', statesSelectColumn()),
            title: 'Destination State',
            minWidth: 110,
          },
          {
            ...keyColumn(
              'destinationCountry',
              selectColumn({
                choices: [
                  { label: 'US', value: 'us' },
                  { label: 'CA', value: 'ca' },
                ],
              }),
            ),
            title: 'Destination Country',
            minWidth: 110,
          },
          {
            ...keyColumn('rateAmount', floatColumn),
            title: 'Rate Amount',
            minWidth: 125,
          },
          {
            ...keyColumn(
              'rateCurrency',
              selectColumn({
                choices: [{ label: 'USD', value: 'usd' }],
              }),
            ),
            title: 'Rate Currency',
          },
          {
            ...keyColumn(
              'rateType',
              selectColumn({
                choices: [
                  { label: 'Per Mile', value: 'per-mile' },
                  { label: 'Flat', value: 'flat' },
                ],
              }),
            ),
            title: 'Rate Type',
            minWidth: 125,
          },
          {
            ...keyColumn('minimumRate', floatColumn),
            title: 'Minimum Rate',
          },
          {
            ...keyColumn('distance', intColumn),
            title: 'Distance',
          },
          {
            ...keyColumn(
              'distanceUOM',
              selectColumn({
                choices: [{ label: 'mi', value: 'mi' }],
              }),
            ),
            title: 'Distance Unit',
          },
          {
            ...keyColumn('fuelIncluded', checkboxColumn),
            title: 'Fuel Included',
          },
          {
            ...keyColumn(
              'serviceId',
              selectColumn({
                choices: [{ label: 'truckload', value: 'truckload' }],
              }),
            ),
            title: 'Service ID',
            minWidth: 150,
          },
          {
            ...keyColumn('startDate', dateColumn),
            title: 'Contract Start Date',
          },
          {
            ...keyColumn('endDate', dateColumn),
            title: 'Contract End Date',
          },
          {
            ...keyColumn('contractNumber', textColumn),
            title: 'Contract Number',
          },
          {
            ...keyColumn('equipmentTypes', {
              ...multiSelectColumn({
                choices: equipmentTypes
                  .filter(e => e.workflows.includes('truckload'))
                  .map(e => ({
                    label: e.name,
                    value: e.key,
                  })),
              }),
            }),
            title: 'Equipment Types',
            minWidth: 500,
          },
        ]}
      />
    </div>
  )
}

const statesSelectColumn = () => {
  return selectColumn({
    choices: states.map(s => ({
      label: s.abbreviation,
      value: s.abbreviation,
    })),
  })
}
