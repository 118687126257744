import dayjs from 'dayjs'
import xor from 'lodash/xor'
import xorBy from 'lodash/xorBy'
import { shallow } from 'zustand/shallow'

import { FvLinkButton } from '@fv/client-components'
import { type FullShipment, type QuoteStopType } from '@fv/client-types'
import { type EquipmentAccessorialType } from '@fv/models'

import InfoBox from '../../components/InfoBox'
import {
  handlingUnitWeight,
  toLoadHandlingUnitFormModel,
} from '../commodities/load-items/loadItemHelpers'
import { type LoadHandlingUnitFormModel } from '../commodities/load-items/types'
import { quoteFuncs } from '../quote/quoteFuncs'
import { type ShipmentLocationFormModel } from '../shipment-location-form/types'
import { requireRateLocation } from './bookFuncs'
import { useBookingFormCtx, useBookingFormLoad } from './BookingFormProvider'

type Props = {
  onUndo: () => void
}
export const RateChangeNotice = ({ onUndo }: Props) => {
  const state = useBookingFormCtx(
    s => ({
      equipmentAccessorials: s.equipmentAccessorials,
      isEdit: s.isEdit,
      items: s.items,
      locations: s.locations,
      rateLocationType: s.rateLocationType,
      undoChanges: s.actions.undoChanges,
    }),
    shallow,
  )
  const load = useBookingFormLoad()
  const quote = useBookingFormCtx(s => s.quote)
  if (!load || !quote) return null

  const needsRerate =
    quote.source !== 'shipper' &&
    requireRerate(load, {
      equipmentAccessorials: state.equipmentAccessorials,
      items: state.items,
      locations: state.locations,
      rateLocationType: state.rateLocationType,
    })

  if (!needsRerate) return null

  return (
    <InfoBox className="mb-0 mt-8">
      You changed some of the information used to get rates. This may affect the
      price of the shipment.{' '}
      <FvLinkButton theme="underlined" onClick={onUndo}>
        Undo changes
      </FvLinkButton>
    </InfoBox>
  )
}

function requireRerate(
  originalLoad: FullShipment,
  edits: {
    equipmentAccessorials: EquipmentAccessorialType[]
    items: LoadHandlingUnitFormModel[]
    locations: ShipmentLocationFormModel[]
    rateLocationType: QuoteStopType
  },
) {
  const {
    direction,
    equipment,
    isFreightCollect,
    items,
    locations,
    selectedQuote,
    workflow,
  } = originalLoad

  // // Never rerate customer-routed, spot-quote or manual rates
  if (isFreightCollect) return false
  if (quoteFuncs.isSpotWorkflow(selectedQuote)) return false
  if (workflow === 'truckload') return false
  if (selectedQuote?.method === 'manual') return false

  // Pickup date changed or expired rate
  const prevPickup = locations[0].stopDate
  const nextPickup = edits.locations[0].stopDate
  if (!dayjs(prevPickup).isSame(nextPickup, 'day')) {
    if (
      !selectedQuote?.expirationDate ||
      dayjs(nextPickup).isAfter(selectedQuote.expirationDate, 'day')
    ) {
      return true
    }
  }

  // Direction change
  if (requireRateLocation(edits.locations, !!isFreightCollect)) {
    if (edits.rateLocationType === 'origin' && direction !== 'outbound') {
      return true
    }

    if (edits.rateLocationType === 'destination' && direction !== 'inbound') {
      return true
    }
  }

  // Changes to postal code, ship type, location accessorials
  for (const loc of locations) {
    const nextLoc = edits.locations.find(x => x.sequence === loc.sequence)

    if (
      nextLoc?.postalCode !== loc.postalCode ||
      nextLoc.shipType !== (loc.shipType ?? '') ||
      xorBy(nextLoc.accessorials, loc.accessorials, 'key').length
    ) {
      return true
    }
  }

  // Adding or removing items
  if (items.length !== edits.items.length) return true
  for (const item of items) {
    const nextItem = edits.items.find(i => i._id === item._id)
    if (!nextItem) return true
    const prevModel = toLoadHandlingUnitFormModel(item)
    const prevWeight = handlingUnitWeight(prevModel)
    const nextWeight = handlingUnitWeight(nextItem)

    if (
      prevWeight !== nextWeight ||
      nextItem.height !== item.height ||
      nextItem.length !== item.length ||
      nextItem.width !== item.width ||
      nextItem.freightClass !== item.freightClass ||
      nextItem.type !== item.type ||
      nextItem.hazardous !== item.hazardous ||
      nextItem.fullNmfc !== prevModel.fullNmfc ||
      nextItem.declaredValue !== item.declaredValue //||
      // nextItem.legacyContains?.quantity !== prevModel.legacyContains?.quantity
    ) {
      return true
    }
  }

  // Adding or removing equipment accessorials
  const originalAccessorials = (equipment.accessorials ?? []).map(a => a.key)
  if (xor(originalAccessorials, edits.equipmentAccessorials).length) {
    return true
  }

  return false
}
