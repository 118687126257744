import { type ParsedQuery } from 'query-string'
import { useRef, useState } from 'react'

import {
  FvButton,
  type IconProps,
  LoadMore,
  Tooltip,
} from '@fv/client-components'
import { type ListShipment } from '@fv/client-types'

import ExportOptions from '../../components/ExportOptions'
import InfoBox from '../../components/InfoBox'
import QuotedByFilter from '../../components/inputs/QuotedByFilter'
import LoadDetailPanel from '../../components/list/LoadDetailPanel'
import { supportMessage } from '../../constants'
import { HideForVendor } from '../auth/HasRole'
import { useRole } from '../auth/hooks'
import { CompactFilterBar } from '../shipment-filters/CompactFilterBar'
import { ShipmentFilterPanel } from '../shipment-filters/FilterPanel'
import { useHandleFilterChange } from '../shipment-filters/hooks'
import { InProcessFiltersProvider } from '../shipment-filters/InProcessFiltersProvider'
import { ShipmentUploadPanel } from '../shipments-upload/ShipmentUploadPanel'
import { useInProcessShipments } from './hooks/useInProcessShipments'
import {
  type SortBy,
  useInProcessListParams,
  useSetActiveLoadId,
} from './hooks/useShipmentListParams'
import { ShipmentCard } from './ShipmentCard'
import SortButton from './SortButton'
import { SortDirectionToggle } from './SortDirectionToggle'

export const InProcessShipmentList = () => {
  const role = useRole()
  const sortOptions = [
    {
      icon: 'calendar-day' as IconProps['icon'],
      label: 'Pickup date',
      value: 'pickupDate' as SortBy,
    },
    ...(role !== 'vendor'
      ? [
          {
            icon: 'clock' as IconProps['icon'],
            label: 'Messages',
            value: 'messageDate' as SortBy,
          },
        ]
      : []),
  ]
  const listRef = useRef<HTMLDivElement>(null)
  const shipmentsQuery = useInProcessShipments()
  const [uploadActive, setUploadActive] = useState(false)
  const shipmentList: ListShipment[] = shipmentsQuery.data
  const { activeLoadId, quotedById, sortBy, sortDirection, onChange } =
    useInProcessListParams(shipmentList)
  const setActiveLoad = useSetActiveLoadId()
  const [isExporting, setIsExporting] = useState(false)
  const { handle: handleChange, searchParams } = useHandleFilterChange(listRef)

  const isLoading =
    shipmentsQuery.isLoading ||
    (shipmentsQuery.isFetching && !shipmentList.length)

  const handleFilterChange = (data: ParsedQuery) => {
    handleChange({
      ...data,
      quotedBy: [quotedById],
      sortBy,
      sortDirection,
    })
  }

  return (
    <InProcessFiltersProvider>
      <ShipmentFilterPanel onChange={handleFilterChange} />

      <div
        className="shipment-list-in-process b1400:col-span-2"
        id="shipmentList"
        ref={listRef}
      >
        <nav className="mb-6">
          <div className="flex flex-wrap items-center">
            <HideForVendor>
              <QuotedByFilter
                className="b1600:max-w-none b1600:w-full b1600:flex-none b1600:mb-4 b1400:mb-0 b1400:max-w-auto b1400:w-auto b1300:max-w-none b1350:mb-4 b1350:w-full"
                quotedById={quotedById}
                onChange={quotedById =>
                  onChange({ quotedBy: !quotedById ? null : [quotedById] })
                }
              />
            </HideForVendor>

            <span>
              <SortDirectionToggle sortDirection={sortDirection} />{' '}
              <span className="b1100:hidden">Sort:</span>
            </span>

            <div className="divided-content divided-content--start ml-4">
              {sortOptions.map(o => (
                <SortButton currentSortBy={sortBy} key={o.value} {...o} />
              ))}

              <HideForVendor>
                <FvButton
                  icon="file-export"
                  onClick={() => setIsExporting(true)}
                >
                  Export
                </FvButton>
              </HideForVendor>
              <div className="b1450:hidden b1350:inline b1150:hidden">
                <Tooltip label="Upload">
                  <FvButton icon="upload" onClick={() => setUploadActive(true)}>
                    Upload
                  </FvButton>
                </Tooltip>
              </div>
              <div className="hidden b1450:inline b1350:hidden b1150:inline">
                <Tooltip label="Upload">
                  <FvButton
                    icon="upload"
                    onClick={() => setUploadActive(true)}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="b1400:block border-t-fv-gray mt-4 hidden basis-full border-t border-dashed pt-4">
            <CompactFilterBar onChange={handleFilterChange} />
          </div>
        </nav>

        {shipmentsQuery.isError && (
          <InfoBox icon="triangle">
            Unable to load shipments, {supportMessage}
          </InfoBox>
        )}

        {isLoading ? (
          <InfoBox icon="spinner">Loading...</InfoBox>
        ) : (
          <>
            {shipmentList.map(load => (
              <ShipmentCard
                isActive={load.loadId === activeLoadId}
                key={load.loadId}
                onClick={() => setActiveLoad(load.loadId)}
                shipment={load}
              />
            ))}

            {!shipmentList.length && <InfoBox>No shipments available.</InfoBox>}
          </>
        )}

        <LoadMore
          className="mt-4"
          fetchNextPage={shipmentsQuery.fetchNextPage}
          hasNextPage={shipmentsQuery.hasNextPage}
          isLoading={shipmentsQuery.isFetchingNextPage}
        />
      </div>

      <LoadDetailPanel key={activeLoadId} loadId={activeLoadId} />
      <ShipmentUploadPanel
        isOpen={uploadActive}
        closePanel={() => setUploadActive(false)}
      />
      <ExportOptions
        closePanel={() => setIsExporting(false)}
        isOpen={isExporting}
        queryString={searchParams.toString()}
        listType={'in-process'}
      />
    </InProcessFiltersProvider>
  )
}
