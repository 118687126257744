import { type Quote, type UIQuote } from '@fv/client-types'
import { type Workflow } from '@fv/models'

import { quoteFuncs } from '../features/quote/quoteFuncs'
import { useUserSettings } from './settings'

export const useRateModifier = () => {
  const userSettings = useUserSettings()

  return (quote: UIQuote | Quote, workflow: Workflow) =>
    quoteFuncs.applyMarkup(quote, workflow, userSettings.data?.rateModifier)
}
