import {
  composeQuoteCarrier,
  FvButton,
  FvNum,
  SliderPanel,
  type SliderPanelProps,
  useFvNavigate,
} from '@fv/client-components'
import { type ShipmentLocation, type UIQuote } from '@fv/client-types'

import { Loading } from '../../components/Loading'
import { useLoad } from '../../hooks/shipments'
import { useCarrierPublicSettings } from '../../hooks/useCarrierPublicSettings'
import { routes } from '../../routes'
import { HasPermission } from '../auth'
import { useVendorBookRate } from '../quote/useBookRate'
import { useCanSchedulePickup } from './useCanSchedulePickup'

type Props = SliderPanelProps & {
  loadId: string
  quote: UIQuote
}
export const VendorBookPanel = ({ loadId, quote, ...props }: Props) => {
  const navigate = useFvNavigate()
  const loadQuery = useLoad(loadId)
  const load = loadQuery.data
  const carrierSettingsQuery = useCarrierPublicSettings(quote.carrierId)
  const canSchedulePickup = useCanSchedulePickup({
    isFreightCollect: load.isFreightCollect,
    workflow: load.workflow,
    locations: load.locations?.map(({ type, stopDate }) => ({
      type,
      stopDate: stopDate ? new Date(stopDate) : null,
    })),
    items: load.items,
    customerRoutedCarrierId: quote.carrierId,
    selectedQuote: quote,
  })
  const carrierSettings = carrierSettingsQuery.carrierPublicSettings
  const requireOriginEmail = carrierSettings?.requiresOriginEmail
  const vendorBookRate = useVendorBookRate()

  const handleSubmit = (schedulePickup: boolean) => async () => {
    const origin = load.locations.find(l => l.type === 'origin')
    const needsOriginEmail = !origin.contactEmail && requireOriginEmail
    const needsContactPhone = load.locations.some(
      l => loadLocationContactPhoneRequired(l) && !l.contactPhone,
    )
    if (needsOriginEmail || needsContactPhone) {
      navigate(routes.vendorBook(loadId, quote._id))
      return
    }

    await vendorBookRate.mutateAsync({
      loadId,
      quoteId: quote._id,
      schedulePickup,
    })
    navigate(routes.details(loadId))
  }

  if (!load || carrierSettingsQuery.isFetching) {
    return (
      <SliderPanel isOpen={props.isOpen}>
        <Loading />
      </SliderPanel>
    )
  }

  return (
    <SliderPanel {...props}>
      <p>
        You're about to book this shipment with{' '}
        <strong>{composeQuoteCarrier({ quote })}</strong> with their{' '}
        <strong>{quote.serviceDescription}</strong> service
        <HasPermission name="view-rates">
          {' '}
          for{' '}
          <strong>
            <FvNum val={quote.amount} currency />
          </strong>
        </HasPermission>
        . Are you sure you want to continue?
      </p>
      <div className="flex items-center">
        {carrierSettings.allowBolOnly && (
          <>
            <FvButton
              theme="default"
              icon="file-alt"
              onClick={handleSubmit(false)}
              loading={vendorBookRate.isLoading}
            >
              Print BOL
            </FvButton>
            - or -{' '}
          </>
        )}
        {canSchedulePickup && (
          <FvButton
            theme="primary"
            icon="truck"
            onClick={handleSubmit(true)}
            loading={vendorBookRate.isLoading}
          >
            Schedule Pickup & Print BOL
          </FvButton>
        )}
      </div>
    </SliderPanel>
  )
}

const loadLocationContactPhoneRequired = (location: ShipmentLocation) => {
  return (
    location.type === 'origin' ||
    location.shipType === 'residential' ||
    location.accessorials.some(a => a.key === 'notify') ||
    location.accessorials.some(a => a.key === 'schedule')
  )
}
