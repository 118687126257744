import clsx from 'clsx'
import dayjs from 'dayjs'
import { useState } from 'react'

import {
  CheckboxField,
  FvDate,
  FvNum,
  Icon,
  SliderPanel,
  Tooltip,
} from '@fv/client-components'
import { type ContractedRate, displayContractedRateLoc } from '@fv/models'

import { ActionList, ActionListItem } from '../../../components/ActionList'
import { List, ListItem } from '../../../components/List'
import { useTypeNames } from '../../../hooks/settings'
import { ContractedRateForm } from './ContractedRateForm'
import {
  xOverflowShadow,
  xOverflowShadowLeft,
  xOverflowShadowRight,
} from './utils'

type Props = {
  rate: ContractedRate
  style: React.CSSProperties
  checked: boolean
  onChange: (checked: boolean) => void
}

export const RateRow = ({ rate, style, checked, onChange }: Props) => {
  const { equipmentName } = useTypeNames()
  const [isOpen, setIsOpen] = useState(false)

  const errors = [
    ...(rate.errors?.map(e => e.message) ?? []),
    dayjs.utc().endOf('day').isAfter(rate.endDate)
      ? 'This rate has expired'
      : '',
  ].filter(Boolean)

  return (
    <tr style={style}>
      <td className="!p-0 sticky left-0 !bg-fv-beer-xlight !border-fv-beer-medium w-12">
        <div className="w-12 flex justify-center">
          {errors?.length ? (
            <Tooltip
              label={
                <List>
                  {errors?.map((e, i) => <ListItem key={i}>{e}</ListItem>)}
                </List>
              }
            >
              <Icon
                icon="exclamation-triangle"
                className="text-fv-orange-bright"
              />
            </Tooltip>
          ) : (
            <Icon icon="check-circle" className="text-fv-green" />
          )}
        </div>
      </td>
      <td className="!p-0 sticky left-[calc(3rem+2px)] !bg-fv-beer-xlight !border-fv-beer-medium w-12">
        <div className="w-12 flex justify-center">
          <CheckboxField
            name={`${rate._id}_checkbox`}
            checked={checked}
            onChange={() => onChange(!checked)}
          />
        </div>
      </td>
      <td
        className={clsx(
          'sticky left-[calc(6rem+4px)] !bg-fv-beer-xlight !border-fv-beer-medium max-w-52',
          xOverflowShadow,
          xOverflowShadowLeft,
        )}
      >
        <div className="truncate">{rate.carrierName}</div>
      </td>
      <td>{rate.contractNumber}</td>
      <td>{displayContractedRateLoc(rate.origin)}</td>
      <td>{displayContractedRateLoc(rate.destination)}</td>
      <td className="font-oxygen text-right">
        <FvNum currency val={rate.rate.amount} includeEmptyDecimals />
      </td>
      <td>{rate.rate.rateType}</td>
      <td>{rate.fuelIncluded ? 'Yes' : 'No'}</td>
      <td>{rate.equipmentTypes.map(equipmentName).join(' / ')}</td>
      <td>
        <FvDate val={rate.startDate} format="MM/DD/YYYY" />
      </td>
      <td>
        <FvDate val={rate.endDate} format="MM/DD/YYYY" />
      </td>
      <td
        className={clsx(
          'sticky right-0 !bg-fv-beer-xlight !border-fv-beer-medium !px-0 text-center',
          xOverflowShadow,
          xOverflowShadowRight,
        )}
      >
        <ActionList className="flex justify-center">
          <ActionListItem icon="pen-alt" onClick={() => setIsOpen(true)} />
        </ActionList>
      </td>

      <SliderPanel
        width="64rem"
        isOpen={isOpen}
        closePanel={() => setIsOpen(false)}
      >
        <ContractedRateForm
          onCancel={() => setIsOpen(false)}
          onSubmit={() => {
            setIsOpen(false)
          }}
          rate={rate}
        />
      </SliderPanel>
    </tr>
  )
}
