import clsx from 'clsx'
import { type ChangeEvent, useState } from 'react'

import {
  CheckboxField,
  FvButton,
  FvLinkButton,
  Icon,
  type IconProps,
  Tooltip,
  ValidatedForm,
} from '@fv/client-components'
import {
  addressFuncs,
  defaultCloseTime,
  defaultOpenTime,
  validateEmailInput,
  validateEmailListInput,
} from '@fv/client-core'
import {
  type ClientAddressLookupResult,
  type LegacyShipType,
  legacyShipTypes,
} from '@fv/client-types'
import {
  type AddressLookupContext,
  type LocationAccessorialType,
  type Workflow,
} from '@fv/models'

import { AddressInput } from '../../components/inputs/AddressInput'
import { ContactPhoneInput } from '../../components/inputs/ContactPhoneInput'
import {
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import { hasInsideOption, hasLiftgateOption } from '../../utils/locationFuncs'
import { usePreferredWorkflows } from '../profiile/hooks'
import { DockTimeInput } from '../shipment-location-form/DockTimeInput'
import { type FormAddress } from './types'
import { useAddressBook } from './useAddressBook'
import { useCacheFormattedAddress } from './useAddressSearch'
import { useCreateAddress } from './useCreateAddress'
import { useDeleteAddress } from './useDeleteAddress'
import { useUpdateAddress } from './useUpdateAddress'

const shipTypeOptions = [...legacyShipTypes]

const workflowDisplays: Record<
  Workflow,
  {
    icon: IconProps['icon']
    label: string
  }
> = {
  parcel: { icon: 'box', label: 'Parcel' },
  ltl: { icon: 'forklift', label: 'LTL' },
  truckload: { icon: 'truck-moving', label: 'Truckload' },
}

type Props = {
  initialValues?: ClientAddressLookupResult
  context?: AddressLookupContext
}

export const AddressForm = ({ initialValues, context }: Props) => {
  const workflows = usePreferredWorkflows()
  const {
    cancel,
    deleteAddress,
    getCheckboxProps,
    getInputProps,
    handleChanges,
    isDeleting,
    isEditing,
    isLoading,
    onValidSubmit,
    setWorkflow,
    values,
    workflow,
  } = useAddressForm(initialValues, workflows[0])

  const canHaveInside = hasInsideOption(values.shipType)
  const canHaveLiftgate = hasLiftgateOption(values.shipType)

  const workflowEnabled = (w: Workflow) =>
    w === 'truckload' || values.country !== 'mx'

  return (
    <ValidatedForm
      className="flex h-full flex-col justify-between"
      onValidSubmit={onValidSubmit}
    >
      <div className="-mx-6 overflow-auto px-6 pb-8">
        <h6 className="mb-0">
          <Icon icon="building" className="text-fv-gray-dark" />
          {isEditing ? (
            <span>Edit address</span>
          ) : (
            <span>Add a new address</span>
          )}
        </h6>
        <hr className="mb-8 mt-2" />
        <div className="flex flex-wrap">
          <div className="form-row">
            <InputGroup
              className="basis-full"
              inputProps={getInputProps('company', {
                placeholder: 'Company name',
              })}
              inputType="text"
              label="Company"
              required
            />

            <InputGroup
              className="basis-1/3"
              inputProps={getInputProps('address', {
                placeholder: 'Street address',
                readOnly: values.isAccountLocation,
              })}
              inputType="text"
              label="Street address"
              required
            />

            <InputGroup
              className="basis-1/3"
              inputProps={getInputProps('address2', {
                readOnly: values.isAccountLocation,
              })}
              inputType="text"
              label="Address 2"
            />

            <InputGroupWrapper
              className="basis-1/3"
              label="City, state, zip"
              name="cityStateZip"
              required
            >
              <AddressInput
                context={context}
                formatAddress={addressFuncs.cityStateZip}
                isClearable={false}
                location={values}
                name="cityStateZip"
                onLocationChange={x => {
                  if (isLoading || isDeleting) return

                  if (x?.country === 'mx') {
                    setWorkflow('truckload')
                  }

                  handleChanges({
                    city: x?.city || '',
                    country: x?.country || 'us',
                    lat: x?.lat || 0,
                    lng: x?.lng || 0,
                    postalCode: x?.postalCode || '',
                    state: x?.state || '',
                    timezone: x?.timezone || '',
                    adminArea3: x?.adminArea3,
                  })
                }}
                placeholder="Zip/postal code"
                readOnly={values.isAccountLocation}
                required
              />
            </InputGroupWrapper>

            <InputGroup
              className="basis-1/3"
              inputProps={getInputProps('contactName')}
              inputType="text"
              label="Contact name"
            />

            <ContactPhoneInput
              className="basis-1/3"
              disabled={isLoading || isDeleting}
              name="contactPhone"
              onChange={handleChanges}
              values={{
                contactPhone: values.contactPhone,
                contactPhoneExt: values.contactPhoneExt,
              }}
            />

            <InputGroup
              className="basis-1/3"
              inputProps={getInputProps('contactEmail')}
              inputType="text"
              label="Contact email"
            />

            <InputGroup
              className="basis-1/2"
              inputProps={getInputProps('shipType', {
                options: shipTypeOptions,
              })}
              inputType="select"
              label="Location type"
              required
            />

            <DockTimeInput
              className="basis-1/4"
              disabled={isLoading || isDeleting}
              label="Open time"
              value={values}
              name="opensAt"
              onChange={handleChanges}
            />

            <DockTimeInput
              className="basis-1/4"
              disabled={isLoading || isDeleting}
              label="Close time"
              value={values}
              name="closesAt"
              onChange={handleChanges}
            />

            <InputGroup
              className="basis-full"
              inputProps={getInputProps('instructions')}
              inputType="text"
              label="Special instructions"
            />
            <InputGroup
              className="basis-full"
              inputProps={getInputProps('bolFollowers')}
              inputType="text"
              label="Share shipments with"
            />

            <CheckboxField
              className="form-group"
              {...getInputProps('sendBOL', {
                label: 'Send copy of bill of lading',
              })}
            />
            <hr className="basis-full" />
          </div>

          <div className="divided-content divided-content--start my-4 basis-full">
            <span>Above address options for</span>

            {workflows
              .map(w => ({
                key: w,
                display: workflowDisplays[w],
              }))
              .map(({ key, display }) => (
                <Tooltip
                  key={key}
                  label={
                    workflowEnabled(key)
                      ? null
                      : 'MX support currently not available'
                  }
                >
                  <FvLinkButton
                    className={clsx({
                      'active-hint-indicator': workflow === key,
                      'opacity-50': !workflowEnabled(key),
                    })}
                    icon={display.icon}
                    onClick={() => workflowEnabled(key) && setWorkflow(key)}
                  >
                    {display.label}
                  </FvLinkButton>
                </Tooltip>
              ))}
          </div>
          <hr className="basis-full" />

          {workflow === 'ltl' && (
            <>
              {(canHaveInside || canHaveLiftgate) && (
                <div className="mb-4 flex space-x-8">
                  {canHaveLiftgate && (
                    <CheckboxField
                      {...getCheckboxProps('liftgate', 'Liftgate required')}
                    />
                  )}

                  {canHaveInside && (
                    <CheckboxField
                      {...getCheckboxProps('inside', 'Inside pickup/delivery')}
                    />
                  )}
                </div>
              )}

              <div className="flex space-x-8">
                <CheckboxField
                  {...getCheckboxProps('notify', 'Call/notify before delivery')}
                />

                <CheckboxField
                  {...getCheckboxProps('schedule', 'Delivery appointment')}
                />

                <CheckboxField
                  {...getCheckboxProps(
                    'sort-and-segregate',
                    'Sort & segregate',
                  )}
                />
              </div>
            </>
          )}

          {workflow === 'parcel' && (
            <div className="flex space-x-8">
              <CheckboxField
                {...getCheckboxProps('no-signature', 'No signature')}
              />

              <CheckboxField
                {...getCheckboxProps('signature-required', 'Signature')}
              />

              <CheckboxField
                {...getCheckboxProps(
                  'adult-signature-required',
                  'Adult signature',
                )}
              />

              {values.shipType === 'residential' && (
                <CheckboxField
                  {...getCheckboxProps(
                    'indirect-signature-required',
                    'Indirect signature',
                  )}
                />
              )}
            </div>
          )}

          {workflow === 'truckload' && (
            <div className="flex space-x-8">
              <CheckboxField
                {...getCheckboxProps('schedule', 'Appointment required')}
              />

              <CheckboxField
                {...getCheckboxProps('driver-assist', 'Driver assist')}
              />

              <CheckboxField
                {...getCheckboxProps('notify', 'Arrival notification')}
              />
              <CheckboxField {...getCheckboxProps('twic', 'TWIC required')} />
            </div>
          )}
        </div>
      </div>

      <div className="flyout-footer-actions flex items-center">
        <FvButton
          icon={isLoading ? 'spinner' : isEditing ? 'pen-alt' : 'plus'}
          theme="primary"
          type="submit"
        >
          {isEditing ? 'Update' : 'Add'} address and apply
        </FvButton>

        {isEditing && !initialValues?.isAccountLocation && (
          <FvButton
            icon={isDeleting ? 'spinner' : 'trash'}
            onClick={deleteAddress}
            theme="default"
          >
            Delete address
          </FvButton>
        )}

        <FvButton icon="times" onClick={cancel} theme="plain">
          Cancel
        </FvButton>
      </div>
    </ValidatedForm>
  )
}

function buildFormAddress(v?: ClientAddressLookupResult): FormAddress {
  return {
    address: v?.address || '',
    address2: v?.address2 || '',
    bolFollowers: v?.bolFollowers?.join(', ') || '',
    city: v?.city || '',
    closesAt: v ? v.dockHoursEnd : defaultCloseTime,
    company: v?.company || '',
    contactEmail: v?.contactEmail || '',
    contactName: v?.contactName || '',
    contactPhone: v?.contactPhone || '',
    contactPhoneExt: v?.contactPhoneExt || '',
    country: v?.country || 'us',
    instructions: v?.instructions || '',
    isAccountLocation: !!v?.isAccountLocation,
    lat: v?.lat || 0,
    lng: v?.lng || 0,
    mappedAccessorials: v?.mappedAccessorials ?? {
      ltl: [],
      parcel: [],
      truckload: [],
    },
    postalCode: v?.postalCode || '',
    opensAt: v ? v.dockHoursStart : defaultOpenTime,
    sendBOL: !!v?.sendBOL,
    shipType: v?.shipType || 'business dock',
    state: v?.state || '',
    timezone: v?.timezone || '',
  }
}

function useAddressForm(
  initialValues?: ClientAddressLookupResult,
  initialWorkflow?: Workflow,
) {
  const addressId = initialValues?._id
  const cacheFormattedAddress = useCacheFormattedAddress()
  const selectAddress = useAddressBook(s => s.selectAddress)
  const addressBookEdit = useAddressBook(s => s.edit)
  const isEditing = !!addressId

  const createAddress = useCreateAddress()
  const updateAddress = useUpdateAddress()
  const removeAddress = useDeleteAddress()
  const isDeleting = removeAddress.isLoading
  const isLoading = createAddress.isLoading || updateAddress.isLoading
  const disabled = isDeleting || isLoading

  const [workflow, setWorkflow] = useState<Workflow>(initialWorkflow ?? 'ltl')
  const [values, setValues] = useState<FormAddress>(() =>
    buildFormAddress(initialValues),
  )

  function handleChanges(changes: Partial<FormAddress>) {
    setValues(prev => ({ ...prev, ...changes }))
  }

  function toggleAccessorial(
    workflow: Workflow,
    name: LocationAccessorialType | 'no-signature',
  ) {
    setValues(prev => {
      let nextAccessorials = prev.mappedAccessorials[workflow].slice()
      const hasAccessorial = nextAccessorials.some(a => a.key === name)

      // Can only select one signature option at a time
      if (name === 'no-signature' || name.includes('signature')) {
        nextAccessorials = nextAccessorials.filter(
          a => !a.key.includes('signature'),
        )

        if (!hasAccessorial && name !== 'no-signature') {
          nextAccessorials.push({ key: name })
        }
      } else if (
        // Cannot select `notify` and `schedule` together for LTL
        workflow === 'ltl' &&
        (name === 'notify' || name === 'schedule')
      ) {
        nextAccessorials = nextAccessorials.filter(
          a => a.key !== 'notify' && a.key !== 'schedule',
        )

        if (!hasAccessorial) nextAccessorials.push({ key: name })
      } else {
        nextAccessorials = hasAccessorial
          ? nextAccessorials.filter(a => a.key !== name)
          : nextAccessorials.concat({ key: name })
      }

      return {
        ...prev,
        mappedAccessorials: {
          ...prev.mappedAccessorials,
          [workflow]: nextAccessorials,
        },
      }
    })
  }

  function getCheckboxProps(
    name: LocationAccessorialType | 'no-signature',
    label: string,
  ) {
    const checked =
      name === 'no-signature'
        ? values.mappedAccessorials[workflow].every(
            a => !a.key.includes('signature'),
          )
        : values.mappedAccessorials[workflow].some(a => a.key === name)

    return {
      checked,
      disabled,
      label,
      name,
      onChange: () => toggleAccessorial(workflow, name),
    }
  }

  function getInputProps(
    name: keyof Omit<FormAddress, 'accessorials' | 'charges' | 'lat' | 'lng'>,
    props?: any,
  ) {
    return {
      disabled,
      name,
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (name === 'sendBOL') {
          return handleChanges({
            sendBOL: (e.target as HTMLInputElement).checked,
          })
        }

        if (name === 'bolFollowers') {
          validateEmailListInput(e.target as HTMLInputElement)
        }

        if (name === 'contactEmail') {
          validateEmailInput(e.target as HTMLInputElement)
        }

        if (name === 'shipType') {
          const shipType = e.target.value as LegacyShipType

          if (
            !hasInsideOption(shipType) &&
            values.mappedAccessorials.ltl.some(a => a.key === 'inside')
          ) {
            toggleAccessorial('ltl', 'inside')
          }

          if (
            !hasLiftgateOption(shipType) &&
            values.mappedAccessorials.ltl.some(a => a.key === 'liftgate')
          ) {
            toggleAccessorial('ltl', 'liftgate')
          }

          if (
            shipType !== 'residential' &&
            values.mappedAccessorials.parcel.some(
              a => a.key === 'indirect-signature-required',
            )
          ) {
            toggleAccessorial('parcel', 'indirect-signature-required')
          }
        }

        handleChanges({ [name]: e.target.value })
      },
      ...(name === 'sendBOL'
        ? {
            checked: values.sendBOL,
          }
        : {
            value: values[name],
          }),
      ...props,
    }
  }

  function deleteAddress() {
    if (isDeleting || isLoading || !addressId) return

    if (
      window.confirm(
        'Are you sure you want to delete this entry from your address book?',
      )
    ) {
      removeAddress.mutate(
        { id: addressId },
        {
          onSuccess: () => addressBookEdit(undefined),
        },
      )
    }
  }

  function onValidSubmit() {
    if (isLoading || isDeleting) return
    const opts = {
      onSuccess: (address: ClientAddressLookupResult) => {
        const formatted = addressFuncs.companyAddress(address)
        cacheFormattedAddress({ search: formatted }, address)
        selectAddress?.(address)
      },
    }

    if (isEditing) updateAddress.mutate({ ...values, id: addressId }, opts)
    else createAddress.mutate(values, opts)
  }

  return {
    cancel: () => !isDeleting && !isLoading && addressBookEdit(undefined),
    deleteAddress,
    getCheckboxProps,
    getInputProps,
    handleChanges,
    isDeleting,
    isEditing,
    isLoading,
    onValidSubmit,
    setWorkflow,
    values,
    workflow,
  }
}
