import { UserInfo } from './user-info'

const reasonTypes = ['urgent', 'serviceLevel', 'preference', 'other'] as const
export type ReasonType = (typeof reasonTypes)[number]

export interface QuoteSelectionReason {
  reasonType: ReasonType
  description?: string
  user: UserInfo
  lastUpdated: Date
}

export const quoteSelectionReasonLabels: Record<
  ReasonType,
  { label: string; desc?: string }
> = {
  other: { label: 'Other' },
  preference: {
    label: 'Customer preference',
    desc: 'The customer prefers this carrier and service level.',
  },
  serviceLevel: {
    label: 'Service requirements',
    desc: 'This is the least expensive provider that can supply all the required services.',
  },
  urgent: {
    label: 'Urgent shipment',
    desc: 'This shipment needs to be assigned to a provider ASAP',
  },
}
