import {
  type ChangeEventHandler,
  type Dispatch,
  type RefObject,
  type SetStateAction,
} from 'react'

import {
  type FormBillTo,
  type FullShipment,
  type LoadEquipment,
  type MakeOptional,
  type QuoteSelectionReason,
  type ShipmentStatus,
  type UIQuote,
} from '@fv/client-types'
import { type EquipmentType, type WeightUOM } from '@fv/models'
import { type DTO } from '@fv/models/core'

import { type FormCommodity } from '../commodities/FormCommodity'
import { type FormCustomsBroker } from '../customsBroker/brokerTypes'
import { type FormEmergencyContact } from '../emergencyContact/contactTypes'
import { type ShipmentLocationFormModel } from '../shipment-location-form/types'

export const billToKey = 'BILL_TO' as const
export const commoditiesKey = 'COMMODITIES' as const
export const emergencyContactKey = 'EMERGENCY_CONTACT' as const
export const loadKeyPrefix = 'LOAD' as const
export const locationKeyPrefix = 'LOCATION' as const
export const brokerKeyPrefix = 'CUSTOMS_BROKER' as const

type LoadKey = `LOAD_${string}`
type LocationKey = `LOCATION_${number}`
type BrokerKey = `${typeof brokerKeyPrefix}_${number}`

const formSectionKeys = [
  billToKey,
  commoditiesKey,
  emergencyContactKey,
] as const

export type FormSectionKey =
  | LoadKey
  | LocationKey
  | BrokerKey
  | (typeof formSectionKeys)[number]

export function composeLoadKey(id: string): LoadKey {
  return `${loadKeyPrefix}_${id}`
}

export function composeLocationKey(sequence: number): LocationKey {
  return `${locationKeyPrefix}_${sequence}`
}

export function composeBrokerKey(sequence: number): BrokerKey {
  return `${brokerKeyPrefix}_${sequence}`
}

export type BookingMode = 'finishBOL' | 'retender'
export type LoadToBook = FullShipment & { quoteId: string }
export type PendingLoadToBook = MakeOptional<LoadToBook, 'quoteId'>
export type ReferrerType = 'details' | 'listing'

export type CarrierBookSettings = {
  requiresDeclaredValue: boolean
  requiresFullBOLDetails: boolean
  allowTLPostalCodeChange: boolean
}

export type BookFormLoad = {
  equipment: LoadEquipment
  items: FormCommodity[]
  isMultiTruck?: boolean
  loadId: string
  quoteId: string
  quoteRequestId: string
  status: ShipmentStatus
  type: EquipmentType
  weight: number
  weightUOM: WeightUOM
}

export type FormSection = {
  isValid: boolean
  key: FormSectionKey
  ref: RefObject<HTMLFormElement | null> | null
}

export type BookingFormContext = {
  activateSection: (key: FormSectionKey) => void
  activeSection?: FormSectionKey
  billTo: FormBillTo
  bolNumber: string
  setBolNumber: Dispatch<SetStateAction<string>>
  bookShipment: () => void
  customsBrokers: FormCustomsBroker[]
  emergencyContact: FormEmergencyContact
  formSections: FormSection[]
  goToNextSection: () => void
  hasPickupOnlyPrompt: boolean
  isBooking: boolean
  isPickupOnly: boolean
  isRetender: boolean
  loads: BookFormLoad[]
  locations: ShipmentLocationFormModel[]
  onPickupOnlyChange: ChangeEventHandler<HTMLInputElement>
  pickup?: FullShipment['pickup']
  quoteSelectionReason?: DTO<QuoteSelectionReason>
  requireQuoteReason: boolean
  selectedQuotes: UIQuote[]
  setBillTo: Dispatch<SetStateAction<FormBillTo>>
  setCustomsBrokers: Dispatch<SetStateAction<FormCustomsBroker[]>>
  setEmergencyContact: Dispatch<SetStateAction<FormEmergencyContact>>
  setFormRef: (k: FormSectionKey, r: FormSection['ref']) => void
  setFormSections: Dispatch<SetStateAction<FormSection[]>>
  setLoads: Dispatch<SetStateAction<BookFormLoad[]>>
  setLocations: Dispatch<SetStateAction<ShipmentLocationFormModel[]>>
  settings: CarrierBookSettings
  tags?: string[]
  setTags: Dispatch<SetStateAction<string[]>>
  shareEmails: string
  setShareEmails: Dispatch<SetStateAction<string>>
}
