import clsx from 'clsx'
import { useState } from 'react'

import {
  FedEx,
  FvButton,
  FvLinkButton,
  ValidatedForm,
} from '@fv/client-components'
import { countries, marketingUri, useFormModel } from '@fv/client-core'

import InfoBox from '../../../components/InfoBox'
import { InputGroup } from '../../../components/inputs/InputGroup'
import { useAccountId } from '../../../hooks/settings/useAppContext'
import { FedexError } from './FedexError'
import {
  genCustomerName,
  useFxfeProvisionInitMutation,
  useFxfeSetStep,
  useFxfeSetToken,
  useFxfeWizardContext,
} from './hooks'
import {
  type FxfeMfaInitializationRequest,
  type FxfeProvisionResponse,
  isOptionsResponse,
} from './types'

export const AccountAddressStep = () => {
  const accountId = useAccountId()
  const setStep = useFxfeSetStep()
  const { setTokenFromDetails } = useFxfeSetToken()
  const {
    options: [, setOptions],
    accountInfo: [wizardAccountInfo, setWizardAccountInfo],
  } = useFxfeWizardContext()
  const [model, updateModel] = useState<FxfeMfaInitializationRequest>({
    ...wizardAccountInfo,
  })
  const { register } = useFormModel({ value: model, onChange: updateModel })
  const fxfeMutation = useFxfeProvisionInitMutation()
  const [error, setError] = useState<FxfeProvisionResponse>()

  const handleSubmit = async () => {
    model.customerName = genCustomerName(accountId)
    setWizardAccountInfo(model)
    const response = await fxfeMutation.mutateAsync({
      ...model,
    })
    if (isOptionsResponse(response)) {
      setTokenFromDetails(response.details)
      setOptions(response.options)
      setStep('mfa-option')
    } else if (response.status === 'ok') {
      setStep('success')
    } else if (response.status === 'error') {
      setError(response)
    }
  }

  return (
    <div>
      <InfoBox>
        Enter your <FedEx /> account details below. IMPORTANT: The address
        entered must match the address on your account <strong>exactly</strong>.{' '}
        <FvLinkButton
          href={`${marketingUri}/fedex-integration#address-validation`}
          target="_blank"
          theme="callout"
        >
          Learn more
        </FvLinkButton>
      </InfoBox>
      <FedexError response={error} />
      <ValidatedForm onValidSubmit={handleSubmit}>
        <div className={clsx('grid gap-x-2', {})}>
          <InputGroup
            className="basis-full"
            label="FedEx Express™ account number"
            inputType="text"
            inputProps={{
              ...register('accountNumber'),
              maxLength: 9,
              pattern: '\\d+',
            }}
          />
        </div>
        <div className="">
          <InputGroup
            className="basis-full"
            label="Address"
            inputType="text"
            inputProps={{ ...register('address') }}
          />
        </div>
        <div className="flex gap-x-2">
          <InputGroup
            label="City"
            className="basis-1/3"
            inputType="text"
            inputProps={{ ...register('city') }}
          />
          <InputGroup
            label="State"
            inputType="text"
            inputProps={{ ...register('state') }}
          />
          <InputGroup
            label="Postal code"
            inputType="text"
            className="basis-1/6"
            inputProps={{ ...register('postalCode') }}
          />
          <InputGroup
            label="Country"
            className="basis-1/6"
            inputType="select"
            inputProps={{ ...register('country'), options: countries }}
          />
        </div>
        <div className="flex justify-end">
          <FvButton theme="plain" onClick={() => setStep('terms')}>
            Back
          </FvButton>
          <FvButton
            theme="primary"
            type="submit"
            loading={fxfeMutation.isLoading}
          >
            Continue
          </FvButton>
        </div>
      </ValidatedForm>
    </div>
  )
}
