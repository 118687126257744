import { type PropsWithChildren } from 'react'

import logo from '@fv/client-assets/freightview.svg'

type Props = PropsWithChildren<{
  title?: string
  subtitle?: string
  fvBug?: boolean
}>
export const EntryOverlay = ({ children, title, subtitle, fvBug }: Props) => {
  return (
    <div className="App bg-fv-blue-25 pt-8 h-screen pb-12 overflow-auto flex flex-col">
      <div className="flex-1 flex justify-center items-center">
        <div className="max-w-[34rem]">
          <div className="flex items-start">
            {fvBug && <img src={logo} alt="Logo" className="mr-4 -mt-px" />}
            {title && <h1 className="text-lg col-span-2 mb-4">{title}</h1>}
          </div>
          {subtitle && (
            <p className="text-md mt-3 mb-6 col-span-2">{subtitle}</p>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}
