import partition from 'lodash/partition'
import {
  GenericIssue,
  GenericSchema,
  InferInput,
  InferOutput,
  safeParse,
  safeParseAsync,
} from 'valibot'

const buildErrorPath = (issue: GenericIssue<unknown>): string => {
  return issue.path?.map(p => p.key).join('.') ?? ''
}

export interface IValidationErrorEntry {
  propertyName: string
  message: string
  allowedValues?: string[]
  providedValue?: any
}

const errorsFromIssues = (
  output: unknown,
  issues?: GenericIssue[],
): IValidationErrorEntry[] => {
  if (!issues) {
    return []
  }

  return issues.flatMap<IValidationErrorEntry>(baseIssue => {
    const path = buildErrorPath(baseIssue)
    const [typeIssues, otherIssues] = partition(
      baseIssue.issues ?? [baseIssue],
      i => i.message.includes('Invalid type'),
    )

    return [
      ...(typeIssues.length
        ? [
            {
              propertyName: path,
              message: `Must be of type ${typeIssues.map(t => t.expected).join(' or ')}`,
              providedValue: typeIssues[0].input,
            },
          ]
        : []),
      ...otherIssues.map(i => ({
        propertyName: path,
        message: i.message,
        providedValue: i.input,
      })),
    ]
  })
}

export const validateSync = <
  TSchema extends GenericSchema,
  TData extends InferInput<TSchema>,
>(
  schema: TSchema,
  data: { [k in keyof TData]: unknown },
): { output: InferOutput<TSchema>; errors: IValidationErrorEntry[] } => {
  const { issues, success, output } = safeParse(schema, data, {
    abortEarly: false,
  })

  if (success) {
    return { output, errors: [] }
  }

  return {
    errors: errorsFromIssues(output, issues),
    output,
  }
}

export const validate = async <TSchema extends GenericSchema>(
  schema: TSchema,
  data: unknown,
): Promise<{
  output: InferOutput<TSchema>
  errors: IValidationErrorEntry[]
}> => {
  const { issues, success, output } = await safeParseAsync(schema, data, {
    abortEarly: false,
  })

  if (success) {
    return { output, errors: [] }
  }

  return {
    errors: errorsFromIssues(output, issues),
    output,
  }
}

export type vOutput<T extends GenericSchema> = InferOutput<T>
export type vInput<T extends GenericSchema> = InferInput<T>

export * from './schema/core'
export * from './schema/custom'
