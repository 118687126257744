import { ObjectId } from '@freightview/object-id'

import { useFormModel } from '@fv/client-core'

import { FreightItemForm } from './FreightItemForm'
import { useItemsState } from './loadItemsContext'
import { ParcelItemForm } from './ParcelItemForm'
import {
  type FormLayoutProps,
  type FormProps,
  LoadHandlingUnitSchema,
} from './types'

export const LoadItemForm = ({ value, onChange }: FormProps) => {
  const { disabled, workflow } = useItemsState()
  const { register } = useFormModel({
    value,
    onChange,
    disabled,
    key: value._id ?? new ObjectId().toHexString(), // TODO make Id required on model,
    schema: LoadHandlingUnitSchema,
  })
  const layoutProps: FormLayoutProps = {
    register,
    value,
    onChange,
  }

  return (
    <>
      {workflow !== 'parcel' && <FreightItemForm {...layoutProps} />}
      {workflow === 'parcel' && <ParcelItemForm {...layoutProps} />}
    </>
  )
}
