import { useMemo } from 'react'

import {
  type FullShipment,
  type ListShipment,
  type UIQuote,
} from '@fv/client-types'
import { type Currency, type EquipmentType } from '@fv/models'

import { useActiveResponses } from './useActiveResponses'
import { useInactiveResponses } from './useInactiveResponses'

export type QuoteRequestResponse = {
  amount?: number
  carrierId?: string
  currency?: Currency
  email?: string
  equipmentType?: EquipmentType
  errors?: UIQuote['errors']
  hasDeclined?: boolean
  hasResponded?: boolean
  id: string
  isProcessing?: boolean
  lastResponseDate?: string
  method?: UIQuote['method']
  name: string
  status?: UIQuote['status']
  userId?: string
  pricingMethod?: UIQuote['pricingMethod']
  pricingType?: UIQuote['pricingType']
  charges?: UIQuote['charges']
  serviceId?: UIQuote['serviceId']
  providerName?: string
  isSpot?: boolean
}

export type QuoteRequestResponses = {
  active: QuoteRequestResponse[]
  declined: QuoteRequestResponse[]
  pending: QuoteRequestResponse[]
}

export function useQuoteRequestResponses(opts: {
  filter?: 'date' | 'price'
  includePendingApiRates?: boolean
  load?: FullShipment | ListShipment
}): QuoteRequestResponses {
  const activeResponses = useActiveResponses(opts)
  const inactiveResponses = useInactiveResponses(opts.load?.loadId)

  return useMemo(
    () => ({
      active: activeResponses,
      declined: inactiveResponses.filter(r => r.hasDeclined),
      pending: inactiveResponses.filter(r => !r.hasResponded && !r.hasDeclined),
    }),
    [activeResponses, inactiveResponses],
  )
}
