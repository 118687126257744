import clsx from 'clsx'

import { FvButton, FvNum } from '@fv/client-components'
import { type ListShipment, type PropsWithClassName } from '@fv/client-types'
import { capitalizeFirstLetter } from '@fv/models/core'

import { quoteFuncs } from '../quote/quoteFuncs'
import { type ChargeListQuote, type ChargeListState } from './types'

type Props = PropsWithClassName<{
  load: ListShipment
  quote: ChargeListQuote
  isChecked?: boolean
  chargeState: ChargeListState
}>

export const ChargeList = ({ className, isChecked, chargeState }: Props) => {
  const { canEdit, quote } = chargeState

  return (
    <>
      <div
        className={clsx(
          'flex-1 relative divide-y divide-dotted divide-fv-beer-medium [&>div]:py-2 border-t bg-fv-beer-xlight py-2 border border-fv-beer-medium border-r-0 border-l-0 mt-4 mb-2 px-4 -mx-2 after:w-4 after:h-4 after:bg-fv-beer-xlight after:absolute after:border after:border-r-transparent after:border-b-transparent after:border-fv-beer-medium after:z-10 after:left-10 after:rotate-45 after:-top-[calc(.5rem+1px)]',
          className,
          isChecked && '!mb-[calc(-1rem-1px)]',
        )}
      >
        {quoteFuncs.sortCharges(quote.charges).map((charge, ix) => (
          <div className="flex items-center gap-x-2" key={ix}>
            <span>{capitalizeFirstLetter(charge.name)}</span>
            {charge.name === 'other' && !!charge.providerDetail && (
              <span>({charge.providerDetail})</span>
            )}
            <span className="border-t border-fv-gray border-dashed flex-1" />
            <span className="font-oxygen">
              <FvNum val={charge.amount} currency includeEmptyDecimals />
            </span>
          </div>
        ))}
        {canEdit && (
          <div
            className={clsx(
              { '!pt-5': quoteFuncs.sortCharges(quote.charges).length > 0 },
              'flex divide-x divide-fv-gray [&>div]:px-3',
            )}
          >
            <div className="first:pl-0">
              <FvButton
                theme="underlined"
                icon="pen-alt"
                onClick={chargeState.toggleEditing}
              >
                Edit charges
              </FvButton>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
