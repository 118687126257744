import uniq from 'lodash/uniq'

import { useFormModelState, validateEmailString } from '@fv/client-core'
import { type AccountUserProfileUpdateDto, type Workflow } from '@fv/models'

import { useAppContext } from '../../hooks/settings'
import { useCurrentUser } from '../../hooks/settings/useAppContext'
import { useAccountSettings } from '../settings/account-settings/hooks'

const defaultWorkflowSort: Workflow[] = ['ltl', 'truckload', 'parcel']

export const useWorkflows = () => {
  const { workflows } = useCurrentUser()
  return workflows
}
export function usePreferredWorkflows(): Workflow[] {
  const workflows = useWorkflows()
  const { quoting } = useAccountSettings()
  const first = quoting.defaultWorkflow ?? 'ltl'
  return uniq([first, ...defaultWorkflowSort.filter(f => f !== first)]).filter(
    pref => workflows.some(w => w === pref),
  )
}

export const useProfileInfoFormModel = () => {
  const contextQuery = useAppContext()
  const user = contextQuery.data.user
  return useFormModelState<AccountUserProfileUpdateDto>({
    initialValue: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      company: user?.company ?? '',
      title: user?.title ?? '',
      email: user?.email ?? '',
      phoneNumber: user?.phoneNumber?.replace(/\D/g, '') ?? '',
    },
    schema: {
      email: {
        validate: e => (validateEmailString(e) ? '' : 'Must be a valid email'),
      },
    },
  })
}
