import {
  type QueryObserverOptions,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { type UIQuote } from '@fv/client-types'

import { shipperFetch } from '../../utils/shipperFetch'
import { useLoad } from './useLoad'

export const quoteKeys = {
  all: ['quotes'] as const,
  load: (id?: string, version?: number) =>
    [...quoteKeys.all, id ?? null, version ?? null] as const,
  rerated: (loadId?: string, version?: number) =>
    [
      'rerated-quote',
      ...(loadId ? [loadId] : []),
      ...(version ? [version] : []),
    ] as const,
}

async function fetchQuotes(loadId?: string): Promise<UIQuote[]> {
  if (!loadId) throw new Error('`loadId` required to fetch quotes')
  return shipperFetch<UIQuote[]>(`/shipments/${loadId}/quotes`)
}

type Opts = {
  interval?: QueryObserverOptions<UIQuote[]>['refetchInterval']
  enabled?: boolean
}
export function useQuotes(loadId?: string, opts?: Opts) {
  const client = useQueryClient()
  const load = useLoad(loadId)
  const { interval, enabled } = opts ?? { enabled: true }
  const key = quoteKeys.load(loadId, load.data?.currentQuoteVersion)
  const query = useQuery<UIQuote[]>(key, () => fetchQuotes(loadId), {
    enabled: Boolean(loadId) && enabled,
    refetchInterval: interval,
  })
  const invalidate = () => client.invalidateQueries(key)
  return {
    ...query,
    invalidate,
  }
}

// Used to fetch quotes for multiple loads at once
export function useQuoteRequestQuotes(loadIds: string[]) {
  const data: { [key: string]: UIQuote[] } = {}
  const uniqueLoadIds = Array.from(new Set(loadIds))

  const quoteQueries = useQueries({
    queries: uniqueLoadIds.map(id => ({
      enabled: Boolean(id),
      queryFn: () => fetchQuotes(id),
      queryKey: quoteKeys.load(id),
    })),
  })

  uniqueLoadIds.forEach((id, index) => {
    data[id] = quoteQueries[index].data ?? []
  })

  return {
    data,
    isLoading: quoteQueries.some(q => q.isLoading && q.isFetching),
  }
}
