import { FvButton } from '@fv/client-components'
import {
  type ChargeModel,
  chargeOptions,
  currencyToString,
  parseCurrency,
} from '@fv/client-core'
import { type ChargeName } from '@fv/models'

import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import { SelectField } from '../../components/inputs/SelectField'

type Props = {
  charge: ChargeModel
  onChange: (charge: ChargeModel) => void
  onRemove: () => void
}

export const ChargeField = ({ charge, onChange, onRemove }: Props) => {
  const handleEdit = <T extends keyof ChargeModel>(
    key: T,
    value: ChargeModel[T],
  ) => {
    onChange({ ...charge, [key]: value })
  }

  return (
    <div className="flex items-center gap-3">
      <SelectField
        className="w-auto"
        name="charge-name"
        onChange={e => handleEdit('name', e.target.value as ChargeName)}
        options={chargeOptions}
        value={charge.name}
        required
      />

      {charge.name === 'other' && (
        <InputGroup
          className="mb-0"
          inputProps={{
            name: 'charge-description',
            value: charge.providerDetail || '',
            onChange: e => handleEdit('providerDetail', e.target.value),
            placeholder: 'Enter charge description',
          }}
          inputType="text"
          required
        />
      )}
      <div className="flex-1 h-px border-t border-dotted w-full border-fv-gray" />
      <FvButton
        icon="trash"
        iconClass="text-fv-orange-bright"
        onClick={onRemove}
      />

      <InputGroup
        className="mb-0 w-32"
        inputProps={{
          name: 'charge-amount',
          isPrice: true,
          value: charge.amount ? currencyToString(charge.amount) : '',
          className: 'text-right',
          onChange: e => {
            const amount = parseCurrency(e.target.value)
            handleEdit('amount', isNaN(amount) ? 0 : amount)
          },
          min: 1,
        }}
        inputType="amount"
        required
        startContent={<InputAdornment icon="dollar-sign" position="start" />}
      />
    </div>
  )
}
