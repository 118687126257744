import upperFirst from 'lodash/upperFirst'
import { useCallback } from 'react'

import { FvButton, Icon } from '@fv/client-components'
import { type UIQuote } from '@fv/client-types'

import { useLoad } from '../../hooks/shipments'
import { usePermissions } from '../auth'
import { useAwardLoads } from '../booking/mutations'
import { useAcceptRerate, useReratedQuote } from '../quote/useReratedQuote'
import { useRevertRerate } from '../quote/useResetRerate'

type PriceDisplayProps = {
  quote?: Pick<UIQuote, 'amount'> | null
  status: 'new' | 'old'
}

const PriceDisplay = ({ quote, status }: PriceDisplayProps) => {
  if (!quote) return null

  return (
    <div>
      <p className="mb-2 text-xl">{upperFirst(status)} price</p>
      <hr className="mt-0" />
      <span className="text-8xl">${quote.amount.toFixed(2)}</span>
    </div>
  )
}

type ReratingOverlayProps = {
  loadId: string
  onAcceptRerate: (quoteId: string) => void
  onDeclineRerate: () => void
}

export const ReratingOverlay = ({
  loadId,
  onAcceptRerate,
  onDeclineRerate,
}: ReratingOverlayProps) => {
  const awardLoads = useAwardLoads()
  const acceptRerate = useAcceptRerate()
  const revertRerate = useRevertRerate()
  const { canViewRates } = usePermissions()
  const { data: load } = useLoad(loadId, { refetchWhileQuoting: true })

  const originalQuote = load?.selectedQuote
  const newQuoteQuery = useReratedQuote(
    load,
    load?.quoteProcessStatus === 'rerate-ready',
  )

  const handleContinueClick = useCallback(async () => {
    if (load?.loadId) {
      const updatedLoad = await acceptRerate.mutateAsync({
        loadId: load?.loadId,
      })
      updatedLoad.selectedQuote &&
        onAcceptRerate(updatedLoad.selectedQuote?._id)
      return
    }
  }, [acceptRerate, load?.loadId, onAcceptRerate])

  if (
    !load ||
    newQuoteQuery.isFetching ||
    load.quoteProcessStatus === 'rerating' ||
    !canViewRates
  ) {
    return (
      <div>
        <Icon icon="sync" size="3x" />
        <span className="text-5xl">Confirming your rate...</span>
      </div>
    )
  }

  if (load.quoteProcessStatus === 'rerate-ready') {
    return (
      <div className="flex flex-col">
        <p className="text-4xl">Your rate went up.</p>

        <div className="mb-4 flex items-center">
          <PriceDisplay quote={originalQuote} status="old" />
          <Icon className="text-fv-orange mx-10" icon="arrow-up" size="6x" />
          <PriceDisplay quote={newQuoteQuery.data} status="new" />
        </div>

        <p className="text-lg">Is that okay or do you want to go back?</p>

        <div className="flex justify-end">
          <FvButton
            {...(awardLoads.isLoading && { icon: 'spinner' })}
            onClick={async () => {
              if (awardLoads.isLoading || revertRerate.isLoading) return
              await revertRerate.mutateAsync({ loadId })
              onDeclineRerate()
            }}
          >
            Wait, go back
          </FvButton>

          <FvButton
            loading={awardLoads.isLoading || acceptRerate.isLoading}
            {...(awardLoads.isLoading && { icon: 'spinner' })}
            onClick={handleContinueClick}
            theme="primary"
          >
            Okay, continue
          </FvButton>
        </div>
      </div>
    )
  }

  return null
}
