import {
  Commodity,
  Currency,
  DimensionUOM,
  LoadItem as LoadItemBase,
  WeightUOM,
} from '@fv/models'
import { DTO } from '@fv/models/core'

import { HazardousDetailFormModel } from './HazardousDetail'
import { ClientPackagingType } from './PackagingType'
import { DeepPartial } from './utils'

export type ItemRefNum = 'tracking-num' | 'other'

export type LoadItem = Omit<
  DTO<Commodity>,
  'type' | 'freightClass' | 'hazardous'
> & {
  type?: ClientPackagingType
  freightClass?: number
  nmfcNumber?: number
  nmfcSubNumber?: number
  hazardous?: HazardousDetailFormModel[]
  weight?: number
  weightUOM?: WeightUOM
  length?: number
  width?: number
  height?: number
  dimensionsUOM?: DimensionUOM
  declaredValue?: number
  declaredValueCurrency?: Currency
  customsValue?: number
  customsValueCurrency?: Currency
  // refNums?: ItemRefNum[] // commenting this out for now since it makes no sense
}

export type LoadHandlingUnit = Omit<
  DTO<LoadItemBase>,
  'contains' | 'type' | 'freightClass' | 'description' | 'hazardous'
> & {
  type?: ClientPackagingType
  freightClass?: number
  contains: LoadItem[]
  description?: string
  hazardous?: HazardousDetailFormModel[]
}

export const isHandlingUnit = (
  item: DeepPartial<LoadItem | LoadHandlingUnit>,
): item is LoadHandlingUnit => !!(item as LoadHandlingUnit).contains
