import { emailRegExpMatcher } from '@fv/client-core'
import { type ShipmentShare } from '@fv/client-types'

import { type ShipmentLocationFormModel } from '../features/shipment-location-form/types'

export const buildSharesDto = (
  shares: string,
  locations: ShipmentLocationFormModel[],
): ShipmentShare[] => {
  const rootShares = (
    shares.match(emailRegExpMatcher) || []
  ).map<ShipmentShare>(email => ({
    email,
    role: 'share',
    status: 'queued',
  }))

  const locationShares = locations
    .filter(x => x.copyBOL)
    .flatMap<ShipmentShare>(
      x =>
        x.shares.match(emailRegExpMatcher)?.map(email => ({
          email,
          role: x.type,
          sequence: x.sequence,
          status: 'queued',
        })) ?? [],
    )

  return [...rootShares, ...locationShares]
}
