import dayjs from 'dayjs'
import { type ChangeEvent, useCallback, useEffect, useState } from 'react'

import { composeStopDetails } from '@fv/client-core'
import { type MilitaryTime, type ShipmentLocation } from '@fv/client-types'
import { type StopDateType, type StopType } from '@fv/models'

import { DayPickerPanel } from '../../features/dates/DayPickerPanel'
import { type ShipmentLocationFormModel } from '../../features/shipment-location-form/types'
import createStopDetailOptions from '../../utils/createStopDetailOptions'
import AppointmentConfig, { apptRequiredKey } from '../inputs/AppointmentConfig'
import CheckboxField from '../inputs/CheckboxField'
import {
  type RouteStop,
  useRouteStopDateRange,
} from '../QuoteRequestFormProvider/useRouteStopDateRange'

type StopArg = RouteStop &
  Pick<ShipmentLocation, 'accessorials' | 'stopDateType' | 'stopType'>

type StopDetailConfigProps = {
  sequence: number
  onChange: (data: Partial<ShipmentLocationFormModel>) => void
  isLastStop: boolean
  stop: StopArg
}

const StopDetailConfig = ({
  sequence,
  stop,
  isLastStop,
  onChange,
}: StopDetailConfigProps) => {
  const { accessorials = [], stopDate, stopDateType, stopType } = stop

  const isFirstStop = sequence === 0
  const stopDetails = composeStopDetails({ stopDate, stopDateType, stopType })
  const stopDetailOptions = createStopDetailOptions({
    isFirstStop,
    isLastStop,
    stopDate,
  })

  const setApptDetails = useCallback(
    (
      details: {
        opensAt?: MilitaryTime | null
        closesAt?: MilitaryTime | null
      } | null,
    ) => {
      const nextAccessorials = accessorials.filter(
        a => a.key !== apptRequiredKey,
      )

      if (details) {
        const { opensAt, closesAt } = details

        nextAccessorials.push({
          key: apptRequiredKey,
          ...(opensAt && { opensAt }),
          ...(closesAt && { closesAt }),
        })
      }

      onChange({ accessorials: nextAccessorials })
    },
    [accessorials, onChange],
  )

  const selectStopDetails =
    (details: string) => (e: ChangeEvent<HTMLInputElement>) => {
      // Do not allow stop details to be removed, only changed
      if (e.target.checked) {
        let stopDateType: StopDateType = 'on'
        let stopType: StopType = 'pickup'

        if (details.includes('by')) stopDateType = 'by'
        if (details.includes('Deliver')) stopType = 'delivery'
        if (details.includes('and')) stopType = 'both'

        onChange({ stopDateType, stopType })
      }
    }

  return (
    <div className="quote-date-picker__requirements">
      <div>
        {isLastStop && 'Destination'}
        {isFirstStop && 'Origin'}
        {!isLastStop && !isFirstStop && `Stop ${sequence}`}
      </div>
      <hr className="mx-0 my-2 mb-4" />

      {stopDetailOptions.map((option, index) => (
        <CheckboxField
          checked={stopDetails === option}
          className="checkbox mb-4"
          key={option}
          label={option}
          name={`stop-instructions-${index}`}
          onChange={selectStopDetails(option)}
        />
      ))}

      <AppointmentConfig
        apptDetails={accessorials.find(a => a.key === apptRequiredKey)}
        setApptDetails={setApptDetails}
      />
    </div>
  )
}

type RouteStopDateProps = Pick<
  StopDetailConfigProps,
  'onChange' | 'sequence'
> & {
  onClose: () => void
  isOpen: boolean
  shouldRestrictRange?: boolean
  showStopDetailConfig?: boolean
  stops: StopArg[]
  required?: boolean
}

export const RouteStopDate = ({
  onClose,
  isOpen,
  sequence,
  onChange,
  shouldRestrictRange,
  showStopDetailConfig = true,
  stops,
  required,
}: RouteStopDateProps) => {
  const stopDate = stops[sequence]?.stopDate
  const stopDateInstance = stopDate ? new Date(stopDate) : undefined
  const { maxDate, minDate } = useRouteStopDateRange({
    sequence,
    shouldRestrictRange,
    stops,
  })

  const [localState, setLocalState] = useState<StopArg>(undefined)
  const disabledDays = (day: Date) => {
    if (!minDate && !maxDate) return false
    if (!minDate) return dayjs(day).isAfter(maxDate)
    if (!maxDate) return dayjs(day).endOf('d').isBefore(minDate)
    return dayjs(day).isBefore(minDate) || dayjs(day).isAfter(maxDate)
  }

  useEffect(() => {
    if (!isOpen) {
      setLocalState(undefined)
    } else {
      const stop = stops[sequence]
      setLocalState({
        ...stop,
        stopDate: stop.stopDate ? dayjs(stop.stopDate).toDate() : undefined,
      })
    }
  }, [isOpen, stops, sequence])

  return (
    <DayPickerPanel
      open={isOpen}
      mode="single"
      defaultMonth={stopDateInstance || minDate}
      disabled={disabledDays}
      fromMonth={shouldRestrictRange ? new Date() : undefined}
      required={required}
      onSelect={stopDate => {
        if (showStopDetailConfig) {
          setLocalState(p => ({ ...p, stopDate }))
          return
        }
        onChange({
          stopDate,
        })
        onClose()
      }}
      onClose={onClose}
      selected={
        localState?.stopDate ? dayjs(localState.stopDate).toDate() : undefined
      }
      rightContent={
        showStopDetailConfig &&
        localState && (
          <StopDetailConfig
            sequence={sequence}
            onChange={data => {
              setLocalState(p => ({ ...p, ...data }))
            }}
            stop={localState}
            isLastStop={sequence === stops.length - 1}
          />
        )
      }
      {...(showStopDetailConfig && {
        onSave: (stopDate: Date) => {
          onChange({ ...localState, stopDate })
          onClose()
        },
        onCancel: onClose,
      })}
      showClear={showStopDetailConfig && sequence > 0}
    />
  )
}
