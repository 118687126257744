import clsx from 'clsx'
import { Fragment } from 'react'

import { SelectField } from '@fv/client-components'
import { type EquipmentAccessorial } from '@fv/client-types'
import { type EquipmentAccessorialType } from '@fv/models'

import { type AccessorialTypeEnum } from '../../hooks/settings/useAccessorialOptions'
import NumberInput from '../inputs/NumberInput'
import YesNoInput from '../inputs/YesNoInput'

type PromptOption =
  | string
  | {
      text: string
      value: string
    }

const promptStyle = {
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '3fr 2fr',
}

type Props = {
  accessorialOptions: AccessorialTypeEnum[]
  accessorials?: EquipmentAccessorial[]
  id: string
  setAccessorials: (d: { accessorials: EquipmentAccessorial[] }) => void
}

const EquipmentAccessorialConfig = ({
  accessorialOptions,
  accessorials,
  id,
  setAccessorials,
}: Props) => {
  const equipmentAccessorials = accessorials ?? []

  return (
    <>
      {accessorialOptions.map(o => {
        const accKey = o.key as EquipmentAccessorialType
        const accessorial = equipmentAccessorials.find(a => a.key === accKey)
        const checked = !!accessorial
        const fieldName = `${id}_${accKey}`

        return (
          <Fragment key={accKey}>
            <div className="flex items-center">
              <label htmlFor={fieldName} className="col-auto">
                {o.name}
              </label>
              <div className="flex-pinline flex-1" />
              <div className="col-auto">
                <YesNoInput
                  checked={checked}
                  name={fieldName}
                  onChange={e => {
                    setAccessorials({
                      accessorials: e.target.checked
                        ? equipmentAccessorials.concat({
                            key: accKey,
                          } as EquipmentAccessorial)
                        : equipmentAccessorials.filter(a => a.key !== accKey),
                    })
                  }}
                />
              </div>
            </div>

            {checked &&
              o.prompts?.map(p => {
                const fieldName = `${id}_${accKey}_${p.key}`
                const PromptComponent = p.options ? SelectField : NumberInput
                const options: PromptOption[] = ['']

                if (p.options) {
                  p.options.forEach(o => {
                    options.push({
                      text: o.label,
                      value: String(o.value),
                    })
                  })
                }

                const handleChange = (e: any) => {
                  const value = e.target.value

                  setAccessorials({
                    accessorials: equipmentAccessorials.map(a => {
                      if (a.key !== accKey) return a
                      return {
                        ...a,
                        [p.key]:
                          value && p.promptType === 'number'
                            ? Number(value)
                            : value,
                      }
                    }),
                  })
                }

                return (
                  <div key={p.key} style={promptStyle}>
                    <label
                      htmlFor={fieldName}
                      className="label required"
                      style={{ marginBottom: 0 }}
                    >
                      {p.label}
                    </label>

                    <PromptComponent
                      className={clsx('form-control', {
                        'form-control--select': !!p.options,
                      })}
                      name={fieldName}
                      onChange={handleChange}
                      options={options}
                      required
                      // @ts-expect-error equipment accesorials are difficult
                      value={String(accessorial?.[p.key] ?? '')}
                      // Temp settings can be negative
                      {...(accKey !== 'temp' && { min: 1 })}
                      {...(accessorial.key === 'temp' &&
                        p.key === 'min' && { max: accessorial?.max })}
                      {...(accessorial.key === 'temp' &&
                        p.key === 'max' && { min: accessorial?.min })}
                    />
                  </div>
                )
              })}
          </Fragment>
        )
      })}
    </>
  )
}

export default EquipmentAccessorialConfig
