import { useQueryClient } from '@tanstack/react-query'
import { Suspense, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  FvButton,
  FvLinkButton,
  SliderPanel,
  type SliderPanelProps,
  Tooltip,
} from '@fv/client-components'
import { downloadCSV, showApiError } from '@fv/client-core'

import { Help } from '../../../components/Help'
import { InputGroup } from '../../../components/inputs/InputGroup'
import { TabLink, TabLinkList } from '../../../components/TabLink'
import { useAccountFeatures } from '../../auth'
import { useAccountSettings } from '../account-settings/hooks'
import { ContractedRateForm } from './ContractedRateForm'
import { ContractedRatesBulkEditor } from './ContractedRatesBulkEditor'
import { useCarrierOptions } from './hooks'
import { useAddContractedRates } from './mutations'
import { contractedRatesKeys } from './queries'
import { loadedRatesTemplate } from './templates'
import { type FlatContractedRate } from './types'
import UploadForm from './UploadForm'
import { rowHasError, unflattenRates } from './utils'

type Props = SliderPanelProps

export const AddRateSlider = ({ isOpen, closePanel }: Props) => {
  const queryClient = useQueryClient()
  const settings = useAccountSettings()
  const [searchParams] = useSearchParams()
  const carriers = useCarrierOptions()
  const { fuelAndDistance } = useAccountFeatures()
  const [addMode, setAddMode] = useState<'upload' | 'add'>('upload')
  const [uploadRates, setUploadRates] = useState<FlatContractedRate[]>([])
  const [carrierId, setCarrierId] = useState('')
  const addRates = useAddContractedRates()

  useEffect(() => {
    if (!carrierId && carriers.data.length) {
      setCarrierId(carriers.data[0]?.value)
    }
  }, [carriers, carrierId, setCarrierId])

  useEffect(() => {
    if (!isOpen) {
      setAddMode('upload')
      setUploadRates([])
      setCarrierId('')
    }
  }, [isOpen])

  useEffect(() => {
    setCarrierId(searchParams.get('carrierId') ?? '')
  }, [searchParams])

  return (
    <SliderPanel width="80vw" isOpen={isOpen} closePanel={closePanel}>
      <TabLinkList>
        <TabLink
          icon="upload"
          isActive={addMode === 'upload'}
          onClick={() => {
            setAddMode('upload')
          }}
        >
          Upload rates
        </TabLink>
        <TabLink
          icon="plus"
          isActive={addMode === 'add'}
          onClick={() => setAddMode('add')}
        >
          Add a rate manually
        </TabLink>
      </TabLinkList>
      {addMode === 'add' && <hr />}

      {addMode === 'add' && (
        <ContractedRateForm
          onCancel={() => closePanel()}
          onSubmit={() => {
            queryClient.invalidateQueries(contractedRatesKeys.all)
            closePanel()
          }}
        />
      )}
      {addMode === 'upload' && (
        <>
          <div className="flex items-end gap-2 bg-fv-blue-25 border-fv-blue-200 border p-4 my-8">
            <div>
              <InputGroup
                label="Carrier"
                className="mb-0 @cb1200:flex-none flex-1"
                inputType="select"
                required
                inputProps={{
                  options: carriers.data ?? [],
                  name: 'carrierId',
                  onChange: e => setCarrierId(e.target.value),
                  value: carrierId,
                }}
              />
            </div>
            <div className="basis-1/2">
              <label className="label" htmlFor="carrierId">
                <span>
                  File{' '}
                  <Help>
                    {' '}
                    You can upload a .CSV file containing your contracted rates.
                    Freightview requires the .CSV file to be in a specific
                    structure.
                  </Help>
                </span>
              </label>
              <UploadForm onSubmit={rates => setUploadRates([...rates])} />
            </div>
            <div className="border-t border-dotted border-fv-blue-300 h-px flex-1 self-center mt-7" />
            <FvLinkButton
              theme="secondary"
              className="ml-2"
              icon="download"
              transform="up-1.5"
              onClick={() => {
                downloadCSV(loadedRatesTemplate, 'LoadedRatesTemplate')
              }}
            >
              Download example
            </FvLinkButton>
          </div>
          {uploadRates.length > 0 && (
            <>
              <div style={{ height: 'calc(100vh - 335px)' }}>
                <Suspense>
                  <ContractedRatesBulkEditor
                    value={uploadRates}
                    onChange={rates => {
                      if (addRates.isLoading) return

                      setUploadRates(rates)
                    }}
                  />
                </Suspense>
              </div>

              <div className="flex gap-x-3 absolute bottom-5 right-5">
                <FvButton icon="times" onClick={closePanel}>
                  Cancel
                </FvButton>
                <Tooltip
                  label={
                    uploadRates.some(r =>
                      rowHasError(
                        r,
                        fuelAndDistance,
                        settings.truckload?.enableMexico,
                      ),
                    )
                      ? 'Some rows are invalid'
                      : ''
                  }
                >
                  <FvButton
                    theme="primary"
                    icon="cloud-upload"
                    loading={addRates.isLoading}
                    disabled={uploadRates.some(r =>
                      rowHasError(
                        r,
                        fuelAndDistance,
                        settings.truckload?.enableMexico,
                      ),
                    )}
                    onClick={async () => {
                      await addRates.mutateAsync(
                        unflattenRates(carrierId, uploadRates),
                        {
                          onSuccess: () => closePanel(),
                          onError: e =>
                            showApiError('Error uploading rates', e),
                        },
                      )
                    }}
                  >
                    Upload
                  </FvButton>
                </Tooltip>
              </div>
            </>
          )}
        </>
      )}
    </SliderPanel>
  )
}
