import { memo, useLayoutEffect, useRef } from 'react'
import {
  type CellComponent,
  type CellProps,
  type Column,
} from 'react-datasheet-grid'
import Select, { type GroupBase, type SelectInstance } from 'react-select'

import { coerceArray } from '@fv/models/core'

type Choice = {
  label: string
  value: string
}

type SelectOptions = {
  choices: Choice[]
  disabled?: boolean
}

const SelectComponent = memo(
  ({
    active,
    rowData,
    setRowData,
    focus,
    stopEditing,
    columnData,
  }: CellProps<string | null, SelectOptions>) => {
    const ref = useRef<SelectInstance<Choice, false, GroupBase<Choice>>>(null)

    useLayoutEffect(() => {
      if (focus) {
        ref.current?.focus()
      } else {
        ref.current?.blur()
      }
    }, [focus])

    return (
      <Select
        ref={ref}
        styles={{
          container: provided => ({
            ...provided,
            flex: 1,
            alignSelf: 'stretch',
            pointerEvents: focus ? undefined : 'none',
          }),
          control: provided => ({
            ...provided,
            height: '100%',
            border: 'none',
            boxShadow: 'none',
            background: 'none',
          }),
          indicatorSeparator: provided => ({
            ...provided,
            opacity: 0,
          }),
          indicatorsContainer: provided => ({
            ...provided,
            opacity: active ? 1 : 0,
          }),
          placeholder: provided => ({
            ...provided,
            opacity: active ? 1 : 0,
          }),
          menuPortal: provided => ({
            ...provided,
            zIndex: 9999,
          }),
        }}
        isDisabled={columnData.disabled}
        value={
          columnData.choices.find(({ value }) => value === rowData) ?? null
        }
        menuPortalTarget={document.body}
        menuIsOpen={focus}
        onChange={choice => {
          if (choice === null) return

          setRowData(choice.value)
          setTimeout(stopEditing, 0)
        }}
        onMenuClose={() => stopEditing({ nextRow: false })}
        options={columnData.choices}
      />
    )
  },
) as CellComponent<string | null, SelectOptions>

export const selectColumn = (
  options: SelectOptions,
): Column<string | null, SelectOptions> => ({
  component: SelectComponent,
  columnData: options,
  disableKeys: true,
  keepFocus: true,
  disabled: options.disabled,
  deleteValue: () => null,
  copyValue: ({ rowData }) =>
    options.choices.find(choice => choice.value === rowData)?.label ?? null,
  pasteValue: ({ value }) =>
    options.choices.find(
      choice => choice.label === value || choice.value === value,
    )?.value ?? null,
})

const MultiSelectComponent = memo(
  ({
    active,
    rowData,
    setRowData,
    focus,
    stopEditing,
    columnData,
  }: CellProps<string[] | null, SelectOptions>) => {
    const ref = useRef<SelectInstance<Choice, true, GroupBase<Choice>>>(null)

    useLayoutEffect(() => {
      if (focus) {
        ref.current?.focus()
      } else {
        ref.current?.blur()
      }
    }, [focus])

    return (
      <Select
        ref={ref}
        styles={{
          container: provided => ({
            ...provided,
            flex: 1,
            alignSelf: 'stretch',
            pointerEvents: focus ? undefined : 'none',
            maxWidth: '100%',
          }),
          control: provided => ({
            ...provided,
            height: '100%',
            border: 'none',
            boxShadow: 'none',
            background: 'none',
          }),
          valueContainer: provided => ({
            ...provided,
            maxWidth: '100%',
            flexWrap: 'nowrap',
          }),
          indicatorSeparator: provided => ({
            ...provided,
            opacity: 0,
          }),
          indicatorsContainer: provided => ({
            ...provided,
            opacity: active ? 1 : 0,
          }),
          placeholder: provided => ({
            ...provided,
            opacity: active ? 1 : 0,
          }),
          menuPortal: provided => ({
            ...provided,
            zIndex: 9999,
          }),
        }}
        isDisabled={columnData.disabled}
        value={
          columnData.choices.filter(({ value }) => rowData?.includes(value)) ??
          null
        }
        menuPortalTarget={document.body}
        menuIsOpen={focus}
        onChange={choice => {
          if (choice === null) return

          const rowData = coerceArray(choice as Choice | Choice[]).map(
            v => v.value,
          )

          setRowData(rowData)
          setTimeout(stopEditing, 0)
        }}
        onMenuClose={() => stopEditing({ nextRow: false })}
        options={columnData.choices}
        isMulti
      />
    )
  },
) as CellComponent<string[] | null, SelectOptions>

export const multiSelectColumn = (
  options: SelectOptions,
): Column<string[] | null, SelectOptions> => ({
  component: MultiSelectComponent,
  columnData: options,
  disableKeys: true,
  keepFocus: true,
  disabled: options.disabled,
  deleteValue: () => null,
  copyValue: ({ rowData }) =>
    options.choices
      .filter(choice => rowData.includes(choice.value))
      .map(v => v.label)
      .join() ?? null,
  pasteValue: ({ value }) => {
    const values = value.split(',').map(v => v.trim())
    const choices = options.choices.filter(
      choice => values.includes(choice.value) || values.includes(choice.label),
    )
    return choices.map(v => v.value)
  },
})
