import { createContext, useContext } from 'react'
import { useStore } from 'zustand'

import {
  type LoadItemsStore,
  type LoadItemsStoreState,
} from './loadItemsFormState'

export const LoadItemsContext = createContext<LoadItemsStore | null>(null)

export const useItemsContext = <T>(
  selector: (state: LoadItemsStoreState) => T,
): T => {
  const context = useContext(LoadItemsContext)

  if (!context) {
    throw new Error('Missing LoadItemsContext.Provider in the tree')
  }
  return useStore(context, selector)
}

export const useItemActions = () => {
  return useItemsContext(s => s.actions)
}
export const useItemsState = () => {
  return useItemsContext(({ actions, ...state }) => state)
}
