import clsx from 'clsx'
import { type ChangeEvent } from 'react'
import toast from 'react-hot-toast'
import { useToggle } from 'usehooks-ts'

import { type SelectFieldOption } from '@fv/client-types'

import { SelectField } from '../../components/inputs/SelectField'
import { Loading } from '../../components/Loading'
import { useCurrentUser } from '../../hooks/settings/useAppContext'
import { useUpdateVendorCurrentShipper } from './mutations'
import { useVendorShippers } from './queries'

export const ShipperSelector = () => {
  const shippersQuery = useVendorShippers()
  const user = useCurrentUser()
  const updateShipper = useUpdateVendorCurrentShipper()
  const [updating, toggleUpdating] = useToggle()

  const handleChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    toggleUpdating()
    try {
      await updateShipper.mutateAsync({ _id: e.target.value })
    } catch {
      toggleUpdating()
      toast.error('Error updating shipper')
    }
  }

  if (shippersQuery.isLoading) {
    return (
      <div>
        <Loading className="mb-0 py-2 px-4" />
      </div>
    )
  }

  const options =
    shippersQuery.data?.map<SelectFieldOption>(d => ({
      text: d.name,
      value: d._id,
    })) ?? []

  if (!options.length) return null
  return (
    <div className="pl-3 relative">
      <SelectField
        name="vendor-shipper"
        className={clsx({
          'opacity-25': updating,
        })}
        value={user._id}
        options={options}
        onChange={handleChange}
      />
      {updating && (
        <Loading className="z-40 absolute top-0 left-0 w-full h-full" />
      )}
    </div>
  )
}
