import clsx from 'clsx'
import pluralize from 'pluralize'
import {
  type Dispatch,
  type MouseEvent,
  type SetStateAction,
  useCallback,
} from 'react'

import {
  DelimitedContent,
  FvButton,
  FvLinkButton,
  Icon,
  PhoneNumberDisplay,
  Tooltip,
} from '@fv/client-components'
import {
  addressFuncs,
  composeStopDetails,
  militaryToStandardTime,
} from '@fv/client-core'
import { type ClientPackagingType } from '@fv/client-types'

import { List, ListItem } from '../../components/List'
import { PillHeader } from '../../components/PillHeader'
import { useBookingForm } from '../../features/booking/hooks'
import {
  commoditiesKey,
  composeLocationKey,
} from '../../features/booking/types'
import { type FormCommodity } from '../../features/commodities/FormCommodity'
import { useTypeNames } from '../../hooks/settings'
import { type ShipmentLocationFormModel } from '../shipment-location-form/types'
import { BookingLocationForm } from './BookingLocationForm'

const ContactDetails = ({
  email,
  name,
  phone,
}: {
  email: string
  name: string
  phone: string
}) => {
  if (!email && !name && !phone) return null

  return (
    <ListItem>
      <Icon icon="user" className="fa-fw text-fv-gray" />
      <span>
        Contact:{' '}
        <DelimitedContent delimiter={<>&nbsp;/&nbsp;</>}>
          {name && <span>{name}</span>}
          {phone && <PhoneNumberDisplay val={phone} />}
          {email && (
            <FvLinkButton theme="underlined" href={`mailto:${email}`}>
              {email}
            </FvLinkButton>
          )}
        </DelimitedContent>
      </span>
    </ListItem>
  )
}

type BookingLocationProps = {
  location: ShipmentLocationFormModel
  setDateSequence: Dispatch<SetStateAction<number | null>>
  addStop: (sequence: number) => void
  removeStop: (sequence: number) => void
}

export const BookingLocation = ({
  addStop,
  location,
  setDateSequence,
  removeStop,
}: BookingLocationProps) => {
  const {
    address,
    address2,
    closesAt,
    company,
    contactEmail,
    contactName,
    contactPhone,
    opensAt,
    refNum,
    sequence,
    stopDate,
    stopDateType,
    stopType,
  } = location

  const {
    activateSection,
    activeSection,
    formSections,
    goToNextSection,
    isPickupOnly,
    loads,
    setFormRef,
    setLocations,
  } = useBookingForm()

  const { packageName } = useTypeNames()
  const sectionKey = composeLocationKey(sequence)
  const isEditing = activeSection === sectionKey
  const section = formSections.find(s => s.key === sectionKey)
  const stopDetails = composeStopDetails({ stopDate, stopDateType, stopType })
  const hasValidItems = formSections.find(
    s => s.key === commoditiesKey,
  )?.isValid
  const isOrigin = location.type === 'origin'
  const isDestination = location.type === 'destination'
  const isStop = location.type === 'stop'

  const isMultiTruck = loads.some(load => load.isMultiTruck === true)

  const itemsToPickUp = loads.reduce((items, load) => {
    return items.concat(load.items.filter(i => i.pickSequence === sequence))
  }, [] as FormCommodity[])

  const itemsToDeliver = loads.reduce((items, load) => {
    return items.concat(load.items.filter(i => i.dropSequence === sequence))
  }, [] as FormCommodity[])

  const openDatePicker = useCallback(
    (e?: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
      e?.preventDefault()
      setDateSequence(sequence)
    },
    [sequence, setDateSequence],
  )

  const setValues = useCallback(
    (values: object) => {
      setLocations(prev =>
        prev.map(x => {
          if (x.sequence !== sequence) return x
          return { ...x, ...values }
        }),
      )
    },
    [sequence, setLocations],
  )

  return (
    <section className="booking-step-ctn">
      <div
        className={clsx('booking-step', {
          editing: isEditing,
          valid: section?.isValid,
        })}
      >
        <PillHeader
          st
          className={clsx(
            '-top-3.5 z-10 mb-3 flex min-h-[3.5rem] items-center',
            !isEditing &&
              !section?.isValid &&
              '-ml-[calc(.5rem)] w-[calc(100%-3rem)]',
            isEditing && 'absolute left-[calc(1.5rem)] w-[calc(100%-7rem)]',
            section?.isValid &&
              'absolute -left-[calc(.5rem+1px)] w-[calc(100%-3rem+1px)]',
            isOrigin && 'origin-container',
            isDestination && 'destination-container',
            isStop && 'beer-container',
          )}
        >
          {section?.isValid && (
            <FvButton
              theme="round"
              onClick={() => activateSection(sectionKey)}
              icon="pen-alt"
            />
          )}
          <div className="flex items-start">
            <Icon
              className={clsx('fa-fw', {
                'text-fv-green': isDestination,
                'text-fv-orange': isOrigin,
                'text-fv-beer': isStop,
              })}
              icon="map-marker"
              transform="down-3.5"
            />
            <p className="mb-0 leading-6">
              <FvLinkButton
                href="#"
                theme="underlined"
                onClick={openDatePicker}
              >
                {stopDetails}
              </FvLinkButton>
              <span>{' at '}</span>
              {addressFuncs.companyAddress(location, {
                includeLine2: true,
                dependentOnCompany: false,
              })}
            </p>
          </div>
          {!isMultiTruck && (
            <div className="relative -right-4 ml-auto flex">
              <Tooltip label="Forget a stop? Add a stop after this location.">
                <FvButton
                  icon="plus"
                  theme="round"
                  onClick={() => addStop(sequence)}
                />
              </Tooltip>

              {isStop && (
                <FvButton
                  icon="trash"
                  theme="round"
                  iconClass="text-fv-orange"
                  onClick={() => removeStop(sequence)}
                />
              )}
            </div>
          )}
        </PillHeader>

        {isEditing && (
          <BookingLocationForm
            onValidSubmit={goToNextSection}
            openDatePicker={openDatePicker}
            sectionKey={sectionKey}
            setFormRef={setFormRef}
            setValues={setValues}
            values={location}
          />
        )}

        {section?.isValid && (
          <div className="booking-step__summary">
            <List fcp0 lcp0>
              <ListItem dashed>
                <Icon icon="building" className="fa-fw text-fv-gray" />
                <span>
                  {company && `${company}`} {address && `/ ${address}`}
                  {address2 && `, ${address2}`}
                </span>
              </ListItem>

              <ContactDetails
                email={contactEmail}
                name={contactName}
                phone={contactPhone}
              />

              {!isPickupOnly && hasValidItems && itemsToPickUp.length > 0 && (
                <ListItem className="!gap-x-0">
                  <Icon className="fa-fw text-fv-gray" icon="forklift" />
                  <span>
                    Picking up {composeItemList(itemsToPickUp, packageName)}{' '}
                    {opensAt &&
                      `/ Ready time: ${militaryToStandardTime(opensAt)}`}
                    {closesAt &&
                      `/ Close time: ${militaryToStandardTime(closesAt)}`}
                    {refNum && ` / Ref# ${refNum}`}
                  </span>
                </ListItem>
              )}

              {!isPickupOnly && hasValidItems && itemsToDeliver.length > 0 && (
                <ListItem className="!gap-x-0">
                  <Icon className="fa-fw text-fv-gray" icon="forklift" />
                  <span>
                    Delivering {composeItemList(itemsToDeliver, packageName)}{' '}
                    {opensAt &&
                      `/ Ready time: ${militaryToStandardTime(opensAt)}`}
                    {closesAt &&
                      `/ Close time: ${militaryToStandardTime(closesAt)}`}
                    {refNum && ` / Ref# ${refNum}`}
                  </span>
                </ListItem>
              )}
            </List>
          </div>
        )}
      </div>
    </section>
  )
}

function composeItemList(
  items: FormCommodity[],
  getPackageName: (key: ClientPackagingType) => string,
) {
  return `${items.map(i => i.description).join(', ')} / ${items
    .map(i =>
      pluralize(getPackageName(i.type ?? 'pallet'), Number(i.quantity), true),
    )
    .join(', ')}`
}
