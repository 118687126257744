import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { FvButton, FvLinkButton } from '@fv/client-components'

import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import { newMessageId, usePostMessage } from '../../hooks/messages'
import { messageKeys } from '../../hooks/messages/useMessages'
import { type Message } from '../../hooks/messages/usePostMessage'
import { useSpotQuoteRecipients } from '../../hooks/spotQuotes'

type Props = {
  carrierId?: string
  loadId: string
  userId?: string
  carrierName?: string
}

const MessageField = ({ carrierId, loadId, userId, carrierName }: Props) => {
  const [message, setMessage] = useState('')
  const postMessage = usePostMessage()
  const queryClient = useQueryClient()
  const recipientsQuery = useSpotQuoteRecipients(loadId)
  const hasCarrierRecipient = recipientsQuery.data?.some(
    d => d.carrierId === carrierId,
  )

  useEffect(() => {
    setMessage('')
  }, [carrierId, userId, loadId])

  if (carrierId && !hasCarrierRecipient) return null

  function sendMessage() {
    const messageText = message.trim()
    if (postMessage.isLoading || !messageText) return

    const doPost = () =>
      postMessage
        .mutateAsync({
          loadId,
          messageText,
          ...(carrierId && { carrierId }),
          ...(userId && { userId }),
        })
        .catch(() => {
          toast.error(
            () => (
              <div className="flex items-center">
                <div>Failed to send message to {carrierName}. </div>
                <div>
                  <FvLinkButton
                    theme="callout"
                    className="mr-4"
                    onClick={() => {
                      doPost()
                      toast.dismiss(newMessageId)
                    }}
                  >
                    Retry
                  </FvLinkButton>
                  <FvLinkButton
                    theme="callout"
                    onClick={() => {
                      toast.dismiss(newMessageId)
                    }}
                  >
                    Dismiss
                  </FvLinkButton>
                </div>
              </div>
            ),
            {
              style: {
                width: '500px',
                maxWidth: 'none',
              },
              id: newMessageId,
              duration: Infinity,
            },
          )
          queryClient.setQueryData(
            messageKeys.load(loadId),
            (prev?: Message[]) =>
              prev?.filter(m => m._id !== newMessageId) ?? [],
          )
        })
    doPost()
    setMessage('')
  }

  return (
    <InputGroup
      className=""
      inputType="text"
      inputProps={{
        name: 'message',
        value: message,
        onChange: e => {
          setMessage(e.target.value)
        },
        onKeyDown: e => {
          e.key === 'Enter' && sendMessage()
        },
      }}
      endContent={
        <InputAdornment position="end">
          <FvButton
            onClick={sendMessage}
            icon="paper-plane"
            transform="up-1"
            theme="default"
          >
            <span>Send</span>
          </FvButton>
        </InputAdornment>
      }
    />
  )
}

export default MessageField
