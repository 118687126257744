import { type Workflow } from '@fv/models'

import { ShipmentFormSection } from '../shipment-form/ShipmentFormSection'
import { type ShipmentCustomsBrokerFormModel } from './brokerTypes'
import { CustomsBrokerForm, type LoadLocationLookup } from './CustomsBrokerForm'

type Props = {
  workflow: Workflow
  disabled?: boolean
  required?: boolean
  requireDeclaredValue: boolean
  value: ShipmentCustomsBrokerFormModel[]
  onChange: (value: ShipmentCustomsBrokerFormModel[]) => void
  loadLocation: LoadLocationLookup
}
export const ShipmentBrokerFormSection = ({
  workflow,
  disabled,
  required,
  value,
  loadLocation,
  onChange,
  requireDeclaredValue,
}: Props) => {
  return (
    <ShipmentFormSection
      title="Customs broker"
      className="gray-container"
      pillClassName="gray-container !border-2"
    >
      {value.map((broker, ix) => (
        <div className="grid grid-cols-4 gap-x-2" key={ix}>
          <CustomsBrokerForm
            workflow={workflow}
            loadLocation={loadLocation}
            disabled={disabled}
            required={required}
            setValues={v =>
              onChange(value.map((b, i) => (i === ix ? { ...b, ...v } : b)))
            }
            values={broker}
            requireValue={requireDeclaredValue}
          />
        </div>
      ))}
    </ShipmentFormSection>
  )
}
