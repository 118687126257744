import { ObjectId } from '@freightview/object-id'
import cloneDeep from 'lodash/cloneDeep'

import { type ShipmentLocation } from '@fv/client-types'
import {
  type LegacyShipType,
  type LocationAccessorialType,
  type LocationType,
  type StopType,
  type Workflow,
} from '@fv/models'

import { toFormLocation } from '../features/booking/hooks'
import {
  buildShipmentLocation,
  emptyLocationFormModel,
  type ShipmentLocationFormModel,
} from '../features/shipment-location-form/types'

export const insideShipTypes: LegacyShipType[] = [
  'airport',
  'business no dock',
  'farm',
  'limited access',
  'military',
  'mine',
  'place of worship',
  'residential',
  'school',
  'prison',
]

export const liftgateShipTypes = insideShipTypes.concat('construction')

export function hasInsideOption(shipType: '' | LegacyShipType) {
  return !!shipType && insideShipTypes.includes(shipType)
}

export function hasLiftgateOption(shipType: '' | LegacyShipType) {
  return !!shipType && liftgateShipTypes.includes(shipType)
}

export function sanitizeLocation<
  T extends Pick<ShipmentLocationFormModel, 'accessorials' | 'shipType'>,
>(location: T, workflow?: Workflow): T {
  location = cloneDeep(location)

  if (workflow === 'parcel') {
    location.shipType =
      location.shipType === 'residential' || !location.shipType
        ? location.shipType
        : 'business dock'
  }

  // Remove invalid accessorials
  if (location.shipType) {
    const { shipType } = location
    location.accessorials = location.accessorials.filter(a => {
      if (a.key === 'inside') return insideShipTypes.includes(shipType)
      if (a.key === 'liftgate') {
        return liftgateShipTypes.includes(shipType)
      }
      return true
    })
  }

  return location
}

export function formStopsToLocationsDTO(
  stops: ShipmentLocationFormModel[],
): ShipmentLocation[] {
  return stops.map<ShipmentLocation>(stop => {
    let type: LocationType = stop.sequence === 0 ? 'origin' : 'stop'
    if (stop.sequence === stops.length - 1) type = 'destination'
    return {
      ...buildShipmentLocation(stop),
      type,
    }
  })
}

export function locationToFormStop(
  x: ShipmentLocation,
  workflow?: Workflow,
): ShipmentLocationFormModel {
  return sanitizeLocation(
    toFormLocation(x, { workflow: workflow ?? 'ltl' }),
    workflow,
  )
}

export function buildStop({
  sequence,
  shipType,
  stopDate = null,
  stopType,
}: {
  sequence: number
  shipType?: LegacyShipType
  stopDate?: Date | null
  stopType: StopType
}): ShipmentLocationFormModel {
  return {
    ...emptyLocationFormModel,
    type: stopType === 'pickup' ? 'origin' : 'destination',
    shipType: shipType || '',
    sequence,
    stopDate,
    stopDateType: 'on',
    stopType,

    // Front-end only
    id: new ObjectId().toHexString(),
  }
}

export function getApplicableAccessorials(
  location: Pick<ShipmentLocation, 'type' | 'accessorials'>,
  workflow: Workflow,
) {
  if (location.type !== 'origin' || workflow === 'truckload') {
    return location.accessorials ?? []
  }

  const nonOriginAccessorials: LocationAccessorialType[] = [
    'schedule',
    'notify',
  ]

  return (
    location.accessorials?.filter(
      a => !nonOriginAccessorials.includes(a.key),
    ) ?? []
  )
}

export const isPuertoRicanLoc = ({ state }: Pick<ShipmentLocation, 'state'>) =>
  state === 'PR'
