import { useMutation } from '@tanstack/react-query'

import {
  type ProductType,
  type UserPermissionType,
  type VerifyTokenDto,
  type VerifyTokenResponse,
} from '@fv/models'

import {
  useAppContext,
  useSubscriptionStatus,
  useUserSettings,
} from '../../hooks/settings'
import { isCarrierAccountUser } from '../../utils/accountFuncs'
import { shipperFetch } from '../../utils/shipperFetch'

export function useAccountFeatures() {
  const settingsQuery = useUserSettings()
  const subscriptionStatus = useSubscriptionStatus()
  const features = settingsQuery.data?.products
  const isTrial = subscriptionStatus === 'trial'
  const hasTruckload = !!features?.includes('truckLoad')

  return {
    mixedPallets: !!features?.includes('mixedPallets'),
    parcel: isTrial || !!features?.includes('parcel'),
    truckLoad: isTrial || hasTruckload,
    fuelAndDistance:
      isTrial || (!!features?.includes('fuelAndDistance') && hasTruckload),
    automatedAuditing: !!features?.includes('automatedAuditing'),
    ltl: !!features?.includes('ltl'),
    vendorPortal: !!features?.includes('vendorPortal'),
  } satisfies Record<ProductType, boolean>
}

export function useHasPermission(name: UserPermissionType) {
  const contextQuery = useAppContext()
  const permissions = contextQuery.data?.user.permissions

  return !!permissions?.includes(name)
}

export function usePermissions() {
  return {
    canAudit: useHasPermission('audit'),
    canBook: useHasPermission('book'),
    canManage: useHasPermission('manage'),
    canQuote: useHasPermission('quote'),
    canManageProducts: useHasPermission('manage-products'),
    canViewRates: useHasPermission('view-rates'),
  }
}

export const useIsCarrierAccount = () => {
  const context = useAppContext()
  if (!context.data?.user) return false

  return isCarrierAccountUser(context.data.user)
}

export const useVerifyToken = () => {
  return useMutation((body: VerifyTokenDto) =>
    shipperFetch<VerifyTokenResponse>('/auth/verify', {
      body,
      method: 'POST',
    }),
  )
}
export const useIsAdminAccount = () => {
  const context = useAppContext()
  if (!context.data?.user) return false

  return context.data.user.accountRoles?.includes('admins') ?? false
}

export const useRole = () => {
  const contextQuery = useAppContext()
  if (!contextQuery.data.user) throw new Error('User data not present')

  return contextQuery.data.user.userRole
}
