import { useCurrency } from '../../../hooks/settings'
import { type FuelTableEntry } from './types'

type Props = { row: FuelTableEntry }

const FuelTableRow = ({ row }: Props) => {
  const priceParts = useCurrency()
  const [lbDollars, lbCents] = priceParts(row.lowerBoundary)
  const [rateDollars, rateCents] = priceParts(row.rate)
  const [ubDollars, ubCents] = priceParts(row.upperBoundary)

  const currency = row.currency.toLocaleUpperCase()
  const lowerBoundary = `$${lbDollars}.${lbCents}`
  const rate = `$${rateDollars}.${rateCents}`
  const type = row.calculationType.replace(/-/g, ' ')
  const upperBoundary = `$${ubDollars}.${ubCents}`

  return (
    <div className="flex px-4 py-2 even:bg-white odd:bg-fv-gray-100 ">
      <div className="col-3 text-center">{lowerBoundary}</div>
      <div className="col-3 text-center">{upperBoundary}</div>
      <div className="col-2 text-center">{type}</div>
      <div className="col-2 text-center">{rate}</div>
      <div className="col-2 text-center">{currency}</div>
    </div>
  )
}

export default FuelTableRow
