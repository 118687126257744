import { Icon } from '@fv/client-components'
import { type UIQuote } from '@fv/client-types'

import { useCurrency } from '../../hooks/settings'
import { usePermissions } from '../auth'

type Props = { rate: UIQuote }
export const RatePriceDisplay = ({ rate }: Props) => {
  const priceParts = useCurrency()
  const { canViewRates } = usePermissions()
  const [dollars, cents] = priceParts(rate.amount)
  if (!canViewRates && rate.amount === 0) return null // cheapest quote

  return (
    <>
      {!canViewRates && <Icon icon="plus" className="text-black mr-4" />}
      <div className="price__dollars">${dollars}</div>
      <div className="price__cents">{cents}</div>
    </>
  )
}
