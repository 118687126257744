import clsx from 'clsx'

import { Icon, Tooltip } from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'

import { HideForVendor } from '../auth/HasRole'

export const VendorShipmentIcon = ({ className }: PropsWithClassName) => {
  return <Icon icon="store-alt" className="text-fv-orange" />
}

export const VendorShipmentListIndicator = ({
  className,
}: PropsWithClassName) => {
  return (
    <HideForVendor>
      <Tooltip label="This is a vendor shipment">
        <div
          className={clsx(
            'flex items-center  px-4 bg-white border-l border-l-fv-gray',
            className,
          )}
        >
          <VendorShipmentIcon className="" />
        </div>
      </Tooltip>
    </HideForVendor>
  )
}
