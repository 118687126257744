import { ObjectId } from '@freightview/object-id'
import { createStore } from 'zustand'

import { submitForm } from '@fv/client-core'
import { type Workflow } from '@fv/models'

import { defaultLoadHandlingUnit, isValidItem } from './loadItemHelpers'
import {
  type HandlingUnitFormUpdate,
  type LoadHandlingUnitFormModel,
  type LoadStep,
} from './types'

export interface LoadItemsFormState {
  items: LoadHandlingUnitFormModel[]
  activeIndex: number
  activeContainsIndex?: number
  workflow: Workflow
  step: LoadStep
  disabled?: boolean
  itemForm: HTMLFormElement | null
  carrierCode?: string
  editingContainsUnit?: LoadHandlingUnitFormModel
}

export interface LoadItemActions {
  activateContains: (ix?: number) => void
  activateItem: (ix: number) => void
  addItem: () => void
  clearCurrentItem: () => void
  clearItem: (index: number) => void
  duplicateCurrentItem: () => void
  duplicateItem: (index: number) => void
  setEditingContains: (unit?: LoadHandlingUnitFormModel) => void
  removeCurrentItem: () => void
  removeItem: (index: number) => void
  resetItems: () => void
  setFormRef: (formRef: HTMLFormElement | null) => void
  setItems: (items: LoadHandlingUnitFormModel[]) => void
  updateCurrentItem: (item: HandlingUnitFormUpdate) => void
  updateItem: (index: number, item: HandlingUnitFormUpdate) => void
  validateForm: () => boolean
  validateItems: (workflow: Workflow, step: LoadStep) => boolean
}

export interface LoadItemsStoreState extends LoadItemsFormState {
  actions: LoadItemActions
}

const initialStateFromWorkflow = ({
  workflow,
  step,
  items,
}: LoadItemsStoreInit): LoadItemsFormState => ({
  items: items?.length ? items : [defaultLoadHandlingUnit(workflow)],
  activeIndex: 0,
  workflow,
  itemForm: null,
  step,
})

export type LoadItemsStoreInit = {
  workflow: Workflow
  step: LoadStep
  items?: LoadHandlingUnitFormModel[]
}

export const createItemsStore = (init: LoadItemsStoreInit) =>
  createStore<LoadItemsStoreState>()((set, get) => ({
    ...initialStateFromWorkflow(init),
    actions: {
      activateItem: activeIndex => set({ activeIndex }),
      activateContains: activeContainsIndex => set({ activeContainsIndex }),
      addItem: () =>
        set(prev => ({
          items: [...prev.items, defaultLoadHandlingUnit(prev.workflow)],
          activeIndex: prev.items.length,
        })),
      clearCurrentItem: () => {
        const {
          actions: { clearItem },
          activeIndex,
        } = get()
        clearItem(activeIndex)
      },
      clearItem: index => {
        get().actions.updateItem(index, {
          description: undefined,
          type: undefined,
          stackable: undefined,
          quantity: undefined,
          weight: undefined,
          length: undefined,
          width: undefined,
          height: undefined,
          freightClass: undefined,
          isHazardous: undefined,
          fullNmfc: undefined,
        })
      },
      duplicateCurrentItem: () => {
        const {
          actions: { duplicateItem },
          activeIndex,
        } = get()
        duplicateItem(activeIndex)
      },
      duplicateItem: index =>
        set(({ items }) => {
          const duplicate = {
            ...JSON.parse(JSON.stringify(items[index])),
            _id: new ObjectId().toHexString(),
          }
          return {
            items: [...items.slice(0, index), duplicate, ...items.slice(index)],
            activeIndex: index + 1,
          }
        }),
      setEditingContains: editingContainsUnit => set({ editingContainsUnit }),
      removeCurrentItem: () => {
        const {
          actions: { removeItem },
          activeIndex,
        } = get()
        removeItem(activeIndex)
      },
      removeItem: index =>
        set(({ items, activeIndex }) => ({
          items: items.filter((i, ix) => ix !== index),
          activeIndex: Math.max(0, activeIndex - 1),
        })),
      resetItems: () =>
        set(({ workflow }) => ({
          items: [defaultLoadHandlingUnit(workflow)],
          activeIndex: 0,
        })),
      setFormRef: (itemForm: HTMLFormElement | null) =>
        set(() => ({ itemForm })),
      setItems: items => set(() => ({ items })),
      updateCurrentItem: update => {
        const {
          actions: { updateItem },
          activeIndex,
        } = get()
        updateItem(activeIndex, update)
      },
      updateItem: (index, update) =>
        set(({ items }) => {
          const currentItem = items[index]
          items[index] = {
            ...currentItem,
            ...update,
          }
          return {
            items,
          }
        }),
      validateItems: (workflow: Workflow, step: LoadStep) => {
        const { actions, items } = get()
        const invalidItem = items.find((i, ix) => {
          if (!isValidItem(i, workflow, step)) {
            actions.activateItem(ix)
            return true
          }
          return false
        })
        if (invalidItem) {
          setTimeout(() => actions.validateForm(), 0) // wait for re-render to validate form
        }
        return !invalidItem
      },
      validateForm: () => {
        const form = get().itemForm
        const valid = form?.checkValidity() ?? false
        if (!valid && form) {
          submitForm(form)
        }
        return valid
      },
    },
  }))
export type LoadItemsStore = ReturnType<typeof createItemsStore>
