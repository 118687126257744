import { type FormAddressBase } from '@fv/client-types'
import { type Country, type ShipmentCustomsBrokerDto } from '@fv/models'

export const defaultFormBroker: FormCustomsBroker = {
  address: '',
  address2: '',
  city: '',
  companyName: '',
  country: 'us',
  email: '',
  phone: '',
  phoneExt: '',
  postalCode: '',
  state: '',
  value: '',
  type: '',
}

export interface FormCustomsBroker extends FormAddressBase {
  companyName: string
  email: string
  phone: string
  phoneExt: string
  value: string
  type: 'import' | 'export' | ''
}

export type CustomsBroker = Partial<
  Omit<FormCustomsBroker, 'country' | 'value'>
> & {
  companyName: string
  country?: Country
  phone: string
  value?: number
}

export type ShipmentCustomsBrokerFormModel = Omit<
  Required<ShipmentCustomsBrokerDto>,
  'type' | 'value'
> & {
  type: ShipmentCustomsBrokerDto['type'] | ''
  value: string
}

export const buildLoadCustomsBrokerDTO = (
  model: ShipmentCustomsBrokerFormModel,
): ShipmentCustomsBrokerDto => {
  return {
    ...model,
    type: model.type === '' ? undefined : model.type,
    ...(model.value && { value: parseFloat(model.value) }),
  }
}
export const buildShipmentCustomsBrokerFormModel = (
  broker: CustomsBroker,
): ShipmentCustomsBrokerFormModel => ({
  ...defaultFormBroker,
  ...broker,
  value: broker.value?.toString() || '',
})
