import { createSearchParams } from 'react-router-dom'

import { type AwardChangeReason, type FullShipment } from '@fv/client-types'
import { type ShipperUserRole, type Workflow } from '@fv/models'

import { type BookingMode, type ReferrerType } from './features/booking/types'
import { usePreferredWorkflows } from './features/profiile/hooks'
import { type RatesListingMode } from './features/quote/useRatesListing'
import {
  ltlRecentFilter,
  ltlSavedFitler,
  parcelRecentFilter,
  parcelSavedFilter,
  tlAwardedFilter,
  tlQuotingFilter,
} from './features/shipment-filters/in-process-filters'
import { getFilterQueryString } from './types/SavedFilter'
import { type SettingsPageType } from './constants'

export interface QuoteRouteParams {
  id: string
  mode: 'copy' | 'edit'
}
export interface BookTruckloadParams {
  quoteRequestId: string
  mode?: BookingMode
  reason?: AwardChangeReason | null
  referrer?: ReferrerType
  selectedQuotes: Record<string, string>
}

export const routes = {
  book: ({
    loadId,
    quoteId,
  }: Pick<FullShipment, 'loadId'> & { quoteId: string }) => {
    return `/book/${loadId}/${quoteId}`
  },
  bookTruckload: (opts: BookTruckloadParams) => {
    const params = createSearchParams({
      selected: JSON.stringify(opts.selectedQuotes),
      ...(opts.mode && { mode: opts.mode }),
      ...(opts.reason && { reason: opts.reason }),
      ...(opts.referrer && { ref: opts.referrer }),
    })

    return `/pending/${opts.quoteRequestId}?${params}`
  },
  customerRouted: () => '/book/customer-routed',
  details: (loadId: string) => `/details/${loadId}`,
  quote: (workflow: Workflow, params?: QuoteRouteParams) => {
    let path = `/${workflow}/quote`
    if (workflow === 'truckload') path = '/quote-request'
    if (params) path += `/${params.id}/${params.mode}`
    return path
  },
  rates: (
    load: Pick<FullShipment, 'workflow' | 'loadId' | 'quoteRequestId'>,
    mode?: RatesListingMode,
    role?: ShipperUserRole,
  ) => {
    const { loadId, quoteRequestId, workflow } = load

    return workflow === 'truckload' && role !== 'vendor'
      ? `/quote/${quoteRequestId}`
      : [`/rates/${loadId}`, mode].filter(x => x).join('/')
  },
  recentQuotes: (workflow: Workflow) => {
    const filter =
      workflow === 'truckload'
        ? tlQuotingFilter
        : workflow === 'parcel'
          ? parcelRecentFilter
          : ltlRecentFilter
    return `/pending?${getFilterQueryString(filter)}`
  },
  savedQuotes: (workflow: Workflow) => {
    const filter =
      workflow === 'truckload'
        ? tlAwardedFilter
        : workflow === 'parcel'
          ? parcelSavedFilter
          : ltlSavedFitler
    return `/pending?${getFilterQueryString(filter)}`
  },
  settings: (
    page: SettingsPageType,
    mode?: 'add' | 'edit' | 'fuel',
    rateId?: string,
  ) => {
    let path = `/settings/${page}`
    if (mode) path += `/${mode}`
    if (mode && rateId) path += `/${rateId}`
    return path
  },
  contractedRates: (
    mode?: 'add' | 'edit' | 'fuel' | 'list',
    opts?: {
      rateId?: string
      carrierId?: string
    },
  ) => {
    const root = '/settings/contracted-rates'
    if (mode === 'add' || mode === 'fuel') return `${root}/add`
    if (opts?.carrierId && mode === 'list') {
      return `${root}?carrierId=${opts?.carrierId}`
    }
    if (mode === 'edit' && opts?.rateId) {
      return `${root}/edit/${opts?.rateId}`
    }
    return root
  },

  provisioning: (tab: 'requested' | 'active') => {
    return `/provisioning/${tab}`
  },

  /*vendor routes */
  create: () => '/create',
  editLoad: (loadId: string) => {
    // TODO VP - name
    return `/shipments/${loadId}/edit`
  },
  vendorBook: (loadId: string, quoteId: string) =>
    `/shipments/${loadId}/book/${quoteId}`,
  /*end vendor routes */
  activeShipments: () => `/active`,
}

export const useQuotePage = () => {
  const workflows = usePreferredWorkflows()
  return routes.quote(workflows[0])
}

export const useHomePage = () => {
  return useQuotePage()
}
