import { notUndefined, useVirtualizer } from '@tanstack/react-virtual'
import clsx from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'

import { FvButton, Icon, Tooltip } from '@fv/client-components'
import { parseQueryString, stringifyQueryString } from '@fv/client-core'
import { type ContractedRatesFilter, type SortDir } from '@fv/models'

import { Loading } from '../../../components/Loading'
import { ExtendContractedRates } from './ExtendContractedRates'
import { ContractedRatesListFilter } from './ListFilter'
import { exportRates, useRemoveContractedRates } from './mutations'
import { useContractedRates } from './queries'
import { RateRow } from './RateRow'
import { SortLink } from './SortLink'
import { type ContractedRateSort } from './types'
import {
  xOverflowShadow,
  xOverflowShadowLeft,
  xOverflowShadowRight,
} from './utils'

export const ContractedRatesListing = () => {
  const removeRates = useRemoveContractedRates()
  const scrollRef = useRef<HTMLTableSectionElement>(null)
  const [exporting, setExporting] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const filter = useMemo<ContractedRatesFilter>(
    () => ({
      carrierId: searchParams.get('carrierId') ?? '',
      originSearch: searchParams.get('originSearch') ?? '',
      destSearch: searchParams.get('destSearch') ?? '',
      contractSearch: searchParams.get('contractSearch') ?? '',
      showExpired: searchParams.get('showExpired') === 'true',
      sortBy:
        (searchParams.get('sortBy') as ContractedRateSort) ??
        ('carrierName' as ContractedRateSort),
      sortDir:
        (Number(searchParams.get('sortDir')) as SortDir) || (1 as SortDir),
    }),
    [searchParams],
  )

  const ratesQuery = useContractedRates(filter)
  const [checked, setChecked] = useState<string[]>([])

  if (ratesQuery.hasNextPage && !ratesQuery.isFetching) {
    ratesQuery.fetchNextPage({ cancelRefetch: false })
  }

  const allRates = ratesQuery.data?.pages.flatMap(page => page.rates) ?? []

  const rowVirtualizer = useVirtualizer({
    count: allRates.length,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => 57,
  })
  const virtualItems = rowVirtualizer.getVirtualItems()

  // https://github.com/TanStack/virtual/issues/585#issuecomment-1716173260
  const [before, after] =
    virtualItems.length > 0
      ? [
          notUndefined(virtualItems[0]).start -
            rowVirtualizer.options.scrollMargin,
          rowVirtualizer.getTotalSize() -
            notUndefined(virtualItems[virtualItems.length - 1]).end,
        ]
      : [0, 0]

  function changeFunc({ limit, ...rest }: ContractedRatesFilter) {
    return setSearchParams(prev =>
      stringifyQueryString({
        ...parseQueryString(prev.toString()),
        ...rest,
      }),
    )
  }

  const handleSortClick = (sort: string) => {
    setSearchParams(prev =>
      stringifyQueryString({
        ...parseQueryString(prev.toString()),
        sortBy: sort as ContractedRateSort,
        sortDir:
          Number(prev.get('sortDir')) === 1 && sort === prev.get('sortBy')
            ? -1
            : 1,
      }),
    )
  }

  useEffect(() => {
    setChecked([])
  }, [filter])

  return (
    <>
      <ContractedRatesListFilter value={filter} onSubmit={changeFunc} />

      <div className="w-full overflow-auto flex-1" ref={scrollRef}>
        <table className="min-w-full border-separate border-spacing-0">
          <thead className="shadow-lg sticky top-0 z-10 [&_th]:px-4 [&_th]:py-3 [&_th]:text-left [&_th]:border [&_tr:first-child_th]:border-fv-blue-200 [&_th]:font-normal [&_tr:first-child_th]:bg-fv-blue-50 [&_th]:whitespace-nowrap [&_th]:z-10 [&_tr:not(first-child)_th]:bg-white">
            <tr>
              <th className="!text-center !p-0 sticky left-0 !bg-fv-beer-light !border-fv-beer-medium min-w-[3rem]">
                <SortLink
                  sort="status"
                  currentSort={filter.sortBy}
                  currentDir={filter.sortDir}
                  onClick={handleSortClick}
                >
                  <Tooltip label="Status">
                    <Icon icon="info-circle" />
                  </Tooltip>
                </SortLink>
              </th>

              <th className="!text-center !p-0 sticky left-[calc(3rem+2px)] !bg-fv-beer-light !border-fv-beer-medium min-w-[3rem]">
                <Tooltip
                  label={
                    ratesQuery.isFetching
                      ? 'We are loading all of your rates'
                      : 'Check all'
                  }
                >
                  <FvButton
                    icon="check-double"
                    disabled={ratesQuery.isFetching}
                    onClick={() =>
                      setChecked(
                        checked.length === allRates.length
                          ? []
                          : allRates.map(rate => rate._id),
                      )
                    }
                  />
                </Tooltip>
              </th>
              <th
                className={clsx(
                  'sticky left-[calc(6rem+4px)] !bg-fv-beer-light !border-fv-beer-medium',
                  xOverflowShadow,
                  xOverflowShadowLeft,
                )}
              >
                <SortLink
                  sort="carrierName"
                  currentSort={filter.sortBy}
                  currentDir={filter.sortDir}
                  onClick={handleSortClick}
                >
                  Carrier
                </SortLink>
              </th>
              <th>
                <SortLink
                  sort="contractNumber"
                  currentSort={filter.sortBy}
                  currentDir={filter.sortDir}
                  onClick={handleSortClick}
                >
                  Contract #
                </SortLink>
              </th>
              <th>
                <SortLink
                  sort="origin"
                  currentSort={filter.sortBy}
                  currentDir={filter.sortDir}
                  onClick={handleSortClick}
                >
                  Origin
                </SortLink>
              </th>
              <th>
                <SortLink
                  sort="destination"
                  currentSort={filter.sortBy}
                  currentDir={filter.sortDir}
                  onClick={handleSortClick}
                >
                  Destination
                </SortLink>
              </th>
              <th className="!text-right">
                <SortLink
                  sort="amount"
                  currentSort={filter.sortBy}
                  currentDir={filter.sortDir}
                  onClick={handleSortClick}
                >
                  Rate amount
                </SortLink>
              </th>
              <th>Type</th>
              <th>Fuel</th>
              <th>Equipment</th>
              <th>
                <SortLink
                  sort="startDate"
                  currentSort={filter.sortBy}
                  currentDir={filter.sortDir}
                  onClick={handleSortClick}
                >
                  Date start
                </SortLink>
              </th>
              <th>
                <SortLink
                  sort="endDate"
                  currentSort={filter.sortBy}
                  currentDir={filter.sortDir}
                  onClick={handleSortClick}
                >
                  Date end
                </SortLink>
              </th>
              <th
                className={clsx(
                  '!text-center sticky right-0 !bg-fv-beer-light !border-fv-beer-medium',
                  xOverflowShadow,
                  xOverflowShadowRight,
                )}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="[&_td]:px-4 [&_td]:py-3 [&_td]:border-fv-gray-200 [&_td]:border [&_td:first-child]:text-left [&_td]:whitespace-nowrap [&_td]:bg-white">
            {before > 0 && (
              <tr>
                <td colSpan={13} style={{ height: before }} />
              </tr>
            )}
            {virtualItems.map(vi => (
              <RateRow
                key={vi.key}
                rate={allRates[vi.index]}
                style={{
                  height: `${vi.size}px`,
                }}
                checked={checked.includes(allRates[vi.index]._id)}
                onChange={isChecked => {
                  setChecked(checked =>
                    isChecked
                      ? [...checked, allRates[vi.index]._id]
                      : checked.filter(id => id !== allRates[vi.index]._id),
                  )
                }}
              />
            ))}
            {after > 0 && (
              <tr>
                <td colSpan={13} style={{ height: after }} />
              </tr>
            )}
          </tbody>
        </table>
        {ratesQuery.isInitialLoading && <Loading className="my-6" />}
      </div>
      {checked.length > 0 && (
        <div className="footer-actions col-span-full -mx-6 -mb-9">
          <FvButton
            theme="default"
            icon="file-export"
            loading={exporting}
            onClick={() => {
              setExporting(true)
              exportRates(checked, allRates)
              setExporting(false)
              setChecked([])
            }}
          >
            Export
          </FvButton>

          <ExtendContractedRates
            rates={checked}
            onExtend={() => setChecked([])}
          />

          <FvButton
            icon="trash"
            theme="default"
            iconClass="text-fv-orange-bright"
            loading={removeRates.isLoading}
            onClick={() => {
              const isConfirmed = window.confirm(
                `Are you sure you want to remove these ${checked.length} rates?`,
              )

              if (!isConfirmed) return

              removeRates
                .mutateAsync(checked)
                .then(() => {
                  setChecked([])
                })
                .catch(e => {
                  toast.error(e.message)
                })
                .finally(() => {
                  removeRates.reset()
                })
            }}
          >
            Remove selected
          </FvButton>
        </div>
      )}
    </>
  )
}
