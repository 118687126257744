import { useParams } from 'react-router-dom'

import { useFvNavigate } from '@fv/client-components'

import { Loading } from '../../components/Loading'
import { LoadSummary } from '../../components/LoadSummary/LoadSummary'
import { useLoad } from '../../hooks/shipments'
import { useLoadRatesPage } from '../../route-hooks'
import { routes } from '../../routes'
import { useAwardLoads } from '../booking/mutations'
import { useUpdateShipment } from '../shipment-form/mutations'
import { buildShipmentLocation } from '../shipment-location-form/types'
import { ShipmentForm } from './ShipmentForm'
import { ShipmentFormPageLayout } from './ShipmentFormPageLayout'
import { ShipmentFormProvider } from './ShipmentFormProvider'
import { useShipmentFormState } from './store'
import { type ShipmentFormModel } from './types'
import { mapLoadDtoToAwardDto, mapShipmentFormModelToLoadDTO } from './utils'

const useLoadId = () => {
  const { loadId } = useParams()
  return loadId
}

export const ShipmentEditPage = () => {
  const loadId = useLoadId()
  const loadQuery = useLoad(loadId)
  const isLoading = loadQuery.isLoading
  const load = loadQuery.data

  if (isLoading) return <Loading />

  return (
    <ShipmentFormProvider init={{ load }}>
      <ShipmentEditPageContent />
    </ShipmentFormProvider>
  )
}

const ShipmentEditPageContent = () => {
  const award = useAwardLoads()
  const { locations, initialLoad: load } = useShipmentFormState()
  const update = useUpdateShipment(load.loadId)
  const navigate = useFvNavigate()
  const ratesPage = useLoadRatesPage()

  const handleSave = async (model: ShipmentFormModel) => {
    if (load.status === 'pending') {
      await update.mutateAsync(
        mapShipmentFormModelToLoadDTO(model, load.workflow),
      )
      return navigate(ratesPage(load))
    } else {
      const dto = mapLoadDtoToAwardDto(
        model,
        load.loadId,
        load.selectedQuote._id,
        true,
        load.schedulePickup,
      )
      await award.mutateAsync(dto)
      return navigate(routes.details(load.loadId))
    }
  }
  return (
    <ShipmentFormPageLayout
      sidebarContent={
        <div className="flex-1 h-full flex">
          <LoadSummary
            load={{
              ...load,
              locations: locations.map(buildShipmentLocation),
            }}
          />
        </div>
      }
    >
      <ShipmentForm
        isSaving={award.isLoading || update.isLoading}
        onSave={handleSave}
      />
    </ShipmentFormPageLayout>
  )
}
