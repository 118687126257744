import clsx from 'clsx'

import { TextAreaField } from '@fv/client-components'
import { addressFuncs, useFormModel, validateEmailList } from '@fv/client-core'
import { type ClientAddressLookupResult } from '@fv/client-types'
import { type EquipmentAccessorialType, type Workflow } from '@fv/models'
import { toggleArrayItem } from '@fv/models/core'

import { AddressInput } from '../../components/inputs/AddressInput'
import CheckboxField from '../../components/inputs/CheckboxField'
import { ContactPhoneInput } from '../../components/inputs/ContactPhoneInput'
import {
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import { FtlLocationAccessorials } from '../../components/inputs/LocationAccessorials'
import { ShipTypeSelector } from '../../components/inputs/ShipTypeSelector'
import { AddressBookButton } from '../addresses/AddressBookButton'
import { HideForVendor } from '../auth/HasRole'
import { useRole } from '../auth/hooks'
import { ShareShipmentWith } from '../book/ShareShipmentWith'
import { useAccountSettings } from '../settings/account-settings/hooks'
import { ShipmentFormSection } from '../shipment-form/ShipmentFormSection'
import { DockTimeInput } from './DockTimeInput'
import { LocationAccessorialForm } from './LocationAccessorialForm'
import {
  type PillDateProps,
  ShipmentLocationPillHeader,
} from './ShipmentLocationPillHeader'
import {
  mapAddressBookToShipmentLocationFormModel,
  mapAtlasAddressToShipmentLocationFormModel,
  type ShipmentLocationFormModel,
} from './types'

type Props = PillDateProps & {
  value: ShipmentLocationFormModel
  disabled?: boolean
  workflow: Workflow
  onChange: (value: ShipmentLocationFormModel) => void
  equipmentAccessorials?: EquipmentAccessorialType[]
  onEquipmentAccessorialChange?: (a: EquipmentAccessorialType[]) => void
  requireEmail?: boolean
}
export const ShipmentLocationForm = (props: Props) => {
  const {
    workflow,
    value,
    disabled,
    onChange,
    equipmentAccessorials,
    onEquipmentAccessorialChange,
    requireEmail,
    allowDateEdit,
  } = props
  const role = useRole()
  const accountSettings = useAccountSettings()
  const { register } = useFormModel({
    value,
    onChange,
  })

  const isFreight = workflow === 'ltl' || workflow === 'truckload'
  const isOrigin = value.type === 'origin'
  const stopTypeDisplay = isOrigin ? 'pickup' : 'delivery'

  const locAccessorials = value?.accessorials.map(a => a.key) ?? []
  const contactPhoneRequired =
    value.type === 'origin' ||
    value?.shipType === 'residential' ||
    locAccessorials.includes('notify') ||
    locAccessorials.includes('schedule')

  if (!location) return null

  function handleAddressSelect(address: ClientAddressLookupResult | null) {
    onChange({
      ...value,
      ...mapAddressBookToShipmentLocationFormModel(address, workflow, false),
    })
  }
  function handleAtlasSelect(atlasAddress: ClientAddressLookupResult | null) {
    onChange({
      ...value,
      ...mapAtlasAddressToShipmentLocationFormModel(
        atlasAddress,
        workflow,
        false,
      ),
    })
  }

  const prefixName = (name: string) => `${value.type}-${name}`

  return (
    <ShipmentFormSection
      className={clsx('bg-fv-gray-100', {
        'border-fv-green bg-fv-green-50': !isOrigin,
        'border-fv-orange bg-fv-orange-25': isOrigin,
      })}
      icon="map-marker"
      pillClassName={clsx({
        'border-fv-green bg-fv-green-50 border-2': !isOrigin,
        'border-fv-orange bg-fv-orange-25 border-2': isOrigin,
      })}
      iconClassName={clsx({
        'text-fv-orange': isOrigin,
        'text-fv-green': !isOrigin,
      })}
      pillContents={
        <ShipmentLocationPillHeader
          value={value}
          workflow={workflow}
          onChange={onChange}
          allowDateEdit={allowDateEdit}
          {...(props.allowDateEdit && {
            allLocations: props.allLocations,
          })}
        />
      }
      title={isOrigin ? 'Pick up' : 'Deliver'}
    >
      <div className="form-row">
        <InputGroupWrapper
          className="b1450:basis-4/12 basis-4/12"
          label="Company"
          name={prefixName('company')}
          required
        >
          <div className="input-group__prepend">
            <AddressBookButton
              context={workflow}
              disabled={disabled}
              setAddress={handleAddressSelect}
            />
          </div>
          <AddressInput
            context={workflow}
            allowFreeText
            formatAddress={x => x.company ?? 'NA'}
            formatOptionLabel={addressFuncs.companyAddress}
            includeAddressBook
            includeAtlas={false}
            isClearable={false}
            location={value}
            name={prefixName('company')}
            onInputChange={company => onChange({ ...value, company })}
            onLocationChange={handleAddressSelect}
            placeholder="Company name"
            required
          />
        </InputGroupWrapper>

        <InputGroup
          className="b1450:basis-4/12 b1600:basis-4/12 basis-3/12"
          inputProps={{ ...register('address'), name: prefixName('address') }}
          inputType="text"
          label="Street address"
          required
        />

        <InputGroup
          className="b1600:basis-4/12 b1450:basis-4/12 basis-2/12"
          inputProps={{
            ...register('address2'),
            name: prefixName('address2'),
            placeholder: 'Address 2',
          }}
          inputType="text"
          label="Address 2"
        />

        <InputGroupWrapper
          className="b1600:basis-4/12 basis-3/12"
          label="City, state & zip code"
          name={prefixName('cityStateZip')}
          required
        >
          <AddressInput
            formatAddress={addressFuncs.cityStateZip}
            isClearable={false}
            location={value}
            name={prefixName('cityStateZip')}
            onLocationChange={handleAtlasSelect}
            placeholder="Zip/postal code"
            required
          />
        </InputGroupWrapper>

        {isFreight && (
          <ShipTypeSelector
            name={prefixName('shipType')}
            value={{
              shipType: value.shipType ?? 'business dock',
              accessorials: value.accessorials ?? [],
            }}
            onChange={({ shipType, accessorials }) => {
              onChange({
                ...value,
                shipType: shipType === '' ? 'business dock' : shipType,
                accessorials,
              })
            }}
            showDetails
            showLabels
            className="basis-4/12"
          />
        )}

        <InputGroup
          className="b1650:basis-2/12 b1600:basis-4/12 basis-3/12"
          inputProps={{
            ...register('contactName'),
            name: prefixName('contactName'),
          }}
          inputType="text"
          label="Contact name"
          required
        />

        <ContactPhoneInput
          className="b1650:basis-3/12 b1600:basis-4/12 b1350:basis-3/12 b1300:basis-4/12 b1200:basis-4/12 basis-2/12"
          disabled={disabled}
          name={prefixName('contactPhone')}
          onChange={values => onChange({ ...value, ...values })}
          required={contactPhoneRequired}
          values={value}
        />

        <InputGroup
          className="b1600:basis-4/12 b1350:basis-3/12 b1300:basis-8/12 b1200:basis-4/12 basis-3/12"
          inputProps={{
            ...register('contactEmail', {
              validate: e =>
                validateEmailList(e)
                  ? ''
                  : 'please enter a comma separated list of emails',
            }),
            name: prefixName('contactEmail'),
          }}
          inputType="text"
          required={requireEmail}
          label="Contact email"
        />

        {(workflow === 'ltl' || isOrigin) && (
          <>
            <DockTimeInput
              className="ready-close-time b1350:basis-3/12 b1200:basis-2/12 basis-2/12"
              label="Ready time"
              value={value}
              name="opensAt"
              onChange={values => onChange({ ...value, ...values })}
              required={isOrigin}
            />

            <DockTimeInput
              className="ready-close-time b1350:basis-3/12 b1200:basis-2/12 basis-2/12"
              label="Close time"
              value={value}
              name="closesAt"
              onChange={values => onChange({ ...value, ...values })}
              required={isOrigin}
            />
          </>
        )}

        <InputGroup
          className="b1600:basis-full b1300:flex-1 flex-1"
          inputProps={{ ...register('refNum'), name: prefixName('refNum') }}
          inputType="text"
          required={
            isOrigin
              ? accountSettings.booking?.shipperNumberRequired
              : accountSettings.booking?.poNumberRequired
          }
          label={
            role === 'vendor'
              ? 'Reference #'
              : isOrigin
                ? "Shipper's #"
                : 'Purchase order #'
          }
        />
      </div>

      <div className="w-full">
        <LocationAccessorialForm
          value={value}
          workflow={workflow}
          onChange={update => onChange({ ...value, ...update })}
        />
        {workflow === 'truckload' && (
          <FtlLocationAccessorials
            vertical
            className="flex gap-y-4 flex-col"
            accessorials={value.accessorials}
            inputNamePrefix={prefixName('locaccessorial')}
            openDatePicker={() => {
              console.log('open date picker')
            }}
            onChange={accessorials => onChange({ ...value, accessorials })}
          />
        )}

        {!isOrigin && workflow === 'ltl' && onEquipmentAccessorialChange && (
          <CheckboxField
            checked={equipmentAccessorials.some(
              a => a === 'protect-from-freezing',
            )}
            className="checkbox checkbox--vertical mb-3"
            disabled={disabled}
            label="Protect from freezing"
            name={prefixName('protect-from-freezing`')}
            onChange={e =>
              onEquipmentAccessorialChange(
                toggleArrayItem(
                  equipmentAccessorials,
                  'protect-from-freezing',
                  e.target.checked,
                ),
              )
            }
          />
        )}
      </div>
      <HideForVendor>
        <ShareShipmentWith
          className="my-4"
          location={value}
          onChange={({ copyBOL, shares }) =>
            onChange({ ...value, copyBOL, shares })
          }
        />
      </HideForVendor>

      <TextAreaField
        name={prefixName('instructions')}
        value={value.instructions}
        onChange={e => onChange({ ...value, instructions: e.target.value })}
        rows={3}
        placeholder={`Other ${stopTypeDisplay} instructions`}
        className="form-control h-auto mt-2"
      />
    </ShipmentFormSection>
  )
}
