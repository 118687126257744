import { clsx } from 'clsx'
import { Children, type ReactNode } from 'react'

import { Icon } from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'

type ErrorProps = PropsWithClassName<{
  errors: ReactNode
}>

export const BookingErrorNotice = ({ errors, className }: ErrorProps) => {
  return (
    <div
      className={clsx(
        'sticky top-0 z-50 border-b border-b-red-200  bg-red-50 px-8 py-2 ',
        className,
      )}
    >
      <ul className="mb-0">
        {Children.map(errors, (error, index) => (
          <li
            className="flex items-start border-b border-dashed border-red-300 py-2 last:border-0 last:pb-0"
            key={index}
          >
            <div className="relative mr-2 mt-[.4rem] flex h-2 w-2 flex-shrink-0">
              <div className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-600 opacity-75" />
              <div className="relative inline-flex h-full w-full rounded-full bg-red-400" />
            </div>
            <Icon
              icon="hand-paper"
              className="text-fv-gray-dark fa-fw"
              transform="down-2"
            />
            <p className="mb-0">{error}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}
