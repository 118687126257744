import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type CarrierStatus } from '@fv/client-types'
import { type Workflow } from '@fv/models'

import { apiUri } from '../../constants'
import { useStaticDataQuery } from '../../hooks/useStaticDataQuery'

export type SlimCarrier = {
  code?: string
  id: string
  name: string
  status: CarrierStatus
  supportsManualRates?: boolean
}

async function fetchCarrierList(workflow: Workflow): Promise<SlimCarrier[]> {
  const endpoint = `${apiUri}/carriers/list`
  const response = await fetchJson(
    endpoint,
    buildFetchOptionsWithAuth({
      query: { workflow },
    }),
  )

  if (response.ok) return response.json
  throw response.errorMessage
}

export function useCarrierList(workflow: Workflow) {
  return useStaticDataQuery(
    ['carrier-list', workflow],
    () => fetchCarrierList(workflow),
    {
      select: data =>
        data.slice().sort((a, b) => {
          const aName = a.name.trim().toLocaleLowerCase()
          const bName = b.name.trim().toLocaleLowerCase()
          return aName.localeCompare(bName)
        }),
    },
  )
}
