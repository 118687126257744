import { useMutation, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import chunk from 'lodash/chunk'
import pluralize from 'pluralize'
import { useCallback } from 'react'
import toast from 'react-hot-toast'

import {
  buildFetchOptionsWithAuth,
  downloadCSV,
  fetchJson,
} from '@fv/client-core'
import { type ContractedRate } from '@fv/models'

import { apiUri, supportMessage } from '../../../constants'
import { contractedRatesKeys, fuelTableKeys } from './queries'
import { loadedRatesTemplate } from './templates'
import { type FuelTable, type FuelTableEntry } from './types'

const MAX_UPLOADS = 20

async function addContractedRates(
  rates: ContractedRate[],
): Promise<ContractedRate[]> {
  const batchCount = Math.ceil(rates.length / MAX_UPLOADS)
  const batches: ContractedRate[][] = []
  const endpoint = `${apiUri}/contracted-rates/many`
  const responses: Array<{
    errorMessage?: { message: string }
    json: ContractedRate[]
    ok: boolean
  }> = []

  for (let i = 0; i < batchCount; i++) {
    const startIndex = i * MAX_UPLOADS
    const batch = rates.slice(startIndex, startIndex + MAX_UPLOADS)
    batches.push(batch)
  }

  for (const batch of batches) {
    const options = buildFetchOptionsWithAuth({
      body: JSON.stringify({ rates: batch }),
      method: 'POST',
    })

    const response = await fetchJson(endpoint, options)
    responses.push(response as (typeof responses)[number])
  }

  if (responses.every(r => r.ok)) {
    return responses.map(r => r.json).flat()
  }

  const errorResponse = responses.find(r => !r.ok)

  throw (
    errorResponse?.errorMessage ??
    new Error(`Unable to upload contracted rates, ${supportMessage}`)
  )
}

export function useAddContractedRates() {
  const queryClient = useQueryClient()

  return useMutation(addContractedRates, {
    onSuccess: rates => {
      // In case duplicates were updated instead of added
      setTimeout(() => {
        queryClient.invalidateQueries(contractedRatesKeys.all)
      }, 2500)

      toast.success(
        `Contracted ${pluralize('rate', rates.length)} ${
          !rates.length ? 'updated' : 'added'
        } successfully.`,
      )
    },
  })
}

async function removeContractedRates(rateIds: string[]) {
  const endpoint = `${apiUri}/contracted-rates`

  for (const chunkedRates of chunk(rateIds, 10000)) {
    const options = buildFetchOptionsWithAuth({
      body: JSON.stringify(chunkedRates),
      method: 'DELETE',
    })

    const response = await fetchJson(endpoint, options)

    if (!response.ok) throw response.errorMessage
  }

  return rateIds
}

export function useRemoveContractedRates() {
  const queryClient = useQueryClient()

  return useMutation(removeContractedRates, {
    onSuccess: rateIds => {
      toast.success(
        `Contracted ${pluralize('rate', rateIds.length)} removed successfully.`,
      )

      queryClient.setQueriesData(
        contractedRatesKeys.all,
        (prev?: { pages: { rates: ContractedRate[] }[] }) => {
          return {
            ...prev,
            pages: prev.pages.map(p => ({
              ...p,
              rates: p.rates.filter(r => !rateIds.includes(r._id)),
            })),
          }
        },
      )
    },
  })
}

async function updateContractedRate(
  rate: ContractedRate,
): Promise<ContractedRate> {
  const endpoint = `${apiUri}/contracted-rates/${rate._id}`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify(rate),
    method: 'PUT',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useUpdateContractedRate() {
  const updateRateCache = useUpdateRateCache()

  return useMutation(updateContractedRate, {
    onSuccess: rate => {
      updateRateCache(rate._id, rate)
      toast.success('Contracted rate updated successfully.')
    },
  })
}

async function extendContractedRates(dto: {
  endDate: Date
  rates: string[]
}): Promise<boolean> {
  const { endDate, rates } = dto
  const endpoint = `${apiUri}/contracted-rates/update-dates`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      endDate,
      rates,
    }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.ok
  throw response.errorMessage
}

export function useExtendContractedRates() {
  const updateRateCache = useUpdateRateCache()

  return useMutation(extendContractedRates, {
    onSettled: (res, error, { endDate, rates }) => {
      if (error) {
        toast.error('Unable to extend contracted rates.')
        return
      }

      rates.forEach(rateId =>
        updateRateCache(rateId, { endDate: endDate.toISOString() }),
      )

      toast.success('Contracted rates extended successfully.')
    },
  })
}

const useUpdateRateCache = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (rateId: string, props: Partial<ContractedRate> = {}) => {
      queryClient.setQueriesData(
        contractedRatesKeys.all,
        (prev: ContractedRate[] | { pages: { rates: ContractedRate[] }[] }) => {
          if (Array.isArray(prev)) {
            return prev.map(r => (r._id === rateId ? { ...r, ...props } : r))
          } else {
            return {
              ...prev,
              pages: prev.pages.map(p => ({
                ...p,
                rates: p.rates.map(r =>
                  r._id === rateId ? { ...r, ...props } : r,
                ),
              })),
            }
          }
        },
      )
    },
    [queryClient],
  )
}

async function addFuelTable(dto: {
  carrierId: string | null
  rows: FuelTableEntry[]
}): Promise<FuelTable> {
  const { carrierId, rows } = dto
  const endpoint = `${apiUri}/contracted-rates/fuel`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({ carrierId, rows }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json

  throw (
    response.errorMessage ??
    new Error(`Unable to upload fuel table, ${supportMessage}`)
  )
}

export function useAddFuelTable(carrierId: string | null) {
  const queryClient = useQueryClient()

  return useMutation(addFuelTable, {
    onSuccess: fuelTable => {
      queryClient.setQueryData(fuelTableKeys.carrier(carrierId), fuelTable)
      if (!fuelTable.carrierId) queryClient.invalidateQueries(fuelTableKeys.all)
      toast.success(`Fuel table uploaded successfully.`)
    },
  })
}

async function removeFuelTable(dto: {
  accountLevel: boolean
  fuelTableId: string
}): Promise<boolean> {
  const endpoint = `${apiUri}/contracted-rates/fuel/${dto.fuelTableId}`
  const options = buildFetchOptionsWithAuth({ method: 'DELETE' })
  const response = await fetchJson(endpoint, options)

  if (response.ok) return dto.accountLevel

  throw (
    response.errorMessage ??
    new Error(`Unable to remove fuel table, ${supportMessage}`)
  )
}

export function useRemoveFuelTable(carrierId: string | null) {
  const queryClient = useQueryClient()

  return useMutation(removeFuelTable, {
    onSuccess: (accountLevel: boolean) => {
      queryClient.setQueryData(fuelTableKeys.carrier(carrierId), null)

      if (accountLevel) queryClient.invalidateQueries(fuelTableKeys.all)
      else queryClient.invalidateQueries(fuelTableKeys.carrier(carrierId))

      toast.success(`Fuel table removed successfully.`)
    },
  })
}

export async function exportRates(
  rateIds: string[],
  filteredRates: ContractedRate[],
) {
  const contracts: ContractedRate[] = []
  rateIds.forEach(element => {
    const contract = filteredRates.find(item => item._id === element)
    contracts.push(contract)
  })
  let data = [
    loadedRatesTemplate[0],
    contracts
      .map(element =>
        [
          element.origin.postalCode,
          element.origin.state,
          element.origin.country,
          element.destination.postalCode,
          element.destination.postalCodeEnd,
          element.destination.state,
          element.destination.country,
          element.rate.amount,
          element.rate.currency,
          element.rate.rateType,
          element.minimumRate,
          element.distance,
          element.distanceUOM,
          element.rate.serviceId,
          dayjs(element.startDate).format('MM/DD/YYYY'),
          dayjs(element.endDate).format('MM/DD/YYYY'),
          element.contractNumber,
          `"${element.equipmentTypes}"`,
          element.fuelIncluded,
        ].join(','),
      )
      .join('\n'),
  ].join('\n')
  data = data.replaceAll('null', '')
  downloadCSV(data, 'selected_rates')
}
