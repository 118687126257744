import { useMutation } from '@tanstack/react-query'

import { shipperFetch } from '../../../utils/shipperFetch'

export type ExportListType = 'ready-completed' | 'in-process'

async function exportShipmentList({
  queryString,
  advancedExport,
  list,
}: {
  queryString: string
  advancedExport: boolean
  list: ExportListType
}) {
  const params = new URLSearchParams(queryString) // Ensure no leading `?`
  return await shipperFetch(`/shipments/${list}/export?${params.toString()}`, {
    body: {
      advanced: advancedExport,
    },
    method: 'POST',
  })
}

export function useExportList() {
  return useMutation(exportShipmentList)
}

async function exportUserList({ queryString }: { queryString: string }) {
  const params = new URLSearchParams(queryString) // Ensure no leading `?`
  return await shipperFetch(`/analytics/users-export?${params.toString()}`, {
    method: 'POST',
  })
}

export function useUserExportList() {
  return useMutation(exportUserList)
}
