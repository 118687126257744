import { type PropsWithChildren } from 'react'

import { FvLinkButton } from '@fv/client-components'
import { type SortDir } from '@fv/models'

import { type ContractedRateSort } from './types'

type SortLinkProps = {
  sort: ContractedRateSort
  currentSort: ContractedRateSort
  currentDir?: SortDir
  onClick: (sort: ContractedRateSort, sortDir: SortDir) => void
}
export const SortLink = ({
  sort,
  children,
  currentSort,
  currentDir,
  onClick,
}: PropsWithChildren<SortLinkProps>) => {
  return (
    <FvLinkButton
      theme="underlined"
      icon={
        currentSort === sort
          ? currentDir === 1
            ? 'arrow-up'
            : 'arrow-down'
          : undefined
      }
      iconClass="text-fv-orange"
      onClick={() =>
        onClick(sort, sort === currentSort ? (currentDir === 1 ? -1 : 1) : 1)
      }
    >
      {children}
    </FvLinkButton>
  )
}
