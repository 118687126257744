import clsx from 'clsx'
import { type ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import { FvLinkButton, Icon, type IconName } from '@fv/client-components'

import { List, ListItem } from '../../components/List'
import { useSubscriptionStatus } from '../../hooks/settings'
import { routes } from '../../routes'
import { HasFeature, HasPermission } from '../auth'
import { HasRole, HideForVendor } from '../auth/HasRole'
import { useIsCarrierAccount } from '../auth/hooks'

type ItemProps = {
  icon?: IconName
  label: ReactNode
  to?: string
  href?: string
}
const SettingsNavItem = ({ to, icon, href, label }: ItemProps) => {
  const { pathname } = useLocation()
  return (
    <ListItem
      custom
      className={clsx('standard-list__action !border-fv-blue-150', {
        active: to && pathname.startsWith(to),
      })}
    >
      <FvLinkButton icon={icon} iconClass="fa-fw" to={to} href={href}>
        {label}
      </FvLinkButton>
    </ListItem>
  )
}

type Props = {
  isDemoAccount: boolean
  isRegistered: boolean
}
export const SettingsNav = ({ isDemoAccount, isRegistered }: Props) => {
  const isCarrierAccount = useIsCarrierAccount()
  const isComplimentary = useSubscriptionStatus() === 'complimentary'

  if (isCarrierAccount) {
    return <nav className="settings-nav" />
  }

  return (
    <nav className="settings-nav relative">
      <List fcp0>
        <SettingsNavItem
          icon="toggle-on"
          to={routes.settings('user-preferences')}
          label="User preferences"
        />

        <HasPermission name="manage">
          <SettingsNavItem
            icon="toggle-on"
            to={routes.settings('account-settings')}
            label="Account settings"
          />
          <SettingsNavItem
            icon="usd-circle"
            to={routes.settings('bill-to')}
            label="Bill-to information"
          />
        </HasPermission>
        <HideForVendor>
          <SettingsNavItem
            icon="building"
            to="/settings/company-locations"
            label="Company locations"
          />
        </HideForVendor>
        <HasPermission name="manage">
          {isRegistered && (
            <SettingsNavItem
              to="/settings/company-users"
              label={
                <>
                  <span className="fa-layers fa-fw">
                    <Icon icon="male" className="fa-fw" transform="left-5" />
                    <Icon icon="female" transform="right-3" />
                  </span>
                  <span>Company users</span>
                </>
              }
            />
          )}

          <SettingsNavItem
            icon="paint-brush"
            to={routes.settings('branding')}
            label="Company branding"
          />
        </HasPermission>

        <HideForVendor>
          <SettingsNavItem
            icon="truck"
            to="/settings/carriers"
            label="Carriers"
          />
          <HasFeature name="vendorPortal">
            <HasPermission name="manage">
              <SettingsNavItem
                icon="store-alt"
                to={routes.settings('vendors')}
                label="Vendors"
              />
            </HasPermission>
          </HasFeature>
        </HideForVendor>
        <SettingsNavItem
          icon="address-book"
          to={routes.settings('address-book')}
          label="Address book"
        />
        <HasRole role="vendor">
          {/* Shipper product catalog link is shown via "manage" */}
          <SettingsNavItem
            icon="box"
            to={routes.settings('product-catalog')}
            label="Product catalog"
          />
        </HasRole>
        <HasPermission name="manage">
          <SettingsNavItem
            icon="envelope"
            to={routes.settings('spot-quote')}
            label="Spot-quote groups"
          />

          <HasFeature name="truckLoad">
            <SettingsNavItem
              icon="money-check-alt"
              to={routes.contractedRates('list')}
              label="Contracted rates"
            />
          </HasFeature>
          <SettingsNavItem
            icon="box"
            to={routes.settings('product-catalog')}
            label="Product catalog"
          />
          <SettingsNavItem
            icon="tags"
            to={routes.settings('tag-management')}
            label="Tag management"
          />
        </HasPermission>
        <HideForVendor>
          <SettingsNavItem
            icon="key"
            to={routes.settings('integration')}
            label="API integration"
          />
        </HideForVendor>
        {!isDemoAccount && !isComplimentary && (
          <HasPermission name="manage">
            <SettingsNavItem
              icon="credit-card-blank"
              to={routes.settings('subscription')}
              label="Subscription"
            />
          </HasPermission>
        )}
      </List>
    </nav>
  )
}
