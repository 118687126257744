import { useQuery } from '@tanstack/react-query'

import { type VendorShipperAccountResponse } from '@fv/models'
import { type DTO } from '@fv/models/core'

import { shipperFetch } from '../../utils/shipperFetch'

export const vendorShipperQueryKeys = {
  all: ['all'],
}
export const useVendorShippers = () =>
  useQuery(vendorShipperQueryKeys.all, () =>
    shipperFetch<DTO<Array<VendorShipperAccountResponse>>>(`/vendor/shippers`),
  )
