import clsx from 'clsx'
import { type ChangeEvent, useMemo } from 'react'

import { FvButton, Icon, Tooltip } from '@fv/client-components'
import { type ListShipment } from '@fv/client-types'
import { type EquipmentType } from '@fv/models'

import CheckboxField from '../../components/inputs/CheckboxField'
import { ListItem } from '../../components/List'
import MessageField from '../../components/messaging/MessageField'
import MessageList from '../../components/messaging/MessageList'
import { Price } from '../../components/shared/Price'
import { useUnreadMessageIds } from '../../hooks/messages'
import { useTypeNames } from '../../hooks/settings'
import { type QuoteRequestResponse } from '../../hooks/shipments/useQuoteRequestResponses'
import { useRetractTender } from '../../hooks/shipments/useRetractTender'
import { HasPermission, usePermissions } from '../auth'
import { ChargeList } from '../charges/ChargeList'
import { ChargesSlider } from '../charges/ChargesSlider'
import { ChargesToggle } from '../charges/ChargesToggle'
import { type ChargeListQuote } from '../charges/types'
import { useChargeList } from '../charges/useChargeList'
import { CarrierSelectForm } from './CarrierSelectForm'
import { RetractTenderForm } from './RetractTenderForm'

type AltEquipmentTypeIconProps = {
  alternateTypes?: EquipmentType[]
  quotedEquipmentType?: EquipmentType
}

const AltEquipmentTypeIcon = ({
  alternateTypes = [],
  quotedEquipmentType,
}: AltEquipmentTypeIconProps) => {
  const { equipmentName } = useTypeNames()
  const altTypeIndex = quotedEquipmentType
    ? (alternateTypes?.indexOf(quotedEquipmentType) ?? -1)
    : -1

  if (!quotedEquipmentType || altTypeIndex < 0) return null

  return (
    <Tooltip label={equipmentName(quotedEquipmentType)}>
      <span className="legend-icon legend-icon--tooltip">
        A{altTypeIndex + 1}
      </span>
    </Tooltip>
  )
}

type MiniQuoteListItemProps = {
  awardedQuoteId?: string
  isActive: boolean
  isChecked: boolean
  load: ListShipment
  quote: QuoteRequestResponse
  readOnly?: boolean
  setActiveQuoteId: (id: string | undefined) => void
  setSelectedQuoteId: (id: string | undefined) => void
  canViewMessages?: boolean
}

export const MiniQuoteListItem = ({
  awardedQuoteId,
  isActive,
  isChecked,
  load,
  quote,
  readOnly = false,
  canViewMessages = true,
  setActiveQuoteId,
  setSelectedQuoteId,
}: MiniQuoteListItemProps) => {
  const { canBook } = usePermissions()
  const { equipment, loadId, status: loadStatus } = load

  const { amount, carrierId, currency, id: quoteId, method, name } = quote
  const chargeQuote: ChargeListQuote = {
    _id: quote.id,
    amount: quote.amount,
    currency: quote.currency,
    providerName: quote.providerName,
    charges: quote.charges,
    isSpot: quote.isSpot,
  }
  const chargeList = useChargeList(chargeQuote)

  const { canRetract } = useRetractTender()
  const unreadMessageIds = useUnreadMessageIds({ carrierId, loadId })
  const hasUnreadMessages = unreadMessageIds.length > 0
  const isLoadCanceled = loadStatus === 'canceled'
  const isLoadAwarded = loadStatus === 'awarded'
  const isQuoteSelected = quoteId === awardedQuoteId
  const showRetender = isLoadAwarded && !isQuoteSelected
  const showRetract = useMemo(
    () => isChecked && canRetract(load) && isQuoteSelected && !readOnly,
    [isChecked, canRetract, load, isQuoteSelected, readOnly],
  )
  const showAward = useMemo(() => {
    if (
      !isChecked ||
      isLoadCanceled ||
      loadStatus === 'delivered' ||
      loadStatus === 'picked-up' ||
      showRetract
    )
      return false

    return loadStatus === 'pending' || !isQuoteSelected
  }, [isLoadCanceled, isChecked, loadStatus, showRetract, isQuoteSelected])

  const label = isQuoteSelected ? (
    <>
      <Icon icon="trophy" className="text-fv-orange" />
      <span>{name}</span>
    </>
  ) : (
    name
  )

  return (
    <ListItem
      className={clsx(
        'flex-wrap',

        isChecked &&
          ' bg-fv-blue-25 !py-4 !px-2 !border !border-fv-blue-200 relative z-20 first:mt-0 !border-r-0 !border-l-0 -mx-2 mb-4',
      )}
    >
      <div className="flex items-center w-full gap-x-2">
        {(readOnly || !canBook) && (
          // add padding and negative margin so icon does not get cut off vertically by truncate
          <div className="flex-1 truncate py-2 -my-2">{label}</div>
          // end
        )}

        {canBook && !readOnly && (
          <CheckboxField
            className="checkbox flex-1"
            checked={isChecked}
            disabled={!amount}
            label={label}
            labelClassName="truncate"
            name={quoteId}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target
              // Can't deselect awarded quote
              if (!checked && isQuoteSelected) return
              setSelectedQuoteId(checked ? quoteId : awardedQuoteId)
            }}
          />
        )}

        <AltEquipmentTypeIcon
          alternateTypes={equipment.alternateTypes}
          quotedEquipmentType={quote.equipmentType}
        />

        <div className="flex flex-nowrap items-center">
          {Boolean(amount) && (
            <HasPermission name="view-rates">
              <Price
                className="shipment-list-pricing__price cursor-pointer min-w-32 justify-end"
                amount={amount}
                currency={currency}
              />
            </HasPermission>
          )}
          <div
            className={clsx(
              'divide-fv-gray flex items-center [&>*]:px-2 ml-2 divide-x',
            )}
          >
            <ChargesToggle
              chargeState={chargeList}
              onClick={() => {
                chargeList.toggleViewing()
                setActiveQuoteId(null)
              }}
            />

            {canViewMessages && (
              <Tooltip label="View or send messages">
                <div className="relative">
                  {hasUnreadMessages && (
                    <div className="shipment-list-pricing__unread-indicator animate-pulse" />
                  )}

                  <FvButton
                    fw
                    icon={
                      hasUnreadMessages ? 'comment-alt-dots' : 'comment-alt'
                    }
                    transform="down-1.5"
                    onClick={() => {
                      setActiveQuoteId(isActive ? undefined : quoteId)
                      chargeList.setIsViewing(false)
                    }}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>

      {showRetract && <RetractTenderForm loadId={loadId} />}

      {showAward && (
        <CarrierSelectForm
          onCancel={() => {
            setSelectedQuoteId(awardedQuoteId)
          }}
          retender={showRetender}
          load={load}
          quote={quote}
        />
      )}

      {isActive && (
        <div className="my-4 w-full">
          <MessageField
            carrierId={carrierId}
            loadId={loadId}
            carrierName={name}
          />

          <MessageList
            carrierId={carrierId}
            isApiMessages={method === 'api'}
            loadId={loadId}
          />
        </div>
      )}
      {chargeList.isViewing && (
        <ChargeList
          isChecked={isChecked}
          chargeState={chargeList}
          load={load}
          quote={chargeQuote}
        />
      )}

      <ChargesSlider chargeState={chargeList} load={load} />
    </ListItem>
  )
}
