import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { showApiError } from '@fv/client-core'
import {
  type CredentialDetail,
  type TestLocationProvisionResponse,
} from '@fv/client-types'
import { type CarrierLocationStatusType } from '@fv/models'

import { shipperFetch } from '../../utils/shipperFetch'
import { provisionerQueryKeys } from './queries'

type AccountFilter = {
  accountId: string
}
type AccountLocationFilter = AccountFilter & {
  locationId: string
}

const putAccount = (
  dto: AccountFilter & {
    model: {
      details: CredentialDetail[]
    }
  },
) =>
  shipperFetch<unknown>(`/provisioner/account/${dto.accountId}`, {
    method: 'PUT',
    body: dto.model,
  })

export const useUpdateAccountProvisioning = () => {
  const queryClient = useQueryClient()
  return useMutation(putAccount, {
    onError: e => {
      showApiError('Error updating details', e)
    },
    onSuccess: () => {
      toast.success('Saved details')
    },
    onSettled(result, error, variables) {
      queryClient.invalidateQueries(
        provisionerQueryKeys.account(variables.accountId),
      )
    },
  })
}
const putLoc = (
  dto: AccountLocationFilter & {
    model: {
      status: CarrierLocationStatusType
      details: CredentialDetail[]
    }
  },
) =>
  shipperFetch<unknown>(
    `/provisioner/account/${dto.accountId}/locations/${dto.locationId}`,
    {
      method: 'PUT',
      body: dto.model,
    },
  )

export const useUpdateLocationProvisioning = () => {
  const queryClient = useQueryClient()
  return useMutation(putLoc, {
    onError: e => {
      showApiError('Error updating location', e)
    },
    onSuccess: () => {
      toast.success('Saved location')
    },
    onSettled(result, error, variables) {
      queryClient.invalidateQueries(
        provisionerQueryKeys.account(variables.accountId),
      )
    },
  })
}

export const useTestLocation = () => {
  return useMutation((filter: AccountLocationFilter) =>
    shipperFetch<TestLocationProvisionResponse>(
      `/provisioner/account/${filter.accountId}/locations/${filter.locationId}/test`,
      {
        method: 'POST',
      },
    ),
  )
}
