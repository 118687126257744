import { useEffect, useState } from 'react'

import { CheckboxField, FvButton, ValidatedForm } from '@fv/client-components'
import { type ContractedRatesFilter } from '@fv/models'

import { InputGroup } from '../../../components/inputs/InputGroup'
import { Loading } from '../../../components/Loading'
import { useContractedRateCarriers } from './queries'

type Props = {
  onSubmit: (filter: ContractedRatesFilter) => void
  value: ContractedRatesFilter
}
export const ContractedRatesListFilter = ({ value, onSubmit }: Props) => {
  const [localData, setLocalData] = useState<ContractedRatesFilter>({
    ...value,
  })
  const carriersQuery = useContractedRateCarriers()
  const carrierOptions =
    carriersQuery.data
      ?.map(d => ({
        text: d.carrierName ?? '',
        value: d.carrierId,
      }))
      .filter(v => !!v.text) ?? []

  const onChange = (
    field: keyof ContractedRatesFilter,
    value: string | boolean,
  ) => {
    setLocalData(d => ({ ...d, [field]: value }))
  }

  useEffect(() => {
    setLocalData(value)
  }, [value])

  const handleSubmit = () => {
    onSubmit({
      ...localData,
    })
  }

  if (carriersQuery.isLoading) {
    return <Loading />
  }
  return (
    <ValidatedForm
      onValidSubmit={handleSubmit}
      className="mb-5 flex flex-wrap @cb1100:gap-2 @cb1100:flex-nowrap gap-y-3 bg-fv-green-50 p-4 border border-fv-green-300"
    >
      <InputGroup
        className="mb-0 basis-1/3 truncate @cb1100:w-auto @cb1100:flex-none @cb1100:max-w-72"
        inputType="select"
        inputProps={{
          options: [{ text: 'All carriers', value: '' }, ...carrierOptions],
          name: '',
          onChange: e => onChange('carrierId', e.target.value),
          value: localData.carrierId,
        }}
      />
      <InputGroup
        className="mb-0 basis-1/3 @cb1100:flex-1 px-2 @cb1100:px-0 @cb1100:max-w-60"
        inputType="text"
        inputProps={{
          name: '',
          placeholder: 'Origin city, state or zip',
          value: localData.originSearch,
          onChange: e => onChange('originSearch', e.target.value),
        }}
      />
      <InputGroup
        className="mb-0 basis-1/3 @cb1100:flex-1 @cb1100:max-w-60"
        inputType="text"
        inputProps={{
          name: '',
          placeholder: 'Destination city, state or zip',
          value: localData.destSearch,
          onChange: e => onChange('destSearch', e.target.value),
        }}
      />
      <InputGroup
        className="mb-0 basis-1/3 @cb1100:flex-1 @cb1100:max-w-60"
        inputType="text"
        inputProps={{
          name: '',
          placeholder: 'Contract number',
          value: localData.contractSearch,
          onChange: e => onChange('contractSearch', e.target.value),
        }}
      />

      <CheckboxField
        className="@cb1100:flex-none flex-1 justify-center border border-fv-gray bg-white @cb1100:border-none @cb1100:bg-transparent mx-2"
        name="showExpired"
        checked={localData.showExpired}
        onChange={e => onChange('showExpired', e.target.checked)}
        label="Show expired"
      />

      <FvButton
        className="!bg-fv-green-100 !border-fv-green-500 ml-auto basis-1/3 justify-center @cb1100:justify-normal @cb1100:basis-0 @cb1100:ml-0"
        icon="search"
        theme="default"
        iconClass="text-fv-green"
        type="submit"
      >
        Search
      </FvButton>
    </ValidatedForm>
  )
}
