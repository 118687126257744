import { useMutation } from '@tanstack/react-query'

import { type UpdateVendorCurrentShipperRequest } from '@fv/models'

import { shipperFetch } from '../../utils/shipperFetch'

const putVendorCurrentShipper = async (
  model: UpdateVendorCurrentShipperRequest,
) =>
  shipperFetch('/vendor/shipper', {
    method: 'PUT',
    body: model,
  })
export const useUpdateVendorCurrentShipper = () => {
  return useMutation(putVendorCurrentShipper, {
    onSuccess() {
      window.location.reload()
    },
  })
}
