import { ObjectId } from '@freightview/object-id'
import omit from 'lodash/omit'
import sum from 'lodash/sum'

import {
  type AwardLoadsDTO,
  type HazardousDetailFormModel,
  type LoadHandlingUnit,
  type MilitaryTime,
  type QuoteRequestDTO,
} from '@fv/client-types'
import {
  type FreightClass,
  type HazardClass,
  type HazardSubClass,
  type LoadHandlingUnitDTO,
  type PackagingType,
  type PackingGroup,
  type UpsertLoadDTO,
  type Workflow,
} from '@fv/models'
import { type DTO } from '@fv/models/core'

import { buildHandlingUnitDTO } from '../commodities/load-items/loadItemHelpers'
import { buildLoadCustomsBrokerDTO } from '../customsBroker/brokerTypes'
import { buildShipmentEquipmentDTO } from '../shipment-equipment-form/types'
import { buildLoadLocationDto } from '../shipment-location-form/types'
import { type ShipmentFormModel } from './types'

// TODO need to move the handling unit mapping closer to the model definitions ... but handling units are a bit all over the place right now
const mapHazDto = (
  haz: HazardousDetailFormModel,
): LoadHandlingUnitDTO['hazardous'][number] => ({
  ...haz,
  hazmatId: haz.hazmatId ?? '',
  primaryClass: (haz.primaryClass ?? '') as HazardClass,
  secondaryClass: (haz.secondaryClass ?? '') as HazardSubClass,
  tertiaryClass: (haz.tertiaryClass ?? '') as HazardSubClass,
  packingGroup: haz.packingGroup as PackingGroup,
})
const mapLegacyToNew = (hu: LoadHandlingUnit): LoadHandlingUnitDTO => {
  return {
    ...omit(hu, 'freightClass'),
    type: hu.type as PackagingType,
    hazardous: hu.hazardous?.map(mapHazDto),
    description: hu.description ?? '',
    contains: hu.contains?.map(c => ({
      ...omit(c, 'freightClass'),
      hazardous: c.hazardous?.map(mapHazDto),
      type: c.type as PackagingType,
      ...(c.freightClass && {
        freightClass: Number(c.freightClass) as FreightClass,
      }),
    })),
    ...(hu.freightClass && {
      freightClass: Number(hu.freightClass) as FreightClass,
    }),
  }
}

export const mapShipmentFormModelToLoadDTO = (
  model: ShipmentFormModel,
  workflow: Workflow,
): DTO<UpsertLoadDTO> => {
  return {
    items: model.items.map(i =>
      mapLegacyToNew(buildHandlingUnitDTO(workflow)(i)),
    ),
    locations: model.locations.map(buildLoadLocationDto),
    bolNumber: model.bolNum,
    equipment: buildShipmentEquipmentDTO(
      model.equipment,
      model.items,
      workflow,
    ),
    isLiveLoad: true,
    quoteRequestId: new ObjectId().toHexString(),
    workflow,
    ...(model.customsBrokers && {
      customsBrokers: [buildLoadCustomsBrokerDTO(model.customsBrokers)],
    }),
    ...(model.emergencyContact && {
      emergencyContact: model.emergencyContact,
    }),
  }
}

/* TODO VP - find better place for this */
export const mapLoadDtoToAwardDto = (
  model: ShipmentFormModel,
  loadId: string,
  quoteId: string,
  createBOL: boolean,
  schedulePickup: boolean,
): AwardLoadsDTO => {
  return {
    createBOL,
    schedulePickup,
    isEdit: false, // TODO VP - isEdit really needed on the award loads model?
    ...(model.billTo && {
      billTo: {
        // TODO VP can we share this mapping somewhere?
        ...model.billTo,
        contactName: model.billTo?.contactName ?? '',
      },
    }),
    bolNumber: model.bolNum,
    ...(model.customsBrokers && {
      customsBroker: [buildLoadCustomsBrokerDTO(model.customsBrokers)],
    }),
    emergencyContact: model.emergencyContact,
    loads: [
      {
        loadId,
        quoteId,
        equipment: {
          accessorials: model.equipment.accessorials || [],
        },
        items: model.items.map(buildHandlingUnitDTO(model.workflow)),
        deliveryOption: model.deliveryOption,
      },
    ],
    locations: model.locations.map(buildLoadLocationDto).map(l => ({
      // TODO VP can we share this mapping somewhere?
      ...l,
      lat: 0,
      lng: 0,
      opensAt: l.opensAt as MilitaryTime,
      closesAt: l.closesAt as MilitaryTime,
      stopDate: l.stopDate,
    })),
    rateLocationType: model.rateLocationType,
    isFreightCollect: model.isFreightCollect,
    tags: [],
  }
}

export const mapLoadDtoToQuoteRequestDto = (
  loadId: string,
  model: ShipmentFormModel,
): QuoteRequestDTO => ({
  ...(model.billTo && {
    billTo: {
      ...model.billTo,
      contactName: model.billTo?.contactName ?? '',
    },
  }),
  deliveryOption: model.deliveryOption,
  equipment: [
    {
      loadId,
      mode: model.workflow,
      declaredValueCurrency: 'usd',
      accessorials: model.equipment.accessorials || [],
      type: model.equipment.type,
      alternateTypes: model.equipment.alternateTypes,
      isHazardous: model.items.some(i => !!i.hazardous),
      weight: sum(model.items.map(i => i.weight ?? 0)),
      weightUOM: 'lbs',
      description: model.equipment.description,
    },
  ],
  isFreightCollect: model.isFreightCollect,
  isLiveLoad: model.isLiveLoad,
  locations: model.locations.map(buildLoadLocationDto).map(l => ({
    ...l,
    lat: 0,
    lng: 0,
    opensAt: l.opensAt as MilitaryTime,
    closesAt: l.closesAt as MilitaryTime,
    stopDate: l.stopDate,
  })),
  orderNumber: '',
  rateLocationType: model.rateLocationType,
  workflow: model.workflow,
})
