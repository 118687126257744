import { type ShipmentLocation } from '@fv/client-types'

import { useAddresses } from '../addresses/useAddress'
import {
  buildShipmentLocationFormModel,
  type LocBuilderLoadProps,
} from './types'

export const useShipLocFormModelBuilder = (load: LocBuilderLoadProps) => {
  const addressQuery = useAddresses(load)
  const build = (loc: ShipmentLocation) =>
    buildShipmentLocationFormModel(loc, load, addressQuery.data)
  return {
    isLoading: addressQuery.loading,
    build,
  }
}
