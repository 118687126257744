import {
  adminUri,
  apiUri,
  appName,
  appVersion,
  getEnvValue,
  logrocketApp,
  marketingUri,
  olarkId,
} from '@fv/client-core'

export {
  adminUri,
  apiUri,
  appName,
  appVersion,
  logrocketApp,
  marketingUri,
  olarkId,
}
export const defaultPageSize = 20

export const countryCodes = {
  CA: 'ca',
  CAN: 'ca',
  MEX: 'mx',
  MX: 'mx',
  US: 'us',
}

export const refrigeratedEquipmentTypes = ['refrigerated-48', 'refrigerated-53']
export const supportMessage = 'please contact support if the issue persists.'
export const maxItemCount = 35
export const MAX_ALT_EQUIP = 5

// Feature flags
export const hasLegacyDetail = JSON.parse(
  getEnvValue('VITE_HAS_LEGACY_DETAIL') ?? 'false',
)

export type SettingsPageType =
  | 'integration'
  | 'user-preferences'
  | 'account-settings'
  | 'product-catalog'
  | 'tag-management'
  | 'change-password'
  | 'branding'
  | 'bill-to'
  | 'address-book'
  | 'spot-quote'
  | 'subscription'
  | 'vendors'
