import { type PropsWithClassName } from '@fv/client-types'

import { InputGroup } from '../../components/inputs/InputGroup'

type Props = {
  value: string
  onChange: (value: string) => void
}
export const ShipmentBolFormField = ({
  className,
  value,
  onChange,
}: PropsWithClassName<Props>) => {
  return (
    <InputGroup
      className={className}
      inputType="text"
      inputProps={{
        maxLength: 50,
        onChange: e => {
          onChange(e.target.value)
        },
        name: 'bol',
        value,
      }}
      label="BOL#"
      helpText="Enter your own BOL# or leave blank and Freightview will assign one for you."
    />
  )
}
