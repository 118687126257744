import { useQueryClient } from '@tanstack/react-query'

import { shipperFetch } from '../../../utils/shipperFetch'
import { useToastedMutation } from '../../../utils/useToastedMutation'
import { vendorKeys } from './queries'
import { type VendorFormModel } from './types'

const upsertVendor = (id?: string) => (model: VendorFormModel) =>
  shipperFetch('/vendors' + (id ? `/${id}` : ''), {
    body: model,
    method: id ? 'PUT' : 'POST',
  })

export const useUpsertVendor = (id?: string) => {
  const queryClient = useQueryClient()
  return useToastedMutation(upsertVendor(id), {
    onSettled: () => {
      queryClient.invalidateQueries(vendorKeys.all)
    },
    toastErrorPrefix: () => 'Error updating vendor',
    toastSuccess: () => 'Vendor updated successfully',
  })
}

const deleteVendor = (id: string) =>
  shipperFetch(`/vendors/${id}`, {
    method: 'DELETE',
  })

export const useDeleteVendor = () => {
  const queryClient = useQueryClient()
  return useToastedMutation(deleteVendor, {
    onSettled: () => {
      queryClient.invalidateQueries(vendorKeys.all)
    },
    toastErrorPrefix: () => 'There was an error removing vendor',
    toastSuccess: () => 'Vendor removed successfully',
  })
}
