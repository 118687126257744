import { type QuoteStopType } from '@fv/client-types'

import { type FormSectionProps } from '../../components/inputs/types'
import { ShipmentLocationForm } from '../shipment-location-form/ShipmentLocationForm'
import { useCarrierBookingFormSettings } from './bookFuncs'
import { useBookingFormCtx } from './BookingFormProvider'

type LocationFormProps = FormSectionProps & {
  type: QuoteStopType
}

export const BookingFormLocation = ({ disabled, type }: LocationFormProps) => {
  const { setLocationValues, toggleEquipmentAccessorial } = useBookingFormCtx(
    s => s.actions,
  )

  const location = useBookingFormCtx(s =>
    s.locations.find(x => x.type === type),
  )
  const requireEmail =
    useCarrierBookingFormSettings()?.requiresOriginEmail && type === 'origin'
  const equipmentAccessorials = useBookingFormCtx(s => s.equipmentAccessorials)
  const workflow = useBookingFormCtx(s => s.workflow)

  if (!location) return null

  return (
    <ShipmentLocationForm
      disabled={disabled}
      workflow={workflow}
      value={location}
      onChange={val => setLocationValues(type, val)}
      equipmentAccessorials={equipmentAccessorials}
      onEquipmentAccessorialChange={val =>
        toggleEquipmentAccessorial(
          'protect-from-freezing',
          val.includes('protect-from-freezing'),
        )
      }
      allowDateEdit={false}
      requireEmail={requireEmail}
    />
  )
}
