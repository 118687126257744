import { SliderPanel } from '@fv/client-components'
import { type ListShipment } from '@fv/client-types'

import { ChargeForm } from './ChargeForm'
import { type ChargeListState } from './types'

export const ChargesSlider = ({
  chargeState,
  load,
}: {
  chargeState: ChargeListState
  load: ListShipment
}) => {
  const { quote, isEditing, setIsEditing } = chargeState

  return (
    <SliderPanel
      className="flex flex-col"
      isOpen={isEditing}
      closePanel={() => setIsEditing(false)}
    >
      {!!quote && (
        <ChargeForm
          chargeState={chargeState}
          load={load}
          onCancel={() => setIsEditing(false)}
        />
      )}
    </SliderPanel>
  )
}
