import { useRef } from 'react'

import { FvButton, ValidatedForm } from '@fv/client-components'
import { type ShipmentLocation } from '@fv/client-types'
import { type EquipmentAccessorial, type LocationType } from '@fv/models'

import { Loading } from '../../components/Loading'
import {
  defaultStopDate,
  getCustomsBrokerLocationContext,
} from '../../utils/shipmentFuncs'
import { loadCanBeBooked } from '../book/bookFuncs'
import { unitIsHazardous } from '../commodities/load-items/loadItemHelpers'
import {
  LoadItemsForm,
  type LoadItemsFormRef,
} from '../commodities/load-items/LoadItemsFormProvider'
import { ShipmentBrokerFormSection } from '../customsBroker/ShipmentBrokerFormSection'
import { EmergencyContactFormSection } from '../emergencyContact/EmergencyContactFormSection'
import { ShipmentEquipmentForm } from '../shipment-equipment-form/ShipmentEquipmentForm'
import {
  defaultDestination,
  defaultOrigin,
} from '../shipment-location-form/types'
import { useShipLocFormModelBuilder } from '../shipment-location-form/useLocFormModelBuilder'
import { AutofillPreviousShipment } from './AutofillPreviousShipment'
import { ShipmentBolFormField } from './ShipmentBolFormField'
import { ShipmentBookActions } from './ShipmentBookActions'
import { ShipmentFormLocations } from './ShipmentFormLocations'
import { ShipmentFormSection } from './ShipmentFormSection'
import { useShipmentFormActions, useShipmentFormState } from './store'
import { type ShipmentFormModel } from './types'

type Props = {
  isSaving: boolean
  onSave?: (model: ShipmentFormModel) => void
  onPrintBOL?: (model: ShipmentFormModel) => void
  onSchedulePickup?: (model: ShipmentFormModel) => void
  onCreate?: (model: ShipmentFormModel) => void
}

export const ShipmentForm = (props: Props) => {
  /*  Setup */
  const { type, workflow, initialLoad, quote } = useShipmentFormState()
  const isBook = type === 'book'
  const isCreate = type === 'create'
  const isEdit = type === 'edit'
  const itemsFormRef = useRef<LoadItemsFormRef>(null)
  /* / Setup */

  const {
    setLocations,
    setItems,
    setEquipment: setEquip,
    setEmergencyContact: setEmergency,
    setCustomsBrokers: setBroker,
    setBolNum,
  } = useShipmentFormActions()
  const {
    locations,
    items,
    equipment: equip,
    emergencyContact: emergency,
    customsBrokers: broker,
    bolNum,
  } = useShipmentFormState()
  const { build, isLoading: isLoadingAddresses } = useShipLocFormModelBuilder(
    initialLoad ?? { workflow },
  )

  /* Computed */
  const isLoading = isLoadingAddresses
  const showBookingActions = isBook && loadCanBeBooked(initialLoad)
  const { required: requireBroker, location: internationalLocation } =
    getCustomsBrokerLocationContext(locations)
  const isHazard = items.some(unitIsHazardous)

  const buildFullModel = (): ShipmentFormModel => ({
    items,
    locations,
    bolNum,
    equipment: equip,
    workflow,
    ...(internationalLocation && { customsBrokers: broker }),
    ...(isHazard && { emergencyContact: emergency }),
  })
  /* /Computed */

  /* Handlers */
  const handleSubmit = async () => {
    if (isCreate && props.onCreate) {
      props.onCreate(buildFullModel())
    } else if (isEdit && props.onSave) {
      props.onSave(buildFullModel())
    } else if (isBook && props.onSchedulePickup) {
      props.onSchedulePickup(buildFullModel())
    }
  }
  const handlePrevLocChange =
    (type: LocationType) => (val: ShipmentLocation | null) => {
      const newVal =
        val !== null
          ? build(val)
          : type === 'origin'
            ? defaultOrigin()
            : defaultDestination()
      setLocations(
        locations.map(l =>
          l.type === type
            ? {
                ...newVal,
                ...(type === 'origin' && {
                  stopDate: defaultStopDate(type),
                  refNum: '',
                }),
              }
            : l,
        ),
      )
    }
  /* /Handlers */

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {isCreate && (
        <div className="bg-fv-blue-50 border-fv-blue-300 flex items-center border-b border-dashed px-6 py-4">
          <AutofillPreviousShipment
            workflow={workflow}
            onDestinationChange={handlePrevLocChange('destination')}
            onOriginChange={handlePrevLocChange('origin')}
            onProductChange={items => {
              setItems(items)
              itemsFormRef.current?.setItems(items)
            }}
          />
        </div>
      )}
      <ValidatedForm onValidSubmit={handleSubmit}>
        <div className="py-10 px-6 bg-white overflow-auto">
          <ShipmentFormLocations
            workflow={workflow}
            value={locations}
            onChange={setLocations}
            equipmentAccessorials={equip.accessorials.map(a => a.key)}
            onEquipmentAccessorialChange={types =>
              setEquip({
                ...equip,
                accessorials: types.map(
                  key => ({ key }) as EquipmentAccessorial,
                ),
              })
            }
          />
          {workflow === 'truckload' && (
            <ShipmentEquipmentForm value={equip} onChange={setEquip} />
          )}

          <LoadItemsForm
            ref={itemsFormRef}
            workflow={workflow}
            initialItems={items}
            step="booking"
            type="inline"
            onChange={setItems}
          />

          {isHazard && (
            <EmergencyContactFormSection
              value={emergency}
              onChange={setEmergency}
            />
          )}
          {internationalLocation && (
            <ShipmentBrokerFormSection
              workflow="ltl"
              loadLocation={internationalLocation}
              value={[broker]}
              onChange={v => setBroker(v[0])}
              requireDeclaredValue={false} // TODO VP
              required={requireBroker}
            />
          )}
          <ShipmentFormSection className="gray-container flex gap-x-2 !mb-0">
            <ShipmentBolFormField
              className="basis-full mb-0"
              value={bolNum}
              onChange={setBolNum}
            />
          </ShipmentFormSection>
        </div>
        <div className="footer-actions row-start-[-1] col-start-1 col-span-3">
          {(isCreate || isEdit) && (
            <FvButton
              type="submit"
              theme="primary"
              icon="check"
              fwd
              loading={props.isSaving}
            >
              {isCreate ? 'Create shipment' : 'Update shipment'}
            </FvButton>
          )}
          {showBookingActions && (
            <ShipmentBookActions
              isLoading={props.isSaving}
              onPrintBOL={() => props.onPrintBOL?.(buildFullModel())}
              items={items}
              locations={locations}
              workflow={workflow}
              quote={quote}
            />
          )}
        </div>
      </ValidatedForm>
    </>
  )
}
