import { type EquipmentAccessorial } from '@fv/client-types'
import { type EquipmentAccessorialType } from '@fv/models'

export function composeEquipmentAccessorialsDescription(
  getAccessorialName: (k: EquipmentAccessorialType) => string,
  accessorials?: EquipmentAccessorial[],
) {
  if (!accessorials?.length) return 'None Selected'

  const countHandler = (a: EquipmentAccessorial) =>
    `${'count' in a ? a.count + ' ' : ''}${getAccessorialName(a.key)}`

  const composerDict: {
    [key: string]: (a: EquipmentAccessorial) => string
  } = {
    'load-bars': countHandler,
    chains: countHandler,
    straps: countHandler,
    tarps: a =>
      `${'size' in a ? a.size + ' ft. ' : ''}${getAccessorialName(a.key)}`,
    temp: a => {
      if ('min' in a && 'max' in a && !!a.uom) {
        return `Temp between ${a.min} and ${a.max} \u00b0${a.uom.toUpperCase()}`
      }

      return 'Temp control'
    },
  }

  return accessorials
    .map(a => {
      return composerDict[a.key]
        ? composerDict[a.key](a)
        : getAccessorialName(a.key)
    })
    .join(' / ')
}
