import clsx from 'clsx'
import dayjs from 'dayjs'
import { type PropsWithChildren } from 'react'

import { Tooltip } from '@fv/client-components'
import { type SlimTrackDetail, type TrackingStatus } from '@fv/client-types'

const loadProgressMap: Record<TrackingStatus, number> = {
  pending: 0,
  'in-transit': 1,
  delivered: 2,
  'not-found': -1,
  error: -1,
}

const getTrackingSummary = (tracking: SlimTrackDetail): string | undefined => {
  const timeStr = dayjs(tracking.lastUpdatedDate).fromNow()

  if (tracking.currentStatus === 'pending') {
    return `Scheduled ~ Updated ${timeStr}`
  } else if (tracking.currentStatus === 'in-transit') {
    if (tracking.currentLocation?.city && tracking.currentLocation?.state) {
      return `In ${tracking.currentLocation.city}, ${tracking.currentLocation.state} ~ ${timeStr}`
    } else {
      return `In Transit ~ ${timeStr}`
    }
  } else if (tracking.currentStatus === 'delivered') {
    return `Delivered ~ ${dayjs(tracking.deliveryDateActual).format(
      'YYYY-MM-DD h:mm A',
    )}`
  }
  return undefined
}

const TrackLabel = ({
  tooltip,
  currentStatus,
  children,
}: PropsWithChildren<{
  tooltip?: string
  currentStatus: TrackingStatus
}>) => {
  const span = (
    <span
      className={clsx(
        'slideRight absolute -top-[.325rem] left-10 whitespace-nowrap py-2 px-2 bg-white z-30  border border-[#ff9a59] before:w-3 before:h-3 before:rotate-45 before:border before:bg-white before:border-l-0 before:border-b-0 before:absolute before:-right-[.4rem] before:top-1/2 before:-translate-y-1/2 before:border-[#ff9a59]',

        currentStatus === 'in-transit' &&
          '!border-[#b5ab48] before:!border-[#b5ab48] !left-10',
        currentStatus === 'delivered' &&
          '!border-fv-green !left-auto !right-12 before:!border-fv-green',
      )}
    >
      {children}
    </span>
  )
  return tooltip ? <Tooltip label={tooltip}>{span}</Tooltip> : span
}

type TrackStatusProps = SlimTrackDetail & {
  targetStatus: TrackingStatus
}

const TrackStatus = ({
  children,
  targetStatus,
  ...tracking
}: PropsWithChildren<TrackStatusProps>) => {
  const currentStatus = tracking.currentStatus
  const completed =
    loadProgressMap[currentStatus] > loadProgressMap[targetStatus]
  const active = currentStatus === targetStatus

  return (
    <li
      className={clsx(
        targetStatus,
        'relative w-8 h-8 bg-white border-fv-gray border-[.2rem] rounded-full z-10 block after:absolute after:top-1/2 after:left-1/2 after:w-4 after:h-4 after:rounded-full after:block after:-translate-x-1/2 after:-translate-y-1/2 ',
        completed && 'after:bg-fv-gray',
        active &&
          currentStatus === 'pending' &&
          '!border-[#ff9a59] after:bg-[#ff9a59]',
        active &&
          currentStatus === 'in-transit' &&
          '!border-[#b5ab48] after:bg-[#b5ab48]',
        active &&
          currentStatus === 'delivered' &&
          '!border-fv-green after:bg-fv-green',
        {
          active,
          completed,
        },
      )}
    >
      {active && (
        <TrackLabel
          tooltip={getTrackingSummary(tracking)}
          currentStatus={currentStatus}
        >
          {children}
        </TrackLabel>
      )}
    </li>
  )
}

type Props = {
  tracking: SlimTrackDetail | undefined
  onClick?: () => unknown
}

export const ShipmentTrackingBar = ({ onClick, tracking }: Props) => {
  const currentStatus = tracking?.currentStatus || 'pending'

  return (
    <ul
      className={clsx(
        'tracking-visual relative my-6 flex items-center justify-between before:left-0 before:absolute  before:w-full before:border-dotted before:border-t before:border-t-fv-gray-500 before:h-px after:h-[2px] after:absolute after:bg-fv-gray transition-all after:w-0 after:left-0',
        currentStatus,
        currentStatus === 'in-transit' && 'after:w-1/2',
        currentStatus === 'delivered' && 'after:w-[95%]',
      )}
      onClick={onClick}
    >
      <TrackStatus
        {...tracking}
        currentStatus={currentStatus}
        targetStatus="pending"
      >
        <span>Scheduled</span>
      </TrackStatus>
      <TrackStatus
        {...tracking}
        currentStatus={currentStatus}
        targetStatus="in-transit"
      >
        <span>In-transit</span>
      </TrackStatus>
      <TrackStatus
        {...tracking}
        currentStatus={currentStatus}
        targetStatus="delivered"
      >
        <span>Delivered</span>
      </TrackStatus>
    </ul>
  )
}
