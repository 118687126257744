import { type FullShipment } from '@fv/client-types'
import { type LoadEquipmentDto, type Workflow } from '@fv/models'

import { type LoadHandlingUnitFormModel } from '../commodities/load-items/types'

export type ShipmentEquipmentFormModel = Omit<LoadEquipmentDto, 'mode'>

export const buildShipmentEquipmentDTO = (
  model: ShipmentEquipmentFormModel,
  items: LoadHandlingUnitFormModel[],
  workflow: Workflow,
): LoadEquipmentDto => ({
  ...(workflow === 'truckload' && {
    ...model,
  }),
  ...(workflow !== 'truckload' && {
    type: workflow,
  }),
  mode: workflow,
  description: model.description || items.map(i => i.description).join(', '),
})

export const buildShipmentEquipmentFormModel = (
  load: FullShipment,
): ShipmentEquipmentFormModel => ({
  ...load.equipment,
  accessorials:
    (load.equipment.accessorials as LoadEquipmentDto['accessorials']) ?? [],
})
