import { useEffect, useRef } from 'react'

import { FvButton, FvLinkButton } from '@fv/client-components'

import { ActionList, ActionListItem } from '../../../components/ActionList'
import { maxItemCount } from '../../../constants'
import { ShipmentFormSection } from '../../shipment-form/ShipmentFormSection'
import { LoadItemForm } from './LoadItemForm'
import { isValidCommodity } from './loadItemHelpers'
import { useItemActions, useItemsState } from './loadItemsContext'
import { type LoadHandlingUnitFormModel } from './types'

export const InlineItemsFormWrapper = () => {
  const { items, disabled } = useItemsState()
  const { addItem, duplicateItem, updateItem, removeItem, clearItem } =
    useItemActions()
  const canAddItem = items.length < maxItemCount
  return (
    <div>
      {items.map((item, index) => (
        <ShipmentFormSection
          className="bg-fv-beer-xlight border-fv-beer-medium"
          key={item._id ?? `loaditemform-${index}`}
          pillClassName="bg-fv-beer-light border-fv-beer-medium border-2"
          cy="item-form"
          pillContents={
            <div className="flex items-center">
              <span className="first:pl-0">Handling unit {index + 1}</span>
              <HazardousPillNotification item={items[index]} />
              <ActionList>
                <ActionListItem
                  disabled={disabled}
                  icon="times"
                  onClick={() => clearItem(index)}
                  title="Clear fields"
                />
                <ActionListItem
                  disabled={disabled || !canAddItem}
                  icon="clone"
                  onClick={() => duplicateItem(index)}
                  title="Duplicate Item"
                />
                {items.length > 1 && (
                  <ActionListItem
                    disabled={disabled}
                    className="last:pr-0"
                    iconClass="text-fv-orange-bright"
                    icon="trash"
                    onClick={() => removeItem(index)}
                    title="Remove item"
                  />
                )}
              </ActionList>
            </div>
          }
        >
          <div className="form-row">
            <LoadItemForm
              value={items[index]}
              onChange={update => updateItem(index, update)}
            />
          </div>
        </ShipmentFormSection>
      ))}

      <FvButton
        disabled={!canAddItem}
        icon="plus"
        theme="underlined"
        onClick={addItem}
        className="block -mt-4 mb-12 border-t border-b border-dashed w-full border-fv-blue py-4 text-left"
      >
        Add another handling unit
      </FvButton>
    </div>
  )
}

type HazardousPillProps = {
  item: LoadHandlingUnitFormModel
}
const HazardousPillNotification = ({ item }: HazardousPillProps) => {
  const { setEditingContains, activateContains } = useItemActions()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.setCustomValidity('Missing Hazmat Info')
  }, [inputRef])

  if (
    !item.contains?.some(i => i.hazardous) ||
    !item.contains?.some(c => !isValidCommodity(c, 'booking'))
  ) {
    return null
  }

  // the pills are only shown in booking mode so step='booking' is safe
  const firstMissingIx = item.contains.findIndex(
    c => !isValidCommodity(c, 'booking'),
  )
  return (
    <div className="relative">
      <input
        className="pointer-events-none absolute left-0 top-0 -z-10 h-full w-full border-transparent bg-transparent"
        type="text"
        ref={inputRef}
      />
      <FvLinkButton
        icon="exclamation-triangle"
        className=""
        onClick={() => {
          setEditingContains(item)
          activateContains(firstMissingIx)
        }}
      >
        Additional Hazmat Info Required
      </FvLinkButton>
    </div>
  )
}
