import { type Dispatch, type SetStateAction, useState } from 'react'

import { FvLinkButton, Icon } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { usePermissions } from '../../../features/auth'
import { HideForVendor } from '../../../features/auth/HasRole'
import { useRole } from '../../../features/auth/hooks'
import DocumentList from '../../../features/documents/DocumentList'
import { MiniQuoteList } from '../../../features/mini-quote-list/MiniQuoteList'
import { useCarrierPublicSettings } from '../../../hooks/useCarrierPublicSettings'
import InfoBox from '../../InfoBox'
import { CancelationMessage } from '../../shared/CancelationMessage'
import { type PanelName } from '../LoadDetailPanel'
import ShipmentActions from '../ShipmentActions'
import { ActivePanelFooter } from './ActivePanelFooter'
import ActivePanelHeader from './ActivePanelHeader'

type ActiveLoadDetailProps = {
  load: FullShipment
  setActivePanel: Dispatch<SetStateAction<PanelName>>
}

export const ActiveLoadDetail = ({
  load,
  setActivePanel,
}: ActiveLoadDetailProps) => {
  const [isShowingRates, setShowingRates] = useState(false)
  const { canBook } = usePermissions()
  const role = useRole()

  const { bol, documents, pickup, selectedQuote, status } = load

  const isCanceled = status === 'canceled'
  const isUpdated = !!bol?.changedAfterDispatch
  const isApiDispatch = pickup?.method === 'api'
  const { carrierPublicSettings } = useCarrierPublicSettings(
    selectedQuote?.carrierId,
  )

  return (
    <div className="shipment-list-ancillary-details-ctn">
      <div className="shipment-list-ancillary-details overflow-auto">
        {isCanceled && (
          <InfoBox>
            <CancelationMessage
              carrierSettings={carrierPublicSettings}
              load={load}
            />
          </InfoBox>
        )}

        {isUpdated && !isCanceled && isApiDispatch && (
          <InfoBox>
            Your bill of lading has been updated. You need to call{' '}
            {carrierPublicSettings?.dispatchPhone && (
              <FvLinkButton href={`tel:${carrierPublicSettings.dispatchPhone}`}>
                {carrierPublicSettings.dispatchPhone}
              </FvLinkButton>
            )}{' '}
            and inform the carrier of your changes.
          </InfoBox>
        )}

        <ActivePanelHeader
          isShowingRates={isShowingRates}
          load={load}
          setActivePanel={setActivePanel}
          setShowingRates={setShowingRates}
        />

        {isShowingRates && (
          <div className="shipment-list-pricing">
            <MiniQuoteList key={selectedQuote?._id} load={load} />
          </div>
        )}

        {!isShowingRates && (
          <ShipmentActions
            setActivePanel={setActivePanel}
            shipment={load}
            showRatesPanel={() => setShowingRates(true)}
          />
        )}

        {!isShowingRates && (
          <div className="shipment-list-documents-ctn">
            <div className="divided-content divided-content--start">
              <p className="mb-0">
                <Icon icon="file" className="text-fv-gray-dark fa-fw" />
                <span>Shipment docs</span>
              </p>
              {!isCanceled && (
                <HideForVendor>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setActivePanel('share')
                    }}
                  >
                    <Icon icon="share" className="fa-fw" />
                    <span>Share</span>
                  </a>
                </HideForVendor>
              )}

              {canBook && role !== 'vendor' && (
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    setActivePanel('upload')
                  }}
                >
                  <Icon icon="upload" />
                  <span>Add / Delete</span>
                </a>
              )}
            </div>
            <hr className="mx-0 my-2 mb-4" />
            <DocumentList documents={documents} />
          </div>
        )}
      </div>

      <ActivePanelFooter
        isShowingRates={isShowingRates}
        load={load}
        setShowingRates={setShowingRates}
      />
    </div>
  )
}
