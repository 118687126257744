import { useMutation, useQueryClient } from '@tanstack/react-query'

import { showApiError } from '@fv/client-core'
import { type ListShipment, type Quote } from '@fv/client-types'
import { type UpdateQuoteDTO } from '@fv/models'

import { loadKeys } from '../../hooks/shipments/useLoad'
import { quoteKeys } from '../../hooks/shipments/useQuotes'
import { shipperFetch } from '../../utils/shipperFetch'

export function useUpdateQuote(loadId: string, quoteId: string) {
  const queryClient = useQueryClient()
  return useMutation(
    (dto: UpdateQuoteDTO): Promise<Quote> =>
      shipperFetch(`/shipments/${loadId}/quotes/${quoteId}`, {
        body: dto,
        method: 'PUT',
      }),
    {
      onSettled(quote, error) {
        if (error) {
          return showApiError('Unable to update quote', error)
        }

        queryClient.setQueryData<Quote[]>(
          quoteKeys.load(loadId, quote?.loadQuoteVersion),
          prev => prev?.map(p => (p._id === quoteId ? quote : p)),
        )

        queryClient.setQueryData<ListShipment>(loadKeys.id(loadId), prev => ({
          ...prev,
          selectedQuote:
            prev.selectedQuote?._id === quoteId ? quote : prev.selectedQuote,
        }))
      },
    },
  )
}
