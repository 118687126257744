import { type FormSectionProps } from '../../components/inputs/types'
import { useBookingFormCtx } from '../book/BookingFormProvider'
import { unitIsHazardous } from '../commodities/load-items/loadItemHelpers'
import { EmergencyContactFormSection } from './EmergencyContactFormSection'

export const LTLContact = ({ disabled }: FormSectionProps) => {
  const { setEmergencyContactValues } = useBookingFormCtx(s => s.actions)
  const contact = useBookingFormCtx(s => s.emergencyContact)
  const required = useBookingFormCtx(s => s.items.some(unitIsHazardous))

  if (!required) return null

  return (
    <EmergencyContactFormSection
      value={contact}
      required={required}
      disabled={disabled}
      onChange={setEmergencyContactValues}
    />
  )
}
