import dayjs from 'dayjs'
import partition from 'lodash/partition'
import { type ChangeEvent, useCallback, useState } from 'react'

import {
  CheckboxField,
  FvLinkButton,
  ValidatedForm,
} from '@fv/client-components'
import { addressFuncs } from '@fv/client-core'
import {
  type ClientAddressLookupResult,
  type QuoteStopType,
} from '@fv/client-types'

import {
  AddressInput,
  type AddressOption,
} from '../../components/inputs/AddressInput'
import {
  InputAdornment,
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import { ShipTypeSelector } from '../../components/inputs/ShipTypeSelector'
import { type FormSectionProps } from '../../components/inputs/types'
import { PillHeader } from '../../components/PillHeader'
import { RouteStopDate } from '../../components/QuoteRequest/RouteStopDate'
import { AddressBookButton } from '../addresses/AddressBookButton'
import { addressMapper } from '../addresses/addressFuncs'
import { requireRateLocation } from '../book/bookFuncs'
import { LocationAccessorialForm } from '../shipment-location-form/LocationAccessorialForm'
import { type ShipmentLocationFormModel } from '../shipment-location-form/types'
import { useQuotePageCtx, useQuotePageWorkflow } from './QuotePageProvider'

const dateFormat = 'ddd MMM D'

export const QuoteFormLocations = ({ disabled }: FormSectionProps) => {
  const [isPickingDate, setPickingDate] = useState(false)
  const actions = useQuotePageCtx(s => s.actions)
  const workflow = useQuotePageWorkflow()
  const isLtl = workflow === 'ltl'
  const isParcel = workflow === 'parcel'
  const load = useQuotePageCtx(s => s.load)
  const locations = useQuotePageCtx(s => s.locations)
  const [origin, destination] = locations

  const formRef = useCallback(
    (ref: HTMLFormElement | null) => actions.setFormRef(ref),
    [actions],
  )

  const organizeAddressOptions = (locs: AddressOption[]) => {
    const takeAddresses = 20
    const [addresses, cities] = partition(locs, loc => !!loc.value._id)
    return [...addresses.slice(0, takeAddresses), ...cities]
  }

  function getInputProps(
    stopType: QuoteStopType,
    name: keyof ShipmentLocationFormModel,
  ) {
    const stop = stopType === 'origin' ? origin : destination

    return {
      disabled,
      name: `${stopType}.${name}`,
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        actions.setLocationData(stopType, { [name]: e.target.value })
      },
      value: stop[name] as string,
    }
  }

  function setAddress(stopType: QuoteStopType) {
    return (location: ClientAddressLookupResult | null) => {
      if (disabled) return
      const data = addressMapper.dtoToFormStop(location, workflow)
      actions.setLocationData(stopType, data)
    }
  }

  return (
    <div className="ltl-quoting-origin b900:mb-12 flex flex-col basis-1/2 relative ">
      <ValidatedForm
        autoReportNextError={false}
        className="flex w-auto flex-1 flex-col"
        ref={formRef}
      >
        <PillHeader className="beer-container !border-2">
          Pickup and delivery
        </PillHeader>

        <div className="flex flex-wrap gap-x-2 px-4 pt-10 bg-fv-orange-25 border-fv-orange border">
          <InputGroupWrapper
            className="basis-full"
            label="Pick up from origin"
            name="origin"
            required
          >
            <InputAdornment position="start">
              <FvLinkButton
                disabled={!origin.postalCode}
                href={getMapUrl(origin)}
                className="!bg-fv-orange-50"
                icon="map-marker"
                iconClass="text-fv-orange"
                rel="noreferrer"
                target="_blank"
                fw
              />
            </InputAdornment>

            <AddressBookButton
              context={workflow}
              disabled={disabled}
              setAddress={setAddress('origin')}
            />

            <AddressInput
              context={workflow}
              includeAddressBook
              location={origin}
              filterOptions={organizeAddressOptions}
              name="origin"
              onLocationChange={setAddress('origin')}
              placeholder="Company, zip or postal code"
              required
            />
          </InputGroupWrapper>

          {isParcel && (
            <>
              <LocationAccessorialForm
                value={origin}
                disabled={disabled}
                workflow={load.workflow}
                onChange={value => actions.setLocationData('origin', value)}
              />

              <InputGroup
                className="basis-full"
                inputProps={{
                  name: 'schedulePickup',
                  onChange: actions.handleLoadChange,
                  value: `${load.schedulePickup ?? false}`,
                  options: [
                    { label: 'Daily Pickup', value: 'false' },
                    {
                      label: 'Schedule Pickup',
                      value: 'true',
                      disabled: load.isFreightCollect,
                    },
                  ],
                }}
                inputType="radio"
                label="Pickup Type"
              />
            </>
          )}

          <InputGroup
            className="basis-4/12"
            inputProps={{
              ...getInputProps('origin', 'stopDate'),
              onChange: undefined,
              onFocus: () => setPickingDate(true),
              readOnly: true,
              value: origin.stopDate
                ? dayjs(origin.stopDate).format(dateFormat)
                : '',
            }}
            inputType="text"
            label="Pick up on"
            required
            startContent={
              <InputAdornment position="start" icon="calendar-day" />
            }
          />

          <InputGroup
            className="flex-1"
            inputProps={getInputProps('origin', 'refNum')}
            inputType="text"
            label="Shipper's #"
            startContent={
              <InputAdornment
                icon="hashtag"
                iconClass="fa-fw text-fv-gray-dark"
                position="start"
              />
            }
          />

          {isLtl && (
            <ShipTypeSelector
              className="basis-full"
              value={origin}
              onChange={({ shipType, accessorials }) => {
                actions.setLocationData('origin', { shipType, accessorials })
              }}
              name="origin-shipType"
              label="Pickup location type"
            />
          )}

          {isLtl && (
            <LocationAccessorialForm
              value={origin}
              disabled={disabled}
              workflow={load.workflow}
              onChange={value => actions.setLocationData('origin', value)}
            />
          )}
        </div>

        <div className="flex flex-wrap bg-fv-green-50 p-4 -mt-px border border-fv-green">
          <InputGroupWrapper
            className="basis-full"
            label="Deliver to destination"
            name="destination"
            required
          >
            <InputAdornment position="start">
              <FvLinkButton
                disabled={!destination.postalCode}
                className="!bg-fv-green-50"
                fw
                href={getMapUrl(destination)}
                icon="map-marker"
                iconClass="text-fv-green"
                rel="noreferrer"
                target="_blank"
              />
            </InputAdornment>
            <div className="input-group__prepend">
              <AddressBookButton
                context={workflow}
                disabled={disabled}
                setAddress={setAddress('destination')}
              />
            </div>
            <AddressInput
              context={workflow}
              includeAddressBook
              location={destination}
              filterOptions={organizeAddressOptions}
              name="destination"
              onLocationChange={setAddress('destination')}
              placeholder="Company, zip or postal code"
              required
            />
          </InputGroupWrapper>

          {isLtl && (
            <ShipTypeSelector
              className="basis-full"
              value={destination}
              onChange={({ shipType, accessorials }) => {
                actions.setLocationData('destination', {
                  shipType,
                  accessorials,
                })
              }}
              name="destination-shipType"
              label="Delivery location type"
            />
          )}
          <div className="flex-col">
            <LocationAccessorialForm
              workflow={load.workflow}
              value={destination}
              onChange={value => actions.setLocationData('destination', value)}
            />

            {isLtl && (
              <CheckboxField
                checked={load.protectFromFreezing}
                className="checkbox checkbox--vertical"
                disabled={disabled}
                label="Protect from freezing"
                name="protectFromFreezing"
                onChange={actions.handleLoadChange}
              />
            )}

            <CheckboxField
              checked={load.isFreightCollect}
              className="checkbox checkbox--vertical"
              disabled={disabled}
              label="Customer routed shipment (bill recipient / 3rd party)"
              name="isFreightCollect"
              onChange={actions.handleLoadChange}
            />
          </div>

          {requireRateLocation(locations, load.isFreightCollect) && (
            <>
              <hr className="bg-[#c4e1c4]" />
              <InputGroup
                className="mt-4 basis-full"
                inputProps={{
                  disabled,
                  name: 'rateLocationType',
                  onChange: actions.handleLoadChange,
                  options: locations.map(x => ({
                    text: addressFuncs.streetAddress(x),
                    value: x.sequence === 0 ? 'origin' : 'destination',
                  })),
                  value: load.rateLocationType,
                }}
                inputType="select"
                label="Which account would you like to use to rate this shipment?"
              />
            </>
          )}
        </div>
      </ValidatedForm>

      <RouteStopDate
        onClose={() => setPickingDate(false)}
        isOpen={isPickingDate}
        sequence={0}
        onChange={data => {
          actions.setLocationData('origin', data)
        }}
        showStopDetailConfig={false}
        stops={locations}
      />
    </div>
  )
}

function getMapUrl(location: ShipmentLocationFormModel) {
  const { address, city, state, postalCode } = location
  const baseUrl = 'https://www.google.com/maps'
  const satelliteView = 'data=!3m1!1e3'
  let query = ''

  if (address) query += address + ','
  if (city) query += city + ','
  if (state) query += state + ','
  if (postalCode) query += postalCode
  if (!query) return `${baseUrl}/@/${satelliteView}`

  const encodedQuery = encodeURIComponent(query)
  return `${baseUrl}/place/${encodedQuery}/@/${satelliteView}`
}
