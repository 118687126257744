import clsx from 'clsx'
import {
  type ChangeEvent,
  type ChangeEventHandler,
  type MouseEvent,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react'

import { FvButton, TextField } from '@fv/client-components'
import {
  addressFuncs,
  defaultCloseTime,
  defaultOpenTime,
  emailRegExpMatcher,
} from '@fv/client-core'
import {
  type ClientAddressLookupResult,
  type MilitaryTime,
} from '@fv/client-types'
import { type Country } from '@fv/models'

import { ValidatedForm } from '../../components/inputs'
import { AddressInput } from '../../components/inputs/AddressInput'
import { apptRequiredKey } from '../../components/inputs/AppointmentConfig'
import { ContactPhoneInput } from '../../components/inputs/ContactPhoneInput'
import { FtlLocationAccessorials } from '../../components/inputs/LocationAccessorials'
import { ShipTypeSelector } from '../../components/inputs/ShipTypeSelector'
import { type FormSectionKey } from '../../features/booking/types'
import { useBookSettings } from '../../hooks/settings'
import { AddressBookButton } from '../addresses/AddressBookButton'
import { ShareShipmentWith } from '../book/ShareShipmentWith'
import { DockTimeInput } from '../shipment-location-form/DockTimeInput'
import { type ShipmentLocationFormModel } from '../shipment-location-form/types'
import { useBookingForm } from './hooks'

type BookingLocationFormProps = {
  onValidSubmit: () => void
  openDatePicker: (e?: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void
  sectionKey: FormSectionKey
  setFormRef: (
    key: FormSectionKey,
    ref: RefObject<HTMLFormElement | null>,
  ) => void
  setValues: (values: Partial<ShipmentLocationFormModel>) => void
  values: ShipmentLocationFormModel
}

export const BookingLocationForm = ({
  onValidSubmit,
  openDatePicker,
  sectionKey,
  setFormRef,
  setValues,
  values,
}: BookingLocationFormProps) => {
  const { settings } = useBookingForm()
  const { shipperNumberRequired } = useBookSettings()
  const formRef = useRef<HTMLFormElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const isRefNumRequired = values.sequence === 0 && shipperNumberRequired
  const isAccountLocation = Boolean(values.accountLocationId)

  useEffect(() => {
    setFormRef(sectionKey, formRef)
  }, [sectionKey, setFormRef])

  const setAddress = useCallback(
    (x: ClientAddressLookupResult) => {
      setValues({
        ...(x.accountLocationId && { accountLocationId: x.accountLocationId }),
        accessorials: x.mappedAccessorials?.truckload ?? [],
        address: x.address || '',
        address2: x.address2 || '',
        addressBookId: x._id,
        adminArea3: x.adminArea3 || '',
        city: x.city || '',
        closesAt: (x.dockHoursEnd as MilitaryTime) ?? defaultCloseTime,
        company: x.company || '',
        contactEmail: x.contactEmail || '',
        contactName: x.contactName || '',
        contactPhone: x.contactPhone?.replace(/\D/g, '') || '', // Remove non-digit characters
        contactPhoneExt: '', // x.contactPhoneExt
        country: x.country.trim().toLowerCase() as Country,
        instructions: x.instructions || '',
        postalCode: x.postalCode || '',
        opensAt: (x.dockHoursStart as MilitaryTime) ?? defaultOpenTime,
        state: x.state || '',
        timezone: x.timezone,
      })
    },
    [setValues],
  )

  function onFieldChange(
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ) {
    setValues({ [e.target.name]: e.target.value })
  }

  function handleEmailChange(e: ChangeEvent<HTMLInputElement>) {
    onFieldChange(e)

    const { value } = e.target
    const isValid = !value || value.match(emailRegExpMatcher)

    emailRef.current?.setCustomValidity(
      isValid ? '' : 'Email address must be valid',
    )
  }

  return (
    <ValidatedForm onValidSubmit={onValidSubmit} ref={formRef}>
      <div className="mb-4">
        <div className="form-row">
          <div className="form-group b1500:basis-4/12 basis-3/12">
            <label htmlFor="company" className="label required">
              Company
            </label>
            <div className="input-group">
              <div className="input-group__prepend">
                <AddressBookButton
                  context="truckload"
                  postalCodeFilter={
                    settings.allowTLPostalCodeChange
                      ? undefined
                      : values.postalCode
                  }
                  setAddress={setAddress}
                />
              </div>
              <TextField
                autoFocus={!values.company}
                className="form-control"
                name="company"
                onChange={onFieldChange as ChangeEventHandler<HTMLInputElement>}
                required
                value={values.company}
              />
            </div>
          </div>

          <div className="b1500:basis-4/12 basis-3/12">
            <ShipTypeSelector
              value={values}
              onChange={({ shipType, accessorials }) =>
                setValues({ shipType, accessorials })
              }
              showLabels
              showDetails
              name={`${sectionKey}-shipType`}
            />
          </div>

          <div className="form-group basis-4/12">
            <label htmlFor="address" className="label required">
              Street address
            </label>
            <TextField
              className="form-control"
              name="address"
              onChange={onFieldChange as ChangeEventHandler<HTMLInputElement>}
              readOnly={isAccountLocation}
              required
              value={values.address}
            />
          </div>

          <div className="form-group b1500:basis-4/12 basis-2/12">
            <label htmlFor="address2" className="label">
              Address 2
            </label>
            <TextField
              className="form-control"
              name="address2"
              onChange={onFieldChange as ChangeEventHandler<HTMLInputElement>}
              value={values.address2}
            />
          </div>

          <div className="form-group b1650:basis-5/12 b1500:basis-4/12 basis-3/12">
            <label htmlFor="cityStateZipCountry" className="label required">
              City, State, Zip, Country
            </label>
            <AddressInput
              context="truckload"
              formatAddress={addressFuncs.cityStateZip}
              addressType="shipping"
              location={values}
              name="cityStateZipCountry"
              onLocationChange={val =>
                setValues({
                  city: val?.city || '',
                  country: val?.country || 'us',
                  postalCode: val?.postalCode || '',
                  state: val?.state || '',
                  timezone: val?.timezone || '',
                  adminArea3: val.adminArea3 || '',
                })
              }
              readOnly={!settings.allowTLPostalCodeChange}
              required
            />
          </div>

          <div className="form-group b1500:basis-4/12 basis-3/12">
            <label htmlFor="contactName" className="label">
              Contact name
            </label>
            <TextField
              className="form-control"
              name="contactName"
              onChange={onFieldChange as ChangeEventHandler<HTMLInputElement>}
              value={values.contactName}
            />
          </div>

          <div className="form-group basis-4/12">
            <label htmlFor="contactEmail" className="label">
              Contact email
            </label>
            <TextField
              className="form-control"
              name="contactEmail"
              onChange={handleEmailChange}
              type="email"
              ref={emailRef}
              value={values.contactEmail}
            />
          </div>

          <ContactPhoneInput
            className="b1650:basis-3/12 b1300:basis-4/12 basis-2/12"
            name="contactPhone"
            onChange={setValues}
            values={values}
          />

          <div className="form-group b1650:flex-1 b1500:basis-4/12 basis-2/12">
            <label
              htmlFor="refNum"
              className={clsx('label', { required: isRefNumRequired })}
            >
              Reference#
            </label>
            <TextField
              className="form-control"
              name="refNum"
              onChange={onFieldChange as ChangeEventHandler<HTMLInputElement>}
              required={isRefNumRequired}
              value={values.refNum}
            />
          </div>
          <div className="basis-auto">
            <DockTimeInput
              className="form-group--booking-ready"
              label="Ready time"
              value={values}
              name="opensAt"
              onChange={setValues}
              required
            />
          </div>
          <div className="basis-auto">
            <DockTimeInput
              className="form-group--booking-close"
              label="Close time"
              value={values}
              name="closesAt"
              onChange={setValues}
              required
            />
          </div>

          <div className="form-group b1650:basis-full b1500:flex-1 flex-1">
            <label htmlFor="instructions" className="label">
              Special instructions
            </label>
            <TextField
              className="form-control"
              name="instructions"
              onChange={onFieldChange as ChangeEventHandler<HTMLInputElement>}
              placeholder="i.e. Please use dock number 3 -or- Use east entrance"
              value={values.instructions}
            />
          </div>
        </div>

        <FtlLocationAccessorials
          accessorials={values.accessorials}
          className={clsx(
            'route-accessorial-options route-accessorial-options--booking',
            {
              'mb-8': !!values.accessorials.find(
                a => a.key === apptRequiredKey,
              ),
            },
          )}
          onChange={accessorials => setValues({ accessorials })}
          openDatePicker={openDatePicker}
        />
        <ShareShipmentWith
          className="mt-4"
          location={values}
          onChange={({ copyBOL, shares }) => setValues({ copyBOL, shares })}
        />
        <hr className="mb-8" />
      </div>
      <div className="flex items-center gap-4">
        <FvButton theme="secondary" icon="arrow-down-to-line" fwd type="submit">
          Looks good, proceed
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
