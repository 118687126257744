import clsx from 'clsx'
import dayjs from 'dayjs'
import { Fragment, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useToggle } from 'usehooks-ts'

import gmapsIcon from '@fv/client-assets/gmaps-icon.png'
import {
  FvButton,
  FvLinkButton,
  Icon,
  type IconProps,
  PhoneNumberDisplay,
  TextField,
  Tooltip,
  useFvNavigate,
  ValidatedForm,
} from '@fv/client-components'
import { showApiError } from '@fv/client-core'
import {
  type FullShipment,
  type ShipmentLocation,
  type UIQuote,
} from '@fv/client-types'
import { type Mode } from '@fv/models'

import InfoBox from '../../components/InfoBox'
import { useUserSettings } from '../../hooks/settings'
import { useCarrierPublicSettings } from '../../hooks/useCarrierPublicSettings'
import { routes } from '../../routes'
import { getCarrierDetails, getOrigin } from '../../utils/shipmentFuncs'
import { usePermissions } from '../auth'
import { HideForVendor } from '../auth/HasRole'
import { useRole } from '../auth/hooks'
import { VendorBookPanel } from '../book/VendorBookPanel'
import { quoteFuncs } from './quoteFuncs'
import { RateCharges } from './RateCharges'
import { RatePriceDisplay } from './RatePriceDisplay'
import { useVendorBookRate } from './useBookRate'
import { useSaveQuote } from './useSaveQuote'
import { type Terminal, useTerminals } from './useTerminals'

type PendingRateProps = {
  carrierName: string
}

export const PendingRate = ({ carrierName }: PendingRateProps) => {
  return (
    <div className="ltl-rates-ctn__group">
      <div className="ltl-rates-ctn__rate mt-4 flex flex-nowrap">
        <InfoBox
          className="mb-0 flex w-full items-center justify-between"
          custom
        >
          <div>
            {carrierName}
            <div className="text-xs">Your direct rate</div>
          </div>
          <Icon icon="sync" />
        </InfoBox>
      </div>
    </div>
  )
}

type RateTagProps = {
  children: string
  icon?: IconProps['icon']
  iconClass?: string
  tooltip?: string
  className?: string
}

const RateTag = ({
  children,
  icon,
  iconClass,
  tooltip,
  className,
}: RateTagProps) => {
  return (
    <Tooltip label={tooltip}>
      <div
        className={`bg-fv-blue-200 absolute right-0 top-0 border border-r-0 border-t-0 border-fv-blue-300 px-1 py-px text-sm leading-4 ${className}`}
      >
        {!!icon && <Icon icon={icon} className={iconClass} />}
        <span className={clsx({ 'ml-1': !!icon })}>{children}</span>
      </div>
    </Tooltip>
  )
}

const modeNames: Record<Mode, string> = {
  air: 'Air',
  bulk: 'Bulk',
  consolidated: 'Consolidated',
  intermodal: 'Intermodal',
  ltl: 'LTL',
  ocean: 'Ocean',
  parcel: 'Parcel',
  truckload: 'Truckload',
}

const ServiceDescription = ({ rate }: { rate: UIQuote }) => {
  let description = ''

  const {
    assetCarrierName,
    deliveryDate,
    guaranteed,
    method,
    mode,
    pricingDescription,
    pricingType,
    providerName,
    serviceDescription,
    endOfDay,
  } = rate

  if (method === 'manual') {
    description =
      pricingType === 'spot' ? 'Spot quote' : (serviceDescription ?? '')

    if (description) description += ' / '
  }

  description += modeNames[mode]

  if (pricingDescription) {
    description += ` (${pricingDescription})`
  }

  if (assetCarrierName && providerName && assetCarrierName !== providerName) {
    description += ` via ${providerName}`
  }

  if (deliveryDate) {
    const date = dayjs.utc(deliveryDate)
    const timeFormat = date.minute() === 0 ? 'hA' : 'h:mmA'

    if (endOfDay) {
      description += ` by End of Day ${date.format('MMM D')}`
    } else if (guaranteed) {
      description += ` by ${date.format(timeFormat)} ${date.format('MMM D')}`
    }
  }

  return (
    <p className="m-0 overflow-hidden text-ellipsis whitespace-nowrap pr-6">
      {getCarrierDetails({ selectedQuote: rate })}
      <br />
      <span
        className={clsx('whitespace-nowrap text-fv-beer-dark text-sm', {
          '!text-fv-gray-600': rate.method === 'manual',
        })}
      >
        {description}
      </span>
    </p>
  )
}

type TerminalDetailsProps = {
  locations: ShipmentLocation[]
  terminals?: UIQuote['terminals']
}

const TerminalDetails = ({ locations, terminals }: TerminalDetailsProps) => {
  const containerClass = 'relative grow bg-[#fefdf4] p-6'
  const terminalsQuery = useTerminals(terminals)

  if (!terminals || !terminals.length || terminalsQuery.loading) {
    return (
      <div className={containerClass}>
        {terminalsQuery.loading && (
          <>
            <Icon icon="sync" />
            <span>Loading terminals...</span>
          </>
        )}
      </div>
    )
  }

  const terminalMapUrl = getTerminalMapUrl(locations, terminalsQuery.data)

  return (
    <div className={containerClass}>
      {terminalMapUrl && (
        <div className="absolute right-0 top-0">
          <a
            className="block border border-r-0 border-t-0 border-[#d8cfaf] bg-white p-2"
            href={terminalMapUrl}
            rel="noreferrer"
            target="_blank"
            title="Get directions to a terminal"
          >
            <img alt="Google Maps" className="w-5" src={gmapsIcon} />
          </a>
        </div>
      )}

      <div className="flex">
        {terminalsQuery.data.map((t, ix) => (
          <Fragment key={t._id}>
            <p className="mb-0">
              Terminal code: {t.terminalCode}
              <br />
              {t.address}
              {t.address2 && <br />}
              {t.address2}
              <br />
              {t.city} {t.state}, {t.postalCode}
              {t.contactPhone && <br />}
              {t.contactPhone && <PhoneNumberDisplay val={t.contactPhone} />}
            </p>

            {ix < terminalsQuery.data.length - 1 && (
              <div className="relative mx-12 w-px bg-[#d8cfaf]">
                <div className="absolute -left-4 top-1/2 flex h-8 w-8 -translate-y-2/4 items-center justify-center rounded-full border-2 border-[#bdd5b0] bg-[#ffffff] text-sm">
                  To
                </div>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

type RateRowProps = {
  expanded: boolean
  isAdditionalRate?: boolean
  isShowingAll?: boolean
  load: FullShipment
  locations: ShipmentLocation[]
  moreRatesCount?: number
  rate: UIQuote
  saveRateId: string
  isReadOnly?: boolean
  highlight: boolean
  pickupDate: string
  setSaveRateId: (id: string) => void
  toggleShowAll?: () => void
}

// rate-item-ltl.jade
export const RateRow = ({
  expanded,
  isAdditionalRate = false,
  isShowingAll,
  load,
  locations,
  moreRatesCount,
  rate,
  saveRateId,
  isReadOnly,
  highlight,
  pickupDate,
  setSaveRateId,
  toggleShowAll,
}: RateRowProps) => {
  const origin = getOrigin({ locations })
  const [saveQuoteRefNum, setSaveQuoteRefNum] = useState(
    origin?.refNums?.[0]?.value || '',
  )
  const [showBreakdown, setShowBreakdown] = useState(expanded)
  const [showVendorBook, toggleVendorBook] = useToggle()
  const role = useRole()
  const navigate = useFvNavigate()
  const { canBook, canViewRates } = usePermissions()
  const hasRateMod = !!useUserSettings().data?.rateModifier
  const saveQuote = useSaveQuote()
  const { carrierPublicSettings } = useCarrierPublicSettings(rate.carrierId)
  const rateCanBeBooked =
    carrierPublicSettings &&
    (carrierPublicSettings.allowBolOnly ||
      carrierPublicSettings.dispatchMethod !== 'disabled')
  const bookRate = useVendorBookRate()

  useEffect(() => {
    setShowBreakdown(expanded)
  }, [expanded])

  if (rate.status === 'requesting' && rate.method !== 'manual') {
    return <PendingRate carrierName={rate.providerName} />
  }

  const hasCharges = !!rate.charges?.length
  const hasQuoteNum = !!rate.quoteNum
  const hasTerminalDetails = !!rate.terminals?.length
  const hasBreakdown =
    (hasCharges || hasQuoteNum || hasTerminalDetails) && canViewRates
  const showRateDetails = hasBreakdown && showBreakdown
  const estDelivery = quoteFuncs.getEstimatedDeliveryDate(pickupDate, rate)
  const isImmediateBook = load.workflow === 'truckload' && role === 'vendor'

  const handleSaveQuoteSubmit = async () => {
    if (saveQuote.isLoading) return

    saveQuote.mutate(
      {
        loadId: load.loadId,
        quoteId: rate._id,
        refNum: saveQuoteRefNum,
      },
      {
        onSuccess: () => {
          toast.success('Quote saved')
          navigate(routes.quote('ltl'))
        },
        onError: e => showApiError('Unable to save quote', e),
      },
    )
  }

  const handleRateSelect = async (rate: UIQuote) => {
    if (isReadOnly || !canBook || rate.status === 'requesting') {
      return
    }

    if (!rateCanBeBooked) {
      toast.error(
        `This carrier doesn't support booking shipments through Freightview.`,
      )
      return
    }

    if (isImmediateBook) {
      await bookRate.mutateAsync({
        loadId: load.loadId,
        quoteId: rate._id,
        schedulePickup: true,
      })
      navigate(routes.details(load.loadId))
      return
    } else if (role === 'vendor') {
      toggleVendorBook()
    } else {
      navigate(
        routes.book({
          loadId: load.loadId,
          quoteId: rate._id,
        }),
      )
    }
  }

  return (
    <>
      <div
        className={clsx(`ltl-rates-ctn__rate flex flex-nowrap`, {
          grayscale: isReadOnly && !highlight,
        })}
      >
        <div className="flex min-w-0 grow basis-full items-stretch border border-gray-300">
          <div className="flex w-20 flex-shrink-0 flex-col items-stretch text-center">
            <div className="border-b border-gray-300 bg-[#f3fbff] py-1 text-xs">
              {rate.equipmentType.toLocaleUpperCase()}
            </div>
            <div className="flex flex-1 items-center justify-center bg-white py-2">
              <Icon
                icon={rate.equipmentType === 'parcel' ? 'dolly' : 'forklift'}
                className="text-fv-gray-dark"
                transform="shrink-2"
              />
            </div>
            <div />
          </div>
          <div
            className={clsx(
              'ltl-rates-ctn__service relative flex flex-1 items-center overflow-hidden border-l border-l-gray-300 px-4 py-3 group',
              rate.method === 'manual' ? 'bg-fv-blue-50' : 'bg-fv-beer-xlight',
              {
                'cursor-pointer': !!toggleShowAll,
                'hover:bg-fv-beer-light': moreRatesCount,
              },
            )}
            onClick={toggleShowAll}
          >
            <ServiceDescription rate={rate} />

            {!!moreRatesCount && (
              <div className="ml-auto flex whitespace-nowrap">
                {!isShowingAll ? (
                  <Icon
                    icon="chevron-down"
                    className="text-fv-orange-bright"
                    transform="grow-4"
                  />
                ) : (
                  <Icon
                    icon="chevron-up"
                    className="text-fv-orange-bright"
                    transform="grow-4"
                  />
                )}
                <span>+ {moreRatesCount}</span>
              </div>
            )}

            {rate.interline && (
              <RateTag
                icon="random"
                iconClass="text-fv-orange group-hover:!text-fv-orange-bright"
                className="!bg-fv-beer-light !border-fv-beer-medium"
              >
                Interline
              </RateTag>
            )}

            {rate.method === 'manual' && !quoteFuncs.isSpotWorkflow(rate) && (
              <RateTag tooltip="Manually entered rate">M</RateTag>
            )}

            {quoteFuncs.isSpotWorkflow(rate) && (
              <RateTag tooltip="Spot quote rate">S</RateTag>
            )}
          </div>
          <div
            className={clsx(
              'flex w-28 items-center justify-center border-x border-x-gray-300 px-4',
              rate.method === 'manual' ? 'bg-fv-blue-50' : 'bg-fv-green-50',
            )}
          >
            {rate.transitDaysMin ? (
              <>
                <Tooltip
                  label={
                    !!estDelivery &&
                    `Est. delivery date: ${estDelivery
                      .local()
                      .format('MMM DD')}`
                  }
                >
                  <div
                    className={clsx(
                      'text-l flex h-[2rem] w-[2rem] items-center justify-center rounded-full border-2 border-[#bdd5b0] bg-white text-center',
                      { 'border-[#9ab0b7]': rate.method === 'manual' },
                    )}
                  >
                    {rate.transitDaysMin}
                    {rate.interline && '+'}
                  </div>
                </Tooltip>
                <span className="ml-1">days</span>
              </>
            ) : (
              <Icon className="text-fv-gray-500" icon="dash" />
            )}
          </div>

          <div
            onClick={() => {
              if (rate.status === 'requesting') return
            }}
            className={clsx(
              'relative flex w-[13rem] items-center border-r border-x-gray-300 px-8 text-black',
              {
                'bg-fv-blue-100': rate.method === 'manual',
                'bg-fv-green-150':
                  !isAdditionalRate && rate.method !== 'manual',
                'bg-[#efffe8]': isAdditionalRate && rate.method !== 'manual',
              },
              isReadOnly && 'border-r-0',
            )}
          >
            <div className="ml-auto flex justify-center items-center">
              <div>
                <div
                  className={clsx('price font-oxygen text-[1.7rem]', {
                    'cursor-pointer': !isReadOnly && canBook,
                  })}
                  onClick={() => handleRateSelect(rate)}
                >
                  <RatePriceDisplay rate={rate} />
                </div>
                {rate.expirationDate && (
                  <div className="text-xs border-t pt-[.1rem] border-fv-green-500 border-dotted mt-[.1rem] text-right">
                    Expires on {dayjs.utc(rate.expirationDate).format('MMM D')}
                  </div>
                )}
              </div>
            </div>

            {hasBreakdown && (
              <FvLinkButton
                className="absolute left-0 top-0 cursor-pointer border border-l-0 border-t-0 border-[#bdd5b0] bg-white px-[.35rem] py-[.2rem]"
                icon="ellipsis-v"
                iconClass="text-fv-orange"
                onClick={e => {
                  setShowBreakdown(p => !p)
                  e.stopPropagation()
                }}
                transform="grow-4"
              />
            )}
          </div>
          {!isReadOnly && canBook && (
            <FvButton
              title={
                rateCanBeBooked
                  ? undefined
                  : `This carrier doesn't support booking through Freightview`
              }
              className="flex w-32 flex-shrink-0 cursor-pointer items-center justify-center bg-white py-4 hover:bg-fv-beer-light"
              icon={rate.status === 'requesting' ? 'sync' : 'arrow-left'}
              onClick={() => handleRateSelect(rate)}
              loading={bookRate.isLoading}
              transform="up-1"
            >
              {!isImmediateBook && rate.status !== 'requesting' && (
                <span>Choose</span>
              )}
              {isImmediateBook && <span>Award</span>}
            </FvButton>
          )}
        </div>

        {!isReadOnly && (
          <HideForVendor>
            <FvLinkButton
              className="flex w-8 flex-shrink-0 items-center justify-end"
              icon="star-sharp"
              onClick={() => setSaveRateId(rate._id)}
              title="Save as favorite"
            />
          </HideForVendor>
        )}
      </div>

      {showRateDetails && (
        <div
          className={clsx('ltl-rates-ctn__breakdown -mt-px mb-4 flex', {
            grayscale: isReadOnly && !highlight,
          })}
        >
          <div className="flex grow items-stretch border border-gray-300">
            <TerminalDetails locations={locations} terminals={rate.terminals} />

            <div className="relative w-[25rem] border-l border-l-gray-300 bg-fv-green-50">
              {(canBook || !hasRateMod) && (
                <RateCharges charges={rate.charges} amount={rate.amount} />
              )}

              {rate.quoteNum && (
                <div className="border-t border-t-gray-300 bg-fv-green-100 p-6">
                  Carrier quote number is {rate.quoteNum}
                </div>
              )}

              <div
                className={clsx(
                  'absolute -top-px w-8 -translate-x-2/4 transform overflow-hidden',
                  !isReadOnly ? 'left-2/4' : 'left-3/4',
                )}
              >
                <div
                  className={clsx(
                    ' h-4 w-4 origin-top-left -rotate-45 transform border border-gray-300',
                    {
                      'bg-[#ceeefd]': rate.method === 'manual',
                      'bg-[#e4f9d9]':
                        !isAdditionalRate && rate.method !== 'manual',
                      'bg-[#efffe8]':
                        isAdditionalRate && rate.method !== 'manual',
                    },
                  )}
                />
              </div>
            </div>
          </div>
          {!isReadOnly && <div className="w-8" />}
        </div>
      )}

      {saveRateId === rate._id && (
        <div className="relative flex">
          <div className="-mb-[1px] -mt-px flex w-full grow items-center space-x-4 border border-gray-300 bg-white px-8 py-4">
            Save this rate for later access
            <Icon icon="arrow-right" className="text-fv-gray-dark ml-2" />
            <ValidatedForm
              className="input-group w-[24rem]"
              onValidSubmit={handleSaveQuoteSubmit}
            >
              <TextField
                autoFocus
                className="form-control"
                name="refNum"
                placeholder="Enter a reference number"
                onChange={e => setSaveQuoteRefNum(e.target.value)}
                value={saveQuoteRefNum}
                required
              />

              <div className="input-group__append">
                <FvButton
                  theme="default"
                  icon={saveQuote.isLoading ? 'spinner' : 'star-sharp'}
                  type="submit"
                  transform="up-1"
                >
                  Save
                </FvButton>

                <FvButton
                  icon="times"
                  onClick={() => setSaveRateId('')}
                  theme="plain"
                  type="button"
                />
              </div>
            </ValidatedForm>
          </div>
          <div className="w-8" />
          <div className="absolute -top-px left-40 w-8 overflow-hidden">
            <div
              className={clsx(
                'h-4 w-4 origin-top-left -rotate-45 transform border border-gray-300',
                {
                  'bg-[#ebf7fb]': rate.method === 'manual' && !showRateDetails,
                  'bg-fv-beer-light':
                    rate.method !== 'manual' || showRateDetails,
                },
              )}
            />
          </div>
        </div>
      )}

      <VendorBookPanel
        isOpen={showVendorBook}
        closePanel={toggleVendorBook}
        loadId={load.loadId}
        quote={rate}
      />
    </>
  )
}

type RateGroupProps = {
  expanded: boolean
  load: FullShipment
  locations: ShipmentLocation[]
  rates: UIQuote[]
  saveRateId: string
  setSaveRateId: (id: string) => void
  isReadOnly?: boolean
  pickupDate: string
}

export const RateGroup = ({
  expanded,
  load,
  locations,
  rates,
  saveRateId,
  isReadOnly,
  pickupDate,
  setSaveRateId,
}: RateGroupProps) => {
  const [showAllRates, setShowAllRates] = useState(expanded)
  const hasMoreRates = rates.length > 1
  const primaryRate = rates[0]
  const toggleShowAll = hasMoreRates
    ? () => setShowAllRates(p => !p)
    : undefined

  useEffect(() => {
    setShowAllRates(expanded)
  }, [expanded])

  const rowProps = {
    expanded,
    load,
    locations,
    saveRateId,
    isReadOnly,
    setSaveRateId,
    toggleShowAll,
    pickupDate,
  }

  return (
    <div
      className={clsx(
        'mb-3 relative',
        showAllRates &&
          'after:absolute after:w-2 after:inset-y-3 after:border-r-0 after:-left-3 after:border-fv-orange after:border',
      )}
    >
      <RateRow
        {...rowProps}
        isShowingAll={showAllRates}
        moreRatesCount={rates.length - 1}
        rate={primaryRate}
        highlight={
          (!showAllRates && rates.some(r => r.status === 'awarded')) ||
          primaryRate.status === 'awarded'
        }
      />

      {showAllRates &&
        rates
          .slice(1)
          .map(r => (
            <RateRow
              {...rowProps}
              isAdditionalRate
              key={r._id}
              rate={r}
              highlight={r.status === 'awarded'}
            />
          ))}
    </div>
  )
}

function getTerminalMapUrl(
  locations: ShipmentLocation[],
  terminals: Terminal[],
) {
  const originTerminal = terminals[0]
  const destTerminalIx = terminals.length - 1
  const destTerminal = terminals[destTerminalIx]

  if (
    !originTerminal.lat ||
    !originTerminal.lng ||
    !destTerminal.lat ||
    !destTerminal.lng
  ) {
    return null
  }

  const origin = locations[0]
  const destIx = locations.length - 1
  const dest = locations[destIx]

  let terminalMapUrl = 'https://www.google.com/maps/dir'
  terminalMapUrl += `/${origin.lat},${origin.lng}`
  terminalMapUrl += `/${originTerminal.lat},${originTerminal.lng}`
  terminalMapUrl += `/${destTerminal.lat},${destTerminal.lng}`
  terminalMapUrl += `/${dest.lat},${dest.lng}`

  return terminalMapUrl
}
